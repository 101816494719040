import React, { useState } from 'react'
import styles from "./index.module.css";
import { notification } from 'antd';
import { useMutation } from '@apollo/client/react';
import { FORGOT_PASSWORD } from './Graphql/Mutations';
import MainHeader from "../../Components/Header";
import ForgotPasswordHeader from './Components/ForgotPasswordHeader'
import ForgotPasswordForm from './Components/ForgotPasswordForm'

const ForgotPasswordScreen = () => {

    const [email, setEmail] = useState('')
    const [forgotPassword, {loading: loadingForgotPassword}] = useMutation(FORGOT_PASSWORD, {
      variables:{
        input: {
          email: email
        }
      }
    });

    const handleForgotPassword = () => {
        forgotPassword().then(() => {
          notification.success({
            message: "Éxito",
            description: "Si tu cuenta se encuentra registrada, recibirás un correo que te permitirá cambiar tu contraseña",
            placement: "bottomRight",
          });
        }).catch(e => {
          console.log(e)
        })
    }

    const formValidation = () => {
        if (email.trim() === "") {
          notification.error({
            message: "Error",
            description: "Debe completar todos los campos",
            placement: "bottomRight",
          });
          return false;
        }
        return true;
      };

    return (
        <>
        <MainHeader />
        <div className={styles.container}>
            <ForgotPasswordHeader />
            <ForgotPasswordForm
                email={email}
                setEmail={setEmail}
                formValidation={formValidation}
                handleForgotPassword={handleForgotPassword}
                loadingForgotPassword={loadingForgotPassword}
            />
        </div>
        </>
    )
}

export default ForgotPasswordScreen