import gql from "graphql-tag";

const VERIFY_ACCOUNT = gql`
mutation verifyAccount($token: String!){
    verifyAccount(token: $token) {
      _id
      firstName
      lastName
      email
    }
  }
`;

export { VERIFY_ACCOUNT }