import React from "react";
import styles from "./index.module.css";
import { Collapse, Table, Empty } from "antd";
import deleteIcon from "../../Assets/delete-icon.svg";

const { Panel } = Collapse;

const Employees = ({ columns, data, handleDeleteEmployeeModal }) => {
  return (
    <>
      <Table className={styles.table} dataSource={data} columns={columns} />
      <Collapse
        className={styles.accordion}
        expandIconPosition="right"
        accordion
      >
        <h3 className={styles.accordionTitle}>Correo</h3>
        {data?.length > 0 ? (
          data?.map((employee, index) => (
            <Panel
              header={
                <h3 className={styles.accordionPanelTitle}>{employee.email}</h3>
              }
              key={index}
              className={styles.accordionPanel}
            >
              <div className={styles.accordionContent}>
                <div className={styles.accordionContentItem}>
                  <h4 className={styles.accordionContentItemTitle}>Nombre:</h4>
                  <p className={styles.tableName}>{employee?.firstName}</p>
                </div>
                <div className={styles.accordionContentItem}>
                  <h4 className={styles.accordionContentItemTitle}>
                    Apellido:
                  </h4>
                  <p className={styles.tableName}>{employee?.lastName}</p>
                </div>
                <div className={styles.tableActions}>
                  <button
                    className={styles.tableActionsButton}
                    onClick={() => handleDeleteEmployeeModal(employee)}
                  >
                    <img src={deleteIcon} alt="" />
                  </button>
                </div>
              </div>
            </Panel>
          ))
        ) : (
          <Empty description="No hay empleados" className={styles.accordionEmpty}  />
        )}
      </Collapse>
    </>
  );
};

export default Employees;
