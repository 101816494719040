import React from "react";
import styles from "./index.module.css";
import UserIcon from "../../../../Assets/yellow-user-icon.svg";

const LoginHeader = () => {
  return (
    <header className={styles.header}>
      <img src={UserIcon} alt="" className={styles.headerIcon} />
      <h2 className={styles.headerTitle}>Inicia sesión</h2>
    </header>
  );
};

export default LoginHeader;
