import gql from "graphql-tag";

const CREATE_PROMOTION = gql`
mutation createPromotion($input: CreatePromotionInput!, $localImage: Upload, $promoImage: Upload){
    createPromotion(input: $input, localImage: $localImage, promoImage: $promoImage){
      _id
    }
  }
`;

const UPDATE_PROMOTION = gql`
mutation updatePromotion($id: FindPromotion!, $input: CreatePromotionInput!, $promoImage: Upload, $localImage: Upload){
  updatePromotion(id: $id, input: $input, promoImage: $promoImage, localImage: $localImage){
    _id
  }
}
`;

const DELETE_PROMOTION = gql`
mutation deletePromotion($input: FindPromotion!){
  deletePromotion(input: $input){
    _id
  }
}
`;

export { CREATE_PROMOTION, UPDATE_PROMOTION, DELETE_PROMOTION }