import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { Row, Spin, Table, Rate, notification, Collapse } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { COMMENTS_BY_COMPANY } from "../../Graphql/Queries";
import { TOGGLE_COMMENT_STATUS, DELETE_COMMENT } from "../../Graphql/Mutations";
import { getUser, setAccessToken } from "../../../../Helpers/auth";
import WarningModal from "../WarningModal";
import ReviewModal from "../ReviewModal";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import * as dayjs from "dayjs";
import "dayjs/locale/es";
import readIcon from "../../Assets/read-icon.svg";
// import deleteIcon from "../../Assets/delete-icon.svg";
import Switch from "../../../../Components/Switch";
dayjs.locale("es");

const { Panel } = Collapse;

const PromotionsTableReviews = () => {
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [review, setReview] = useState({});
  const {
    refetch,
    loading: loadingTable,
    error: errorTable,
    data: dataTable,
  } = useQuery(COMMENTS_BY_COMPANY, {
    variables: {
      companyId: getUser().company
        ? getUser().company._id
        : JSON.parse(localStorage.getItem("companySelected")) === "all" &&
          JSON.parse(localStorage.getItem("companySelected")) !== null
        ? ""
        : JSON.parse(localStorage.getItem("companySelected")),
      input: {
        page: 1,
        limit: 10000,
      },
    },
  });
  const [toggleCommentStatus] = useMutation(TOGGLE_COMMENT_STATUS);
  const [deleteComment] = useMutation(DELETE_COMMENT);

  const [refreshToken] = useMutation(REFRESH_TOKEN);

  useEffect(() => {
    const getData = () => {
      refreshToken().then((res) => {
        setAccessToken(res.data.refreshToken.token);
        refetch();
      });
    };
    getData();
  }, [refreshToken, refetch]);

  const handleReviewModal = (review) => {
    setReview({ ...review, company: getUser().company });
    setIsReviewModalVisible(true);
  };

  // const handleWarningModal = (review) => {
  //   setReview({ ...review, company: getUser().company });
  //   setIsWarningModalVisible(true);
  // };

  const handleDeleteComment = (commentId) => {
    try {
      deleteComment({
        variables: {
          commentId: commentId,
        },
      }).then(() => {
        notification.success({
          message: "Comentario eliminado",
        });
        setIsWarningModalVisible(false);
        refetch();
      });
    } catch (error) {
      console.log("Error: ", error);
      notification.error({
        message: "Error al eliminar el comentario",
      });
    }
  };

  const handleToogleCommentStatus = (comment) => {
    try {
      toggleCommentStatus({
        variables: {
          visible: !comment.visible,
          commentId: comment._id,
        },
      }).then((res) => {
        notification.success({
          message: "Comentario actualizado",
        });
        setIsReviewModalVisible(false);
        refetch();
      });
    } catch (error) {
      console.log("Error: ", error);
      notification.error({
        message: "Error al actualizar el comentario",
      });
    }
  };

  const columns = [
    {
      title: "Promoción",
      dataIndex: "promotion",
      key: "promotion",
      render: (promotion) => (
        <p className={styles.tableName}>
          {promotion.discount}% de descuento con {promotion.localName}
        </p>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "user",
      key: "user",
      render: (user) => <p className={styles.tableName}>{user.firstName}</p>,
    },
    {
      title: "Creado",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <p className={styles.tableName}>
          {dayjs(createdAt).format("DD-MM-YYYY", "es")}
        </p>
      ),
    },
    {
      title: "Ranking",
      dataIndex: "stars",
      key: "stars",
      render: (stars) => (
        <Rate disabled value={stars} style={{ fontSize: "1rem" }} />
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <div className={styles.tableDeskActions}>
          <button
            onClick={() => handleReviewModal(record)}
            className={styles.tableActionsButton}
          >
            <img src={readIcon} alt="" />
          </button>
          {/* <button
            className={styles.tableActionsButton}
            onClick={() => handleWarningModal(record)}
          >
            <img src={deleteIcon} alt="" />
          </button> */}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "visible",
      key: "visible",
      render: (visible, record) => (
        <div onClick={() => handleToogleCommentStatus(record)}>
          <Switch
            isActive={visible}
            activeText={"Visible"}
            desactiveText={"No visible"}
          />
        </div>
      ),
    },
  ];

  const checkSuperAdminColumn = () => {
    if (getUser().role === "SuperAdmin") {
      columns.splice(2, 0, {
        title: "Empresa",
        dataIndex: "promotion",
        key: "promotion",
        render: (promotion, _record) => (
          <p className={styles.tableName}>{promotion.company.name}</p>
        ),
      });
      return columns;
    } else return columns;
  };

  if (loadingTable)
    return (
      <Row justify="center" align="center">
        <Spin />
      </Row>
    );
  else if (errorTable) return <p>Hubo un error al intentar cargar la data</p>;
  if (dataTable)
    return (
      <>
        <WarningModal
          isVisible={isWarningModalVisible}
          onCancel={() => setIsWarningModalVisible(false)}
          onConfirm={() => handleDeleteComment(review._id)}
        />
        <ReviewModal
          isVisible={isReviewModalVisible}
          onCancel={() => setIsReviewModalVisible(false)}
          onConfirm={handleToogleCommentStatus}
          review={review}
          type={!review.visible ? "Publicar" : "Ocultar"}
        />
        <Table
          rowKey={(col) => col._id}
          columns={checkSuperAdminColumn()}
          dataSource={dataTable?.commentsByCompany?.data}
          className={styles.table}
          style={{ marginTop: "35px" }}
        />
        <Collapse
          className={styles.accordion}
          expandIconPosition="right"
          accordion
        >
          <header className={styles.accordionHeader}>
            <h2>Nombre</h2>
            <h2>Rating</h2>
          </header>
          {dataTable?.commentsByCompany?.data?.map((comment, index) => (
            <Panel
              header={
                <div className={styles.panelHeader}>
                  <h3>{comment.user.firstName}</h3>
                  <Rate
                    className={styles.panelHeaderRate}
                    disabled
                    value={comment.stars}
                    style={{ fontSize: "1rem" }}
                  />
                </div>
              }
              key={index}
              className={styles.accordionPanel}
            >
              <div className={styles.accordionContent}>
                <div className={styles.accordionContentItem}>
                  <h4 className={styles.accordionContentItemTitle}>
                    Promoción:
                  </h4>
                  <p className={styles.tableName}>
                    {comment.promotion.discount}% de descuento con{" "}
                    {comment.promotion.localName}
                  </p>
                </div>
                <div className={styles.accordionContentItem}>
                  <h4 className={styles.accordionContentItemTitle}>Creado:</h4>
                  <p className={styles.tableName}>
                    {dayjs(comment.createdAt).format("DD-MM-YYYY", "es")}
                  </p>
                </div>
                <div className={styles.tableActions}>
                  <button
                    onClick={() => handleReviewModal(comment)}
                    className={styles.tableActionsButton}
                  >
                    <img src={readIcon} alt="" />
                  </button>
                  {/* <button
                    className={styles.tableActionsButton}
                    onClick={() => handleWarningModal(comment)}
                  >
                    <img src={deleteIcon} alt="" />
                  </button> */}
                  <span
                    className={styles.switchContainer}
                    onClick={() => handleToogleCommentStatus(comment)}
                  >
                    <Switch
                      isActive={comment.visible}
                      activeText={"Visible"}
                      desactiveText={"No visible"}
                    />
                  </span>
                </div>
              </div>
            </Panel>
          ))}
        </Collapse>
      </>
    );
};

export default PromotionsTableReviews;
