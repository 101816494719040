import { notification } from "antd";
import checkAge from "../../../Helpers/checkAge";
import regex from "../Utils/regex";

const formValidation = (formData) => {
  if (
    formData.firstName?.trim() === "" ||
    formData.lastName?.trim() === "" ||
    formData.email?.trim() === "" ||
    formData.bithDate?.trim() === "" ||
    formData.gender?.trim() === "" ||
    formData.password?.trim() === "" ||
    formData.confirmPassword?.trim() === ""
  ) {
    return notification.error({
      message: "Error",
      description: "Debe completar todos los campos",
      placement: "bottomRight",
    });
  }
  if (
    formData.firstName.trim().length < 2 ||
    formData.lastName.trim().length < 2
  ) {
    return notification.error({
      message: "Error",
      description:
        "Los campos de nombre y apellido deben tener al menos 2 letras",
      placement: "bottomRight",
    });
  }
  if (
    !regex.firstName.test(formData.firstName.trim()) ||
    !regex.lastName.test(formData.lastName.trim())
  ) {
    return notification.error({
      message: "Error",
      description: "Los campos de nombre y apellido deben contener solo letras",
      placement: "bottomRight",
    });
  }
  if (!checkAge(formData.birthDate.trim())) {
    return notification.error({
      message: "Error",
      description: "Debe tener al menos 18 años para poder registrarse",
      placement: "bottomRight",
    });
  }
  if (!regex.email.test(formData.email.trim())) {
    return notification.error({
      message: "Error",
      description:
        "El correo ingresado no es válido, debe ingresar un formato de correo electrónico válido, ejemplo: correo@dominio.com",
      placement: "bottomRight",
    });
  }
  if (
    formData.password.trim().length < 6 ||
    formData.password.trim().length > 12
  ) {
    return notification.error({
      message: "Error",
      description: "La contraseña debe tener entre 6 y 12 caracteres",
      placement: "bottomRight",
    });
  }
  if (formData.password.trim() !== formData.confirmPassword.trim()) {
    return notification.error({
      message: "Error",
      description: "Las contraseñas no coinciden",
      placement: "bottomRight",
    });
  }

  return true;
};

export default formValidation;
