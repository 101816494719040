import React from "react";
import styles from "./index.module.css";
import RegisterIcon from "../../Assets/register-icon.svg";

const RegisterHeader = () => {
  return (
    <header className={styles.header}>
      <img src={RegisterIcon} alt="" className={styles.headerIcon} />
      <h2 className={styles.headerTitle}>
        Unete a la red de <span>empresas de</span> LOIA
      </h2>
    </header>
  );
};

export default RegisterHeader;
