import React from "react";
import { Modal, Rate } from "antd";
import styles from "./index.module.css";
import closeIcon from "./Assets/closeIcon.svg";

const ReviewModal = ({
  isVisible,
  onCancel,
  onConfirm,
  type = "Publicar",
  review,
}) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      style={{ top: 50 }}
      closeIcon={
        <img
          src={closeIcon}
          alt="close icon"
          style={{
            display: "none",
          }}
        />
      }
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Comentario</h2>
        <button className={styles.headerCloseButton} onClick={onCancel}>
          <img src={closeIcon} alt="close icon" />
        </button>
      </header>

      <section className={styles.rating}>
        <h3 className={styles.ratingTitle}>Calificación</h3>
        <Rate disabled value={review.stars} />
      </section>

      <section className={styles.review}>
        <h3 className={styles.reviewTitle}>Comentario</h3>
        <p className={styles.reviewText}>{review.body}</p>
      </section>

      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={() => onConfirm(review)}>
          {type}
        </button>
      </div>
    </Modal>
  );
};

export default ReviewModal;
