import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, notification, Select } from "antd";
import { CloseOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { CREATE_EMPLOYEE } from "../../Graphql/Mutations";
import { SEGMENTS } from "../../Graphql/Queries";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import { useMutation, useQuery } from "@apollo/client";
import "./index.css";
import { getUser, setAccessToken } from "../../../../Helpers/auth";

const { Option } = Select

const CreateAndEditEmployeeModal = ({ visible, onCancel, action, companyData, employee }) => {
  const [firstName, setFirstName] = useState(employee ? employee.firstName : '')
  const [lastName, setLastName] = useState(employee ? employee.lastName : '')
  const [email, setEmail] = useState(employee ? employee.email : '')
  const [code, setCode] = useState(employee ? employee.password : '')
  const [companyRole, setCompanyRole] = useState(employee ? employee.companyRole : '')

  useEffect(() => {
    if(employee){
      setFirstName(employee.firstName)
      setLastName(employee.lastName)
      setEmail(employee.email)
      setCode(employee.password)
      setCompanyRole(employee.companyRole)
    }else{
      setFirstName('')
      setLastName('')
      setEmail('')
      setCode('')
      setCompanyRole('')
    }
  },[employee])

  const [refreshToken] = useMutation(REFRESH_TOKEN)

  const getAllFormCreateAndEditEmployee = () => {
    let employeeInput = {
      firstName,
      lastName,
      email,
      code,
      companyRole
    }
    return employeeInput
  }

  const [createEmployeeMutation, {loading: loadingCreateEmployee}] = useMutation(CREATE_EMPLOYEE,{
    variables:{
      input: getAllFormCreateAndEditEmployee(),
      companyId: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected'))
    }
  })

  const { data: segments } = useQuery(SEGMENTS, {
    variables: {
        companyId: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected'))
    }
})

  

  const createEmployee = () => {
      refreshToken().then(res => {
          setAccessToken(res.data.refreshToken.token)
          createEmployeeMutation().then(() => {
              notification.success({
                  message: `Éxito`,
                  description:
                    'El empleado ha sido creado exitósamente',
                  placement: "bottomRight",
                });
                onCancel()
                companyData()
                cleanInputs()
          }).catch(e => {
            if(e.toString().startsWith('Error: E11000 duplicate key')){
              notification.error({
                message: `Error`,
                description:
                  'Hubo un error al crear el usuario, ya existe un usuario con este correo',
                placement: "bottomRight",
              });
            }else{
              notification.error({
                message: `Error`,
                description:
                  'Hubo un error al crear el usuario, por favor revisa todos los campos e inténtalo de nuevo.',
                placement: "bottomRight",
              });
            }
           
          })
      })
  }

  const cleanInputs = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setCode('')
  }

  const onFinish = () => {
    if(action === 'create') createEmployee()
  }

  const fields = [
    {
      name: ['firstName'],
      value: firstName
    },
    {
      name: ['lastName'],
      value: lastName
    },
    {
      name: ['email'],
      value: email
    },
    {
      name: ['code'],
      value: code
    }
  ]

  return (
    <>
      <Modal
        className="modalBody"
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ top: 50 }}
        closeIcon={<CloseOutlined />}
      >
        <h1 className="userModal-h1">
          {action === "create" ? "Agregar un empleado" : "Editar un empleado"}
        </h1>
        <Row justify="center" style={{width: '100%'}}>
        <Form layout="vertical" className="modalForm" onFinish={onFinish} requiredMark={false} fields={fields}>
          <Form.Item
            name="firstName"
            label="Nombre empleado"
            rules={[{ required: true, message: "¡Debes ingresar el nombre del empleado!" }]}
          >
            <Input
              className="createUserFormInput"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Apellido empleado"
            rules={[{ required: true, message: "¡Debes ingresar el apellido del empleado!" }]}
          >
            <Input
              className="createUserFormInput"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[{ required: true, message: "¡Debes ingresar un email válido!", type: 'email' }]}
          >
            <Input
              className="createUserFormInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={action === 'edit'}
            />
          </Form.Item>

          {
            action === 'create' ? (
              <Form.Item
                name="code"
                label="Código validador"
                rules={[{ required: true, message: "¡Debes ingresar código!" }]}
              >
                <Input
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  className="createUserFormInput"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  maxLength={4}
                />
              </Form.Item>
            ) : null
          }

          <Form.Item
            name="companyRole"
            label="Segmento del empleado"
          >
            <Select 
              className="formSelectCreateUser"
              value={companyRole}
              bordered={false}
              onChange={(e) => setCompanyRole(e)}
            >
              {
                segments && segments.segments.map(segment => {
                  return <Option value={segment._id}>{segment.name}</Option>
                })
              }
            </Select>
          </Form.Item>

          <Form.Item>
            <Row justify="space-between" align="middle">
              <Col span={12} style={{textAlign: 'center'}}>
                {
                  action === 'create' ? (
                    <Button htmlType="submit" className="CreateAndEditUserModal-Button" loading={loadingCreateEmployee}>
                      Crear
                    </Button>
                  ) : (
                    <Button htmlType="submit" className="CreateAndEditUserModal-Button">
                      Guardar
                    </Button>
                  )
                }
            
            </Col>
            <Col span={12} style={{textAlign: 'center'}}>
            <Button className="CreateAndEditUserModal-Button" onClick={onCancel}> Cancelar </Button>
            </Col>
            </Row>
          </Form.Item>
        </Form>
        </Row>
      </Modal>
    </>
  );
};

export default CreateAndEditEmployeeModal;
