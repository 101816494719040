const checkFormLabel = label =>{
    if(label === 'name') return 'Nombre de promoción'
    else if(label === 'description') return 'Descripción de promoción'
    else if(label === 'type') return 'Tipo de promoción'
    else if(label === 'conditions') return '¿Cómo usar?'
    else if(label === 'localName') return 'Nombre de socio'
    else if(label === 'startDate') return 'Vigencia desde'
    else if(label === 'dueDate') return 'Vigencia hasta'
    else if(label === 'weekDays') return 'Días de vigencia'
    else if(label === 'benefitURL') return 'Web de socio'
    else if(label === 'termAndConditions') return 'Términos y Condiciones'
    else if(label === 'status') return 'Estado'
    else if(label === 'localImageFile') return 'Logo socio'
    else if(label === 'promotionImageFile') return 'Banner promoción'
    else if(label === 'communes') return 'Comunas'
}

export { checkFormLabel }