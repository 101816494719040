import gql from "graphql-tag";

const UPLOAD_PROMOTIONS_BULK = gql`
mutation uploadPromotionsBulk($companyId : String!, $input: [BulkPromotionEntryInput!]!){
    uploadPromotionsBulk(companyId: $companyId, input: $input){
      _id
  
  }
}
`;


export { UPLOAD_PROMOTIONS_BULK }