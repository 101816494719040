import gql from "graphql-tag";

const CREATE_COMMENT = gql`
  mutation createComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      _id
      promotion {
        _id
      }
      visible
      stars
      body
    }
  }
`;

export { CREATE_COMMENT };
