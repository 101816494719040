import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { Button, Form, Modal, notification } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_PASSWORD } from "../../Graphql/Mutations";
import {
  CloseOutlined,
  MinusCircleFilled,
  CloseCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const passwordValidationMessages = {
  newPassword: [
    ["length", "La contraseña debe ser al menos de 8 caracteres."],
    ["uppercase", "Debe contener al menos una mayuscula."],
    ["lowercase", "Debe contener al menos una minuscula."],
    ["numbers", "Debe contener al menos un número."],
    ["specialCharacters", "Debe contener al menos un carácter especial."],
  ],
  confirmPassword: [["confirm", "No se confirmo la nueva contraseña."]],
};

const UpdatePasswordModal = ({ visible, onCancel }) => {
  const history = useHistory();
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const [passwordData, setPasswordData] = useState({
    actualPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [newPasswordRules, setNewPasswordRules] = useState({
    length: "pending",
    uppercase: "pending",
    lowercase: "pending",
    numbers: "pending",
    specialCharacters: "pending",
  });

  const [confirmNewPasswordRules, setConfirmNewPasswordRules] = useState({
    confirm: "pending",
  });

  const handleSubmit = async () => {
    try {
      await updatePassword({
        variables: {
          input: {
            currentPassword: passwordData.actualPassword,
            newPassword: passwordData.newPassword,
            confirmationPassword: passwordData.confirmNewPassword,
          },
        },
      }).then(() => {
        history.push("/userHome");
        notification.success({
          message: "Éxito",
          description: "Contraseña actualizada con éxito.",
          placement: "bottomRight",
        });
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description:
          e.message === "Password do not match"
            ? "La contraseña ingresada como contraseña actual no es válida, verifique e intente nuevamente"
            : "Verifique los datos e intente nuevamente",
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    passwordData.actualPassword.length > 0 && passwordValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordData]);

  const checkValidations = () => {
    if (
      newPasswordRules.length !== "success" ||
      newPasswordRules.uppercase !== "success" ||
      newPasswordRules.lowercase !== "success" ||
      newPasswordRules.numbers !== "success" ||
      newPasswordRules.specialCharacters !== "success" ||
      confirmNewPasswordRules.confirm !== "success"
    ) {
      notification.error({
        message: "Error",
        description: "Verifique los datos e intente nuevamente",
        placement: "bottomRight",
      });
      return false;
    }
    return true;
  };

  const passwordValidation = () => {
    passwordData.newPassword.length > 0 &&
      setNewPasswordRules({
        ...newPasswordRules,
        length: passwordData.newPassword.length >= 8 ? "success" : "failed",
        uppercase:
          passwordData.newPassword
            .split("")
            .some((letter) => /^[A-Z]+$/.test(letter)) === true
            ? "success"
            : "failed",
        lowercase:
          passwordData.newPassword
            .split("")
            .some((letter) => /^[a-z]+$/.test(letter)) === true
            ? "success"
            : "failed",
        numbers:
          passwordData.newPassword
            .split("")
            .some((letter) => /^[0-9]+$/.test(letter)) === true
            ? "success"
            : "failed",
        specialCharacters:
          passwordData.newPassword
            .split("")
            .some(
              (letter) =>
                (letter.charCodeAt(0) >= 32 && letter.charCodeAt(0) <= 47) ||
                (letter.charCodeAt(0) >= 58 && letter.charCodeAt(0) <= 64) ||
                (letter.charCodeAt(0) >= 91 && letter.charCodeAt(0) <= 96) ||
                (letter.charCodeAt(0) >= 123 && letter.charCodeAt(0) <= 126)
            ) === true
            ? "success"
            : "failed",
      });

    passwordData.newPassword.length === 0 &&
      setNewPasswordRules({
        ...newPasswordRules,
        length: "pending",
        uppercase: "pending",
        lowercase: "pending",
        numbers: "pending",
        specialCharacters: "pending",
      });

    passwordData.confirmNewPassword.length === 0
      ? setConfirmNewPasswordRules({
          ...confirmNewPasswordRules,
          confirm: "pending",
        })
      : setConfirmNewPasswordRules(() => ({
          ...confirmNewPasswordRules,
          confirm:
            passwordData.newPassword === passwordData.confirmNewPassword
              ? "success"
              : "failed",
        }));
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  return (
    <Modal
      className={styles.container}
      visible={visible}
      closable
      closeIcon={<CloseOutlined style={{ display: "none" }} />}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ backgroundColor: "#fff", borderRadius: "16px" }}
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Editar contraseña</h2>
        <CloseOutlined className={styles.headerCloseIcon} />
      </header>

      <Form className={styles.form}>
        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="actualPassword">
            Contraseña actual
          </label>
          <input
            className={styles.formItemInput}
            name="actualPassword"
            autoComplete="off"
            type="text"
            onChange={(e) => handleInputChange(e)}
            value={passwordData.actualPassword}
            placeholder="Ingresa la contraseña actual"
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="newPassword">
            Nueva contraseña
          </label>
          <input
            className={styles.formItemInput}
            name="newPassword"
            disabled={!passwordData.actualPassword}
            type="text"
            autoComplete="off"
            onChange={(e) => handleInputChange(e)}
            value={passwordData.newPassword}
            placeholder="Ingresa la nueva contraseña"
          />
          {passwordData.actualPassword && (
            <ul className={styles.formItemValidationList}>
              {passwordValidationMessages.newPassword.map(([rule, message]) => {
                const ruleStatus = newPasswordRules[rule];
                return (
                  <li
                    key={rule}
                    className={`${styles.formItemValidationListItem} ${styles[ruleStatus]}`}
                  >
                    {ruleStatus === "pending" ? (
                      <MinusCircleFilled
                        className={styles.formItemValidationIcon}
                      />
                    ) : ruleStatus === "success" ? (
                      <CheckCircleFilled
                        className={styles.formItemValidationIcon}
                      />
                    ) : (
                      <CloseCircleFilled
                        className={styles.formItemValidationIcon}
                      />
                    )}
                    {message}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="confirmNewPassword">
            Confirmar nueva contraseña
          </label>
          <input
            className={styles.formItemInput}
            name="confirmNewPassword"
            type="text"
            autoComplete="off"
            onChange={(e) => handleInputChange(e)}
            disabled={!passwordData.actualPassword || !passwordData.newPassword}
            value={passwordData.confirmNewPassword}
            placeholder="Confirma la nueva contraseña"
          />
          {passwordData.newPassword && (
            <ul className={styles.formItemValidationList}>
              {passwordValidationMessages.confirmPassword.map(
                ([rule, message]) => {
                  const ruleStatus = confirmNewPasswordRules[rule];
                  return (
                    <li
                      key={rule}
                      className={`${styles.formItemValidationListItem} ${styles[ruleStatus]}`}
                    >
                      {ruleStatus === "pending" ? (
                        <MinusCircleFilled
                          className={styles.formItemValidationIcon}
                        />
                      ) : ruleStatus === "success" ? (
                        <CheckCircleFilled
                          className={styles.formItemValidationIcon}
                        />
                      ) : (
                        <CloseCircleFilled
                          className={styles.formItemValidationIcon}
                        />
                      )}
                      {message}
                    </li>
                  );
                }
              )}
            </ul>
          )}
        </div>
        <Button
          className={styles.formButton}
          disabled={
            !passwordData.actualPassword ||
            !passwordData.newPassword ||
            !passwordData.confirmNewPassword
          }
          onClick={() => {
            checkValidations() && handleSubmit();
          }}
        >
          Guardar
        </Button>
      </Form>
    </Modal>
  );
};

export default UpdatePasswordModal;
