import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, notification, Select, Tooltip  } from "antd";
import { CloseOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { CREATE_USER, UPDATE_USER } from "../../Graphql/Mutations";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import "./index.css";
import { getCompanyId, setAccessToken } from "../../../../Helpers/auth";

const { Option } = Select;

const CreateAndEditUserModal = ({ visible, onCancel, action, usersByCompanyQuery, user }) => {
  const [firstName, setFirstName] = useState(user ? user.firstName : '')
  const [lastName, setLastName] = useState(user ? user.lastName : '')
  const [email, setEmail] = useState(user ? user.email : '')
  const [password, setPassword] = useState(user ? user.password : '')
  const [role, setRole] = useState( user?.role || 'Employee')

  useEffect(() => {
    if(user._id){
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setPassword(user.password)
      setRole(user.role)
    }else{
      setFirstName('')
      setLastName('')
      setEmail('')
      setPassword('')
      setRole('')
    }
  },[user])

  const [refreshToken] = useMutation(REFRESH_TOKEN)

  const getAllFormCreateAndEditUser = () => {
    let userInput = {
      firstName,
      lastName,
      email,
      password,
      role: role || 'Employee',
      company: getCompanyId()
    }
    return userInput
  }

  const [createUserMutation, {loading: loadingCreateUser}] = useMutation(CREATE_USER,{
    variables:{
      input: getAllFormCreateAndEditUser()
    }
  })

  const [updateUserMutation, {loading: loadingUpdateUser}] = useMutation(UPDATE_USER,{
    variables:{
      input: getAllFormCreateAndEditUser(),
      id: user._id
    }
  })

  const createUser = () => {
      refreshToken().then(res => {
          setAccessToken(res.data.refreshToken.token)
          createUserMutation().then(() => {
              notification.success({
                  message: `Éxito`,
                  description:
                    'El usuario ha sido creado exitósamente',
                  placement: "bottomRight",
                });
                onCancel()
                usersByCompanyQuery()
                cleanInputs()
          }).catch(e => {
            if(e.toString().startsWith('Error: E11000 duplicate key')){
              notification.error({
                message: `Error`,
                description:
                  'Hubo un error al crear el usuario, ya existe un usuario con este correo',
                placement: "bottomRight",
              });
            }else{
              notification.error({
                message: `Error`,
                description:
                  'Hubo un error al crear el usuario, por favor revisa todos los campos e inténtalo de nuevo.',
                placement: "bottomRight",
              });
            }
           
          })
      })
  }

  const updateUser = () => {
    refreshToken().then(res => {
        setAccessToken(res.data.refreshToken.token)
        updateUserMutation().then(() => {
            notification.success({
                message: `Éxito`,
                description:
                  'El usuario ha sido actualizado exitósamente',
                placement: "bottomRight",
              });
              onCancel()
              usersByCompanyQuery()
              cleanInputs()
        }).catch(e => {
          if(e.toString().startsWith('Error: E11000 duplicate key')){
            notification.error({
              message: `Error`,
              description:
                'Hubo un error al actualizar el usuario, ya existe un usuario con este correo',
              placement: "bottomRight",
            });
          }
          else if(e.toString().startsWith('Error: La empresa debe tener al menos un administrador')){
            notification.error({
              message: `Error`,
              description:
                'Hubo un error al actualizar el usuario, debe existir al menos un administrador en la empresa.',
              placement: "bottomRight",
            });
          }else{
            notification.error({
              message: `Error`,
              description:
                'Hubo un error al actualizar el usuario, por favor revisa todos los campos e inténtalo de nuevo.',
              placement: "bottomRight",
            });
          }
         
        })
    })
}

  const cleanInputs = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPassword('')
    setRole('')
  }

  const onFinish = () => {
    if(action === 'create'){
      createUser()
    }else {
      updateUser()
    }
  }

  const fields = [
    {
      name: ['firstName'],
      value: firstName
    },
    {
      name: ['lastName'],
      value: lastName
    },
    {
      name: ['email'],
      value: email
    },
    {
      name: ['password'],
      value: password
    },
    {
      name: ['role'],
      value: role
    }
  ]
  const canShowSuperAdminOption = JSON.parse(localStorage.getItem('companySelected')) === 'all' 
  return (
    <>
      <Modal
        className="modalBody"
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ top: 50 }}
        closeIcon={<CloseOutlined />}
      >
        <h1 className="userModal-h1">
          {action === "create" ? "Agregar un usuario" : "Editar un usuario"}
        </h1>
        <Row justify="center" style={{width: '100%'}}>
        <Form layout="vertical" className="modalForm" onFinish={onFinish} requiredMark={false} fields={fields}>
          <Form.Item
            name="firstName"
            label="Nombre empleado"
            rules={[{ required: true, message: "¡Debes ingresar el nombre del empleado!" }]}
          >
            <Input
              className="createUserFormInput"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Apellido empleado"
            rules={[{ required: true, message: "¡Debes ingresar el apellido del empleado!" }]}
          >
            <Input
              className="createUserFormInput"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[{ required: true, message: "¡Debes ingresar un email válido!", type: 'email' }]}
          >
            <Input
              className="createUserFormInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={action === 'edit'}
            />
          </Form.Item>

          {
            action === 'create' ? (
              <Form.Item
                name="password"
                label="Contraseña"
                rules={[{ required: true, message: "¡Debes ingresar una contraseña!" }]}
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  className="createUserFormInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
            ) : null
          }
          
          <Form.Item 
            name="role"
            label={
              <Tooltip 
              color={'white'}
              title={
                <div style={{color: 'black'}}>
                  {
                    canShowSuperAdminOption ? <p><b>Super Administrador:</b> acceso a toda la plataforma incluyendo usuarios.</p> : <>
                     <p><b>Administrador:</b> acceso a toda la plataforma incluyendo usuarios.</p>
                      <p><b>Moderador:</b> acceso a toda la plataforma sin acceso a usuarios.</p>
                    </>
                  }
                 
                </div>
              }>
                <span>Acceso (?)</span>
              </Tooltip>
            }
            rules={[{ required: true, message: "¡Debes ingresar seleccionar una opción!" }]}
          >
            <Select 
              className="formSelectCreateUser"
              value={role}
              bordered={false}
              onChange={(e) => setRole(e)}
            >

          
              {
                canShowSuperAdminOption ? <Option value="SuperAdmin">Super Administrador</Option> :  <>
                  <Option value="Moderator">Moderador</Option>
                  <Option value="Admin">Administrador</Option>
                </>
              }
            </Select>
          </Form.Item>

          <Form.Item>
            <Row justify="space-between" align="middle">
              <Col span={12} style={{textAlign: 'center'}}>
                {
                  action === 'create' ? (
                    <Button htmlType="submit" className="CreateAndEditUserModal-Button" loading={loadingCreateUser}>
                      Crear
                    </Button>
                  ) : (
                    <Button htmlType="submit" className="CreateAndEditUserModal-Button" loading={loadingUpdateUser} onClick={() => updateUser()}>
                      Guardar
                    </Button>
                  )
                }
            
            </Col>
            <Col span={12} style={{textAlign: 'center'}}>
            <Button className="CreateAndEditUserModal-Button" onClick={onCancel}> Cancelar </Button>
            </Col>
            </Row>
          </Form.Item>
        </Form>
        </Row>
      </Modal>
    </>
  );
};

export default CreateAndEditUserModal;
