import React from "react";
import styles from "./index.module.css";
import { Row, Spin } from "antd";
import PromotionsStatusTimeline from "./Components/PromotionsStatusTimeline";
import PromotionsStatus from "./Components/PromotionsStatus";
import PromotionsCreated from "./Components/PromotionsCreated";

const ActivePromotionsBox = ({
  type,
  setType,
  getReports,
  reportsData,
  isLoadingReports,
  getReportsInput = {},
}) => {
  const handleType = (typeFilter) => {
    const newType = type === typeFilter ? "" : typeFilter;
    setType(newType);
    getReports({
      input: {
        ...getReportsInput,
        type: newType,
      },
    });
  };

  if (isLoadingReports) {
    return (
      <div className="container-ReportScreen">
        <Row justify="center" style={{ marginTop: 50, marginBottom: 50 }}>
          <Spin size="large" />
        </Row>
      </div>
    );
  }

  const totalCreatedPromos =
    reportsData?.promotions?.promotionCreationHistory?.reduce((acc, promo) => {
      return acc + (promo?.count || 0);
    }, 0);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Sobre las promociones</h2>

      <h3 className={styles.subTitle}>
        <span>{totalCreatedPromos ? totalCreatedPromos : 0}</span> promociones
        han sido creadas
      </h3>

      <section className={styles.promoTypes}>
        <button
          onClick={() => handleType("shopping")}
          className={`${styles.promoTypesButton} ${
            type === "shopping" && styles.promoTypesButtonActive
          } `}
        >
          Shopping
        </button>

        <button
          onClick={() => handleType("flavor")}
          className={`${styles.promoTypesButton} ${
            type === "flavor" && styles.promoTypesButtonActive
          } `}
        >
          Sabores
        </button>

        <button
          onClick={() => handleType("decoration")}
          className={`${styles.promoTypesButton} ${
            type === "decoration" && styles.promoTypesButtonActive
          } `}
        >
          Decoración
        </button>

        <button
          onClick={() => handleType("health")}
          className={`${styles.promoTypesButton} ${
            type === "health" && styles.promoTypesButtonActive
          } `}
        >
          Salud y Belleza
        </button>

        <button
          onClick={() => handleType("travel")}
          className={`${styles.promoTypesButton} ${
            type === "travel" && styles.promoTypesButtonActive
          } `}
        >
          Panoramas
        </button>

        <button
          onClick={() => handleType("other")}
          className={`${styles.promoTypesButton} ${
            type === "other" && styles.promoTypesButtonActive
          } `}
        >
          Otros
        </button>
      </section>

      <section className={styles.stats}>
        <PromotionsStatusTimeline data={reportsData} />
        <PromotionsStatus data={reportsData} />
        <PromotionsCreated data={reportsData} />
      </section>
    </div>
  );
};

export default ActivePromotionsBox;
