const checkAge = (userBirthDate) => {
  let today = new Date();
  let birthDate = new Date(userBirthDate);
  let age = today.getFullYear() - birthDate.getFullYear();
  let month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age >= 18;
};

export default checkAge;
