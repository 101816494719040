import React, { useState } from "react";
import styles from "./index.module.css";
import StepOne from "./Components/StepOne";
import StepOTwo from "./Components/StepTwo";
import stepOneValidation from "./Helpers/stepOneValidation";
import stepTwoValidation from "./Helpers/stepTwoValidation";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_COMPANY } from "../../Graphql/Mutations";
import { notification } from "antd";

const RegisterForm = () => {
  const history = useHistory();
  const [createCompany] = useMutation(CREATE_COMPANY);
  const [currentStep, setCurrentStep] = useState(1);
  const [registerData, setRegisterData] = useState({
    company: {
      name: "",
      phone: "",
      category: "",
    },
    admin: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
  });

  const handleSubmit = async () => {
    try {
      const { company, admin } = registerData;
      const { data } = await createCompany({
        variables: {
          input: {
            name: company.name,
            phone: company.phone,
            category: company.category,
            adminFirstName: admin.firstName,
            adminLastName: admin.lastName,
            adminEmail: admin.email,
            adminPassword: admin.password,
          },
        },
      });
      if (data) {
        notification.success({
          message: "Éxito",
          description:
            "La compañía se ha creado correctamente, ahora puedes iniciar sesión",
          placement: "bottomRight",
        });
        history.push("/login");
      }
    } catch (error) {
      if(error.message.startsWith('E11000')){
        notification.error({
          message: "Error",
          description: "Este correo ya se encuentra registrado, revisa e inténtalo de nuevo con uno diferente",
          placement: "bottomRight",
        });
      }else
      notification.error({
        message: "Error",
        description: "Hubo un error al intentar registrar la compañía",
        placement: "bottomRight",
      });
    }
  };

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3 className={styles.headerTitle}>
          Datos {currentStep === 1 ? "de la empresa" : "del administrador"}
        </h3>
        <span className={styles.headerSubtitle}>Paso {currentStep} de 2</span>
      </header>

      {currentStep === 1 ? (
        <StepOne
          registerData={registerData}
          setRegisterData={setRegisterData}
        />
      ) : (
        <StepOTwo
          registerData={registerData}
          setRegisterData={setRegisterData}
        />
      )}

      <footer className={styles.footer}>
        {currentStep === 1 ? (
          <button
            className={styles.footerButton}
            onClick={() =>
              stepOneValidation(registerData.company) && setCurrentStep(2)
            }
          >
            Siguiente
          </button>
        ) : (
          <>
            <button
              className={`${styles.footerButton} ${styles.footerGhostButton}`}
              onClick={() => setCurrentStep(1)}
            >
              Anterior
            </button>
            <button
              className={styles.footerButton}
              onClick={() =>
                stepOneValidation(registerData.company) &&
                stepTwoValidation(registerData.admin) &&
                handleSubmit()
              }
            >
              Registrarse
            </button>
          </>
        )}
      </footer>
    </section>
  );
};

export default RegisterForm;
