import React, { useState } from "react";
import styles from "./index.module.css";
import { notification } from "antd";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REGISTER } from "./Graphql/Mutations";
import formValidation from "./Helpers/formValidation";
import MainHeader from "../../Components/Header";
import RegisterHeader from "./Components/RegisterHeader";
import RegisterForm from "./Components/RegisterForm";

const RegisterScreen = ({ history }) => {
  const [register] = useMutation(REGISTER);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    gender: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const parseGender = (gender) => {
    if(gender === 'Masculino') return 'male'
    else if(gender === 'Femenino') return 'female'
    else return 'other'
  }

  const handleRegister = async () => {
    if (formValidation(formData)) {
      try {
        await register({
          variables: {
            input: {
              firstName: formData.firstName.trim(),
              lastName: formData.lastName.trim(),
              email: formData.email.trim(),
              birthDate: formData.birthDate.trim(),
              gender: parseGender(formData.gender),
              role: "User",
              password: formData.password.trim(),
            },
          },
        }).then((res) => {
          notification.success({
            message: "Éxito",
            description: `Te has registrado exitósamente. Ahora intenta loguearte con tu correo ${formData.email}`,
            placement: "bottomRight",
          });
          history.push("/login");
        });
      } catch (e) {
        console.log(e);
        notification.error({
          message: "Error",
          description: "Error al registrar usuario, intente nuevamente",
          placement: "bottomRight",
        });
      }
    }
  };

  return (
    <>
      <MainHeader />
      <div className={styles.container}>
        <RegisterHeader />
        <RegisterForm
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          handleRegister={handleRegister}
          formValidation={formValidation}
        />
      </div>
    </>
  );
};

export default withRouter(RegisterScreen);
