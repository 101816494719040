import React, { useState } from "react";
import styles from "./index.module.css";
import { Button, Form, Modal, Select, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import { ASSOCIATE_MY_COMPANY } from "../../Graphql/Mutations";
import { COMPANIES } from "../../Graphql/Queries";
import formValidation from "./Helpers/formValidation";

const UpdatePasswordModal = ({ visible, onCancel, userEmail }) => {
  const { Option } = Select;
  const { data: companiesData } = useQuery(COMPANIES, {
    variables: {
      input: {
        page: 1,
        limit: 10000
      }
    }
  });
  const [associateMyCompany] = useMutation(ASSOCIATE_MY_COMPANY);
  const [formData, setFormData] = useState({
    company: "",
    email: userEmail,
    validator: "",
  });

  const handleSubmit = async () => {
    if (formValidation(formData)) {
      try {
        await associateMyCompany({
          variables: {
            input: {
              companyId: formData.company,
              email: formData.email.toLowerCase(),
              code: formData.validator,
            },
          },
        }).then(() => {
          notification.success({
            message: "Success",
            description: "Empresa asociada correctamente",
            placement: "bottomRight",
          });
        });
      } catch (err) {
        console.log(err);
        notification.error({
          message: "Error",
          description:
            err.message === "email not found"
              ? "Email no encontrado"
              : "Error al asociar empresa, verifique los datos e intente nuevamente",
          placement: "bottomRight",
        });
      }
    }
  };

  return (
    <Modal
      className={styles.container}
      visible={visible}
      closable
      closeIcon={<CloseOutlined style={{ display: "none" }} />}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ backgroundColor: "#fff", borderRadius: "16px" }}
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Agrega tu empresa</h2>
        <CloseOutlined className={styles.headerCloseIcon} />
      </header>

      <Form className={styles.form}>
        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="actualPassword">
            Empresa
          </label>
          <Select
            showArrow={true}
            placeholder="Seleccione una empresa"
            onChange={(value) => setFormData({ ...formData, company: value })}
            className={`${styles.formItemInput} ${styles.formItemInputSelect} `}
          >
            {companiesData?.companies?.data?.map((company) => (
              <Option key={company._id} value={company._id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </div>

        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="actualPassword">
            Correo corporativo
          </label>
          <input
            className={styles.formItemInput}
            name="email"
            autoComplete="off"
            type="text"
            disabled
            placeholder="Correo corporativo"
            value={userEmail}
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="actualPassword">
            Dato validador
          </label>
          <input
            className={styles.formItemInput}
            name="validator"
            autoComplete="off"
            type="text"
            maxLength={4}
            onChange={(e) =>
              setFormData({ ...formData, validator: e.target.value })
            }
            placeholder="Dato validador"
          />
        </div>
        <Button className={styles.formButton} onClick={() => handleSubmit()}>
          Guardar
        </Button>
      </Form>
    </Modal>
  );
};

export default UpdatePasswordModal;
