import gql from "graphql-tag";

const COMPANY = gql`
query company($input: FindCompany!){
    company(input: $input){
      _id
      name
      phone
      logo
      icon
      category{
          _id
      }
    }
  }
`;

export { COMPANY }