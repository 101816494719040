import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import HomeScreen from './HomeScreen'
import ContactScreen from './ContactScreen'
import PromotionTypeScreen from './PromotionTypeScreen'
import PromotionSearchScreen from './PromotionSearchScreen'
import LoginScreen from './LoginScreen'
import RegisterScreen from './RegisterScreen'
import CompanyOnoarding from './CompanyOnboarding'
import CompanyRegister from './CompanyRegister'
import CompanyHomeScreen from './CompanyHomeScreen'
import CompanyPromotionsScreen from './CompanyPromotionsScreen'
import CompanyReportScreen from './CompanyReportScreen'
import CompanyUsersScreen from './CompanyUsersScreen'
import CompanyReviewsScreen from './CompanyReviewsScreen'
import CompanyCompanies from './CompanyCompanies'
import CompanyNotifications from './CompanyNotifications'
import CompanyLayout from './CompanyLayout'
import UserLayout from './UserLayout'
import UserHomeScreen from './UserHomeScreen'
import UserPartnerScreen from './UserPartnerScreen'
import UserAddPartnerScreen from './UserAddPartnerScreen'
import UserAddAllyScreen from './UserAddAllyScreen'
import UserAddCompanyScreen from './UserAddCompanyScreen'
import VerifyAccountScreen from './VerifyAccountScreen'
import ForgotPasswordScreen from './ForgotPasswordScreen'
import ChangePasswordScreen from './ChangePasswordScreen'
import MyCompanyScreen from './MyCompanyScreen'

const Screens = () => {

  const CompanyLayoutRoute = (Component, ...rest) => {
    let Comp = Component.component
    return(
      <CompanyLayout>
        <Route 
        {...rest}
        render={props => {
          return (
            <Comp {...props}/>
          )
        }
        }>
        </Route>
      </CompanyLayout>
    )
  }

  const UserLayoutRoute = (Component, ...rest) => {
    let Comp = Component.component
    return(
      <UserLayout>
        <Route 
        {...rest}
        render={props => {
          return (
            <Comp {...props}/>
          )
        }
        }>
        </Route>
      </UserLayout>
    )
  }

  return (
    <Switch>
      <Route path="/home" component={HomeScreen}/>
      <Route path="/contact" component={ContactScreen}/>
      <Route path="/promotion/:type" component={PromotionTypeScreen}/>
      <Route path="/searchPromotion/:input" component={PromotionSearchScreen}/>
      <Route path="/login" component={LoginScreen}/>
      <Route path="/register" component={RegisterScreen}/>
      <Route path="/forgotPassword" component={ForgotPasswordScreen}/>
      <Route path="/companyOnboarding" component={CompanyOnoarding}/>
      <Route path="/companyRegister" component={CompanyRegister}/>
      <Route path="/verify/:token" component={VerifyAccountScreen}/>
      <Route path="/changePassword/:token" component={ChangePasswordScreen}/>
      <CompanyLayoutRoute path="/companyHome" component={CompanyHomeScreen}/>
      <CompanyLayoutRoute path="/companyPromotions" component={CompanyPromotionsScreen}/>
      <CompanyLayoutRoute path="/companyReport" component={CompanyReportScreen}/>
      <CompanyLayoutRoute path="/companyUsers" component={CompanyUsersScreen}/>
      <CompanyLayoutRoute path="/companyReviews" component={CompanyReviewsScreen}/>
      <CompanyLayoutRoute path="/companyCompanies" component={CompanyCompanies}/>
      <CompanyLayoutRoute path="/companyNotifications" component={CompanyNotifications}/>
      <CompanyLayoutRoute path="/myCompany" component={MyCompanyScreen}/>
      <UserLayoutRoute path="/userHome" component={UserHomeScreen}/>
      <UserLayoutRoute path="/userPartners" component={UserPartnerScreen}/>
      <UserLayoutRoute path="/addPartners" component={UserAddPartnerScreen}/>
      <UserLayoutRoute path="/addAlly" component={UserAddAllyScreen}/>
      <UserLayoutRoute path="/addCompany" component={UserAddCompanyScreen}/>
      <Route path="/" component={() => <Redirect to={'/home'} />} />
    </Switch>
  )
}

export default Screens 