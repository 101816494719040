import React from "react";
import styles from "../StepOne/index.module.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, Input } from "antd";

const StepTwo = ({ registerData, setRegisterData }) => {
  return (
    <Form className={styles.form}>
      <div className={`${styles.formItem} ${styles.formItemFlex}`}>
        <label className={styles.formItemLabel} htmlFor="adminFirstName">
          Nombre
        </label>
        <input
          className={styles.formItemInput}
          name="adminFirstName"
          autoComplete="off"
          type="text"
          maxLength={40}
          placeholder="Nombre del administrador..."
          value={registerData.admin.firstName}
          onChange={(e) =>
            setRegisterData({
              ...registerData,
              admin: { ...registerData.admin, firstName: e.target.value },
            })
          }
        />
      </div>

      <div className={`${styles.formItem} ${styles.formItemFlex}`}>
        <label className={styles.formItemLabel} htmlFor="adminLastName">
          Apellido
        </label>
        <input
          className={styles.formItemInput}
          name="adminLastName"
          autoComplete="off"
          maxLength={40}
          type="text"
          placeholder="Apellido del administrador..."
          value={registerData.admin.lastName}
          onChange={(e) =>
            setRegisterData({
              ...registerData,
              admin: { ...registerData.admin, lastName: e.target.value },
            })
          }
        />
      </div>

      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="adminEmail">
          Correo electrónico
        </label>
        <input
          className={styles.formItemInput}
          name="adminEmail"
          autoComplete="off"
          spellCheck="false"
          type="email"
          placeholder="Correo electrónico del administrador..."
          value={registerData.admin.email}
          onChange={(e) =>
            setRegisterData({
              ...registerData,
              admin: { ...registerData.admin, email: e.target.value },
            })
          }
        />
      </div>

      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="adminPassword">
          Contraseña
        </label>
        <Input.Password
          className={styles.formItemInput}
          name="adminPassword"
          type="password"
          spellCheck="false"
          placeholder="Contraseña del administrador..."
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          value={registerData.admin.password}
          onChange={(e) =>
            setRegisterData({
              ...registerData,
              admin: { ...registerData.admin, password: e.target.value },
            })
          }
        />
      </div>

      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="adminPasswordConfirm">
          Confirmar contraseña
        </label>
        <Input.Password
          className={styles.formItemInput}
          name="adminPasswordConfirm"
          type="password"
          spellCheck="false"
          placeholder="Confirmar contraseña del administrador..."
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          value={registerData.admin.passwordConfirm}
          onChange={(e) =>
            setRegisterData({
              ...registerData,
              admin: { ...registerData.admin, passwordConfirm: e.target.value },
            })
          }
        />
      </div>
    </Form>
  );
};

export default StepTwo;
