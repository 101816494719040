import React from "react";
import styles from "./index.module.css";
import Wave from "../../../../Assets/yellow-wave.png";

const Banner = ({ type, description, icon }) => {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerTitleContainer}>
          <img src={icon} alt="contact-icon" className={styles.headerIcon} />
          <h1 className={styles.headerTitle}>{type}</h1>
        </div>
        <p className={styles.headerDescription}>{description}</p>
      </header>
      <img src={Wave} alt="wave" className={styles.wave} />
    </>
  );
};

export default Banner;
