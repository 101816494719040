import React, { useState } from "react";
import PromotionsExcelModal from "./Components/PromotionsExcelModal";
import "./index.css";
import styles from "./index.module.css";
import previewIcon from "../../Assets/preview-icon.svg";
import saveIcon from "../../Assets/save-icon.svg";
import Switch from "../../../../../../Components/Switch";
import { Button } from 'antd'


const checkVisibility = visibility => {
  if(visibility === "visible") return "notVisible"
  else return "visible"
};

const FormHeader = ({
  promoVisible,
  handleVisible,
  handleSaveForm,
  handleOpenPreview,
  loadingCreatePromotion,
  loadingUpdatePromotion,
  page,
}) => {
  const [visible, setVisible] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(null);
  const onCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <PromotionsExcelModal
        visible={visible}
        onCancel={onCancel}
        fileUploadSuccess={fileUploadSuccess}
        setFileUploadSuccess={setFileUploadSuccess}
      />
      <header className={styles.container}>
        <div className={styles.header}>
        <h2 className={styles.title}>Promoción</h2>
        <span
            onClick={() => {
              const newVisibility = checkVisibility(promoVisible);
              handleVisible(newVisibility);
            }
            }
          >
            <Switch
              activeText={"Visible"}
              desactiveText={"No visible"}
              isActive={promoVisible === "visible" ? true : false}
            />
          </span>
          </div>

        <div className={styles.buttonsContainer}>
          {page === "create" && (
            <button
              className={`${styles.button} ${styles.buttonGray}`}
              onClick={() => {
                setVisible(true);
                setFileUploadSuccess(null);
              }}
            >
              <span>Carga masiva</span>
            </button>
          )}
          <button onClick={() => handleOpenPreview()} 
          className={`${styles.button} ${styles.buttonPreview}`}>
            <img src={previewIcon} alt="" />
            <span>Previsualizar</span>
          </button>

          <Button
            onClick={() => handleSaveForm()}
            className={styles.button}
            loading={loadingCreatePromotion || loadingUpdatePromotion}
          >
            <img src={saveIcon} alt="" />
            <span>Guardar</span>
          </Button>
        </div>
      </header>
    </>
  );
};

export default FormHeader;
