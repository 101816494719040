import React from "react";
import styles from "./index.module.css";
import parseArrayOfDays from "../../Helpers/weekDaysToSpanish";
import ReactGA from "react-ga4";
import DefaultIcon from "../../Assets/logo192.png";

const PromotionCard = ({ showModal, newViewPromotion, pro }) => {
  const trackPromotionEvents = () => {
    ReactGA.event({
      category: "promotions",
      action: "view_promotion",
      label: "view_promotion",
    });
    newViewPromotion({
      variables: {
        input: {
          modelName: "promotions",
          eventType: "view",
          ref: pro._id,
        },
      },
    });
  };

  return (
    <article
      className={styles.container}
      onClick={() => {
        showModal(pro);
        trackPromotionEvents();
      }}
    >
      <img className={styles.image} src={pro.image} alt="" />
      <div className={styles.iconContainer}>
        <img
          className={styles.icon}
          src={pro?.company?.icon || DefaultIcon}
          alt={pro?.company?.name || "Loia"}
        />
      </div>
      <header className={styles.header}>
        <h3 className={styles.title}>{pro.localName}</h3>
        <p className={styles.discount}>
          {`${pro.discount}% de descuento todos los ${
            pro.weekDays.length === 7
              ? "días de la semana"
              : parseArrayOfDays(pro.weekDays)
          }`}
        </p>
      </header>
    </article>
  );
};

export default PromotionCard;
