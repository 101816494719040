import React, { useEffect, useState } from 'react'
import styles from "./index.module.css"
import { useParams } from 'react-router'
// import { mockPromotions } from '../../../../Helpers/mockPromotions'
import { checkIcon, isPromotion } from '../../../../Helpers/promotionsHelper'
import './index.css'
import { Button, Col, Spin, Result, Row } from 'antd'
import ModalPromotion from '../../../../Components/ModalPromotion'
import { useQuery, useMutation } from "@apollo/client";
import { PAGINATED_PROMOTIONS } from '../../Graphql/Queries'
import { checkLabel } from '../../../../Helpers/promotionsHelper'
import PromotionsFilter from '../../../../Components/PromotionsFilter'
import { NEW_VIEW } from '../../../../Graphql/Mutations'
import Banner from '../Banner'
import PromotionCard from "../../../../Components/PromotionCard";
import NoPromotions from "../../../../Components/NoPromotions";
import useFilters from '../../../../Hooks/useFilters'
import FilterTags from '../../../../Components/PromotionsFilter/Components/FilterTags'

const Promotions = () => {
    const { type } = useParams()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [promotion, setPromotion] = useState({})
    const [promotions, setPromotions] = useState([])
    const [loading, setLoading] = useState(false);
    const  {
        filters,
        setFilters,
        activeFilters,
        setActiveFilters,
        deleteFilter,
        checkFilterParams,
    } = useFilters()
    const { error, refetch: promotionData } = useQuery(PAGINATED_PROMOTIONS, {
        variables: {
            input: {
                page: 1,
                limit: 10000,
                filterFields: ["type"],
                filterValues: [type],
                allied: filters.allied,
            }
        }
    })

    const [newViewPromotion] = useMutation(NEW_VIEW)

    useEffect(() => {
        const { filterFields, filterValues } = checkFilterParams(filters);
        setActiveFilters({
          filterFields: [...filterFields],
          filterValues: [...filterValues],
          allied: filters.allied,
        });
        getPromotions();
        // eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        setFilters({
          weekDays: [],
          discount: [],
          communes: [],
          allied: filters.allied,
        });
        setPromotions([]);
        // eslint-disable-next-line
    }, [type]);
    
    useEffect(() => {
            if(promotionData === undefined || loading) return
            else getPromotions() 
    // eslint-disable-next-line
    }, [promotionData, type]);
    
    const getPromotions = async () => {
        setLoading(false)
        const { filterFields, filterValues } = checkFilterParams(filters)
            const data = await promotionData({
                input: {
                    limit: 1000,
                    page: 1,
                    filterFields: ['type', ...filterFields],
                    filterValues: [type, ...filterValues],
                    allied: filters.allied,
                }
            })
            setPromotions(data?.data?.paginatedPromotions?.data)
    }

  const showModal = (promotion) => {
    setIsModalVisible(true);
    setPromotion(promotion)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
    if(error) return (
        <Result
            status="500"
            title="500"
            subTitle="Lo sentimos, hubo un error al intentar cargar la información."
            extra={<Button type="primary"><a href="/"> Volver a intentarlo </a></Button>}
        />
    )
    else if(!isPromotion(type)) 
    return (
        <p>Lo sentimos, no conseguimos una promoción del tipo {type}</p>
    )
    else if(loading) return (
        <Row justify="center" style={{height: '60vh'}} align="middle">
            <Spin style={{textAlign: 'center', margin:"5rem auto"}} className="loadingSpinner" size="large"/>
        </Row>
    )
    else
    return(
        <>
        <Banner 
        icon={checkIcon(type)}
        type={checkLabel(type)}
        description={`Aquí encontrarás todas las promociones de ${checkLabel(type)}`}
        />
        <Row justify="center" align='middle' style={{ marginTop: "2rem"}}>
                <Col md={16} lg={15} xl={17}></Col>
    
                <Col xs={24} sm={24} md={6} lg={5} xl={3}> 
                    <PromotionsFilter filters={filters} setFilters={setFilters} />
                </Col>

                <Col md={2}></Col>

                <FilterTags
                activeFilters={activeFilters}
                deleteFilter={deleteFilter}
                />
        </Row>

        <ModalPromotion visible={isModalVisible} promotion={promotion} onCancel={handleCancel}/>
            
            {
                promotions.length > 0 ? (
                    <Row className={styles.mainRow}>
                        <Col md={2} ></Col>
                        <Col xs={24} sm={24} md={20} className={styles.mainCol}>
                        {promotions.map((promotion,index) => {
                            return (
                                    <PromotionCard
                                        pro={promotion}
                                        showModal={showModal}
                                        newViewPromotion={newViewPromotion}
                                        key={index}
                                    />
                                    )
                                })}
                                </Col>
                        <Col md={2} ></Col>
                    </Row>
                ):(
                    <NoPromotions/>
                )
            }
            
        </>
    )
}

export default Promotions