import React from "react";
import styles from "./index.module.css";

const UserScreenContent = ({ title, children }) => {
  return (
    <section className={styles.UserScreenContent}>
      <h3 className={styles.UserScreenContentTitle}>{title}</h3>
      {children}
    </section>
  );
};

export default UserScreenContent;
