import React from "react";
import styles from "./index.module.css";
import { Collapse, Table, Empty } from "antd";

import deleteIcon from "../../Assets/delete-icon.svg";
import editIcon from "../../Assets/edit-icon.svg";

const { Panel } = Collapse;

const Users = ({
  columns,
  data,
  checkRole,
  handleCreateAndEditUserModal,
  handleDeleteUserModal,
}) => {
  return (
    <>
      <Table className={styles.table} dataSource={data} columns={columns} />
      <Collapse
        className={styles.accordion}
        expandIconPosition="right"
        accordion
      >
        <h3 className={styles.accordionTitle}>Correo</h3>
        {data?.length > 0 ? (
          data?.map((user, index) => (
            <Panel
              header={
                <h3 className={styles.accordionPanelTitle}>{user.email}</h3>
              }
              key={index}
              className={styles.accordionPanel}
            >
              <div className={styles.accordionContent}>
                <div className={styles.accordionContentItem}>
                  <h4 className={styles.accordionContentItemTitle}>Nombre:</h4>
                  <p className={styles.tableName}>{user?.firstName}</p>
                </div>
                <div className={styles.accordionContentItem}>
                  <h4 className={styles.accordionContentItemTitle}>
                    Apellido:
                  </h4>
                  <p className={styles.tableName}>{user?.lastName}</p>
                </div>
                <div className={styles.accordionContentItem}>
                  <h4 className={styles.accordionContentItemTitle}>Acceso:</h4>
                  <p className={styles.tableAcces}>{checkRole(user?.role)}</p>
                </div>
                <div className={styles.tableActions}>
                  <button
                    className={styles.tableActionsButton}
                    onClick={() => handleCreateAndEditUserModal("edit", user)}
                  >
                    <img src={editIcon} alt="" />
                  </button>
                  <button
                    className={styles.tableActionsButton}
                    onClick={() => handleDeleteUserModal(user)}
                  >
                    <img src={deleteIcon} alt="" />
                  </button>
                </div>
              </div>
            </Panel>
          ))
        ) : (
          <Empty
            description="No hay usuarios"
            className={styles.accordionEmpty}
          />
        )}
      </Collapse>
    </>
  );
};

export default Users;
