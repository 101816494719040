import React from "react";
import styles from "./index.module.css";
import { Form, DatePicker } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import Switch from "../../../../../../Components/Switch";

const LifeBoxForm = ({
  weekDays,
  handleWeekDays,
  handleCheckAllDays,
  startDate,
  handleStartDate,
  dueDate,
  handleDueDate,
  disabledDate,
}) => {
  return (
    <div className={styles.formContainer}>
      <Form.Item className={styles.formItem} label="Días de Vigencia">
        <div className={styles.daysContainer}>
          <button
            className={styles.dayButton}
            onClick={() => handleWeekDays("monday")}
          >
            {weekDays.includes("monday") ? (
              <CheckCircleFilled className={styles.dayButtonCheck} />
            ) : (
              <div className={styles.dayButtonUnchecked}></div>
            )}
            <span className={styles.dayButtonText}>Lunes</span>
          </button>

          <button
            className={styles.dayButton}
            onClick={() => handleWeekDays("tuesday")}
          >
            {weekDays.includes("tuesday") ? (
              <CheckCircleFilled className={styles.dayButtonCheck} />
            ) : (
              <div className={styles.dayButtonUnchecked}></div>
            )}
            <span className={styles.dayButtonText}>Martes</span>
          </button>

          <button
            className={styles.dayButton}
            onClick={() => handleWeekDays("wednesday")}
          >
            {weekDays.includes("wednesday") ? (
              <CheckCircleFilled className={styles.dayButtonCheck} />
            ) : (
              <div className={styles.dayButtonUnchecked}></div>
            )}
            <span className={styles.dayButtonText}>Miércoles</span>
          </button>

          <button
            className={styles.dayButton}
            onClick={() => handleWeekDays("thursday")}
          >
            {weekDays.includes("thursday") ? (
              <CheckCircleFilled className={styles.dayButtonCheck} />
            ) : (
              <div className={styles.dayButtonUnchecked}></div>
            )}
            <span className={styles.dayButtonText}>Jueves</span>
          </button>

          <button
            className={styles.dayButton}
            onClick={() => handleWeekDays("friday")}
          >
            {weekDays.includes("friday") ? (
              <CheckCircleFilled className={styles.dayButtonCheck} />
            ) : (
              <div className={styles.dayButtonUnchecked}></div>
            )}
            <span className={styles.dayButtonText}>Viernes</span>
          </button>

          <button
            className={styles.dayButton}
            onClick={() => handleWeekDays("saturday")}
          >
            {weekDays.includes("saturday") ? (
              <CheckCircleFilled className={styles.dayButtonCheck} />
            ) : (
              <div className={styles.dayButtonUnchecked}></div>
            )}

            <span className={styles.dayButtonText}>Sábado</span>
          </button>

          <button
            className={styles.dayButton}
            onClick={() => handleWeekDays("sunday")}
          >
            {weekDays.includes("sunday") ? (
              <CheckCircleFilled className={styles.dayButtonCheck} />
            ) : (
              <div className={styles.dayButtonUnchecked}></div>
            )}
            <span className={styles.dayButtonText}>Domingo</span>
          </button>
        </div>
      </Form.Item>

      <div
        className={styles.daysSwitchContainer}
        onClick={() => handleCheckAllDays(weekDays.length === 7 ? false : true)}
      >
        <Switch
          className={styles.daysSwitch}
          isActive={weekDays.length === 7}
        />
        <span className={styles.daysSwitchText}>
          Seleccionar todos los días
        </span>
      </div>

      <Form.Item
        className={`${styles.formItem} ${styles.formItemDate} `}
        label="Disponible desde"
      >
        <DatePicker
          className={styles.formInputDatePicker}
          placeholder="Seleccione una fecha"
          onSelect={(e) => handleStartDate(e)}
          value={startDate}
          format={"DD/MM/YYYY"}
        />
      </Form.Item>
      <Form.Item
        className={`${styles.formItem} ${styles.formItemDate} `}
        label="Disponible hasta"
      >
        <DatePicker
          disabledDate={disabledDate}
          className={styles.formInputDatePicker}
          placeholder="Seleccione una fecha"
          onSelect={(e) => handleDueDate(e)}
          value={dueDate}
          format={"DD/MM/YYYY"}
        />
      </Form.Item>
    </div>
  );
};

export default LifeBoxForm;
