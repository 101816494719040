import gql from "graphql-tag";

const UPLOAD_USERS_BULK = gql`
mutation uploadUsersBulk($input: [BulkUserEntryInput!]!, $companyId: String){
  uploadUsersBulk(input: $input, companyId: $companyId) {
    _id
  }
}  
`;


export { UPLOAD_USERS_BULK }