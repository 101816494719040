import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CONTACT_EMAIL } from "./Graphql/Mutations";
import { notification } from "antd";
import MainHeader from "../../Components/Header";
import MainFooter from "../../Components/Footer";
import ContactForm from "./Components/ContactForm";
import ContactHeader from "./Components/ContactHeader";

const ContactScreen = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    reason: "",
    message: "",
  });

  const [mutateFunction, { loading }] = useMutation(CONTACT_EMAIL, {
    variables: {
      input: {
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        reason: formData.reason,
        description: formData.message,
      },
    },
  });

  const sendEmail = async () => {
    try {
      await mutateFunction().then(() => {
        notification.success({
          message: `¡Listo!`,
          description: "¡Hemos enviado tu mensaje con éxito!",
          placement: "bottomRight",
        });
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          reason: "",
          message: "",
        });
      });
    } catch (e) {
      notification.error({
        message: `Error`,
        description:
          "Hubo un error al intentar contactarnos, inténtelo de nuevo más tarde",
        placement: "bottomRight",
      });
      console.log(e);
    }
  };

  return (
    <>
      <MainHeader />

      <section id="background">
        <ContactHeader />

        <ContactForm
          formData={formData}
          setFormData={setFormData}
          sendEmail={sendEmail}
          loading={loading}
        />
      </section>

      <MainFooter />
    </>
  );
};

export default ContactScreen;
