import React from "react";
import styles from "../index.module.css";
import { ResponsiveBar } from "@nivo/bar";
import InfoIcon from "../../../../Assets/info-icon.svg";

const UsersOriginChart = ({ data }) => {
  if (!data) return null;

  const dataChart =
    data?.traffic?.trafficByChannel?.map((item) => {
      return {
        status: item?.channel,
        count: item?.count || 0,
        color: "#F3722C",
      };
    }) || [];

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3>Origen de los usuarios</h3>
        <img src={InfoIcon} alt="" />
      </header>
      <div style={{ height: "85%", width: "100%" }}>
        <ResponsiveBar
          data={dataChart.reverse()}
          keys={["count"]}
          indexBy="status"
          animate={true}
          margin={{ bottom: 40, left: 90, right: 40, top: 10 }}
          padding={0.4}
          layout="horizontal"
          valueScale={{ type: "linear", min: 0, max: "auto" }}
          indexScale={{ type: "band", round: true }}
          colors={({ id, data }) => data?.color}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          valueFormat=">-c"
          axisRight={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          enableGridX={true}
          enableGridY={true}
          isInteractive={true}
          labelTextColor="#fff"
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          minValue={0}
        />
      </div>
    </section>
  );
};

export default UsersOriginChart;
