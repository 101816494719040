import React from "react";
import { Modal, Button, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { DELETE_USER } from "../../Graphql/Mutations";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import { setAccessToken } from "../../../../Helpers/auth";
import styles from './index.module.css'
import "./index.css";

const DeleteUserModal = ({ visible, onCancel, usersByCompanyQuery, user }) => {

  const [deleteUserMutation, {loading: loadingDeleteUser}] = useMutation(DELETE_USER,{
    variables:{
      id: user._id
    }
  })
  const [refreshToken] = useMutation(REFRESH_TOKEN)

  const deleteUser = () => {
    refreshToken().then(res => {
        setAccessToken(res.data.refreshToken.token)
        deleteUserMutation().then(() => {
            notification.success({
                message: `Éxito`,
                description:
                  'El usuario ha sido eliminado exitósamente',
                placement: "bottomRight",
              });
              onCancel()
              usersByCompanyQuery()
        }).catch(e => {
          if(e.toString().startsWith('Error: No puedes eliminar al unico administrador de la empresa')){
            notification.error({
              message: `Error`,
              description:
                'Hubo un error al eliminar el usuario, debe existir al menos un administrador en la empresa',
              placement: "bottomRight",
            });
          }else 
            notification.error({
              message: `Error`,
              description:
                'Hubo un error al eliminar el usuario, por favor inténtalo de nuevo.',
              placement: "bottomRight",
            });
        })
    })
}

  return (
    <>
      <Modal
            className={styles.container}
            visible={visible}
            footer={null}
            bodyStyle={{ paddingBottom: "2rem" }}
            onCancel={onCancel}
            closeIcon={<CloseOutlined className={styles.closeIcon} />}
        >
            <section className={styles.content}>
                <header className={styles.header}>
                <h3 className={styles.headerTitle}>
                    Eliminar usuario
                </h3>
                <span>¿Estás seguro/a que quieres eliminar a este usuario?</span>
                </header>

                
                <footer className={styles.footer}>
                    <Button
                        className={`${styles.footerButton} ${styles.footerGhostButton}`}
                        onClick={() => onCancel()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        loading={loadingDeleteUser}
                        className={styles.footerButton}
                        onClick={() => deleteUser()}
                    >
                        Eliminar
                    </Button>
                </footer>
            </section>
        </Modal>
    </>
  );
};

export default DeleteUserModal;
