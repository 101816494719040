import React, { useState } from "react";
import styles from "./index.module.css";
import addIcon from "../../Assets/add-icon.svg";
import CreateCompanyModal from "../CreateCompanyModal";

const Header = () => {
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState(false);

  return (
    <header className={styles.container}>
      <h2 className={styles.title}>Empresas</h2>
      <button
        className={styles.button}
        onClick={() => setIsCreateCompanyModalOpen(true)}
      >
        <img src={addIcon} alt="" />
        <span>Crear</span>
      </button>
      <CreateCompanyModal
        isVisible={isCreateCompanyModalOpen}
        onCancel={() => setIsCreateCompanyModalOpen(false)}
      />
    </header>
  );
};

export default Header;
