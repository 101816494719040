import React from "react";
import styles from "../index.module.css";
import { ResponsiveLine } from "@nivo/line";
import InfoIcon from "../../../../Assets/info-icon.svg";
import moment from "moment";

const ActiveUsersChart = ({ data }) => {
  if (!data) return null;

  const activeUsers = data?.visibility?.activeUsers || [];

  const totalActiveUsers = activeUsers?.reduce(
    (acc, item) => acc + item?.count,
    0
  );

  const dataChart = [
    {
      id: "Activo",
      color: "#E4056F",
      data:
        activeUsers?.map((item) => {
          return {
            x: moment(item?.date)?.format("DD/MM"),
            y: item?.count,
          };
        }) || [],
    },
  ];

  const getTickValues = () => {
    const tickValues = activeUsers
      ?.filter(
        (item, index) => index % Math.floor(activeUsers?.length / 5) === 0
      )
      .map((item) => moment(item?.date).format("DD/MM") || "");
    return tickValues;
  };

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3>
          <strong>{totalActiveUsers || 0}</strong>
          {` usuarios activos`}
        </h3>
        <img src={InfoIcon} alt="" />
      </header>
      <div style={{ height: "80%", width: "100%" }}>
        <ResponsiveLine
          data={dataChart}
          margin={{
            top: 10,
            right: 30,
            bottom: 40,
            left: 50,
          }}
          colors={["#E4056F"]}
          xScale={{
            type: "point",
          }}
          yScale={{
            type: "linear",
            stacked: true,
            min: "auto",
            max: "auto",
          }}
          stacked={true}
          curve="monotoneX"
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: activeUsers?.length > 8 ? getTickValues() : undefined,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          dotSize={10}
          dotColor="inherit:darker(0.3)"
          dotBorderWidth={2}
          dotBorderColor="#ffffff"
          enableDotLabel={true}
          dotLabel="y"
          dotLabelYOffset={-12}
          animate={true}
          isInteractive={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </section>
  );
};

export default ActiveUsersChart;
