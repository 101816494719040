import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Drawer, Collapse } from "antd";

import closeIcon from "../../../../Assets/Drawer/close-icon.svg";
import houseIcon from "../../../../Assets/Drawer/house-icon.svg";
import moneyIcon from "../../../../Assets/Drawer/money-icon.svg";
import chatIcon from "../../../../Assets/Drawer/chat-icon.svg";
import legalIcon from "../../../../Assets/Drawer/legal-icon.svg";
import questionIcon from "../../../../Assets/Drawer/question-icon.svg";
import userDrawerIcon from "../../../../Assets/Drawer/user-drawer-icon.svg";
import userIcon from "../../../../Assets/Drawer/user-icon.svg";
import userIconRose from "../../../../Assets/user-icon-rose.svg";
import { getUser } from "../../../../Helpers/auth";

const NavDrawer = ({
  visible,
  isLoged = false,
  onClose,
  handleLogout,
  firstName = "",
  lastName = "",
}) => {
  const history = useHistory();
  const { Panel } = Collapse;

  return (
    <Drawer
      placement="right"
      className={styles.drawer}
      closable
      closeIcon={null}
      maskStyle={{ backgroundColor: "transparent" }}
      onClose={() => onClose()}
      visible={visible}
      drawerStyle={{
        padding: "0px 12px",
        boxShadow: "2px 0px 10px rgba(0, 0, 0, 1)",
      }}
      key="right"
      width={360}
      footer={
        <div className={styles.drawerFooter}>
          {isLoged ? (
            <button
              className={styles.drawerFooterButton}
              onClick={() => {
                handleLogout();
                onClose();
              }}
            >
              <img src={closeIcon} alt="close" className="closeIcon" />
              Cerrar Sesión
            </button>
          ) : (
            <button
              className={styles.drawerFooterButtonCompany}
              onClick={() => history.push("/companyOnboarding")}
            >
              Eres una empresa? Forma parte!
            </button>
          )}
        </div>
      }
    >
      <div className={styles.drawerHeader}>
        <img
          src={isLoged ? userIcon : userIconRose}
          alt=""
          onClick={() =>
            isLoged ? history.push("/userHome") : history.push("/login")
          }
          className={`${
            isLoged
              ? styles.drawerHeaderUserIcon
              : styles.drawerHeaderUserIconRose
          }`}
        />
        {isLoged ? (
          <h2
            className={styles.drawerHeaderUserName}
            onClick={() =>
              isLoged ? history.push("/userHome") : history.push("/login")
            }
          >
            {`${firstName} ${lastName}`}
          </h2>
        ) : (
          <h2 className={styles.drawerHeaderMessage}>
            <Link to="/login">Inicia sesión</Link> o{" "}
            <Link to="/register">regístrate</Link>
          </h2>
        )}
      </div>

      <Collapse ghost accordion expandIconPosition="right">
        <Panel
          showArrow={false}
          header={
            <div
              className={styles.panelHeader}
              onClick={() => history.push("/")}
            >
              <img src={houseIcon} alt="" className={styles.panelHeaderImage} />
              <h3 className={styles.panelHeaderTitle}>Inicio</h3>
            </div>
          }
          key="1"
        />
        {isLoged && getUser()?.role === "User" && (
          <Panel
            header={
              <div className={styles.panelHeader}>
                <img
                  src={userDrawerIcon}
                  alt=""
                  className={styles.panelHeaderImage}
                />
                <h3 className={styles.panelHeaderTitle}>Mi cuenta</h3>
              </div>
            }
            key="2"
          >
            <nav className={styles.panelNav}>
              <span
                className={styles.panelNavLink}
                onClick={() =>
                  isLoged ? history.push("/userHome") : history.push("/login")
                }
              >
                Datos personales
              </span>
              <span
                className={styles.panelNavLink}
                onClick={() =>
                  isLoged
                    ? history.push("/userPartners")
                    : history.push("/login")
                }
              >
                Mis socios
              </span>
            </nav>
          </Panel>
        )}
        {isLoged && getUser()?.role !== "User" && (
          <Panel
            showArrow={false}
            header={
              <div
                className={styles.panelHeader}
                onClick={() => history.push("/companyHome")}
              >
                <img
                  src={userDrawerIcon}
                  alt=""
                  className={styles.panelHeaderImage}
                />
                <h3 className={styles.panelHeaderTitle}>Mi cuenta</h3>
              </div>
            }
            key="2"
          />
        )}
        {!isLoged && (
          <Panel
            showArrow={false}
            header={
              <div
                className={styles.panelHeader}
                onClick={() => history.push("/login")}
              >
                <img
                  src={userDrawerIcon}
                  alt=""
                  className={styles.panelHeaderImage}
                />
                <h3 className={styles.panelHeaderTitle}>Mi cuenta</h3>
              </div>
            }
            key="2"
          />
        )}
        <Panel
          showArrow={false}
          header={
            <div className={styles.panelHeader}>
              <img src={moneyIcon} alt="" className={styles.panelHeaderImage} />
              <h3
                onClick={() => history.push("/")}
                className={styles.panelHeaderTitle}
              >
                Mis descuentos
              </h3>
            </div>
          }
          key="3"
        />
        <Panel
          showArrow={false}
          header={
            <div
              className={styles.panelHeader}
              onClick={() => history.push("/contact")}
            >
              <img src={chatIcon} alt="" className={styles.panelHeaderImage} />
              <h3 className={styles.panelHeaderTitle}>Sugerencias</h3>
            </div>
          }
          key="4"
        />
        <Panel
          header={
            <div className={styles.panelHeader}>
              <img src={legalIcon} alt="" className={styles.panelHeaderImage} />
              <h3 className={styles.panelHeaderTitle}>Legales</h3>
            </div>
          }
          key="5"
        >
          <nav className={styles.panelNav}>
            <Link to="/" className={styles.panelNavLink}>
              Términos y condiciones
            </Link>
            <Link to="/" className={styles.panelNavLink}>
              Politicas de privacidad
            </Link>
            <Link to="/" className={styles.panelNavLink}>
              Legales
            </Link>
          </nav>
        </Panel>
        <Panel
          header={
            <div className={styles.panelHeader}>
              <img
                src={questionIcon}
                alt=""
                className={styles.panelHeaderImage}
              />
              <h3 className={styles.panelHeaderTitle}>Ayuda</h3>
            </div>
          }
          key="6"
        >
          <nav className={styles.panelNav}>
            <Link to="/contact" className={styles.panelNavLink}>
              Contáctanos
            </Link>
            <Link to="/" className={styles.panelNavLink}>
              Preguntas frecuentes
            </Link>
            <Link to="/" className={styles.panelNavLink}>
              Como funciona
            </Link>
          </nav>
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default NavDrawer;
