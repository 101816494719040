import gql from "graphql-tag";

const REGISTER = gql`
mutation register($input: CreateUserInput!){
    register(input: $input) {
      _id
    }
  }
`;

export { REGISTER }