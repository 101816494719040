import { useState } from "react";
import styles from "./index.module.css";
import { Collapse, notification as AntNotification, Table } from "antd";
import { useMutation } from "@apollo/client";
import EditIcon from "../../Assets/edit-icon.svg";
import { withRouter } from "react-router-dom";
import { SELECT_NOTIFICATION_PROMOTIONS } from "../../Graphql/Mutations";

const { Panel } = Collapse;

const PromotionsTable = ({
  notification,
  getNotification,
  selectedPromotions,
  promotionsAvailable,
  refreshEmailPreview,
}) => {
  const [tablePageSize, setTablePageSize] = useState(5);

  const [
    selectNotificationPromotions,
    { loading: isSelectingNotificationPromotions },
  ] = useMutation(SELECT_NOTIFICATION_PROMOTIONS);

  const getUpdatedNotificationPromotions = (promotion) => {
    const isPromotionSelected = selectedPromotions?.some(
      (promo) => promo._id === promotion._id
    );

    if (!isPromotionSelected) {
      const selectedPromotionIds = selectedPromotions?.map(
        (promo) => promo._id
      );
      return [...selectedPromotionIds, promotion._id];
    }

    const filteredPromotions = selectedPromotions?.filter(
      (promo) => promo._id !== promotion._id
    );
    const updatedPromotionsIds = filteredPromotions?.map((promo) => promo._id);
    return updatedPromotionsIds;
  };

  const handleSelectNotificationPromotions = (promotion) => {
    const updatedNotificationPromotions =
      getUpdatedNotificationPromotions(promotion);

    selectNotificationPromotions({
      variables: {
        id: notification?._id,
        promotions: updatedNotificationPromotions,
      },
    })
      .then(() => {
        AntNotification.success({
          message: "Operación exitosa",
          description: "Promociones seleccionadas actualizadas correctamente.",
          placement: "bottomRight",
        });
        getNotification();
        refreshEmailPreview();
      })
      .catch((error) => {
        const errorMessage =
          typeof error?.message === "string"
            ? error.message
            : "Hubo un error al intentar actualizar las promociones de la notificación, inténtelo de nuevo.";
        AntNotification.error({
          message: "Error",
          description: errorMessage,
          placement: "bottomRight",
        });
      });
  };

  const mappedPromotions = promotionsAvailable?.map((promotion) => ({
    ...promotion,
    selected: selectedPromotions?.some((promo) => promo._id === promotion._id),
  }));

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (name) => (
        <p
          className={`${styles.tableSingleRowValue} ${styles.tableCenteredText} `}
        >
          {name}
        </p>
      ),
    },
    {
      title: "Socio",
      dataIndex: "company",
      key: "company",
      render: (company) => (
        <p className={styles.tableSingleRowValue}>{company?.name}</p>
      ),
    },
    {
      title: "Nombre del socio",
      dataIndex: "localName",
      key: "localName",
      render: (localName) => (
        <p className={styles.tableSingleRowValue}>{localName}</p>
      ),
    },
    {
      title: "Seleccionado",
      dataIndex: "selected",
      key: "selected",
      render: (selected) => (
        <p className={styles.tableSingleRowValue}>
          <span
            style={{
              color: selected ? "#49BA35" : "#FF3247",
              fontWeight: "bold",
            }}
          >
            {selected ? "Si" : "No"}
          </span>
        </p>
      ),
    },
    {
      title: "",
      key: "action",
      render: (promotion) => (
        <div className={styles.tableActions}>
          <button
            className={styles.tableActionsButton}
            disabled={isSelectingNotificationPromotions}
            style={{ opacity: isSelectingNotificationPromotions ? 0.5 : 1 }}
            onClick={() => handleSelectNotificationPromotions(promotion)}
          >
            <img src={EditIcon} alt="Icon" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <section className={styles.container}>
      <Table
        className={styles.table}
        dataSource={mappedPromotions}
        onChange={(pagination) => setTablePageSize(pagination?.pageSize || 5)}
        pagination={{
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          pageSize: tablePageSize || 5,
          defaultPageSize: 5,
          showSizeChanger: true,
        }}
        columns={columns}
      />

      <Collapse
        className={styles.accordion}
        expandIconPosition="right"
        accordion
      >
        {mappedPromotions?.map((promotion, index) => (
          <Panel
            header={promotion?.name}
            key={index + promotion?._id}
            className={styles.accordionPanel}
          >
            <div className={styles.accordionContent}>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Nombre:</h4>
                <p className={styles.tableSingleRowValue}>{promotion?.name}</p>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Socio:</h4>
                <p className={styles.tableSingleRowValue}>
                  {promotion?.company?.name}
                </p>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Nombre del socio:
                </h4>
                <p className={styles.tableSingleRowValue}>
                  {promotion?.localName}
                </p>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Seleccionado:
                </h4>
                <p className={styles.tableSingleRowValue}>
                  {promotion?.selected ? "Si" : "No"}
                </p>
              </div>

              <div className={styles.tableActions}>
                <button
                  className={styles.tableActionsButton}
                  disabled={isSelectingNotificationPromotions}
                  style={{
                    opacity: isSelectingNotificationPromotions ? 0.5 : 1,
                  }}
                  onClick={() => handleSelectNotificationPromotions(promotion)}
                >
                  <img src={EditIcon} alt="" />
                </button>
              </div>
            </div>
          </Panel>
        ))}
      </Collapse>
    </section>
  );
};

export default withRouter(PromotionsTable);
