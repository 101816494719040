import gql from "graphql-tag";

const SEGMENTS = gql`
query segments($companyId: String!){
  segments(companyId: $companyId){
    _id
    name
    company{
      _id
    }
  }
}
`;


export { SEGMENTS }