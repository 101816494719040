import styles from "./index.module.css";
import { Col, Form, Modal, Row, Select } from "antd";

const PromotionsFiltersModal = ({
  sortBy,
  setSortBy,
  filter,
  setFilter,
  showPromotionsFiltersModal,
  setShowPromotionsFiltersModal,
}) => {
  const { Option } = Select;

  return (
    <Modal
      visible={showPromotionsFiltersModal}
      onCancel={() => setShowPromotionsFiltersModal(false)}
      footer={null}
      bodyStyle={{ padding: "0px" }}
      width={"95%"}
      className={styles.modal}
      style={{ maxWidth: "600px" }}
      centered
      closeIcon={<></>}
    >
      <section className={styles.container}>
        <Row justify="center">
          <Col
            xs={24}
            md={0}
            className={`${styles.formItemsContainer} ${styles.sortInputsContainer}`}
          >
            <Form.Item className={styles.formItem} label="Ordenar por">
              <Select
                className={styles.selectInputWithLabel}
                placeholder="Selecciona una opción"
                defaultValue={sortBy?.field || "CreatedAt"}
                value={sortBy?.field || "CreatedAt"}
                onChange={(value) => setSortBy({ ...sortBy, field: value })}
              >
                <Option value="Code">ID</Option>
                <Option value="CreatedAt">Fecha</Option>
                <Option value="Visible">Visibilidad</Option>
                <Option value="Status">Estado</Option>
              </Select>
            </Form.Item>
            <Form.Item className={styles.formItem} label="">
              <Select
                className={styles.selectInputForValue}
                placeholder="Ordenar por"
                defaultValue={sortBy?.direction || "DESC"}
                value={sortBy?.direction || "DESC"}
                onChange={(value) => setSortBy({ ...sortBy, direction: value })}
              >
                <Option value="ASC">Ascendente</Option>
                <Option value="DESC">Descendente</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={12}
            md={0}
            className={`${styles.formItemsContainer} ${styles.filterInputsContainer}`}
          >
            <Form.Item className={styles.formItem} label="Visibilidad">
              <Select
                className={`${styles.selectInputWithLabel} ${styles.selectInputForFilter}`}
                placeholder="Filtrar por"
                defaultValue={filter?.visible || undefined}
                value={filter?.visible || undefined}
                onChange={(value) =>
                  setFilter({ ...filter, visible: value })
                }
              >
                <Option value={true}>Visible</Option>
                <Option value={false}>No visible</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={12}
            md={0}
            className={`${styles.formItemsContainer} ${styles.filterInputsContainer}`}
          >
            <Form.Item className={styles.formItem} label="Estado">
              <Select
                className={`${styles.selectInputWithLabel} ${styles.selectInputForFilter}`}
                placeholder="Filtrar por"
                defaultValue={filter?.status || undefined}
                value={filter?.status || undefined}
                onChange={(value) => setFilter({ ...filter, status: value })}
              >
                <Option value="Active">Activado</Option>
                <Option value="Inactive">No activado</Option>
                <Option value="Expired">Expirado</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </section>
    </Modal>
  );
};

export default PromotionsFiltersModal;
