import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

import promotionsIcon from "../../../CompanyLayout/Assets/CompanyRoseIcons/promotions-icon.svg";
import reportsIcon from "../../../CompanyLayout/Assets/CompanyRoseIcons/reports-icon.svg";
import usersIcon from "../../../CompanyLayout/Assets/CompanyRoseIcons/users-icon.svg";
import commentsIcon from "../../../CompanyLayout/Assets/CompanyRoseIcons/comments-icon.svg";
import companyIcon from "../../../CompanyLayout/Assets/CompanyRoseIcons/company-icon.svg";
import companiesIcon from "../../../CompanyLayout/Assets/CompanyRoseIcons/companies-icon.svg";
import notificationsIcon from "../../../CompanyLayout/Assets/CompanyRoseIcons/notification-icon.svg";

const CompanyNav = ({ role }) => {
  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>
        <li className={styles.navItem}>
          <Link to="/companyPromotions" className={styles.navLink}>
            <img src={promotionsIcon} alt="" className={styles.navLinkIcon} />
            <span className={styles.navLinkText}>Promociones</span>
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/companyReport" className={styles.navLink}>
            <img src={reportsIcon} alt="" className={styles.navLinkIcon} />
            <span className={styles.navLinkText}>Reportes</span>
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/companyUsers" className={styles.navLink}>
            <img src={usersIcon} alt="" className={styles.navLinkIcon} />
            <span className={styles.navLinkText}>Usuarios</span>
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/companyReviews" className={styles.navLink}>
            <img src={commentsIcon} alt="" className={styles.navLinkIcon} />
            <span className={styles.navLinkText}>Comentarios</span>
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/companyHome" className={styles.navLink}>
            <img src={companyIcon} alt="" className={styles.navLinkIcon} />
            <span className={styles.navLinkText}>Mi empresa</span>
          </Link>
        </li>
        {role === "SuperAdmin" && (
          <li className={styles.navItem}>
            <Link to="/companyCompanies" className={styles.navLink}>
              <img src={companiesIcon} alt="" className={styles.navLinkIcon} />
              <span className={styles.navLinkText}>Empresas</span>
            </Link>
          </li>
        )}
        {role === "SuperAdmin" && (
          <li className={styles.navItem}>
            <Link to="/companyNotifications" className={styles.navLink}>
              <img
                src={notificationsIcon}
                alt=""
                className={styles.navLinkIcon}
              />
              <span className={styles.navLinkText}>Notificaciones</span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default CompanyNav;
