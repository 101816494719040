import React, { useState } from 'react'
import { Button, Modal, Upload,  notification, Tooltip } from 'antd'
import { CloseOutlined } from "@ant-design/icons"
import { useApolloClient, useMutation } from '@apollo/client'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { UPLOAD_PROMOTIONS_BULK } from './Graphql/Mutation'
import ExcelImg from '../Asset/excel-img.png'
import AlertImgError from '../Asset/alert-img-error.png'
import SuccessImg from '../Asset/succes-Img.png'
import "./index.css";
import downloadTemplate from '../../../../../../../../Helpers/downloadTemplate'
import { parseExcelTemplateToJson } from '../../../../../../../../Helpers/parseExcelTemplateToJson'
import { PROMOTIONS_BY_COMPANY } from '../../../../../../Graphql/Queries'
import { getCompanyId } from '../../../../../../../../Helpers/auth'

const PromotionsExcelModal = ({visible, onCancel, fileUploadSuccess, setFileUploadSuccess, history}) => {

  const [loadingExcelFile, setLoadingExcelFile] = useState(false)
  const [uploadPromotionsMutation, { loading: loadingUploadPromotionsMutation }] = useMutation(UPLOAD_PROMOTIONS_BULK)
  const client = useApolloClient()

  const download = () => downloadTemplate('promotions', []).catch(e => notification.error({
    message: `Error`,
    description:
    e?.message || 'Ha ocurrido un error al descargar el archivo',
    placement: "bottomRight",
  }))

  

  const uploadPromotions = async({originFileObj: file}) => {

    try { 

      const data = await parseExcelTemplateToJson(file, 'promotions')
      await uploadPromotionsMutation({
        variables:{
          input: data,
          companyId: getCompanyId()
        }
      })

      setFileUploadSuccess(true)
      setLoadingExcelFile(false)
      onCancel()
      client.refetchQueries({
        include: [PROMOTIONS_BY_COMPANY]
      })
      notification.success({
        message: `Éxito`,
        description:
          'La carga masiva se ha completado con éxito',
        placement: "bottomRight",
      });
    } catch (error) {
      notification.error({
        message: `Error`,
        description:
          error?.message || 'Ha ocurrido un error al cargar el archivo',
        placement: "bottomRight",
      });

      setFileUploadSuccess(false)
      setLoadingExcelFile(false)

    }
   
  }

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

const handleChange = info => {
    if (info.file.status === 'uploading') {
        setLoadingExcelFile(true);
      return;
    }
    if (info.file.status === 'done') {
      uploadPromotions(info.file)
    }
  };

    return (
        <Modal
        className="modalBody"
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ top: 50 }}
        closeIcon={<CloseOutlined />}
      >
        {
          fileUploadSuccess === false ? (
            <div className="PromotionsExcelModal-content">
              <img src={AlertImgError} alt="logo" style={{paddingTop:"20px"}} className="logoIcon"/>
              <h1 className="PromotionsExcelModal-title">Error en carga de archivo Favor intente de nuevo</h1>
              <Button className="CreateAndEditUserModal-Button" onClick={() => setFileUploadSuccess(null)}>
                Reintentar
              </Button>
              <Button
                className="CreateAndEditUserModal-Button"
                type="button"
                htmlType="submit"
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </div>
          ) : fileUploadSuccess === null ? (
        <div className="PromotionsExcelModal-content">
          <h1 className="PromotionsExcelModal-title">Favor proceda a cargar archivo</h1>
          <h3 className="PromotionsExcelModal-h3">
            Descarga formato aquí :<img src={ExcelImg} alt="logo" className="logoIcon" style={{cursor: 'pointer'}} onClick={() => download()}/>
          </h3>
          <Tooltip 
            color={'white'}
            title={
              <div style={{color: 'black'}}>
                <p><b>Categoría:</b> hace referencia a la categoría que pertenecerá la promoción. Puede ser: <b>Compras, Sabores, Decoraciones, Salud, Viajes o Otros</b>.</p>
                <p><b>Estados:</b> hace referencia al estado que estará inicialmente la promoción. Puede ser: <b>Activa, Inactiva</b>.</p>
                <p><b>Fechas:</b> tanto para la fecha de inicio como fecha fin de la promoción, el formato es el siguiente: <b>dd/mm/yy</b>.</p>
              </div>
            }>
            <p style={{padding: 20, fontWeight: 'bold'}}>¿No sabes que colocar en cada campo? Mira esta leyenda (?)</p>
          </Tooltip>
          <Upload
            name="localLogo"
            showUploadList={false}
            customRequest={dummyRequest}
            onChange={handleChange}
          >
            <Button className="CreateAndEditUserModal-Button" loading={loadingUploadPromotionsMutation || loadingExcelFile}>
              Adjuntar archivo
            </Button>
          </Upload>
          <Button
            className="CreateAndEditUserModal-Button"
            type="button"
            htmlType="submit"
            onClick={onCancel}
          >
            Cancelar
          </Button>
        </div>
          ): (
            <div className="PromotionsExcelModal-content">
              <img src={SuccessImg} alt="logo" style={{paddingTop:"20px"}} className="logoIcon"/>
              <h1 className="PromotionsExcelModal-title">Su archivo se cargó correctamente</h1>
            </div>
          )
        }
           

      </Modal>
    )
}

export default withRouter(PromotionsExcelModal)