import React from "react";
import MainHeader from "../../Components/Header";
import Promotions from "./Components/Promotions";
import MainFooter from "../../Components/Footer";

const PromotionTypeScreen = () => {
  return (
    <>
      <MainHeader />
      <section className="background">
        <Promotions />
      </section>
      <MainFooter />
    </>
  );
};

export default PromotionTypeScreen;
