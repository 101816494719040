import React, { useState } from "react";
import styles from "./index.module.css";
import { Row, Spin, Table, notification, Collapse } from "antd";
import "./index.css";
import { useMutation } from "@apollo/client";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import { DELETE_PROMOTION } from "../../Graphql/Mutation";
import { setAccessToken, getUser } from "../../../../Helpers/auth";
import ModalPromotion from '../../../../Components/ModalPromotion'
import editIcon from "../../Assets/edit-icon.svg";
import NoPromotions from "../../../../Components/NoPromotions";
import deleteIcon from "../../Assets/delete-icon.svg";
import DeletePromotionModal from "./Components/DeletePromotionModal";
import * as dayjs from 'dayjs'
import "dayjs/locale/es"
dayjs.locale("es");

const { Panel } = Collapse;

const PromotionsTable = ({
  loadingTable, 
  errorTable, 
  dataTable, 
  setPage,
  promotion,
  setPromotion,
  promotionsByCompanyQuery
}) => {

  const [tablePageSize, setTablePageSize] = useState(5)
  const [detailModalVisible, setDetailModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  // const [selectedPromotion, setSelectedPromotion] = useState({})
  const [selectedPromotion] = useState({})
  const [deletePromotion, { loading: loadingDelete}] = useMutation(DELETE_PROMOTION)
  const [refreshToken] = useMutation(REFRESH_TOKEN)

  const onCancelDetailModal = () => {
    setDetailModalVisible(false)
  }

  const onCancelDeleteModal = () => {
    setDeleteModalVisible(false)
  }

// const handleDetailModal = promotion => {
//   setSelectedPromotion({...promotion, company: getUser().company !== null ? getUser().company : promotion.company})
//     setDetailModalVisible(true)
// }

  const handleDeletePromotion = () => {
      refreshToken().then(res =>{
        setAccessToken(res.data.refreshToken.token)
        deletePromotion({
          variables:{
            input:{
              _id: promotion._id
            }
          }
        }).then(() => {
          notification.success({
            message: `Éxito`,
            description:
              'La promoción ha sido eliminada exitósamente.',
            placement: "bottomRight",
          });
          setDeleteModalVisible(false)
          promotionsByCompanyQuery()
        })
      }).catch(e => {
        console.log(e)
        notification.error({
          message: `Error`,
          description:
            'Hubo un error al intentar eliminar la promoción.',
          placement: "bottomRight",
        });
      })
    
  }

  const handleModal = record => {
    setPromotion(record)
    setDeleteModalVisible(true)
  }

  const handleEdit = record => {

    if(!getUser().company){
      if(JSON.parse(localStorage.getItem('companySelected')) === 'all' || JSON.parse(localStorage.getItem('companySelected')) === null){
        notification.warning({
          message: `Aviso`,
          description:
            'Para poder editar una promoción, por favor escoge una empresa en específico para continuar.',
          placement: "bottomRight",
        });
      }else{
        setPromotion(record)
        setPage('edit')
      }
    }else{
      setPromotion(record)
      setPage('edit')
    }
  }

  const columns = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      render: (code) => (
        <p className={styles.tableName}>{code}</p>
      )
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (_,record) => <p className={styles.tableName}>{record.discount}% de descuento con {record.localName}</p>
    },
    {
      title: "Hasta",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date) => (
        <>
        <p className={styles.tableName}>{dayjs(date).format("DD-MM-YYYY", "es")}</p>
        </>
      )
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
        {
          status === 'Active' ? (
            <p className={`${styles.tableName} ${styles.tableNameStatusActive}`}>Activo</p>
          ): null
        }
        {
          status === 'Inactive' ? (
            <p className={`${styles.tableName} ${styles.tableNameStatusInactive}`}>Inactivo</p>
          ): null
        }
        {
          status === 'Expired' ? (
            <p className={`${styles.tableName} ${styles.tableNameStatusVence}`}>Vencido</p>
          ): null
        }
        </>
      )
    },
    {
      title: "Visible",
      dataIndex: "visible",
      key: "visible",
      render: (visible) => (
        <p
        className={`${styles.tableName} ${
          visible
            ? styles.tableNameVisibleTrue
            : styles.tableNameVisibleFalse
        }`}
      >
        {visible ? "Si" : "No"}
      </p>
      )
    },
    {
      title: "Creado por",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (createdBy) => (
        <p className={styles.tableName}>{createdBy.email}</p>
      )
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <div className={styles.tableDeskActions}>
          <button
            onClick={() => handleEdit(record)}
            className={styles.tableActionsButton}
          >
            <img src={editIcon} alt="" />
          </button>
          <button
            onClick={() => handleModal(record)}
            className={styles.tableActionsButton}
          >
            <img src={deleteIcon} alt="" />
          </button>
        
        </div>
      ),
    },
  ];

  const checkSuperAdminColumn = () => {
    if(getUser().role === 'SuperAdmin'){
      columns.splice(5, 0, {
        title: "Empresa",
        dataIndex: "company",
        key: "company",
        render: (company, _record) => (
            <p className={styles.tableName}>{company.name}</p> 
        )
      });
      return columns
    }else return columns
  }

  if(loadingTable) {
    return (
    <Row justify="center" align="center">
      <Spin /> 
    </Row>
    )
  }
  
  if (errorTable) {
    return (
    <p>Hubo un error al intentar cargar la data</p>
    )
  }

  return (
    <>
    <DeletePromotionModal 
      visible={deleteModalVisible}
      onCancel={onCancelDeleteModal}
      handleDeletePromotion={handleDeletePromotion}
      loadingDelete={loadingDelete}
      // todo: arreglar el delete modal de las promociones
    />
      <ModalPromotion
        visible={detailModalVisible}
        promotion={selectedPromotion}
        onCancel={onCancelDetailModal}
      />

      <Table
        rowKey={(col) => col._id}
        columns={checkSuperAdminColumn()}
        dataSource={dataTable.promotionsByCompany}
        className={styles.table}
        onChange={(pagination) => setTablePageSize(pagination?.pageSize || 5)}
        pagination={{ 
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          pageSize: tablePageSize || 5,
          defaultPageSize: 5,
        }}
      />

      <Collapse
        className={dataTable?.promotionsByCompany?.length === 0 ? styles.emptyAccordion : styles.accordion}
        expandIconPosition="right"
        accordion
        >
        {dataTable?.promotionsByCompany?.length === 0 ? <NoPromotions className={styles.noPromotions} /> : (
        <>
        <h2 className={styles.accordionTitle}>Nombre</h2>
        {dataTable?.promotionsByCompany?.map((promo, index) => (
          <Panel
            header={
              <h3 className={styles.panelHeaderTitle}>
                {promo.discount}% de descuento con {promo.localName}{" "}
              </h3>
            }
            key={index}
            className={styles.accordionPanel}
          >
            <div className={styles.accordionContent}>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Hasta:</h4>
                <p className={styles.tableName}>
                  {dayjs(promo.dueDate).format("DD-MM-YYYY", "es")}
                </p>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Estado:</h4>
                {promo.status === "Active" ? (
                  <p
                    className={`${styles.tableName} ${styles.tableNameStatusActive}`}
                  >
                    Activo
                  </p>
                ) : null}
                {promo.status === "Inactive" ? (
                  <p
                    className={`${styles.tableName} ${styles.tableNameStatusInactive}`}
                  >
                    Inactivo
                  </p>
                ) : null}
                {promo.status === "Expired" ? (
                  <p
                    className={`${styles.tableName} ${styles.tableNameStatusVence}`}
                  >
                    Vencido
                  </p>
                ) : null}
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Visible:</h4>
                <p
                  className={`${styles.tableName} ${
                    promo.visible
                      ? styles.tableNameVisibleTrue
                      : styles.tableNameVisibleFalse
                  }`}
                >
                  {promo.visible ? "Si" : "No"}
                </p>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Creado por:
                </h4>
                <p className={styles.tableName}>{promo.createdBy.email}</p>
              </div>
              <div className={styles.tableActions}>
                <button
                  onClick={() => handleEdit(promo)}
                  className={styles.tableActionsButton}
                >
                  <img src={editIcon} alt="" />
                </button>
                <button
                  onClick={() => handleModal(promo)}
                  className={styles.tableActionsButton}
                >
                  <img src={deleteIcon} alt="" />
                </button>
              </div>
            </div>
          </Panel>
        ))}</>
        )}

      </Collapse>
    </>
  );
  
};

export default PromotionsTable;