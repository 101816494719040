import React, { useState } from "react";
import { Button, Input, Spin, notification, Result } from "antd";
import { FrownOutlined,SearchOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Footer from "../../Components/Footer";
import { useMutation } from "@apollo/client";
import { useCompanies } from "./Graphql/CustomHooks";
import { ADD_ALLIED_TO_USER } from "./Graphql/Mutations";
import "./index.css";

const UserAddAllyScreen = ({ history }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [addAlliedToUser] = useMutation(ADD_ALLIED_TO_USER);
  const {error, loading, data: companiesData,} = useCompanies(currentPage, search);

  const handleSearch = (e) => setSearch(e.target.value);

  const toggleCompany = (companyId) => {
    if (selectedCompanies.includes(companyId)) {
      setSelectedCompanies(selectedCompanies.filter((c) => c !== companyId));
    } else setSelectedCompanies([...selectedCompanies, companyId]);
  };

  const handleSubmit = async () => {
    try {
      await addAlliedToUser({
        variables: {
          companiesIds: selectedCompanies,
        },
      }).then(() => history.push("/userHome"));
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description:
          "Verifica que las empresas seleccionadas no hallan sido agregadas previamente a tu lista de aliados e intenta de nuevo.",
        placement: "bottomRight",
      });
    }
  };

  return (
    <>
      <div className="h1-userScreen">Agregar aliado</div>

      <div style={{ textAlign: "center" }}>
        <div className="SearchWeb-AddAlly">
          <Input
            className="searchInput-AddAlly"
            onChange={(e) => handleSearch(e)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setCurrentPage(1);
                setSearch("");
              }
            }}
            placeholder="Búscador"
            prefix={<SearchOutlined className="searchIcon" />}
            spellCheck="false"
            type="search"
            value={search}
          />
        </div>

        <section className="allies-container">
          {loading && <Spin />}

          {(!loading && companiesData.searchCompany?.data.length === 0) || error 
          ? (
            <Result
              icon={<FrownOutlined />}
              title="No se encontraron aliados"
            />
          ) : null}

          {companiesData &&
            companiesData.searchCompany.data.length > 0 &&
            companiesData.searchCompany.data.map((company) => (
              <div
                className={`allied ${
                  selectedCompanies.includes(company._id)
                    ? "allied--selected"
                    : ""
                }`}
                key={company._id}
                onClick={() => toggleCompany(company._id)}
              >
                <img
                  className="allied__logo"
                  src={company.logo}
                  alt={company.name}
                  title={company.name}
                />
              </div>
            ))}
        </section>
      </div>

      <div style={{ textAlign: "center", marginBottom: "45px" }}>
        {companiesData &&
          companiesData.searchCompany.metadata.totalDocs >
            companiesData.searchCompany.data.length && (
            <button
              className="a-userAddAllyScreen"
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Ver más
            </button>
          )}

        <div>
          <Button
            style={{ marginTop: "20px" }}
            className="userAddAllyScreen-ButtonHome"
            onClick={() => {
              if (selectedCompanies.length > 0) {
                handleSubmit();
              } else {
                notification.error({
                  message: "Error",
                  description: "Debe seleccionar al menos un aliado",
                  placement: "bottomRight",
                });
              }
            }}
            disabled={selectedCompanies.length === 0}
          >
            Agregar
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(UserAddAllyScreen);
