import styles from "./index.module.css";
import { Form, Input, Button, Select, notification } from "antd";

const ContactForm = ({ formData, setFormData, sendEmail, loading }) => {
  const { Option } = Select;

  const formValidation = () => {
    const { fullName, email, phone, reason, message } = formData;
    if (
      fullName.length === 0 ||
      email.length === 0 ||
      phone.length === 0 ||
      reason.length === 0 ||
      message.length === 0
    ) {
      notification.error({
        message: `Error`,
        description: "Debe completar todos los campos",
        placement: "bottomRight",
      });
      return false;
    }
    return true;
  };

  return (
    <Form className={styles.form} id="form-footer" onFinish={() => sendEmail()}>
      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="name">
          Nombre completo
        </label>
        <input
          className={styles.formItemInput}
          autoComplete="off"
          placeholder="Ingresa tu nombre completo"
          type="text"
          name="name"
          value={formData.fullName}
          onChange={(e) =>
            setFormData({ ...formData, fullName: e.target.value })
          }
        />
      </div>

      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="email">
          Correo electrónico
        </label>
        <input
          className={styles.formItemInput}
          spellCheck="false"
          name="email"
          autoComplete="off"
          type="email"
          placeholder="Ingresa tu correo electrónico"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </div>

      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="phone">
          Número de teléfono
        </label>
        <input
          className={styles.formItemInput}
          name="phone"
          autoComplete="off"
          type="tel"
          placeholder="Ingresa tu número de teléfono"
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        />
      </div>

      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="reason">
          Motivo
        </label>
        <Select
          name="reason"
          showArrow={true}
          placeholder="Selecciona un motivo"
          onChange={(value) => setFormData({ ...formData, reason: value })}
          className={`${styles.formItemInput} ${styles.formItemInputSelect} `}
        >
          <Option value="Sugerencia">Sugerencia</Option>
          <Option value="Reclamo">Reclamo</Option>
          <Option value="Mejoras">Mejoras</Option>
          <Option value="Contacto">Contacto</Option>
        </Select>
      </div>

      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="message">
          Mensaje
        </label>
        <Input.TextArea
          className={`${styles.formItemInput} ${styles.formItemInputTextarea}`}
          autoComplete="off"
          placeholder="Escribenos tu mensaje"
          type="text"
          name="message"
          value={formData.message}
          onChange={(e) =>
            setFormData({ ...formData, message: e.target.value })
          }
        />
      </div>

      <div className={styles.formItem}>
        <Button
          className={styles.formButton}
          loading={loading}
          onClick={() => formValidation() && sendEmail()}
        >
          Enviar mensaje
        </Button>
      </div>
    </Form>
  );
};

export default ContactForm;
