import gql from "graphql-tag";

const FORGOT_PASSWORD = gql`
mutation forgotPassword($input: ForgotPasswordInput!){
    forgotPassword(input: $input){
        _id
    }
  }
`;

export { FORGOT_PASSWORD }