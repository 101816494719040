import gql from "graphql-tag";

const OVERALL_REPORT = gql`
query{
    overallReport{
      totalUsers
      totalUsersLastSevenDays
      totalActivePromotions
      totalPromotionsViews
      totalPromotionsViewsLastMonth
    }
  }
`;


export { OVERALL_REPORT }