import Car from '../Assets/Shopping_car.svg'
import Meal from '../Assets/Meal.svg'
import Lamp from '../Assets/Lamp.svg'
import Icon from '../Assets/Icon_4.svg'
import Plane from '../Assets/Plane.svg'
import ButtonIcon from '../Assets/Button_H.svg'

const typePromotions = [
    "shopping",
    "flavor",
    "decoration",
    "health",
    "travel",
    "other"
]

const checkIcon = type =>{
    if(type === 'shopping') return Car
    else if(type === 'flavor') return Meal
    else if(type === 'decoration') return Lamp
    else if(type === 'health') return Icon
    else if(type === 'travel') return Plane
    else if(type === 'other') return ButtonIcon
}

const checkLabel = type =>{
    if(type === 'shopping') return 'Shopping'
    else if(type === 'flavor') return 'Sabores'
    else if(type === 'decoration') return 'Decoración'
    else if(type === 'health') return 'Salud y Belleza'
    else if(type === 'travel') return 'Panoramas'
    else if(type === 'other') return 'Otros'
}

const isPromotion = type => {
    if(type === 'shopping') return true
    else if(type === 'flavor') return true
    else if(type === 'decoration') return true
    else if(type === 'health') return true
    else if(type === 'travel') return true
    else if(type === 'other') return true
    else return false
}

const colorPromotion = type => {
    if(type === 'shopping') return '#FCC02C'
    else if(type === 'flavor') return '#E4056F'
    else if(type === 'decoration') return '#FCC02C'
    else if(type === 'health') return '#E4056F'
    else if(type === 'travel') return '#FCC02C'
    else if(type === 'other') return '#E4056F'
    else return false
}

export { checkIcon, colorPromotion, isPromotion, typePromotions, checkLabel }