import { useState } from "react";
import styles from "./index.module.css";
import Notifications from "./Components/Notifications";
import NotificationsManagement from "./Components/NotificationsManagement";
import NotificationDetails from "./Components/NotificationDetails";

const CompanyNotifications = () => {
  const [showNotificationDetails, setShowNotificationDetails] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});

  return (
    <section className={styles.container}>
      {showNotificationDetails ? (
        <NotificationDetails
          notificationId={selectedNotification?._id}
          goBack={() => setShowNotificationDetails(false)}
        />
      ) : (
        <>
          <header className={styles.header}>
            <h2 className={styles.title}>Notificaciones a usuarios</h2>
          </header>
          <NotificationsManagement />
          <Notifications
            setShowNotificationDetails={setShowNotificationDetails}
            setSelectedNotification={setSelectedNotification}
            selectedNotification={selectedNotification}
          />
        </>
      )}
    </section>
  );
};

export default CompanyNotifications;
