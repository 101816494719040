import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./index.css"

const WarningModal = ({ isVisible, onCancel, onConfirm }) => {
  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
      closeIcon={<CloseOutlined className="warning-modal__close-button" />}
    >
      <div className="warning-modal">
        <h2 className="warning-modal__title">Eliminar comentario</h2>

        <p className="warning-modal__description">
          ¿Estas seguro que quieres eliminar esta review?
        </p>

        <div className="warning-modal__buttons-container">
          <button className="warning-modal__button" onClick={() => onConfirm()}>
            Eliminar
          </button>
          <button className="warning-modal__button" onClick={() => onCancel()}>
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
