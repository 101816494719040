import React from "react";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";

const Welcome = () => {
  const history = useHistory();

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Empieza a formar parte de LOIA!</h2>

      <p className={styles.description}>
        Las personas recibimos muchos e-mail promocionales de muchas empresas,
        mucha gente cada vez menos personas abren estos mails por recibir tantas
        comunicaciones, algunos van directo a la bandeja de Spam.
      </p>
      <p className={styles.description}>
        Unete a la red de empresas de LOIA y comienza a publicar tus promociones
        a través de nuestra plataforma, recibe feedback directo de tus afiliados
        con las promociones o beneficios que ofreces. Deja que tus clientes
        busquen tus promociones cuando ellos lo necesiten sin necesidad de
        invadirlos con notificaciones.
      </p>

      <button
        className={styles.button}
        onClick={() => history.push("/companyRegister")}
      >
        Unirse a LOIA
      </button>
    </section>
  );
};

export default Welcome;
