import React from 'react'
import { Modal, notification, Button } from 'antd'
import { DELETE_COMPANY } from '../../Graphql/Mutations';
import { REFRESH_TOKEN } from '../../../../Graphql/Mutations';
import { setAccessToken } from '../../../../Helpers/auth';
import { useMutation } from '@apollo/client';
import { CloseOutlined } from "@ant-design/icons";
import styles from './index.module.css'


const DeleteCompanyModal = ({
    deleteModalVisible,
    onCancel,
    company,
    refetchCompanies
}) => {

    const [refreshToken] = useMutation(REFRESH_TOKEN)
    const [deleteCompany, {loading: loadingDeleteCompany}] = useMutation(DELETE_COMPANY, {
        variables:{
            input: {
                _id: company
            }
        }
    })

    const handleSubmit = () => {
        refreshToken().then(res => {
            setAccessToken(res.data.refreshToken.token)
            deleteCompany().then(() => {
                notification.success({
                    message: "Éxito",
                    description: "Has eliminado la empresa exitósamente",
                    placement: "bottomRight",
                  });
                  refetchCompanies({
                    input: {
                      page: 1,
                      limit: 10000,
                    }
                  })
                  onCancel()
            })
        }).catch(e => {
            console.log(e)
        })
    }
    return (
        <Modal
            className={styles.container}
            visible={deleteModalVisible}
            footer={null}
            bodyStyle={{ paddingBottom: "2rem" }}
            onCancel={onCancel}
            closeIcon={<CloseOutlined className={styles.closeIcon} />}
        >
            <section className={styles.content}>
                <header className={styles.header}>
                <h3 className={styles.headerTitle}>
                    Eliminar compañía
                </h3>
                <span>¿Estás seguro/a que quieres eliminar a esta empresa?</span>
                </header>

                
                <footer className={styles.footer}>
                    <Button
                        className={`${styles.footerButton} ${styles.footerGhostButton}`}
                        onClick={() => onCancel()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        loading={loadingDeleteCompany}
                        className={styles.footerButton}
                        onClick={() => handleSubmit()}
                    >
                        Eliminar
                    </Button>
                </footer>
            </section>
        </Modal>
    )
}

export default DeleteCompanyModal