import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Modal, notification as AntNotification } from "antd";
import { useQuery } from "@apollo/client";
import { SEND_TEST_NOTIFICATION_EMAIL } from "../../Graphql/Queries";
import SendTestEmailResultModal from "../SendTestEmailResultModal";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const SendTestNotificationEmailModal = ({
  visible,
  onCancel,
  notification,
}) => {
  const [testEmail, setTestEmail] = React.useState("");

  useEffect(() => {
    setTestEmail("");
  }, [notification]);

  const [showSendTestEmailResultModal, setShowSendTestEmailResultModal] =
    React.useState(false);
  const [sendTestEmailStatus, setSendTestEmailStatus] =
    React.useState("email-sent");

  const { refetch: sendTestNotificationEmail, loading: isSendingTestEmail } =
    useQuery(SEND_TEST_NOTIFICATION_EMAIL, {
      skip: true,
    });

  const handleSendTestNotificationEmail = () => {
    const isValidEmail = validateEmail(testEmail);

    if (!isValidEmail) {
      setShowSendTestEmailResultModal(true);
      setSendTestEmailStatus("invalid-email");
      return;
    }

    sendTestNotificationEmail({
      id: notification?._id,
      email: testEmail?.toLowerCase()?.trim(),
    })
      .then(() => {
        onCancel();
        setTestEmail("");
        setShowSendTestEmailResultModal(true);
        setSendTestEmailStatus("email-sent");
      })
      .catch(() => {
        AntNotification.error({
          message: `Error`,
          description:
            "Hubo un error al intentar enviar el correo de prueba. Intente de nuevo.",
          placement: "bottomRight",
        });
      });
  };

  return (
    <>
      <SendTestEmailResultModal
        status={sendTestEmailStatus}
        visible={showSendTestEmailResultModal}
        onCancel={() => setShowSendTestEmailResultModal(false)}
      />
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ padding: "1rem" }}
        width={"90%"}
        style={{ maxWidth: "500px" }}
        className={styles.modal}
        closeIcon={<div style={{ display: "none !important" }} />}
        centered
      >
        <section className={styles.container}>
          <header className={styles.header}>
            <h2>Correo de prueba</h2>
          </header>

          <div className={styles.inputContainer}>
            <label htmlFor="testEmail">
              Ingresa el correo que recibirá la prueba
            </label>
            <input
              name="testEmail"
              id="testEmail"
              className={styles.emailInput}
              type="email"
              value={testEmail}
              placeholder="test@prueba.com"
              autoComplete="off"
              spellCheck="false"
              onChange={(e) => setTestEmail(e.target.value)}
            />
          </div>

          <footer className={styles.footer}>
            <button className={styles.cancelButton} onClick={onCancel}>
              Regresar
            </button>
            <button
              disabled={isSendingTestEmail || !testEmail}
              className={styles.confirmButton}
              onClick={handleSendTestNotificationEmail}
            >
              Enviar
            </button>
          </footer>
        </section>
      </Modal>
    </>
  );
};

export default SendTestNotificationEmailModal;
