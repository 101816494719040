import gql from "graphql-tag";

const FILTERS = gql`
  query getFilters($filters: PaginatedPromotionsFilterInput) {
    getFilters(filter : $filters) {
      scalars {
        _id
        name
        count
      }
      regions {
        name
        count
        communes {
          name
          count
        }
      }
      companies {
        _id
        name
        count
      }
      discount {
        min
        max
      }
      weekDays {
        day
        count
      }
    }
  }
`;

export { FILTERS };
