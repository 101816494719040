import gql from "graphql-tag";

const PROFILE = gql`
query profile {
  profile {
    _id
    firstName
    lastName
    company {
      _id
      name
      phone
    }
    preferences {
      notifications {
        promotionsAboutToExpire
        newPromotions
      }
    }
    email
    role
  }
}
`;

const CATEGORIES = gql`
query categories{
  categories{
    _id
    name
    description
  }
}
`

export { PROFILE, CATEGORIES }