import gql from "graphql-tag";

const DELETE_COMMENT = gql`
  mutation deleteComment($commentId: String!) {
    deleteComment(commentId: $commentId) {
      promotion {
        _id
        company {
          name
          _id
        }
      }
    }
  }
`;

const TOGGLE_COMMENT_STATUS = gql`
  mutation toggleCommentStatus($visible: Boolean!, $commentId: String!) {
    toggleCommentStatus(visible: $visible, commentId: $commentId) {
      _id
      promotion {
        _id
        name
      }
      stars
      body
      visible
    }
  }
`;

export { DELETE_COMMENT, TOGGLE_COMMENT_STATUS };
