import { useState } from "react";
import { Checkbox } from "antd";
import styles from "./index.module.css";
import ArrowIcon from "../../Assets/black_arrow_icon.svg";

const mapDiscountsToCheckboxOptions = (availableDiscounts = {}) => {
  const { min, max } = availableDiscounts;
  const checkboxOptions = [];

  for (let i = 0; i < 6; i++) {
    const minRange = i * 10;
    const maxRange = minRange + 10;
    const label = `${minRange} - ${maxRange}%`;

    if (maxRange > 50 && max > 50) {
      checkboxOptions.push({ label: ">50%", value: "50-100" });
    } else if (minRange >= min && maxRange <= max) {
      checkboxOptions.push({ label, value: `${minRange}-${maxRange}` });
    } else if (minRange < min && maxRange > min && maxRange <= max) {
      checkboxOptions.push({ label, value: `${min}-${maxRange}` });
    } else if (minRange >= min && minRange < max && maxRange > max) {
      checkboxOptions.push({ label, value: `${minRange}-${max}` });
    }
  }
  return checkboxOptions;
};

const FilterByDiscount = ({
  filters,
  setFilters,
  availableDiscounts,
  getFilteredPromotions,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const checkboxOptions = mapDiscountsToCheckboxOptions(availableDiscounts);

  const handleChange = (values) => {
    const discountsNumbers = values
      ?.join("-")
      ?.split("-")
      ?.map((item) => Number(item));
    setFilters({
      ...filters,
    });

    const newFilters = {
      minDiscount: Math.min(...discountsNumbers),
      maxDiscount: Math.max(...discountsNumbers),
      discountsRanges: [...values],
    };

    setFilters({ ...filters, ...newFilters });
    getFilteredPromotions({
      customFilters: {
        minDiscount: Math.min(...discountsNumbers),
        maxDiscount: Math.max(...discountsNumbers),
      },
    });
  };

  return (
    <section className={styles.container}>
      <header
        className={styles.header}
        onClick={() => setShowOptions(!showOptions)}
      >
        <h2 className={styles.headerTitle}>Descuentos</h2>
        <button
          className={`${styles.headerArrowButton} ${
            showOptions ? styles.rotate : ""
          }`}
        >
          <img src={ArrowIcon} alt="close icon" />
        </button>
      </header>

      {checkboxOptions?.length > 0 && showOptions && (
        <Checkbox.Group
          className={styles.checkboxGroup}
          options={checkboxOptions}
          value={filters?.discountsRanges}
          onChange={(checkedValues) => handleChange(checkedValues)}
        />
      )}
    </section>
  );
};

export default FilterByDiscount;
