import gql from "graphql-tag";

const UPDATE_NOTIFICATION_TRIGGER = gql`
  mutation updateNotificationTrigger(
    $id: String!
    $input: UpdateNotificationTriggerInput!
  ) {
    updateNotificationTrigger(id: $id, input: $input) {
      _id
    }
  }
`;

const SCHEDULE_NOTIFICATION = gql`
  mutation scheduleNotification($id: String!, $date: DateTime!) {
    scheduleNotificationAt(id: $id, date: $date) {
      _id
      scheduledAt
    }
  }
`;

const CREATE_NOTIFICATION_FOR_TRIGGER = gql`
  mutation createNotificationForTrigger($type: NotificationType!) {
    createNotificationForTrigger(type: $type) {
      _id
    }
  }
`;

const CANCEL_SCHEDULED_NOTIFICATION = gql`
  mutation cancelScheduledNotification($id: String!) {
    cancelScheduledNotification(id: $id) {
      _id
    }
  }
`;

const SELECT_NOTIFICATION_PROMOTIONS = gql`
  mutation selectNotificationPromotions($id: String!, $promotions: [String!]!) {
    selectNotificationPromotions(id: $id, promotions: $promotions) {
      _id
    }
  }
`;

export {
  UPDATE_NOTIFICATION_TRIGGER,
  SCHEDULE_NOTIFICATION,
  CANCEL_SCHEDULED_NOTIFICATION,
  SELECT_NOTIFICATION_PROMOTIONS,
  CREATE_NOTIFICATION_FOR_TRIGGER,
};
