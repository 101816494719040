import React from 'react'
import styles from "./index.module.css";
import UserIcon from "../../../../Assets/safe-icon.svg";

const ForgotPasswordHeader = () => {
    return (
        <header className={styles.header}>
            <img src={UserIcon} alt="" className={styles.headerIcon} />
            <h2 className={styles.headerTitle}>¿Olvidaste tu contraseña?</h2>
        </header>
    )
}

export default ForgotPasswordHeader