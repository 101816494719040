import React from "react";
import { Modal } from "antd";
import "./index.css";
import WarningIcon from "./Assets/warningIcon.svg";
import { CloseOutlined } from "@ant-design/icons";

const ErrorModal = ({
  errorModalVisibility,
  setErrorModalVisibility,
  retryFunction,
}) => {
  return (
    <Modal
      visible={errorModalVisibility}
      footer={null}
      closeIcon={<CloseOutlined className="error-modal__close-button" />}
    >
      <div className="error-modal">
        <img src={WarningIcon} alt="warning" className="error-modal__icon" />
        <p className="error-modal__description">
          Error al intentar registrar tu empresa, verifica correo y dato
          validador
        </p>

        <div className="error-modal__buttons-container">
          <button
            className="error-modal__button"
            onClick={() => {
              setErrorModalVisibility(false);
              retryFunction();
            }}
          >
            Reintentar
          </button>
          <button
            className="error-modal__button"
            onClick={() => setErrorModalVisibility(false)}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
