import styles from "./index.module.css";
import VisualizationsChart from "./Components/VisualizationsChart";
import { Row, Spin } from "antd";
import ActiveUsersChart from "./Components/ActiveUsersChart";
import NewUsersChart from "./Components/NewUsersChart";

const VisibilityReports = ({ isLoadingReports, reportsData = {} }) => {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Visibilidad del anuncio</h2>

      {isLoadingReports ? (
        <Row justify="center" style={{ padding: "2rem 0 4rem" }}>
          <Spin size="large" />
        </Row>
      ) : (
        <div className={styles.stats}>
          <VisualizationsChart data={reportsData} />
          <ActiveUsersChart data={reportsData} />
          <NewUsersChart data={reportsData} />
        </div>
      )}
    </section>
  );
};

export default VisibilityReports;
