import { Col, Row } from "antd";
import React from "react";
import styles from "./index.module.css";
import Wave from "../../../../Assets/yellow-wave.png";
import Animation from "./Assets/gif-banner.gif";

const Banner = () => {
  return (
    <>
      <Row className={styles.container}>
        <Col md={2}></Col>
        <Col xs={24} sm={24} md={10} lg={12} className={styles.content}>
          <h1 className={styles.contentTitle}>
            ¡Todos tus beneficios organizados y a tu alcance para cuando los necesites!
          </h1>
          <p className={styles.contentDescription}>
            Crea tu cuenta, selecciona tus socios y conoce todos los beneficios a los que puedes acceder cuando lo necesites.
          </p>
        </Col>
        <Col xs={24} sm={24} md={10} lg={8} className={styles.animation}>
          <img src={Animation} alt="" />
        </Col>
        <Col md={2}></Col>
      </Row>
      <img src={Wave} alt="wave" className={styles.wave} />
    </>
  );
};

export default Banner;
