import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { CREATE_SEGMENT, UPDATE_SEGMENT } from "../../Graphql/Mutations";
import { REFRESH_TOKEN } from "../../../../../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import { getUser, setAccessToken } from '../../../../../../Helpers/auth'
import "./index.css";

const CreateAndEditSegmentModal = ({ visible, onCancel, action, segmentsQuery, segment }) => {
  const [segmentName, setSegmentName] = useState(segment ? segment.name : '')
  const [refreshToken] = useMutation(REFRESH_TOKEN)
  const [createSegmentMutation, {loading}] = useMutation(CREATE_SEGMENT, {
    variables:{
      input:{
        name: segmentName,
        company: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected'))
      }
    }
  })

  const [updateSegmentMutation, {loading: loadingUpdate}] = useMutation(UPDATE_SEGMENT, {
    variables:{
      input:{
        name: segmentName,
        company: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected'))
      },
      id: segment._id
    }
  })

  useEffect(() => {
    if(segment) setSegmentName(segment.name)
    else setSegmentName('')
  }, [segment])

  const createSegment = () => {
      refreshToken().then(res => {
          setAccessToken(res.data.refreshToken.token)
          createSegmentMutation().then(() => {
              notification.success({
                  message: `Éxito`,
                  description:
                    'El segmento ha sido creado exitósamente',
                  placement: "bottomRight",
                });
                segmentsQuery()
                onCancel()
                setSegmentName('')
          })
      }).catch(e => {
        notification.error({
          message: `Error`,
          description:
            'Hubo un error al intentar crear el segmento, por favor revisa todos los campos e inténtalo de nuevo.',
          placement: "bottomRight",
        });
      })
  }

  const updateSegment = () => {
      refreshToken().then(res => {
          setAccessToken(res.data.refreshToken.token)
          updateSegmentMutation().then(() => {
              notification.success({
                  message: `Éxito`,
                  description:
                    'El segmento ha sido actualizado exitósamente',
                  placement: "bottomRight",
                });
                segmentsQuery()
                onCancel()
                setSegmentName('')
          }).catch(e => {
            notification.error({
              message: `Error`,
              description:
                'Hubo un error al intentar actualizar el segmento, por favor revisa todos los campos e inténtalo de nuevo.',
              placement: "bottomRight",
            });
          })
      })
  }
  return (
    <>
      <Modal
        className="CreateAndEditSegmentModal"
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ top: 50 }}
        closeIcon={<CloseOutlined />}
      >
        <h1 className="userModal-h1">
          {action === "create" ? "Agregar un segmento" : "Editar un segmento"}
        </h1>

        <div className="CreateAndEditSegmentModal-content">
          <Form.Item>
            <h3 className="CreateAndEditSegmentModal-h3">Nombre de segmento</h3>
            <Input
              type="text"
              name="name"
              required={true}
              className="contactFormInput CreateAndEditSegmentModal-Input"
              value={segmentName}
              onChange={(e) => setSegmentName(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            {
              action === 'create' ? (
                <Button
                  className="CreateAndEditUserModal-Button"
                  onClick={() => createSegment()}
                  loading={loading}
                >
                  Crear
                </Button>
              ): (
                <Button
                  className="CreateAndEditUserModal-Button"
                  onClick={() => updateSegment()}
                  loading={loadingUpdate}
                >
                  Guardar
                </Button>
              )
            }
            
            <Button
              className="CreateAndEditUserModal-Button"
              onClick={onCancel}
            >
              Cancelar
            </Button>
          </Form.Item>
        </div>
      </Modal>
    </>
  );
};

export default CreateAndEditSegmentModal;
