import gql from "graphql-tag";

const ASSOCIATE_MY_COMPANY = gql`
  mutation associateMyCompany($input: AssociateCompanyInput!) {
    associateMyCompany(input: $input) {
      _id
      company {
        _id
        name
      }
    }
  }
`;

export { ASSOCIATE_MY_COMPANY };
