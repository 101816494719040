import gql from "graphql-tag";

const COMPANIES = gql`
query companies($input: QueryParams){
  companies(input: $input) {
    data{
      _id
      name
      visibles
      reports
      admin
    }
    metadata{
      page
      limit
      totalDocs
      totalPages
    }
  }
}
`;


export { COMPANIES }