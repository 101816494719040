import { useState } from "react";
import { Checkbox } from "antd";
import styles from "./index.module.css";
import ArrowIcon from "../../Assets/black_arrow_icon.svg";

const FilterByCompanies = ({
  filters,
  setFilters,
  availableCompanies,
  getFilteredPromotions,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const checkboxOptions = availableCompanies.map(({ name, count, _id }) => ({
    label: `${name} (${count})`,
    value: _id,
  }));

  const handleChange = (values) => {
    const newFilters = [...values];
    setFilters({ ...filters, partners: newFilters });
    getFilteredPromotions({
      customFilters: {
        partners: newFilters?.length === 0 ? undefined : newFilters,
      },
    });
  };

  return (
    <section className={styles.container}>
      <header
        className={styles.header}
        onClick={() => setShowOptions(!showOptions)}
      >
        <h2 className={styles.headerTitle}>Socios</h2>
        <button
          className={`${styles.headerArrowButton} ${
            showOptions ? styles.rotate : ""
          }`}
        >
          <img src={ArrowIcon} alt="close icon" />
        </button>
      </header>

      {checkboxOptions?.length > 0 && showOptions && (
        <Checkbox.Group
          className={styles.checkboxGroup}
          options={checkboxOptions}
          onChange={(checkedValues) => handleChange(checkedValues)}
          value={filters?.partners}
        />
      )}
    </section>
  );
};

export default FilterByCompanies;
