import React from "react";
import styles from "./index.module.css";
import { useQuery } from "@apollo/client";
import { COMPANY } from "../../Graphql/Queries";

const PromotionFooter = ({ companyLogo, companyName }) => {

  const {data} = useQuery(COMPANY, {
    variables: {
      input: {
        _id: JSON.parse(localStorage.getItem('companySelected'))
      }
    }
  })

  return (
    <footer className={styles.footer}>
      <h3 className={styles.footerText}>Ofrecido por </h3>
      <img
        className={styles.footerLogo}
        alt={companyName}
        title={companyName}
        src={companyLogo ? companyLogo : data && data.company ? data.company.logo : null}
      />
    </footer>
  );
};

export default PromotionFooter;
