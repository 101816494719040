import React, { useEffect } from "react";
import styles from "./index.module.css";
import { withRouter } from "react-router-dom";
import MainHeader from "../../Components/Header";
import Welcome from "./Components/Welcome";
import Illustration from "./Assets/illustration.svg";
import { Col, Row } from "antd";
import { getUser } from "../../Helpers/auth";
import { useHistory } from "react-router-dom";

const CompanyOnboarding = () => {
  const history = useHistory();

  useEffect(() => getUser().role && history.push("/"), [history]);

  return (
    <>
      <MainHeader />
      <Row justify="center" align="middle" className={styles.container}>
        <Col xs={24} sm={24} md={10} lg={9} className={styles.illustrationCol}>
          <img src={Illustration} alt="" className={styles.illustration} />
        </Col>
        <Col xs={24} sm={24} md={14} lg={15} className={styles.welcomeCol}>
          <Welcome />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(CompanyOnboarding);
