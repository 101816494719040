import React, { useState } from "react";
import { Modal, Spin, Row } from "antd";
import { CloseOutlined, PlusOutlined, EditOutlined  } from '@ant-design/icons'
import CreateAndEditSegmentModal from "./Components/CreateAndEditSegmentModal";
import DeleteSegmentsModal from "./Components/DeleteSegmentModal";
import { SEGMENTS } from "./Graphql/Queries";
import { useQuery } from "@apollo/client";
import { getUser } from "../../../../Helpers/auth";
import "./index.css";

const ListSegmentsModal = ({visible, onCancel, usersByCompanyQuery}) => {

    const [segment, setSegment] = useState({})
    const [createAndEditSegmentsModalVisible, setCreateAndEditSegmentsModalVisible] = useState(false)
    const [actionCreateAndEditSegmentsModal, setActionCreateAndEditSegmentsModal] = useState('')

    const [deleteSegmentsModalVisible, setDeleteSegmentsModalVisible] = useState(false)

    const { refetch: segmentsQuery, loading, data } = useQuery(SEGMENTS, {
        variables: {
            companyId: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected'))
        }
    })
    const handleCreateAndEditSegmentsModal = (action, segment) => {
        setCreateAndEditSegmentsModalVisible(true)
        setActionCreateAndEditSegmentsModal(action)
        if(action === 'create'){
            setSegment({})
        }else{
            setSegment(segment)
        }
    }

    const handleDeleteSegmentsModal = segment => {
        setDeleteSegmentsModalVisible(true)
        setSegment(segment)
    }
    
    return (
        <>
        <Modal className="ListSegmentsModal-content" visible={visible} onCancel={onCancel} footer={null} style={{ top: 250 }} closeIcon={<CloseOutlined />}>
            <h1 className="userModal-h1">Segmentos <PlusOutlined onClick={() => handleCreateAndEditSegmentsModal('create')} /></h1>
            {
                loading ? (
                    <Row justify="center" style={{padding: 40}}>
                        <Spin />
                    </Row>
                   
                ):(
                <>
                    {
                        data && data.segments && data.segments.length === 0 ? (
                            <Row justify="center" style={{paddingBottom: 30}}>
                                <h2>Aún no tienes segmentos creados.</h2>
                                <h2>¡Créalos aquí! <PlusOutlined onClick={() => handleCreateAndEditSegmentsModal('create')} /></h2>
                            </Row>
                        ):(
                            <ul className="ListSegmentsModal-ul ListSegmentsModal-content" style={{paddingBottom: 30}}>
                                {
                                    data && data.segments && data.segments.map(segment => {
                                        return(
                                            <li key={segment._id}><h2 >{segment.name} <CloseOutlined style={{marginLeft:"20px"}} onClick={() => {handleDeleteSegmentsModal(segment)}}/> <EditOutlined onClick={() => handleCreateAndEditSegmentsModal('edit', segment)}/></h2></li>
                                        )
                                    })
                                }
                                </ul>  
                        )
                    }
                </>
               
                )
            }
            
        </Modal>
        <CreateAndEditSegmentModal 
            visible={createAndEditSegmentsModalVisible} 
            onCancel={() => setCreateAndEditSegmentsModalVisible(false)}
            action={actionCreateAndEditSegmentsModal} 
            segmentsQuery={segmentsQuery}
            segment={segment}
        />

        <DeleteSegmentsModal 
            visible={deleteSegmentsModalVisible} 
            onCancel={() => setDeleteSegmentsModalVisible(false)}
            segmentsQuery={segmentsQuery}
            segment={segment}
            usersByCompanyQuery={usersByCompanyQuery}
        />
        </>
    )
}

export default ListSegmentsModal