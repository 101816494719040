import React from "react";
import styles from "./index.module.css";

const CompanyStats = ({
  activeUsers = 0,
  newUsers = 0,
  activePromotions = 0,
  totalPromotionsViews = 0,
  totalPromotionsViewsLastMonth = 0
}) => {
  return (
    <ul className={styles.container}>
      <li className={styles.item}>
        <span className={styles.itemValue}>{activeUsers}</span>
        <span className={styles.itemTitle}>Usuarios activos</span>
      </li>
      <li className={styles.item}>
        <span className={styles.itemValue}>{newUsers}</span>
        <span className={styles.itemTitle}>Usuarios nuevos</span>
      </li>
      <li className={styles.item}>
        <span className={styles.itemValue}>{activePromotions}</span>
        <span className={styles.itemTitle}>Promociones activas</span>
      </li>
      <li className={styles.item}>
        <span className={styles.itemValue}>{totalPromotionsViews}</span>
        <span className={styles.itemTitle}>Visualizaciones totales</span>
      </li>
      <li className={styles.item}>
        <span className={styles.itemValue}>{totalPromotionsViewsLastMonth}</span>
        <span className={styles.itemTitle}>Visualizaciones totales del último mes</span>
      </li>
    </ul>
  );
};

export default CompanyStats;
