import gql from "graphql-tag";

const PAGINATED_PROMOTIONS = gql`
  query paginatedPromotions($input: QueryParams) {
    paginatedPromotions(input: $input) {
      data {
        _id
        name
        description
        discount
        type {
          name
          description
        }
        conditions
        company {
          name
          logo
        }
        communes
        localName
        localLogo
        dueDate
        weekDays
        benefitURL
        termAndConditions
        status
        image
      }
      metadata {
        totalDocs
        totalPages
      }
    }
  }
`;

export { PAGINATED_PROMOTIONS };
