import gql from "graphql-tag";

const COMPANIES = gql`
query companies {
  companies{
    _id
    name
  }
}
`;

export { COMPANIES };