import React from "react";
import styles from "./index.module.css";

const PromotionFooter = ({ companyLogo, companyName }) => {
  return (
    <footer className={styles.footer}>
      <h3 className={styles.footerText}>Ofrecido por </h3>
      <img
        className={styles.footerLogo}
        alt={companyName}
        title={companyName}
        src={companyLogo}
      />
    </footer>
  );
};

export default PromotionFooter;
