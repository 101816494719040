import React, { useState } from 'react'
import MainHeader from '../../Components/Header'
import styles from './index.module.css'
import { useMutation } from '@apollo/client/react/hooks'
import { RESTORE_PASSWORD } from './Graphql/Mutations'
import ChangePasswordHeader from './Components/ChangePasswordHeader'
import ChangePasswordForm from './Components/ChangePasswordForm'
import { notification } from 'antd'
import { useParams, withRouter } from 'react-router-dom';

const ChangePasswordScreen = ({history}) => {
    let { token } = useParams()
    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [restorePassword] = useMutation(RESTORE_PASSWORD, {
      variables:{
        input: {
          password: newPassword,
          confirmPassword: repeatPassword
        },
        token: token
      }
    });

    const handleChangePassword = () => {
      restorePassword().then(() => {
        notification.success({
          message: "Éxito",
          description: "¡Has cambiado tu contraseña exitósamente, ahora intenta iniciar sesión!",
          placement: "bottomRight",
        });
        history.push('/login')
      }).catch(e => {
        console.log(e)
      })
    }

    const formValidation = () => {
        if (newPassword.trim() === "" || repeatPassword.trim() === "") {
          notification.error({
            message: "Error",
            description: "Debe completar todos los campos",
            placement: "bottomRight",
          });
          return false;
        }
        else if(newPassword !== repeatPassword){
            notification.error({
                message: "Error",
                description: "Las contraseñas no coinciden.",
                placement: "bottomRight",
              });
              return false; 
        }
        return true;
      };

    return (
        <>
            <MainHeader />
            <div className={styles.container}>
                <ChangePasswordHeader />
                <ChangePasswordForm
                newPassword={newPassword}
                repeatPassword={repeatPassword}
                setNewPassword={setNewPassword}
                setRepeatPassword={setRepeatPassword}
                handleChangePassword={handleChangePassword}
                formValidation={formValidation}
                />
            </div>
        </>
    )
}

export default withRouter(ChangePasswordScreen)