import gql from "graphql-tag";

const SEARCH_COMPANY = gql`
  query searchCompany($input: QueryParams) {
    searchCompany(input: $input) {
      data {
        _id
        name
        logo
      }
      metadata {
        totalPages
        page
        limit
        totalDocs
      }
    }
  }
`;

export { SEARCH_COMPANY };
