import React from "react";
import styles from "./index.module.css";

const Switch = ({
  isActive,
  onClickFn,
  activeText,
  desactiveText,
  className,
  disabled = false,
}) => {
  return (
    <div
      className={`${styles.container} ${className}`}
      onClick={onClickFn}
      style={disabled ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
    >
      <div className={styles.switch}>
        <span
          className={`${styles.switchBar} ${
            isActive ? styles.switchBarActive : styles.switchBarDesactive
          }`}
        ></span>
        <span
          className={`${styles.switchBall} ${
            isActive ? styles.switchBallActive : styles.switchBallDesactive
          }`}
        ></span>
      </div>
      <span className={styles.text}>
        {isActive ? activeText : desactiveText}
      </span>
    </div>
  );
};

export default Switch;
