import React, { useState } from 'react'
import { Button, Modal, Upload,  notification, Tooltip } from 'antd'
import { CloseOutlined } from "@ant-design/icons"
import { useApolloClient, useMutation } from '@apollo/client'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { UPLOAD_USERS_BULK } from './Graphql/Mutation'
import ExcelImg from './Asset/excel-img.png'
import AlertImgError from './Asset/alert-img-error.png'
import SuccessImg from './Asset/succes-Img.png'
import "./index.css";
import downloadTemplate from '../../../../Helpers/downloadTemplate'
import { parseExcelTemplateToJson } from '../../../../Helpers/parseExcelTemplateToJson'
import { USERS_BY_COMPANY } from '../../Graphql/Queries'
import { getCompanyId } from '../../../../Helpers/auth'

const UsersExcelModal = ({visible, onCancel, fileUploadSuccess, setFileUploadSuccess,users}) => {

  const [loadingExcelFile, setLoadingExcelFile] = useState(false)
  const [uploadUsersBulk, { loading: loadingUploadUsersBulk }] = useMutation(UPLOAD_USERS_BULK)
  const client = useApolloClient()

  const download = () => downloadTemplate('users', users).catch(e => notification.error({
    message: `Error`,
    description:
    e?.message || 'Ha ocurrido un error al descargar el archivo',
    placement: "bottomRight",
  }))


  const uploadUsers = async ({ 
    originFileObj: file
  }) => {
    try {
      const data = await parseExcelTemplateToJson(file, 'users')

      await  uploadUsersBulk({
        variables:{
          input: data,
          companyId: getCompanyId()
        }
      })
      await client.refetchQueries({
        include: [USERS_BY_COMPANY]
      })

      setFileUploadSuccess(true)
      setLoadingExcelFile(false)
      onCancel()
      notification.success({
        message: `Éxito`,
        description:
          'La carga masiva se ha completado con éxito',
        placement: "bottomRight",
      });
    } catch (error) {
      setLoadingExcelFile(false)
      setFileUploadSuccess(false)

      notification.error({
        message: `Error`,
        description:
          error?.message || 'Ha ocurrido un error al cargar el archivo',
        placement: "bottomRight",
      });
      
    }
  
  }

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

const handleChange = info => {
    if (info.file.status === 'uploading') {
        setLoadingExcelFile(true);
      return;
    }
    if (info.file.status === 'done') {
      uploadUsers(info.file)
    }
  };

    return (
        <Modal
        className="modalBody"
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ top: 50 }}
        closeIcon={<CloseOutlined />}
      >
        {
          fileUploadSuccess === false ? (
            <div className="UsersExcelModal-content">
              <img src={AlertImgError} alt="logo" style={{paddingTop:"20px"}} className="logoIcon"/>
              <h1 className="UsersExcelModal-title">Error en carga de archivo favor intente de nuevo</h1>
              <Button className="CreateAndEditUserModal-Button" onClick={() => setFileUploadSuccess(null)}>
                Reintentar
              </Button>
              <Button
                className="CreateAndEditUserModal-Button"
                type="button"
                htmlType="submit"
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </div>
          ) : fileUploadSuccess === null ? (
        <div className="UsersExcelModal-content">
          <h1 className="UsersExcelModal-title">Favor proceda a cargar archivo</h1>
          <h3 className="UsersExcelModal-h3">
            Descarga formato aquí :<img src={ExcelImg} alt="logo" className="logoIcon" style={{cursor: 'pointer'}} onClick={() => download()}/>
          </h3>
          <Tooltip 
            color={'white'}
            title={
              <div style={{color: 'black'}}>
                <p><b>Segmento:</b> hace referencia a los segmentos creados por la empresa.</p>
              </div>
            }>
            <p style={{padding: 20, fontWeight: 'bold'}}>¿No sabes que colocar en cada campo? Mira esta leyenda (?)</p>
          </Tooltip>
          <Upload
            name="localLogo"
            showUploadList={false}
            customRequest={dummyRequest}
            onChange={handleChange}
          >
            <Button className="CreateAndEditUserModal-Button" loading={loadingUploadUsersBulk || loadingExcelFile}>
              Adjuntar archivo
            </Button>
          </Upload>
          <Button
            className="CreateAndEditUserModal-Button"
            type="button"
            htmlType="submit"
            onClick={onCancel}
          >
            Cancelar
          </Button>
        </div>
          ): (
            <div className="UsersExcelModal-content">
              <img src={SuccessImg} alt="logo" style={{paddingTop:"20px"}} className="logoIcon"/>
              <h1 className="UsersExcelModal-title">Su archivo se cargó correctamente</h1>
            </div>
          )
        }
           

      </Modal>
    )
}

export default withRouter(UsersExcelModal)