import { notification } from "antd";
import regex from "../Utils/regex";

const stepTwoValidation = (adminData) => {
  const { firstName, lastName, email, password, passwordConfirm } = adminData;

  if (firstName.trim() === "") {
    notification.error({
      message: "Error",
      description: "Debe ingresar el nombre del administrador",
      placement: "bottomRight"
    });
    return false;
  }

  if (lastName.trim() === "") {
    notification.error({
      message: "Error",
      description: "Debe ingresar el apellido del administrador",
      placement: "bottomRight"
    });
    return false;
  }

  if (!regex.name.test(firstName)) {
    notification.error({
      message: "Error",
      description: "El nombre debe contener solo letras",
      placement: "bottomRight"
    });
    return false;
  }

  if (!regex.name.test(lastName)) {
    notification.error({
      message: "Error",
      description: "El apellido debe contener solo letras",
      placement: "bottomRight"
    });
    return false;
  }

  if (email.trim() === "") {
    notification.error({
      message: "Error",
      description: "Debe ingresar el correo del administrador",
      placement: "bottomRight"
    });
    return false;
  }

  if (!regex.email.test(email)) {
    notification.error({
      message: "Error",
      description:
        "Debe ingresar un formato de correo válido, por ejemplo: usuario@ejemplo.com",
        placement: "bottomRight"
    });
    return false;
  }

  if (password.trim() === "") {
    notification.error({
      message: "Error",
      description: "Debe ingresar la contraseña del administrador",
      placement: "bottomRight"
    });
    return false;
  }

  if (password.includes(" ")) {
    notification.error({
      message: "Error",
      description: "La contraseña NO debe contener espacios",
      placement: "bottomRight"
    });
    return false;
  }

  if (!regex.strictPassword.test(password)) {
    notification.error({
      message: "Error",
      description:
        "La contraseña debe contener mínimo 8 caracteres con al menos una letra mayúscula, una letra minúscula, un número y un carácter especial",
        placement: "bottomRight"
    });
    return false;
  }

  if (passwordConfirm.trim() === "") {
    notification.error({
      message: "Error",
      description: "Debe confirmar la contraseña del administrador",
      placement: "bottomRight"
    });
    return false;
  }

  if (password !== passwordConfirm) {
    notification.error({
      message: "Error",
      description: "Las contraseñas no coinciden",
      placement: "bottomRight"
    });
    return false;
  }
  return true;
};

export default stepTwoValidation;
