import React, { useEffect, useState } from "react";
import DemographicsReport from "./Components/PromotionsSeenByUsersBox";
import ActivePromotionsBox from "./Components/ActivePromotionsBox";
import PromotionsTableReport from "./Components/PromotionsTableReports";
import { useQuery } from "@apollo/client";
import { REPORTS } from "./Graphql/Queries";
import { getUser } from "../../Helpers/auth";
import BlurredReports from "./Assets/blurred-reports.png";
import BlurredReportsMobile from "./Assets/blurred-reports-mobile.png";
import VisibilityReports from "./Components/VisibilityReports";
import LockIcon from "./Assets/lock-icon.png";
import "./index.css";
import { Col, Result, Row, Spin } from "antd";
import Header from "./Components/Header";
import moment from "moment";
import dayjs from "dayjs";
import InteractionReports from "./Components/InteractionReports";
import TrafficReports from "./Components/TrafficReports";

const CompanyReportScreen = () => {
  const twoMonthsAgo = dayjs().subtract(2, "months").format("YYYY-MM");
  const currentMonth = dayjs().format("YYYY-MM");
  const companyId = getUser()?.company
    ? getUser().company._id
    : JSON.parse(localStorage.getItem("companySelected")) === "all" &&
      JSON.parse(localStorage.getItem("companySelected")) !== null
    ? ""
    : JSON.parse(localStorage.getItem("companySelected"));

  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState(moment(twoMonthsAgo));
  const [endDate, setEndDate] = useState(moment(currentMonth));
  const [reportsData, setReportsData] = useState(undefined);
  const [reportsError, setReportsError] = useState(false);
  const [isLoadingReports, setIsLoadingReports] = useState(false);

  const { refetch: refetchReports } = useQuery(REPORTS, {
    variables: {
      input: {
        type,
        companyId,
        startDate,
        endDate,
      },
    },
    skip: true,
    fetchPolicy: "no-cache",
  });

  const getReports = (input = false) => {
    const queryVariables = input || {
      input: { type, companyId, startDate, endDate },
    };
    console.log("queryVariables", queryVariables);
    setIsLoadingReports(true);
    refetchReports(queryVariables)
      .then((data) => setReportsData(data?.data?.report))
      .catch(() => setReportsError(true))
      .finally(() => setIsLoadingReports(false));
  };

  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (JSON.parse(localStorage.getItem("companySelected") === "all")) {
    return (
      <Result
        style={{ marginTop: 140 }}
        status="warning"
        title="Para poder ver esta sección selecciona alguna empresa"
      />
    );
  }

  if (reportsError) {
    return (
      <>
        <div className="lock-container">
          <img
            src={LockIcon}
            alt="lock-icon"
            className="lock-icon"
            style={{ marginTop: 20, marginBottom: 10 }}
          />
          <h2 style={{ color: "#E4056F", fontWeight: "bold" }}>Reportes</h2>
          <p>
            Actualmente la sección de reportes se encuentra bloqueada, contacta
            a{" "}
            <span style={{ color: "#E4056F", fontWeight: "bold" }}>
              loia@loia.com
            </span>{" "}
            para poder conocer como conseguir esta sección de reportes, donde
            podrás descubrir los resultados de tus promociones.
          </p>
        </div>

        <img
          src={BlurredReports}
          alt="blurred-reports"
          className="blurred-reports"
        />
        <img
          src={BlurredReportsMobile}
          alt="blurred-reports-mobile"
          className="blurred-reports-mobile"
        />
      </>
    );
  }

  return (
    <>
      <Header
        endDate={endDate}
        startDate={startDate}
        setEndDate={setEndDate}
        getReports={getReports}
        setStartDate={setStartDate}
        isLoadingReports={isLoadingReports}
      />

      {isLoadingReports ? (
        <Row justify="center" style={{ margin: "10rem auto" }}>
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <VisibilityReports
            isLoadingReports={isLoadingReports}
            reportsData={reportsData}
          />
          <DemographicsReport
            type={type}
            isModal={false}
            loadingCompanyReport={isLoadingReports}
            dataCompanyReport={reportsData?.demographics}
            errorCompanyReport={reportsError}
          />

          <Row gutter={24}>
            <Col span={24} lg={12}>
              <InteractionReports reportsData={reportsData} />
            </Col>
            <Col span={24} lg={12}>
              <TrafficReports reportsData={reportsData} />
            </Col>
          </Row>

          <ActivePromotionsBox
            type={type}
            setType={setType}
            getReports={getReports}
            reportsData={reportsData}
            isLoadingReports={isLoadingReports}
            getReportsInput={{
              type,
              companyId,
              startDate,
              endDate,
            }}
          />
        </>
      )}

      <PromotionsTableReport type={type} />
    </>
  );
};

export default CompanyReportScreen;
