import gql from "graphql-tag";

const UPDATE_COMPANY = gql`
mutation updateCompany($input: CreateCompanyInput!, $id: String!){
    updateCompany(input: $input, id: $id){
      _id
    }
  }
`;

const UPDATE_COMPANY_LOGO = gql`
mutation uploadLogo($companyLogo: Upload!, $companyId: String!){
  uploadLogo(companyLogo: $companyLogo, companyId: $companyId){
      _id
    }
  }
`;

const UPDATE_COMPANY_ICON = gql`
mutation uploadIcon($companyIcon: Upload!, $companyId: String!){
  uploadIcon(companyIcon: $companyIcon, companyId: $companyId){
      _id
    }
  }
`;

export { UPDATE_COMPANY, UPDATE_COMPANY_LOGO, UPDATE_COMPANY_ICON }