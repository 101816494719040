import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { PROFILE } from "../../../../../../Graphql/Queries";
import PromotionHeader from "./Components/PromotionHeader";
import PromotionTerms from "./Components/PromotionTerms";
import PromotionFooter from "./Components/PromotionFooter";
import PromotionInfo from "./Components/PromotionInfo";
import "./index.css";

const PreviewPromotionModal = ({ visible, promotion, onCancel }) => {
  const { refetch: refetchProfile } = useQuery(PROFILE, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    refetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: "0px" }}
      width={"90%"}
      className={styles.modal}
      style={{ top: 60 }}
      closeIcon={<CloseOutlined className={styles.modalCloseButton} />}
    >
      <section className={styles.container}>
        <PromotionHeader
          image={promotion?.image}
          localLogo={promotion?.localLogo}
          localName={promotion?.localName}
        />

        <PromotionInfo promotion={promotion} />

        <PromotionTerms termAndConditions={promotion?.termAndConditions} />

        <PromotionFooter
          companyLogo={promotion?.company?.logo}
          companyName={promotion?.company?.name}
        />
      </section>
    </Modal>
  );
};

export default PreviewPromotionModal;
