import React from "react";
// import DatosImg from "../CompanyHomeScreen/Assets/datos.svg";
// import LogoImg from "../CompanyHomeScreen/Assets/loia-logo-companyHomeScreen.svg";
// import LineImg from "../CompanyHomeScreen/Assets/Line-companyHomeScreen.svg";
import { Spin, Row, Result, Button } from "antd";
import { OVERALL_REPORT } from "./Graphql/Queries";
import { useQuery } from "@apollo/client";
import "./index.css";
import CompanyNav from "./Components/CompanyNav";
import { getUser } from "../../Helpers/auth";
import CompanyStats from "./Components/CompanyStats";

const CompanyHomeScreen = () => {
  const { loading, error, data } = useQuery(OVERALL_REPORT, {fetchPolicy: "no-cache"});

  if (loading)
    return (
      <Row justify="center" style={{ marginTop: 250 }}>
        <Spin size="large" />
      </Row>
    );
  if (error)
    return (
      <Result
        status="500"
        title="500"
        subTitle="Lo sentimos, hubo un error al intentar cargar la información."
        extra={
          <Button type="primary">
            <a href="/companyHome"> Volver a intentarlo </a>
          </Button>
        }
      />
    );
  return (
    <>
      <CompanyStats
        activeUsers={data.overallReport.totalUsers}
        newUsers={data.overallReport.totalUsersLastSevenDays}
        activePromotions={data.overallReport.totalActivePromotions}
        totalVisits={data.overallReport.totalVisits}
        totalVisitsLastMonth={data.overallReport.totalVisitsLastMonth}
        totalPromotionsViews={data.overallReport.totalPromotionsViews}
        totalPromotionsViewsLastMonth={data.overallReport.totalPromotionsViewsLastMonth}
      />

      <CompanyNav role={getUser().role} />
    </>
  );
};

export default CompanyHomeScreen;
