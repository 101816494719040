import { notification } from "antd";
import regex from "../Utils/regex";

const formValidation = (formData) => {
  if (
    formData.company?.trim() === "" ||
    formData.email?.trim() === "" ||
    formData.validator?.trim() === ""
  ) {
    return notification.error({
      message: "Error",
      description: "Debe completar todos los campos",
      placement: "bottomRight",
    });
  }
  if (
    !regex.validator.test(formData.validator) ||
    formData.validator.length !== 4 ||
    formData.validator.includes(" ")
  ) {
    return notification.error({
      message: "Error",
      description:
        "El Dato validador debe ser de 4 dígitos y no puede contener espacios",
      placement: "bottomRight",
    });
  }
  if (!regex.email.test(formData.email.trim())) {
    return notification.error({
      message: "Error",
      description:
        "El correo ingresado no es válido, debe ingresar un formato de correo electrónico válido, ejemplo: correo@dominio.com",
      placement: "bottomRight",
    });
  }

  return true;
};

export default formValidation;
