import gql from "graphql-tag";

const CREATE_SEGMENT = gql`
mutation createSegment($input: CreateSegmentInput!){
    createSegment(input: $input){
      _id
    }
  }
`;

const UPDATE_SEGMENT = gql`
mutation updateSegment($input: CreateSegmentInput!, $id: String!){
  updateSegment(input: $input, id: $id){
      _id
    }
  }
`;

const DELETE_SEGMENT = gql`
mutation deleteSegment($id: String!){
  deleteSegment(id: $id){
    _id
  }
}
`;


export { CREATE_SEGMENT, UPDATE_SEGMENT, DELETE_SEGMENT }