import gql from "graphql-tag";

const COMPANY = gql`
  query company($input: FindCompany!) {
    company(input: $input) {
      _id
      name
      phone
      logo
    }
  }
`;

const AVERAGE_COMPANY_RATING = gql`
  query averageCompanyRating($companyId: String!) {
    averageCompanyRating(companyId: $companyId)
  }
`;

const COMMENTS_BY_COMPANY = gql`
  query commentsByCompany($input: QueryParams, $companyId: String) {
    commentsByCompany(input: $input, companyId: $companyId) {
      data {
        _id
        stars
        body
        visible
        createdAt
        user {
          _id
          firstName
          email
        }
        promotion {
          _id
          code
          discount
          localName
          company {
            name
            _id
          }
        }
      }
      metadata {
        page
        limit
        totalPages
        totalDocs
      }
    }
  }
`;

export { COMMENTS_BY_COMPANY, COMPANY, AVERAGE_COMPANY_RATING };
