import gql from "graphql-tag";

const LOGOUT = gql`
mutation{
    logout
  }
`;

const REFRESH_TOKEN = gql`
mutation{
    refreshToken{
      token
    }
  }
`;

const NEW_VIEW = gql`
  mutation createActivityLogs($input: CreateActivityLogsInput!) {
    createActivityLogs(input: $input) {
      _id
    }
  }
`;

export { REFRESH_TOKEN, LOGOUT, NEW_VIEW }