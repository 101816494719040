let accessToken = ''

const setAccessToken = token => {
    accessToken = token
    localStorage.setItem('LoiaToken', token)
}

const getAccessToken = () => {
    return accessToken || localStorage.getItem('LoiaToken')
}

const setUser = newUser => {
    localStorage.setItem('user',JSON.stringify(newUser));
}

const getUser = () => {
    let a = JSON.parse(localStorage.getItem('user'))
    return a
}

const getCompanyId = () => {
    const companyId = JSON.parse(localStorage.getItem('companySelected'))
    const user = getUser()

    if(companyId === 'all')
        return null
    
    
    if(user.role === 'SuperAdmin')
        return companyId
    

    if(user?.company._id)
        return user.company._id

    return null
}
export { getAccessToken, setAccessToken, setUser, getUser, getCompanyId }