import React, { useState } from "react";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import ReviewList from "../ReviewList";
import ReviewModal from "../ReviewModal";
import CommentsIcon from "../../Assests/comments_icon.svg";
import { Rate } from "antd";

const PromotionReviews = ({
  isLoged,
  promoId,
  promotion,
  checkMyCommentData,
  checkMyComment,
}) => {
  const history = useHistory();
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [isReviewsListModalVisible, setIsReviewsListModalVisible] =
    useState(false);

  const redirectToLogin = () => {
    history.push("/login");
  };

  return (
    <section className={styles.infoReviews}>
      <div
        className={styles.infoRatingContainer}
        onClick={() => {
          isLoged ? setIsReviewsListModalVisible(true) : redirectToLogin();
        }}
      >
        <Rate
          disabled
          value={promotion?.averageRating || 0}
          className={styles.infoRatingStars}
          onClick={() => setIsReviewsListModalVisible(true)}
        />
      </div>
      <button
        className={styles.commentButton}
        onClick={() => {
          if (isLoged) {
            checkMyComment();
            setIsReviewModalVisible(true);
          } else redirectToLogin();
        }}
      >
        <img src={CommentsIcon} alt="Comentar" />
      </button>
      <ReviewModal
        isVisible={isReviewModalVisible}
        onCancel={() => setIsReviewModalVisible(false)}
        promotionId={promoId}
        rating={checkMyCommentData?.checkMyComment?.stars || 0}
        comment={checkMyCommentData?.checkMyComment?.body || ""}
      />
      <ReviewList
        isVisible={isReviewsListModalVisible}
        onCancel={() => setIsReviewsListModalVisible(false)}
        promoId={promoId}
      />
    </section>
  );
};

export default PromotionReviews;
