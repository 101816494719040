import React from "react";
import styles from "./index.module.css";
import { Button } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import parseArrayOfDays from "../../../../../../../../Helpers/weekDaysToSpanish";

import * as dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

const parseArrayOfCommunes = (communes) => {
  let result = "";
  communes.map((commune, index) => {
    if (index === 0) result = result + commune;
    else if (index + 1 !== communes.length) result = result + ", " + commune;
    else result = result + " y " + commune;
    return true;
  });

  return result;
};

const PromotionInfo = ({ promotion }) => {
  return (
    <section className={styles.container}>
      <section className={styles.info}>
        <h2 className={styles.infoTitle}>{promotion?.localName}</h2>
        <h2 className={styles.infoPromotionName}>{promotion?.name}</h2>

        {promotion?.communes?.length > 0 && (
          <p className={styles.infoCommunes}>
            <EnvironmentOutlined />
            {`Solo disponible en ${
              promotion?.communes
                ? parseArrayOfCommunes(promotion.communes)
                : null
            }`}
          </p>
        )}

        {promotion?.description && (
          <p
            className={styles.infoDescription}
            dangerouslySetInnerHTML={{ __html: promotion?.description }}
          />
        )}

        {promotion?.conditions && (
          <article className={styles.infoConditions}>
            <h3 className={styles.infoConditionsTitle}>¿Cómo usar?</h3>
            <div
              className={styles.infoConditionsDescription}
              dangerouslySetInnerHTML={{ __html: promotion?.conditions }}
            />
          </article>
        )}
      </section>

      <section className={styles.info}>
        <div className={styles.dates}>
          <h3 className={styles.datesTitle}>Vigencia</h3>
          <p className={styles.datesDescription}>
            Hasta el {dayjs(promotion.dueDate).format("DD MMMM YYYY", "es")}
          </p>
          <h3 className={styles.datesTitle}>Días</h3>
          <p className={styles.datesDescription}>
            {promotion.weekDays ? parseArrayOfDays(promotion.weekDays) : null}
          </p>
          <Button
            href={promotion.benefitURL}
            target="_blank"
            className={styles.buttonBenefitURL}
            shape="round"
            size="middle"
            type="primary"
          >
            <p>¡Conoce más!</p>
          </Button>
        </div>
      </section>
    </section>
  );
};

export default PromotionInfo;
