import { Collapse } from "antd";
import React from "react";
import styles from "./index.module.css";

const PromotionTerms = ({ termAndConditions }) => {
  const { Panel } = Collapse;

  return (
    <Collapse
      ghost
      accordion
      className={styles.collapse}
      expandIconPosition="right"
    >
      <Panel
        showArrow={true}
        header={
          <h3 className={styles.collapseTitle}>Condiciones comerciales</h3>
        }
        key="1"
      >
        <div
          className={styles.collapseDescription}
          dangerouslySetInnerHTML={{ __html: termAndConditions }}
        />
      </Panel>
    </Collapse>
  );
};

export default PromotionTerms;
