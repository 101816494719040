import { useState } from "react";

const checkFilterParams = (filters) => {
  const obj = {
    filterFields: [],
    filterValues: [],
  };
  for (const key in filters) {
    if (filters[key].length > 0) {
      obj.filterFields = [
        ...obj.filterFields,
        ...Array(filters[key].length).fill(key),
      ];
      obj.filterValues = [...obj.filterValues, ...filters[key]];
    }
  }
  return obj;
};

const getActiveFilters = (filters) => {
  const filtersEntries = Object.entries(filters);
  const activeFilters = filtersEntries.filter(([key, value]) => {
    const isDiscount = key === "minDiscount" || key === "maxDiscount";
    const isDiscountRange = key === "discountsRanges";
    const isIncludeAlliedPartners = key === "includeAlliedPartners";
    if (isIncludeAlliedPartners) {
      return true;
    }
    if (isDiscountRange) {
      return false;
    }
    if (isDiscount) {
      return value > 0;
    }
    return value.length > 0;
  });
  return Object.fromEntries(activeFilters);
};

export default function useFilters() {
  const [filters, setFilters] = useState({
    daysOfWeek: [],
    minDiscount: 0,
    maxDiscount: 0,
    discountsRanges: [],
    communes: [],
    scalars: [],
    partners: [],
    includeAlliedPartners: false,
  });

  const [activeFilters, setActiveFilters] = useState({
    filterFields: [],
    filterValues: [],
    includeAlliedPartners: false,
  });

  const deleteFilter = (type, value) => {
    type === "allied"
      ? setFilters({ ...filters, includeAlliedPartners: false })
      : setFilters({
          ...filters,
          [type]: filters[type].filter((item) => item !== value),
        });
  };

  return {
    filters,
    setFilters,
    getActiveFilters,
    activeFilters,
    setActiveFilters,
    deleteFilter,
    checkFilterParams,
  };
}
