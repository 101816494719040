import React, { useState, useEffect } from "react";
import { Modal, Rate, notification } from "antd";
import styles from "./index.module.css";
import closeIcon from "../../Assests/Modal-close-img.svg";
import { useMutation } from "@apollo/client";
import { CREATE_COMMENT } from "../../Graphql/Mutations";
import { COMMENTS } from "../../Graphql/Queries";

const ReviewModal = ({ isVisible, onCancel, rating, comment, promotionId }) => {
  const [review, setReview] = useState({
    rating,
    comment,
  });
  const [commentType, setCommentType] = useState("Comentar");
  const [createComment] = useMutation(CREATE_COMMENT, {
    refetchQueries: [
      {
        query: COMMENTS,
        variables: {
          promoId: promotionId,
          input: {
            page: 1,
            limit: 2,
          },
        },
      },
    ],
  });

  const reviewValidation = () => {
    if (
      review.comment.length === 0 ||
      review.rating === 0 ||
      review.comment.trim() === ""
    ) {
      notification.error({
        message: "Error",
        description: "Debe completar todos los campos",
        placement: "bottomRight",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (reviewValidation()) {
      try {
        await createComment({
          variables: {
            input: {
              promotion: promotionId,
              body: review.comment,
              stars: review.rating,
            },
          },
        }).then((res) => {
          notification.success({
            message: "Gracias por tu comentario",
            description: "Tu comentario ha sido enviado",
            placement: "bottomRight",
          });
          setReview({
            rating,
            comment,
          });
          onCancel();
        });
      } catch (error) {
        console.log(error);
        notification.error({
          message: "Error",
          description: "No se pudo enviar tu comentario, intenta más tarde",
          placement: "bottomRight",
        });
      }
    }
  };

  useEffect(() => {
    if (rating !== 0 && comment !== "") {
      setReview({
        rating,
        comment,
      });
      setCommentType("Guardar");
    }
  }, [comment, rating]);

  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      style={{ top: 50, maxWidth: "500px" }}
      width={"85%"}
      closeIcon={
        <img
          src={closeIcon}
          alt="close icon"
          style={{
            display: "none",
          }}
        />
      }
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Comenta</h2>
        <button className={styles.headerCloseButton} onClick={onCancel}>
          <img src={closeIcon} alt="close icon" />
        </button>
      </header>

      <section className={styles.rating}>
        <h3 className={styles.ratingTitle}>Califica la promoción:</h3>
        <Rate
          className={styles.ratingStars}
          value={review.rating}
          onChange={(value) => setReview({ ...review, rating: value })}
        />
      </section>

      <section className={styles.review}>
        <h3 className={styles.reviewTitle}>Deja tu comentario:</h3>
        <textarea
          className={styles.reviewTextarea}
          value={review.comment}
          maxLength={250}
          onChange={(e) => setReview({ ...review, comment: e.target.value })}
        />
      </section>

      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={() => handleSubmit()}>
          {commentType}
        </button>
      </div>
    </Modal>
  );
};

export default ReviewModal;
