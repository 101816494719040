import React from "react";
import styles from "./index.module.css";
import { Modal } from "antd";
import InvalidEmailIcon from "../../Assets/invalid-email-icon.svg";
import EmailSentIcon from "../../Assets/email-sent-icon.svg";

const statusMatcher = {
  "invalid-email": {
    label: "Correo inválido",
    icon: InvalidEmailIcon,
    color: "#E4056F",
  },
  "email-sent": {
    label: "Correo enviado",
    icon: EmailSentIcon,
    color: "#49BA35",
  },
};

const SendTestEmailResultModal = ({
  visible,
  onCancel,
  status = "email-sent",
}) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: "1rem" }}
      width={"90%"}
      style={{ maxWidth: "500px" }}
      className={styles.modal}
      closeIcon={<div style={{ display: "none !important" }} />}
      centered
    >
      <section className={styles.container}>
        <img
          src={statusMatcher[status]?.icon}
          alt={statusMatcher[status]?.label}
        />
        <h2 style={{ color: statusMatcher[status]?.color  }}>
          {statusMatcher[status]?.label || ""}
        </h2>
      </section>
    </Modal>
  );
};

export default SendTestEmailResultModal;
