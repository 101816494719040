import React, { useState } from "react";
import styles from "./index.module.css";
import { Row, Spin, Result } from "antd";
import { PlusCircleFilled } from '@ant-design/icons';
import { USERS_BY_COMPANY, COMPANY } from './Graphql/Queries'
import { useQuery } from "@apollo/client";
import { getCompanyId, getUser } from '../../Helpers/auth'
import CreateAndEditUserModal from "./Components/CreateAndEditUserModal";
import ListSegmentsModal from "./Components/ListSegmentsModal";
import DeleteUserModal from "./Components/DeleteUserModal";
import CreateAndEditEmployeeModal from "./Components/CreateAndEditEmployeeModal";
import DeleteEmployeeModal from "./Components/DeleteEmployeeModal";
import UsersExcelModal from "./Components/UsersExcelModal";
import "./index.css";
import Header from "./Components/Header";
import Employees from "./Components/Employees";
import Users from "./Components/Users";

import deleteIcon from "./Assets/delete-icon.svg";
import editIcon from "./Assets/edit-icon.svg";


const CompanyUsersScreen = () => {

  const [user, setUser] = useState({})
  const [employee, setEmployee] = useState({})
  const [isUserList, setIsUserList] = useState(true)
  const [createAndEditUserModalVisible, setCreateAndEditUserModalVisible] = useState(false)
  const [actionCreateAndEditUserModal, setActionCreateAndEditUserModal] = useState('')
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false)

  const [createAndEditEmployeeModalVisible, setCreateAndEditEmployeeModalVisible] = useState(false)
  const [actionCreateAndEditEmployeeModal, setActionCreateAndEditEmployeeModal] = useState('')
  const [deleteEmployeeModalVisible, setDeleteEmployeeModalVisible] = useState(false)

  const [listSegmentsModalVisible, setListSegmentsModalVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [fileUploadSuccess, setFileUploadSuccess] = useState(null)
  const onCancel = () => {
        setVisible(false)
  }

  const companySelected = JSON.parse(localStorage.getItem('companySelected'));
  const companyId = getCompanyId()
  const roles = getUser().role === 'SuperAdmin' ? companySelected === 'all' ? ['SuperAdmin'] : ['Employee', 'Admin', 'Moderator'] : ['Employee', 'Admin', 'Moderator']

  const {refetch: usersByCompanyQuery ,loading, error, data} = useQuery(USERS_BY_COMPANY, {
    variables:{
      roles:  roles,
      companyId: companyId
    }
  })

  const {refetch: companyData, data: company, loading: loadingCompany } = useQuery(COMPANY, {
    variables:{
      input: {
        _id: getUser().company ? getUser().company._id : companySelected,
      }
    },
    skip: getUser().role === 'SuperAdmin' && companySelected === 'all' 
  })

  const handleCreateAndEditUserModal = (action, user) => {
    if(action === 'create') {
      setUser({})
    }else {
      setUser(user)
    }
    setCreateAndEditUserModalVisible(true)
    setActionCreateAndEditUserModal(action)
  }

  const handleCreateAndEditEmployeeModal = (action, user) => {
    if(action === 'create') {
      setEmployee({})
    }else {
      setEmployee(user)
    }
    setCreateAndEditEmployeeModalVisible(true)
    setActionCreateAndEditEmployeeModal(action)
  }

  const handleDeleteUserModal = user => {
    setDeleteUserModalVisible(true)
    setUser(user)
  }

  const handleDeleteEmployeeModal = employee => {
    setDeleteEmployeeModalVisible(true)
    setEmployee(employee)
  }

  const renderSegmentTitle = () => {
    return (
      <p style={{paddingTop: 10}} >Segmento <PlusCircleFilled style={{color: "#FCC02C",
        marginLeft: 5, cursor: 'pointer'}} onClick={() => setListSegmentsModalVisible(true)} /></p>
    )
  }

  const checkRole = role => {
    if (role === 'Employee') return 'Empleado'
    else if (role === 'Moderator') return 'Moderador'
    else if (role === 'Admin') return 'Administrador'
    else if (role === 'SuperAdmin') return 'Super Administrador'
  }

    const column = [
        {
          title: 'Nombre',
          dataIndex: 'firstName',
          key: 'firstName',
          render: (firstName) => <p className={styles.tableName}>{firstName}</p>,
        },
        {
          title: 'Apellido',
          dataIndex: 'lastName',
          key: 'lastName',
          render: (lastName) => <p className={styles.tableName}>{lastName}</p>,
        },
        {
          title: 'Correo',
          dataIndex: 'email',
          key: 'email',
          render: (email) => <p className={styles.tableEmail}>{email}</p>,
        },
        {
          title: 'Acceso',
          dataIndex: 'role',
          key: 'role',
          render: (role) => 
            <p className={styles.tableAcces}>{checkRole(role)}</p>
        },
        {
          title: "",
          dataIndex: "actions",
          key: "actions",
          render: (_, record) => (
            <div className={styles.tableActions}>
              <button onClick={() => handleCreateAndEditUserModal('edit', record)} className={styles.tableActionsButton}>
                <img src={editIcon} alt="" />
              </button>
              <button onClick={() => handleDeleteUserModal(record)} className={styles.tableActionsButton}>
                <img src={deleteIcon} alt="" />
              </button>
            </div>
          ),
        },
    ];


    const columnEmployee = [
      {
        title: 'Nombre',
        dataIndex: 'firstName',
        key: 'firstName',
        render: (firstName) => <p className={styles.tableName}>{firstName}</p>,
      },
      {
        title: 'Apellido',
        dataIndex: 'lastName',
        key: 'lastName',
        render: (lastName) => <p className={styles.tableName}>{lastName}</p>,
      },
      {
        title: 'Correo',
        dataIndex: 'email',
        key: 'email',
        render: (email) => <p className={styles.tableEmail}>{email}</p>,
      },
      {
          title: renderSegmentTitle(),
          dataIndex: 'companyRole',
          key: 'companyRole',
          render: (companyRole) => {
           return ( <>
            {
              companyRole === null ? '' : (
          <p className={styles.tableSegment}>{companyRole.name}</p>
              )
            }
            
            </>
           )
          }

        },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        render: (_, record) => (
          <div className={styles.tableActions}>
            <button onClick={() => handleDeleteEmployeeModal(record)} className={styles.tableActionsButton}>
                <img src={deleteIcon} alt="" />
            </button>
          </div>
        ),
      },
  ];

          if (loading && loadingCompany) return (
            <Row justify="center" style={{marginTop: 250}}>
              <Spin size="large"/>
            </Row>
          )

          if(getUser().role === 'Moderator') return (
            <Result
              style={{marginTop: 140}}
              status="warning"
              title="No tienes permisos para ver esta sección"
            />
          )

          if(error) return (
            <Result
              style={{marginTop: 140}}
              status="warning"
              title="Ha ocurrido un error"
            />
          )

  return (
    <>
      <UsersExcelModal 
        visible={visible} 
        onCancel={onCancel}
        fileUploadSuccess={fileUploadSuccess}
        setFileUploadSuccess={setFileUploadSuccess}  
        users={data?.usersByCompany}  
        
      />
      <Header 
        isUserList={isUserList}
        setIsUserList={setIsUserList}
        addUser={() => handleCreateAndEditUserModal('create', {})} 
        addEmployee={() => handleCreateAndEditEmployeeModal('create', {})} 
        masiveLoad={() => { 
          setVisible(true)
          setFileUploadSuccess(null)
        }}
      />

        {
          isUserList ? (
            <>
            <Users
              columns={column}
              data={data?.usersByCompany}
              checkRole={checkRole}
              handleCreateAndEditUserModal={handleCreateAndEditUserModal}
              handleDeleteUserModal={handleDeleteUserModal}
            />
            <CreateAndEditUserModal 
              visible={createAndEditUserModalVisible} 
              onCancel={() => setCreateAndEditUserModalVisible(false)} 
              action={actionCreateAndEditUserModal}
              usersByCompanyQuery={usersByCompanyQuery}
              user={user}
            />
            <DeleteUserModal 
              visible={deleteUserModalVisible} 
              onCancel={() => setDeleteUserModalVisible(false)}
              usersByCompanyQuery={usersByCompanyQuery}
              user={user}
            />
            </>
          ) : (
            <>
            <Employees
                columns={columnEmployee}
                data={company?.company?.employees}
                handleDeleteEmployeeModal={handleDeleteEmployeeModal}
            />
            <CreateAndEditEmployeeModal 
              visible={createAndEditEmployeeModalVisible} 
              onCancel={() => setCreateAndEditEmployeeModalVisible(false)} 
              action={actionCreateAndEditEmployeeModal}
              companyData={companyData}
              employee={employee}
            />
            <DeleteEmployeeModal 
              visible={deleteEmployeeModalVisible} 
              onCancel={() => setDeleteEmployeeModalVisible(false)}
              companyData={companyData}
              employee={employee}
            />
            <ListSegmentsModal 
              visible={listSegmentsModalVisible} 
              onCancel={() => setListSegmentsModalVisible(false)}
              usersByCompanyQuery={usersByCompanyQuery}
            />
            </>
          )
        }
      
      
    </>
  );
};

export default CompanyUsersScreen;
