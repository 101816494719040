import gql from "graphql-tag";

const LOGIN = gql`
mutation login($input: LoginInput!){
    login(input: $input){
      token
      refreshToken
      user {
        role
      }
    }
  }
`;

export { LOGIN }