const translateWeekDaysToSpanish = weekday => {
    if(weekday === 'monday') return 'Lunes'
    else if(weekday === 'tuesday') return 'Martes'
    else if(weekday === 'wednesday') return 'Miércoles'
    else if(weekday === 'thursday') return 'Jueves'
    else if(weekday === 'friday') return 'Viernes'
    else if(weekday === 'saturday') return 'Sábado'
    else if(weekday === 'sunday') return 'Domingo'
}

const parseArrayOfDays = weekDays => {
    let str = ""
    weekDays.map((weekDay, index) => {
        if(index === 0) str = str + translateWeekDaysToSpanish(weekDay)
        else if(index + 1 !== weekDays.length) str = str + ', ' + translateWeekDaysToSpanish(weekDay)
        else str = str + ' y ' + translateWeekDaysToSpanish(weekDay)
        return true
    })

    return str
}

export default parseArrayOfDays