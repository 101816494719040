import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { Collapse, Table, Row, Spin, Empty } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import { setAccessToken } from "../../../../Helpers/auth";
import { NOTIFICATIONS } from "../../Graphql/Queries";
import SendIcon from "../../Assets/send-icon.svg";
import ReadIcon from "../../Assets/read-icon.svg";
import ScheduleEmailIcon from "../../Assets/send-email-icon.svg";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import ScheduleNotificationModal from "../SchedulteNotificationModal";
import SendTestNotificationEmailModal from "../SendTestEmailNotificationModal";

const { Panel } = Collapse;

const notificationStatusMatcher = {
  Pending: { label: "Pendiente", color: "#E4056F" },
  Scheduled: { label: "Programado", color: "#E5AF28" },
  Sent: { label: "Enviado", color: "#49BA35" },
};

const Notifications = ({
  setShowNotificationDetails,
  setSelectedNotification,
  selectedNotification,
}) => {
  const {
    loading: isLoadingNotifications,
    error: notificationsError,
    data: notificationsData,
    refetch: getNotifications,
  } = useQuery(NOTIFICATIONS);

  const [
    showSendTestNotificationEmailModal,
    setShowSendTestNotificationEmailModal,
  ] = useState(false);
  const [showScheduleNotificationModal, setShowScheduleNotificationModal] =
    useState(false);

  const [refreshToken] = useMutation(REFRESH_TOKEN);

  const handleShowScheduleNotificationModal = (notification) => {
    setSelectedNotification(notification);
    setShowScheduleNotificationModal(true);
  };

  const handleHideScheduleNotificationModal = () => {
    setSelectedNotification({});
    setShowScheduleNotificationModal(false);
  };

  const handleShowSendTestNotificationEmailModal = (notification) => {
    setSelectedNotification(notification);
    setShowSendTestNotificationEmailModal(true);
  };

  const handleHideSendTestNotificationEmailModal = () => {
    setSelectedNotification({});
    setShowSendTestNotificationEmailModal(false);
  };

  useEffect(() => {
    const getInitialData = () => {
      refreshToken()
        .then((res) => {
          setAccessToken(res.data.refreshToken.token);
          getNotifications();
        })
        .catch(() => {});
    };
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (id) => (
        <p
          className={`${styles.tableSingleRowValue} ${styles.tableCenteredText} `}
        >
          {id}
        </p>
      ),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      render: (description) => (
        <p className={styles.tableSingleRowValue}>{description}</p>
      ),
    },
    {
      title: "Fecha de generación",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <p
          className={`${styles.tableSingleRowValue} ${styles.tableCenteredText} `}
        >
          {Boolean(createdAt) ? dayjs(createdAt).format("DD/MM/YYYY") : ""}
        </p>
      ),
    },
    {
      title: "Fecha de programado",
      dataIndex: "scheduledAt",
      key: "scheduledAt",
      render: (scheduledAt) => (
        <p
          className={`${styles.tableSingleRowValue} ${styles.tableCenteredText} `}
        >
          {Boolean(scheduledAt) ? dayjs(scheduledAt).format("DD/MM/YYYY") : ""}
        </p>
      ),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <p
          className={styles.tableStatus}
          style={{
            backgroundColor: notificationStatusMatcher?.[status]?.color,
          }}
        >
          {notificationStatusMatcher?.[status]?.label}
        </p>
      ),
    },
    {
      title: "Creado por",
      dataIndex: "scheduledBy",
      key: "scheduledBy",
      render: (scheduledBy) => (
        <p
          className={`${styles.tableSingleRowValue} ${styles.tableCenteredText} `}
        >
          {scheduledBy?.email}
        </p>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      render: (notification) => (
        <div className={styles.tableActions}>
          <button
            className={styles.tableActionsButton}
            onClick={() => {
              setSelectedNotification(notification);
              setShowNotificationDetails(true);
            }}
          >
            <img src={ReadIcon} alt="Icon" />
          </button>
          <button
            className={styles.tableActionsButton}
            onClick={() =>
              handleShowSendTestNotificationEmailModal(notification)
            }
          >
            <img src={SendIcon} alt="Icon" />
          </button>
          <button
            className={styles.tableActionsButton}
            onClick={() => handleShowScheduleNotificationModal(notification)}
          >
            <img src={ScheduleEmailIcon} alt="Icon" />
          </button>
        </div>
      ),
    },
  ];

  if (isLoadingNotifications) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  if (notificationsError) {
    return (
      <Row justify="center">
        <Empty
          description="No se pudo cargar la información"
          style={{ marginTop: "20px", color: "#aaa", fontWeight: "500" }}
        />
      </Row>
    );
  }

  return (
    <>
      <Table
        className={styles.table}
        dataSource={notificationsData?.notifications}
        pagination={{
          pageSize: 5,
        }}
        columns={columns}
      />

      <ScheduleNotificationModal
        notification={selectedNotification}
        getNotifications={getNotifications}
        visible={showScheduleNotificationModal}
        onCancel={handleHideScheduleNotificationModal}
      />

      <SendTestNotificationEmailModal
        notification={selectedNotification}
        visible={showSendTestNotificationEmailModal}
        onCancel={handleHideSendTestNotificationEmailModal}
      />

      <Collapse
        className={styles.accordion}
        expandIconPosition="right"
        accordion
      >
        {notificationsData?.notifications?.length === 0 ? (
          <h3 className={styles.accordionEmptyTitle}>
            No hay notificaciones disponibles
          </h3>
        ) : (
          <h3 className={styles.accordionTitle}>Notificaciones</h3>
        )}
        {notificationsData?.notifications?.map((notification, index) => (
          <Panel
            header={notification.description}
            key={index + notification?._id}
            className={styles.accordionPanel}
          >
            {Boolean(notification?._id) && (
              <strong className={styles.accordionNotificationId}>
                {`ID: ${notification?._id}`}
              </strong>
            )}
            <div className={styles.accordionContent}>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Fecha de generación:
                </h4>
                <p className={styles.tableSingleRowValue}>
                  {Boolean(notification?.createdAt)
                    ? dayjs(notification?.createdAt).format("DD/MM/YYYY")
                    : ""}
                </p>
              </div>

              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Fecha de programado:
                </h4>
                <p className={styles.tableSingleRowValue}>
                  {Boolean(notification?.scheduledAt)
                    ? dayjs(notification?.scheduledAt).format("DD/MM/YYYY")
                    : ""}
                </p>
              </div>

              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Estado:</h4>
                <p
                  className={styles.tableStatus}
                  style={{
                    backgroundColor:
                      notificationStatusMatcher?.[notification.status]?.color,
                  }}
                >
                  {notificationStatusMatcher?.[notification.status]?.label}
                </p>
              </div>

              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Creado por:
                </h4>
                <p className={styles.tableSingleRowValue}>
                  {notificationStatusMatcher?.scheduledBy?.email}
                </p>
              </div>

              <div className={styles.tableActions}>
                <button
                  className={styles.tableActionsButton}
                  onClick={() => {
                    setSelectedNotification(notification);
                    setShowNotificationDetails(true);
                  }}
                >
                  <img src={ReadIcon} alt="" />
                </button>
                <button
                  className={styles.tableActionsButton}
                  onClick={() =>
                    handleShowSendTestNotificationEmailModal(notification)
                  }
                >
                  <img src={SendIcon} alt="" />
                </button>
                <button
                  className={styles.tableActionsButton}
                  onClick={() =>
                    handleShowScheduleNotificationModal(notification)
                  }
                >
                  <img src={ScheduleEmailIcon} alt="" />
                </button>
              </div>
            </div>
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

export default withRouter(Notifications);
