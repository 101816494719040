import gql from "graphql-tag";

const ADD_ALLIED_TO_USER = gql`
  mutation addAlliedToUser($companiesIds: [String!]!) {
    addAlliedToUser(companiesIds: $companiesIds) {
      _id
      alliedCompanies {
        _id
        name
      }
    }
  }
`;

export { ADD_ALLIED_TO_USER };
