import React from "react";
import styles from "./index.module.css";
import { Form } from "antd";
import RichText from "../../../../../../Components/RichText";

const ConditionsBoxForm = ({
  termsAndConditions,
  setTermsAndConditions,
  howUse,
  setHowUse,
  handleSaveForm,
  loadingCreatePromotion,
  loadingUpdatePromotion,
  page,
  checkMaxCharsInput,
}) => {
  return (
    <div className={styles.formContainer}>
      <Form.Item
        className={`${styles.formItem} ${styles.formItemTextarea}`}
        label={`Términos y condiciones`}
      >
        <RichText
          className={styles.richTextInputContainer}
          value={termsAndConditions}
          onChange={(richText) => {
            checkMaxCharsInput(setTermsAndConditions, richText, 3000);
          }}
        />
      </Form.Item>

      <Form.Item
        className={`${styles.formItem} ${styles.formItemTextarea}`}
        label={`¿Cómo usar?`}
      >
        <RichText
          className={styles.richTextInputContainer}
          value={howUse}
          onChange={(richText) => {
            checkMaxCharsInput(setHowUse, richText, 3000);
          }}
        />
      </Form.Item>
    </div>
  );
};

export default ConditionsBoxForm;
