import styles from "./index.module.css";
import { Row, Col } from "antd";
import ArrowIcon from "../../Assets/arrow-icon.svg";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import PromotionsTable from "../PromotionsTable";
import { NOTIFICATION } from "../../Graphql/Queries";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";

const notificationStatusMatcher = {
  Pending: { label: "Pendiente", color: "#E4056F" },
  Scheduled: { label: "Programado", color: "#E5AF28" },
  Sent: { label: "Enviado", color: "#49BA35" },
};

const notificationTypeMatcher = {
  NewPromotions: { label: "Nuevas promociones" },
  PromotionsAboutToExpire: { label: "Próximos a vencer" },
};

const NotificationDetails = ({ notificationId, goBack }) => {
  const {
    data: notificationData,
    refetch: getNotification,
    loading: isLoadingNotification,
  } = useQuery(NOTIFICATION, { variables: { id: notificationId } });

  const notification = notificationData?.notification || {};

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshEmailPreview = () => {
    const iframe = document.getElementById("emailPreview");
    iframe.src = notification?.previewURL;
  };

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <button onClick={goBack}>
          <img src={ArrowIcon} alt="Regresar" />
          Regresar
        </button>
        <h2>Detalles de notificación</h2>
      </header>

      <Row>
        <Col
          xs={24}
          lg={13}
          xxl={12}
          className={styles.notificationDetailsContainer}
        >
          <Row
            justify="space-between"
            gutter={[16, 16]}
            className={styles.notificationDetailsInputsContainer}
          >
            <Col
              xs={24}
              xxl={10}
              className={styles.notificationDetailsInputContainer}
            >
              <label htmlFor="description">Descripción</label>
              <input
                name="description"
                type="text"
                value={notification?.description}
                disabled
                title={notification?.description}
                placeholder=""
                readOnly
              />
            </Col>
            <Col
              xs={24}
              xl={11}
              xxl={6}
              className={styles.notificationDetailsInputContainer}
            >
              <label htmlFor="notificationType">Tipo de notificación</label>
              <input
                name="notificationType"
                type="text"
                value={notificationTypeMatcher[notification?.type]?.label || ""}
                disabled
                title={notificationTypeMatcher[notification?.type]?.label || ""}
                placeholder=""
                readOnly
              />
            </Col>
            <Col
              xs={24}
              xl={11}
              xxl={6}
              className={styles.notificationDetailsInputContainer}
            >
              <label htmlFor="createdAt">Fecha de generación</label>
              <input
                name="createdAt"
                type="text"
                value={
                  Boolean(notification?.createdAt)
                    ? dayjs(notification?.createdAt).format("DD/MM/YYYY")
                    : ""
                }
                title={
                  Boolean(notification?.createdAt)
                    ? dayjs(notification?.createdAt).format("DD/MM/YYYY")
                    : ""
                }
                disabled
                placeholder=""
                readOnly
              />
            </Col>
            <Col
              xs={24}
              xl={11}
              xxl={10}
              className={styles.notificationDetailsInputContainer}
            >
              <label htmlFor="createdBy">Admin programado</label>
              <input
                name="createdBy"
                type="text"
                value={notification?.scheduledBy?.email}
                title={notification?.scheduledBy?.email}
                disabled
                placeholder=""
                readOnly
              />
            </Col>
            <Col
              xs={24}
              xl={11}
              xxl={6}
              className={styles.notificationDetailsInputContainer}
            >
              <label htmlFor="scheduledAt">Fecha de programado</label>
              <input
                name="scheduledAt"
                type="text"
                value={
                  Boolean(notification?.scheduledAt)
                    ? dayjs(notification?.scheduledAt).format("DD/MM/YYYY")
                    : ""
                }
                title={
                  Boolean(notification?.scheduledAt)
                    ? dayjs(notification?.scheduledAt).format("DD/MM/YYYY")
                    : ""
                }
                disabled
                placeholder=""
                readOnly
              />
            </Col>
            <Col
              xs={24}
              xxl={6}
              className={styles.notificationDetailsInputContainer}
            >
              <label htmlFor="status">Estatus</label>
              <input
                name="status"
                type="text"
                value={
                  notificationStatusMatcher[notification?.status]?.label || ""
                }
                title={
                  notificationStatusMatcher[notification?.status]?.label || ""
                }
                disabled
                placeholder=""
                readOnly
              />
            </Col>
          </Row>

          {Boolean(
            notification?.promotionsAvailable?.length ||
              notification?.selectedPromotions?.length
          ) && (
            <PromotionsTable
              refreshEmailPreview={refreshEmailPreview}
              notification={notification}
              getNotification={getNotification}
              promotionsAvailable={notification?.promotionsAvailable}
              selectedPromotions={notification?.selectedPromotions}
            />
          )}
        </Col>

        <Col xs={24} lg={10} xxl={12} className={styles.emailIframeContainer}>
          <strong>Preview del email</strong>
          {!isLoadingNotification && (
            <iframe
              id="emailPreview"
              src={notification?.previewURL || "Preview no disponible"}
              title="Notification email preview"
            />
          )}
        </Col>
      </Row>
    </section>
  );
};

export default withRouter(NotificationDetails);
