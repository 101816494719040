import React, { useMemo, useState } from "react";
import PromotionsTable from "./Components/PromotionsTable";
import PromotionForm from "./Components/PromotionForm";
import { useQuery } from "@apollo/client";
import { PROMOTIONS_BY_COMPANY } from "./Graphql/Queries";
import { getUser } from "../../Helpers/auth";
import { notification } from "antd";
import "./index.css";
import Header from "./Components/Header";

const CompanyPromotionsScreen = () => {
  const [page, setPage] = useState("list");
  const [promotion, setPromotion] = useState({});

  const companyId = getUser()?.company
    ? getUser()?.company?._id
    : JSON.parse(localStorage.getItem("companySelected")) === "all" &&
      JSON.parse(localStorage.getItem("companySelected")) !== null
    ? ""
    : JSON.parse(localStorage.getItem("companySelected"));

  const [sortBy, setSortBy] = useState({
    field: "CreatedAt",
    direction: "DESC",
  });

  const [filter, setFilter] = useState({
    status: undefined,
    visible: undefined,
  });

  const [search, setSearch] = useState({
    field: "Name",
    value: undefined,
  });

  const promotionsByCompanyQueryVariables = useMemo(() => {
    const addSearchFilter = Boolean(search?.value);
    return {
      companyId,
      sortBy,
      filter: {
        ...filter,
        search: addSearchFilter ? search : undefined,
      },
    };
  }, [companyId, sortBy, filter, search]);

  const {
    refetch: promotionsByCompanyQuery,
    loading: loadingTable,
    error: errorTable,
    data: dataTable,
  } = useQuery(PROMOTIONS_BY_COMPANY, {
    variables: promotionsByCompanyQueryVariables,
    fetchPolicy: "no-cache",
  });

  const handlePage = (a) => {
    if (a === "create") {
      if (!getUser().company) {
        if (
          JSON.parse(localStorage.getItem("companySelected")) === "all" ||
          JSON.parse(localStorage.getItem("companySelected")) === null
        ) {
          notification.warning({
            message: `Aviso`,
            description:
              "Para poder crear una promoción, por favor escoge una empresa en específico para continuar.",
            placement: "bottomRight",
          });
        } else {
          setPromotion({});
          setPage(a);
        }
      } else {
        setPromotion({});
        setPage(a);
      }
    } else {
      setPage(a);
    }
  };

  return (
    <>
      {page === "list" ? (
        <>
          <Header
            handlePage={handlePage}
            filter={filter}
            setFilter={setFilter}
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            setSearch={setSearch}
            getPromotionsByCompany={promotionsByCompanyQuery}
          />
          <PromotionsTable
            promotion={promotion}
            setPromotion={setPromotion}
            loadingTable={loadingTable}
            errorTable={errorTable}
            dataTable={dataTable}
            setPage={setPage}
            promotionsByCompanyQuery={promotionsByCompanyQuery}
          />
        </>
      ) : (
        <PromotionForm
          page={page}
          setPage={setPage}
          promotion={promotion}
          promotionsByCompanyQuery={promotionsByCompanyQuery}
        />
      )}
    </>
  );
};

export default CompanyPromotionsScreen;
