import { useState } from "react";
import { Checkbox } from "antd";
import styles from "./index.module.css";
import ArrowIcon from "../../Assets/black_arrow_icon.svg";

const mapDaysToCheckboxOptions = (availableDays) => {
  const checkboxOptions = availableDays.map(({ day, count }) => {
    const matcher = {
      monday: { label: `Lunes (${count})`, value: day },
      tuesday: { label: `Martes (${count})`, value: day },
      wednesday: { label: `Miércoles (${count})`, value: day },
      thursday: { label: `Jueves (${count})`, value: day },
      friday: { label: `Viernes (${count})`, value: day },
      saturday: { label: `Sábado (${count})`, value: day },
      sunday: { label: `Domingo (${count})`, value: day },
    };
    return matcher[day.toLowerCase()] || null;
  });
  return checkboxOptions.filter((option) => option !== null);
};

const FilterByDay = ({
  filters,
  setFilters,
  availableDays,
  getFilteredPromotions,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const checkboxOptions = mapDaysToCheckboxOptions(availableDays);

  const handleChange = (values) => {
    const newFilters = [...values];
    setFilters({ ...filters, daysOfWeek: newFilters });
    getFilteredPromotions({
      customFilters: {
        daysOfWeek: newFilters?.length === 0 ? undefined : newFilters,
      },
    });
  };

  return (
    <section className={styles.container}>
      <header
        className={styles.header}
        onClick={() => setShowOptions(!showOptions)}
      >
        <h2 className={styles.headerTitle}>Días de la semana</h2>
        <button
          className={`${styles.headerArrowButton} ${
            showOptions ? styles.rotate : ""
          }`}
        >
          <img src={ArrowIcon} alt="close icon" />
        </button>
      </header>

      {checkboxOptions.length > 0 && showOptions && (
        <Checkbox.Group
          className={styles.checkboxGroup}
          options={checkboxOptions}
          onChange={(checkedValues) => handleChange(checkedValues)}
          value={filters?.daysOfWeek}
        />
      )}
    </section>
  );
};

export default FilterByDay;
