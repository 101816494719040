import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { Row, Col, Spin, Input } from "antd";
import { withRouter } from "react-router-dom";
import { PROFILE } from "../../Graphql/Queries";
import { LOGOUT, REFRESH_TOKEN } from "../../Graphql/Mutations";
import { useMutation, useQuery } from "@apollo/client";
import { setAccessToken, setUser } from "../../Helpers/auth";
import UserDrawer from "./Components/UserDrawer";

import Logo from "../../Assets/Logo_loia.png";
import searchIcon from "./Assets/search-icon.svg";
import userIcon from "./Assets/user-icon.svg";
import menuIcon from "../../Assets/Menu/open.svg";
import closeMenuIcon from "../../Assets/Menu/close.svg";
import searchIconWhite from "./Assets/search-icon-white.svg";
import UserDesktopDrawer from "./Components/UserDesktopDrawer";

const UserLayout = ({ children, history }) => {
  const [searchWord, setSearchWord] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [visible, setVisible] = useState(false);
  const [refreshToken, { loading }] = useMutation(REFRESH_TOKEN);
  const { refetch: profileRefetch, data: dataProfile } = useQuery(PROFILE, {
    fetchPolicy: "no-cache",
  });
  const [logout] = useMutation(LOGOUT);

  const handleSearch = (event) => {
    if (event.key === "Enter" && searchWord !== "") {
      history.push(`/searchPromotion/${searchWord}`);
    }
  };
  const handleOpenSearch = () => {
    setIsSearching(!isSearching);
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        setAccessToken("");
        setUser({});
        history.push("/");
        localStorage.removeItem("companySelected");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    refreshToken()
      .then((res) => {
        setAccessToken(res.data.refreshToken.token);
        profileRefetch()
          .then((profile) => {
            if (profile.data.profile.role !== "User")
              history.push("/companyHome");
          })
          .catch((e) => {
            console.log(e);
            history.push("/");
          });
      })
      .catch((e) => {
        if(e.toString().startsWith('Error: Unauthorized')) handleLogout()
        console.log(e);
      });
      // eslint-disable-next-line
  }, [refreshToken, history, profileRefetch]);

  useEffect(() => {
    if (dataProfile) {
      setUser(dataProfile.profile);
    }
  }, [dataProfile]);

  const onClose = () => setVisible(false);

  if (loading)
    return (
      <Row justify="center" style={{ height: "100vh" }}>
        <Spin size="large" />
      </Row>
    );
  return (
    <div className={styles.container}>
      <Row align="middle" justify="space-between" className="containerMain-PLS">
        <Col xs={12} sm={9} md={7} lg={6} className="leftCol-Main-PLS">
            <img src={Logo} onClick={() => history.push("/")}  alt="logo" className="logoIcon" />
        </Col>
        <Col xs={12} sm={15} md={17} lg={18} className="rightCol-Main-PLS">
          <Row justify="space-between" align="middle" style={{ width: "100%" }}>
            <Col span={24} className={styles.headerCompanyWebCol}>
              <div
                className={`
                ${styles.headerCompanyWeb} headerCompanyWeb
                `}
              >
                <Input
                  placeholder="Buscar..."
                  className="searchInputHeader"
                  prefix={
                    <img
                      src={searchIcon}
                      alt=""
                      className="searchIcon"
                      onClick={() => {
                        if (searchWord !== "")
                          history.push(`/searchPromotion/${searchWord}`);
                      }}
                    />
                  }
                  onChange={(e) => setSearchWord(e.target.value)}
                  onKeyPress={handleSearch}
                />
                <div className="userLabel">
                  <h2
                    className={`${styles.userLabelName} userLabelName`}
                    onClick={() => history.push("/login")}
                  >
                    {dataProfile &&
                    dataProfile?.profile?.firstName &&
                    dataProfile?.profile?.lastName
                      ? `${dataProfile?.profile?.firstName} ${dataProfile?.profile?.lastName}`
                      : "Iniciar Sesión"}
                  </h2>
                  <img
                    src={userIcon}
                    alt=""
                    onClick={() => history.push("/login")}
                    className={`${styles.userIcon} userIcon`}
                  />
                  <div
                    className={`${styles.headerCompanyMobile} headerCompanyMobile`}
                  >
                    <img
                      src={!visible ? menuIcon : closeMenuIcon}
                      alt=""
                      className={`${styles.menuIcon} menuResponsiveIconMobile`}
                      onClick={() => setVisible(!visible)}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${styles.headerSearchMobile} headerSearchMobile`}
              >
                <img
                  src={searchIconWhite}
                  alt=""
                  className="searchIconMobile"
                  onClick={() => handleOpenSearch()}
                />
                <img
                  src={!visible ? menuIcon : closeMenuIcon}
                  alt=""
                  className="menuResponsiveIconMobile"
                  onClick={() => setVisible(!visible)}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div
        className={!isSearching ? "searchMobileArea" : "searchMobileAreaOpen"}
      >
        <Input
          placeholder="Buscar..."
          className={
            !isSearching
              ? "searchInputHeaderMobile"
              : "searchInputHeaderMobileOpen"
          }
          prefix={
            <img
              src={searchIcon}
              alt=""
              className="searchIcon"
              onClick={() => {
                if (searchWord !== "")
                  history.push(`/searchPromotion/${searchWord}`);
              }}
            />
          }
          onChange={(e) => setSearchWord(e.target.value)}
          onKeyPress={handleSearch}
        />
      </div>
        <Row id="background" className={styles.containerUserLayout}>
          <Col xs={24} sm={24} md={0}>
            <h2 className={styles.userMobileName}>
              {dataProfile && dataProfile.profile.firstName} {dataProfile && dataProfile.profile.lastName}
            </h2>
          </Col>
          <Col xs={0} sm={0} md={2} xl={1}></Col>
          <Col
            className={styles.userLayoutCol}
            align="top"
            xs={24}
            sm={24}
            md={20}
            xl={15}
          >
            {children}
          </Col>
          <Col className={styles.userDrawerCol} span={3}>
            <UserDesktopDrawer
              visible={true}
              handleLogout={handleLogout}
              firstName={dataProfile && dataProfile.profile.firstName}
              lastName={dataProfile && dataProfile.profile.lastName}
              onClose={onClose}
            />
          </Col>
        </Row>
      <UserDrawer
        visible={visible}
        onClose={onClose}
        handleLogout={handleLogout}
        firstName={dataProfile && dataProfile.profile.firstName}
        lastName={dataProfile && dataProfile.profile.lastName}
      />
    </div>
  );
};

export default withRouter(UserLayout);
