import gql from "graphql-tag";

const PAGINATED_PROMOTIONS = gql`
  query paginatedPromotions($filter: PaginatedPromotionsFilterInput) {
    paginatedPromotions(filter: $filter) {
      data {
        _id
        name
        communes
        discount
        averageRating
        type {
          name
        }
        conditions
        company {
          name
          logo
          icon
        }
        localName
        localLogo
        dueDate
        weekDays
        benefitURL
        termAndConditions
        status
        image
      }
      interestingContent {
        _id
        name
        communes
        discount
        averageRating
        type {
          name
        }
        conditions
        company {
          name
          logo
          icon
        }
        localName
        localLogo
        dueDate
        weekDays
        benefitURL
        termAndConditions
        status
        image
      }
      metadata {
        page
        limit
        totalDocs
        totalPages
      }
    }
  }
`;

const SCALARS = gql`
  query {
    scalars {
      _id
      name
      description
      scalarType {
        name
        id
      }
    }
  }
`;

const PROMOTION = gql`
  query promotion($input: FindPromotion!) {
    promotion(input: $input) {
      _id
      name
      communes
      discount
      averageRating
      type {
        name
      }
      conditions
      company {
        name
        logo
        icon
      }
      localName
      localLogo
      dueDate
      weekDays
      benefitURL
      termAndConditions
      status
      image
    }
  }
`;

export { PAGINATED_PROMOTIONS, SCALARS, PROMOTION };
