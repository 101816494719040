import { notification } from "antd";
import regex from "../Utils/regex";

const stepOneValidation = (companyData) => {
  const { name, category, phone } = companyData;

  if (name.trim() === "") {
    notification.error({
      message: "Error",
      description: "Debe ingresar el nombre de la empresa",
    });
    return false;
  }
  if (phone.trim() === "") {
    notification.error({
      message: "Error",
      description: "Debe ingresar el teléfono de la empresa",
    });
    return false;
  }
  if (!regex.onlyNumbers.test(phone)) {
    notification.error({
      message: "Error",
      description: "El teléfono debe contener solo números",
    });
    return false;
  }
  if (category.trim() === "") {
    notification.error({
      message: "Error",
      description: "Debe ingresar el rubro de la empresa",
    });
    return false;
  }
  return true;
};

export default stepOneValidation;
