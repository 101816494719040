import React from "react";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from './index.module.css'

const DeletePromotionModal = ({ visible, onCancel, handleDeletePromotion, loadingDelete }) => {

  return (
    <>
      <Modal
            className={styles.container}
            visible={visible}
            footer={null}
            bodyStyle={{ paddingBottom: "2rem" }}
            onCancel={onCancel}
            closeIcon={<CloseOutlined className={styles.closeIcon} />}
        >
            <section className={styles.content}>
                <header className={styles.header}>
                <h3 className={styles.headerTitle}>
                    Eliminar promoción
                </h3>
                <span>¿Estás seguro/a que quieres eliminar a esta promoción?</span>
                </header>

                
                <footer className={styles.footer}>
                    <Button
                        className={`${styles.footerButton} ${styles.footerGhostButton}`}
                        onClick={() => onCancel()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        loading={loadingDelete}
                        className={styles.footerButton}
                        onClick={() => handleDeletePromotion()}
                    >
                        Eliminar
                    </Button>
                </footer>
            </section>
        </Modal>
    </>
  );
};

export default DeletePromotionModal;