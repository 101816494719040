import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./index.css";
import SuccesIcon from "./Assets/succesIcon.svg";
import { useHistory } from "react-router-dom";

const SuccesModal = ({ succesModalVisibility, setSuccesModalVisibility }) => {
  const history = useHistory();
  return (
    <Modal
      visible={succesModalVisibility}
      onCancel={() => {
        setSuccesModalVisibility(false);
        history.push("/userHome");
      }}
      footer={null}
      closeIcon={<CloseOutlined className="succes-modal__close-button" />}
    >
      <div className="succes-modal">
        <img src={SuccesIcon} alt="" className="succes-modal__icon" />
        <p className="succes-modal__description">
          Se agrego correctamente tu empresa
        </p>
      </div>
    </Modal>
  );
};

export default SuccesModal;
