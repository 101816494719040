import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { CloseOutlined} from '@ant-design/icons';
import { Row, Spin, Table, Modal, Collapse } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { getUser, setAccessToken } from "../../../../Helpers/auth";
import ModalPromotion from "../../../../Components/ModalPromotion";
import DemographicsReport from "../PromotionsSeenByUsersBox";
import { REFRESH_TOKEN } from '../../../../Graphql/Mutations'
import readIcon from "../../Assets/read-icon.svg";
import reportsIcon from "../../Assets/reports-icon.svg";
import "./index.css";
import * as dayjs from 'dayjs'
import "dayjs/locale/es"
import { PROMOTIONS_BY_COMPANY_REPORT } from "../../Graphql/Queries";
dayjs.locale("es");

const { Panel } = Collapse;

const PromotionsTableReport = ({
  type
}) => {

    const [detailModalVisible, setDetailModalVisible] = useState(false)
    const [statsModalVisible, setStatsModalVisible] = useState(false)
    const [promotion, setPromotion] = useState({})

    const companySelected = JSON.parse(localStorage.getItem('companySelected'))
    const companyId = getUser().company ? getUser().company._id : companySelected === 'all' ?  null : companySelected
    
    const { refetch, loading: loadingTable, error: errorTable, data: dataTable } = useQuery(PROMOTIONS_BY_COMPANY_REPORT, {
        variables:{
          companyId: companyId,
          type: type
        }
      })
    const [refreshToken] = useMutation(REFRESH_TOKEN)


    useEffect(() => {
      const getData = () => {
        refreshToken().then(res => {
          setAccessToken(res.data.refreshToken.token)
          refetch()
        })
      }
      getData()
    },[type, refreshToken, refetch])

    

    const onCancelDetailModal = () => {
        setDetailModalVisible(false)
    }

    const handleDetailModal = promotion => {
        setPromotion({...promotion, company: getUser().company})
        setDetailModalVisible(true)
    }

    const onCancelStatsModal = () => {
        setStatsModalVisible(false)
    }

    const handleStatsModal = promotion => {
        setPromotion({...promotion, company: getUser().company})
        setStatsModalVisible(true)
    }

  const columns = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      render: (code) => <p className={styles.tableName}>{code}</p>,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (_,record) => <p className={styles.tableName}>{record.discount}% de descuento con {record.localName}</p>,
    },
    {
      title: "Hasta",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date) => (
        <>
          <p className={styles.tableName}>
            {dayjs(date).format("DD-MM-YYYY", "es")}
          </p>
        </>
      ),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
          {status === "Active" ? (
            <p className={`${styles.tableName} ${styles.tableNameStatusActive}`}>Activo</p>
          ) : null}
          {status === "Inactive" ? (
            <p className={`${styles.tableName} ${styles.tableNameStatusInactive}`}>Inactivo</p>
          ) : null}
          {status === "Expired" ? (
            <p                     className={`${styles.tableName} ${styles.tableNameStatusVence}`}>Vencido</p>
          ) : null}
        </>
      ),
    },
    {
      title: "Visible",
      dataIndex: "visible",
      key: "visible",
      render: (visible) => (
        <p
        className={`${styles.tableName} ${
          visible
            ? styles.tableNameVisibleTrue
            : styles.tableNameVisibleFalse
        }`}
      >
        {visible ? "Si" : "No"}
      </p>
      ),
    },
    {
      title: "Creado por",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (createdBy) => (
        <p className={styles.tableName}>{createdBy?.email}</p>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <div className={styles.tableDeskActions}>
          <button
            className={styles.tableActionsButton}
            onClick={() => handleDetailModal(record)}
          >
            <img src={readIcon} alt="" />
          </button>
          <button
            onClick={() => handleStatsModal(record)}
            className={styles.tableActionsButton}
          >
            <img src={reportsIcon} alt="" />
          </button>
        </div>
      ),
    },
  ];

  const checkSuperAdminColumn = () => {
    if(getUser().role === 'SuperAdmin'){
      columns.splice(5, 0, {
        title: "Empresa",
        dataIndex: "company",
        key: "company",
        render: (company, _record) => (
            <p className={styles.tableName}>{company?.name}</p> 
        )
      });
      return columns
    }else return columns
  }

  if(loadingTable) return (
    <div className="container-ReportScreen">
    <Row justify="center" align="center">
      <Spin /> 
    </Row>
    </div>
  )
  else if (errorTable) return (
    <p>Hubo un error al intentar cargar la data</p>
  )
  if (dataTable)
  return (
    <>
      <Modal
        visible={statsModalVisible}
        onCancel={onCancelStatsModal}
        footer={null}
        width={"80%"}
        style={{ top: 50 }}
        closeIcon={<CloseOutlined className="closeModalButton" />}
      >
        <DemographicsReport isModal={true} promotion={promotion}/>
      </Modal>
      <ModalPromotion
        visible={detailModalVisible}
        promotion={promotion}
        onCancel={onCancelDetailModal}
      />

      <Table
        rowKey={(col) => col._id}
        columns={checkSuperAdminColumn()}
        dataSource={dataTable.promotionsByCompany}
        className={styles.table}
      />

      <Collapse
        className={styles.accordion}
        expandIconPosition="right"
        accordion
      >
        <h2 className={styles.accordionTitle}>Nombre</h2>
        {dataTable?.promotionsByCompany?.map((promo, index) => (
          <Panel
            header={
              <h3 className={styles.panelHeaderTitle}>
                {promo.discount}% de descuento con {promo.localName}{" "}
              </h3>
            }
            key={index}
            className={styles.accordionPanel}
          >
            <div className={styles.accordionContent}>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Hasta:</h4>
                <p className={styles.tableName}>
                  {dayjs(promo.dueDate).format("DD-MM-YYYY", "es")}
                </p>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Estado:</h4>
                {promo.status === "Active" ? (
                  <p
                    className={`${styles.tableName} ${styles.tableNameStatusActive}`}
                  >
                    Activo
                  </p>
                ) : null}
                {promo.status === "Inactive" ? (
                  <p
                    className={`${styles.tableName} ${styles.tableNameStatusInactive}`}
                  >
                    Inactivo
                  </p>
                ) : null}
                {promo.status === "Expired" ? (
                  <p
                    className={`${styles.tableName} ${styles.tableNameStatusVence}`}
                  >
                    Vencido
                  </p>
                ) : null}
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Visible:</h4>
                <p
                  className={`${styles.tableName} ${
                    promo.visible
                      ? styles.tableNameVisibleTrue
                      : styles.tableNameVisibleFalse
                  }`}
                >
                  {promo.visible ? "Si" : "No"}
                </p>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Creado por:
                </h4>
                <p className={styles.tableName}>{promo?.createdBy?.email}</p>
              </div>
              <div className={styles.tableActions}>
                <button
                  className={styles.tableActionsButton}
                  onClick={() => handleDetailModal(promo)}
                >
                  <img src={readIcon} alt="" />
                </button>
                <button
                  onClick={() => handleStatsModal(promo)}
                  className={styles.tableActionsButton}
                >
                  <img src={reportsIcon} alt="" />
                </button>
              </div>
            </div>
          </Panel>
        ))}
      </Collapse>
    </>
  );
  
};

export default PromotionsTableReport;