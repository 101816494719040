import gql from "graphql-tag";

const CREATE_USER = gql`
mutation createUser($input: CreateUserInput!){
    createUser(input: $input){
      _id
    }
  }
`;

const UPDATE_USER = gql`
mutation updateUser($input: CreateUserInput!, $id: String!){
    updateUser(input: $input, id: $id){
      _id
    }
  }
`;

const DELETE_USER = gql`
mutation deleteUser($id: String!){
    deleteUser(id: $id){
      _id
    }
  }
`;

const CREATE_EMPLOYEE = gql`
mutation addEmployeeToCompany($input: CreateEmployeeInput!, $companyId: String!){
  addEmployeeToCompany(input: $input, companyId: $companyId){
      _id
    }
  }
`;

const DELETE_EMPLOYEE = gql`
mutation deleteEmployeeToCompany($input: String!, $companyId: String!){
  deleteEmployeeToCompany(input: $input, companyId: $companyId){
      _id
    }
  }
`;


export { CREATE_USER, UPDATE_USER, DELETE_USER, CREATE_EMPLOYEE, DELETE_EMPLOYEE }