import React from 'react'
import { Button, Form } from 'antd'
import { Link } from "react-router-dom";
import styles from './index.module.css'

const ForgotPasswordForm = ({
    email,
    setEmail,
    handleForgotPassword,
    formValidation,
    loadingForgotPassword
}) => {

    return (
        <section className={styles.container}>
            <Form id="form-login" className={styles.form}>
                <h2 className={styles.formTitle}>
                    Ingresa el correo de la cuenta para cambiar tu contraseña
                </h2>
                <div className={styles.formItem}>
                <label className={styles.formItemLabel} htmlFor="email">
                    Correo electrónico
                </label>
                <input
                    className={styles.formItemInput}
                    name="email"
                    autoComplete="off"
                    spellCheck="false"
                    type="email"
                    placeholder="Ingresa tu correo"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                </div>

                <Button
                loading={loadingForgotPassword}
                className={styles.formButton}
                onClick={() => formValidation() && handleForgotPassword()}
                >
                Continuar
                </Button>
            </Form>
            <footer className={styles.footer}>
                <p className={styles.footerDescription}>
                    <Link to="/login">Ya recordé mi contraseña</Link>
                </p>
            </footer>
        </section>
    )
}

export default ForgotPasswordForm