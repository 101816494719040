import gql from "graphql-tag";

const NOTIFICATIONS = gql`
  query Notifications {
    notifications {
      _id
      description
      type
      status
      scheduledAt
      createdAt
      __typename
      previewURL
      selectedPromotions {
        _id
        name
        localName
        company {
          name
        }
      }
      promotionsAvailable {
        _id
        name
        localName
        company {
          name
        }
      }
      scheduledBy {
        _id
        email
      }
    }
  }
`;

const NOTIFICATION_TRIGGERS = gql`
  query NotificationTriggers {
    notificationTriggers {
      __typename
      _id
      description
      enabled
      type
      dayOfMonth
      isAvailable
    }
  }
`;

const SEND_TEST_NOTIFICATION_EMAIL = gql`
  query sendTestMailNotification($id: String!, $email: String!) {
    sendTestMailNotification(id: $id, email: $email) {
      _id
    }
  }
`;

const NOTIFICATION = gql`
  query notification($id: String!) {
    notification(id: $id) {
      _id
      description
      type
      status
      scheduledAt
      createdAt
      __typename
      previewURL
      selectedPromotions {
        _id
        name
        localName
        company {
          name
        }
      }
      promotionsAvailable {
        _id
        name
        localName
        company {
          name
        }
      }
      scheduledBy {
        _id
        email
      }
    }
  }
`;

export {
  NOTIFICATION,
  NOTIFICATIONS,
  NOTIFICATION_TRIGGERS,
  SEND_TEST_NOTIFICATION_EMAIL,
};
