import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import "./index.css";
import { useParams } from "react-router";
import { Button, Col, Spin, Result, Row } from "antd";
import ModalPromotion from "../../../../Components/ModalPromotion";
import { useQuery, useMutation } from "@apollo/client";
import { NEW_VIEW } from "../../../../Graphql/Mutations";
import NoPromotions from "../../../../Components/NoPromotions";
import PromotionCard from "../../../../Components/PromotionCard";
import PromotionsFilter from "../../../../Components/PromotionsFilter";
import useFilters from "../../../../Hooks/useFilters";
import { PAGINATED_PROMOTIONS } from "../../../HomeScreen/Graphql/Queries";
import InfiniteScroll from "react-infinite-scroll-component";
import Benefits from "../../../HomeScreen/Components/Promotions/assets/benefits_icon.svg";

const Promotions = () => {
  const { input } = useParams();
  const { filters, setFilters, getActiveFilters } = useFilters();

  const [promotions, setPromotions] = useState([]);
  const [interestingPromotions, setInterestingPromotions] = useState([]);
  const [currentPromotion, setCurrentPromotion] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const [error, setError] = useState(false);
  const [hasMorePromotions, setHasMorePromotions] = useState(true);
  const [showStickyFilters, setShowStickyFilters] = useState(false);
  const [isLoadingPromotions, setIsLoadingPromotions] = useState(true);
  const showNoPromotions = !isLoadingPromotions && promotions?.length === 0;
  const [isPromotionModalVisible, setIsPromotionModalVisible] = useState(false);

  const [newViewPromotion] = useMutation(NEW_VIEW);
  const { refetch: getPaginatedPromotions } = useQuery(PAGINATED_PROMOTIONS, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const getInitialPromotions = async ({ customFilters = {} }) => {
    setIsLoadingPromotions(true);
    setCurrentPage(1);
    const activeFilters = getActiveFilters(filters) || {};
    const commonFilters = {
      limit: 1000,
      search: input?.toLowerCase() || "",
      page: 1,
    };

    try {
      const promotionsData = await getPaginatedPromotions({
        filter: { ...commonFilters, ...activeFilters, ...customFilters },
      });
      setHasMorePromotions(
        promotionsData?.data?.paginatedPromotions?.metadata?.totalPages >
          currentPage
      );
      setPromotions(promotionsData?.data?.paginatedPromotions?.data || []);
      const isInterestingContentAvailable = Boolean(
        promotionsData?.data?.paginatedPromotions?.interestingContent
      );
      if (
        interestingPromotions?.length === 0 &&
        isInterestingContentAvailable
      ) {
        setInterestingPromotions(
          promotionsData?.data?.paginatedPromotions?.interestingContent
        );
      }
    } catch (e) {
      setError(true);
    } finally {
      setIsLoadingPromotions(false);
    }
  };

  const getMorePromotions = async ({ page = 1, customFilters = {} }) => {
    setIsLoadingPromotions(true);
    const activeFilters = getActiveFilters(filters) || {};
    const commonFilters = {
      limit: 1000,
      search: input?.toLowerCase() || "",
      page,
    };

    try {
      const promotionsData = await getPaginatedPromotions({
        filter: { ...commonFilters, ...activeFilters, ...customFilters },
      });
      setHasMorePromotions(
        promotionsData?.data?.paginatedPromotions?.metadata?.totalPages >
          currentPage
      );
      setPromotions(promotionsData?.data?.paginatedPromotions?.data || []);
      const isInterestingContentAvailable = Boolean(
        promotionsData?.data?.paginatedPromotions?.interestingContent
      );
      if (
        interestingPromotions?.length === 0 &&
        isInterestingContentAvailable
      ) {
        setInterestingPromotions(
          promotionsData?.data?.paginatedPromotions?.interestingContent
        );
      }
    } catch (e) {
      setError(true);
    } finally {
      setIsLoadingPromotions(false);
    }
  };

  const showPromotionDetailsModal = (promotion) => {
    setIsPromotionModalVisible(true);
    setCurrentPromotion(promotion);
  };

  useEffect(() => {
    getInitialPromotions({
      customFilters: { includeAlliedPartners: false },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  //to check when header element get's position sticky
  const headerElementObserver = new IntersectionObserver(
    function (entries) {
      // no intersection
      if (entries?.[0]?.intersectionRatio === 0)
        document
          ?.querySelector("#header-container")
          ?.classList.add("stickyHeader");
      // fully intersects
      else if (entries?.[0]?.intersectionRatio === 1)
        document
          ?.querySelector("#header-container")
          ?.classList.remove("stickyHeader");
    },
    { threshold: [0, 1] }
  );
  const headerElement = document?.querySelector("#header-container-top");

  if (headerElement) {
    headerElementObserver.observe(headerElement);
  }

  if (error && !isLoadingPromotions) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Lo sentimos, hubo un error al intentar cargar la información."
        extra={
          <Button type="primary">
            <a href={`/searchPromotion/${input}`}>Volver a intentarlo </a>
          </Button>
        }
      />
    );
  }

  return (
    <Row justify="center" className={styles.container}>
      <ModalPromotion
        promotion={currentPromotion}
        visible={isPromotionModalVisible}
        onCancel={() => setIsPromotionModalVisible(false)}
      />

      {showStickyFilters && <div className={styles.overlay} />}

      <Col
        xs={22}
        md={20}
        lg={18}
        xl={20}
        className={styles.promotionsContainer}
      >
        <div id="header-container-top" />
        <header
          className={`${styles.headingContainer} ${
            showStickyFilters ? styles.headingContainerWithFiltersVisible : {}
          } `}
          id="header-container"
        >
          <img src={Benefits} alt="Benefits icon" />
          <h2>Beneficios</h2>
          <div className={styles.promotionsFiltersSticky}>
            <PromotionsFilter
              filters={filters}
              setFilters={setFilters}
              showShortFiltersList={true}
              getActiveFilters={getActiveFilters}
              showStickyFilters={showStickyFilters}
              setShowStickyFilters={setShowStickyFilters}
              getFilteredPromotions={getInitialPromotions}
            />
          </div>
        </header>

        <section className={styles.promotions}>
          {showNoPromotions ? (
            <NoPromotions className={styles.noPromotions} />
          ) : (
            <InfiniteScroll
              next={() => {
                setCurrentPage(currentPage + 1);
                getMorePromotions({ page: currentPage + 1 });
              }}
              dataLength={promotions?.length || 0}
              hasMore={hasMorePromotions}
              style={{ overflow: "hidden", width: "100%" }}
              loader={
                <Row justify="center">
                  <Spin className="loadingSpinner" size="large" />
                </Row>
              }
            >
              <div className={styles.promotionsGrid}>
                {promotions?.map((item, index) => (
                  <PromotionCard
                    pro={item}
                    key={index}
                    showModal={showPromotionDetailsModal}
                    newViewPromotion={newViewPromotion}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
          {showStickyFilters && (
            <div className={styles.promotionsFiltersGridBox}>
              <PromotionsFilter showStickyFilters={false} />
            </div>
          )}
        </section>

        {interestingPromotions?.length > 0 && (
          <section className={styles.interestingContent}>
            <h2>Otras promociones que te pueden interesar:</h2>

            <div className={styles.promotionsGrid}>
              {interestingPromotions?.map((item, index) => (
                <PromotionCard
                  pro={item}
                  key={index}
                  showModal={showPromotionDetailsModal}
                  newViewPromotion={newViewPromotion}
                />
              ))}
            </div>
          </section>
        )}
      </Col>
    </Row>
  );
};

export default Promotions;
