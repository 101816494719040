export const templates = {
  'users': {
    title: 'Mis usuarios',
    columns: [{ header: 'Nombre', key: 'firstName', width: 20 },
    { header: 'Apellido', key: 'lastName', width: 32 },
    { header: 'Email', key: 'email', width: 20 },
    { header: 'Teléfono', key: 'phone', width: 20 },
    { header: 'Rol', key: 'role', width: 32 },
    { header: 'Segmento', key: 'segment', width: 20 },
    { header: 'Contraseña', key: 'password', width: 32 },]
  },
  'promotions': {
    title: 'Mis promociones',
    columns: [
      { header: 'Nombre', key: 'name', width: 20 },
      { header: 'Descripción', key: 'description', width: 32 },
      { header: 'Categoría', key: 'type', width: 20 },
      { header: 'Condiciones', key: 'conditions', width: 32 },
      { header: 'Nombre del local', key: 'localName', width: 20 },
      { header: 'Logo del local', key: 'localLogo', width: 32 },
      { header: 'Fecha de inicio', key: 'startDate', width: 20 },
      { header: 'Fecha de finalización', key: 'dueDate', width: 32 },
      { header: 'Días de las semana', key: 'weekDays', width: 20 },
      { header: 'URL del local', key: 'benefitURL', width: 32 },
      { header: 'Terminos y condiciones', key: 'termAndConditions', width: 20 },
      { header: 'Estado', key: 'status', width: 32 },
      { header: 'Imagen de la promoción', key: 'image', width: 20 },
      { header: 'Visible', key: 'visible', width: 32 },
      { header: 'Comunas', key: 'communes', width: 20 },
    ]
  }
};
