import { useMutation, useQuery } from "@apollo/client";
import Switch from "../../../../Components/Switch";
import styles from "./index.module.css";
import {
  CREATE_NOTIFICATION_FOR_TRIGGER,
  UPDATE_NOTIFICATION_TRIGGER,
} from "../../Graphql/Mutations";
import { NOTIFICATION_TRIGGERS } from "../../Graphql/Queries";
import { useEffect } from "react";
import { notification } from "antd";

const NotificationsManagement = () => {
  const [
    createNotificationForTrigger,
    { loading: isCreatingNotificationForTrigger },
  ] = useMutation(CREATE_NOTIFICATION_FOR_TRIGGER);

  const [
    updateNotificationTrigger,
    { loading: isUpdatingNotificationTrigger },
  ] = useMutation(UPDATE_NOTIFICATION_TRIGGER);

  const { data: notificationTriggersData, refetch: getNotificationTriggers } =
    useQuery(NOTIFICATION_TRIGGERS);

  const notificationTriggers = notificationTriggersData?.notificationTriggers;

  useEffect(() => {
    getNotificationTriggers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateNotificationTrigger = (triggerInfo) => {
    updateNotificationTrigger({
      variables: {
        id: triggerInfo._id,
        input: {
          enabled: !triggerInfo?.enabled,
        },
      },
    })
      .then(() => {
        getNotificationTriggers();
        notification.success({
          message: "Operación exitosa",
          description: "Preferencia de notificación actualizada",
          placement: "bottomRight",
        });
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description:
            "Hubo un error al intentar actualizar las preferencias de notificaciones, inténtelo de nuevo.",
          placement: "bottomRight",
        });
      });
  };

  const handleCreateNotificationForTrigger = (triggerType) => {
    createNotificationForTrigger({
      variables: { type: triggerType },
    })
      .then(() => {
        getNotificationTriggers();
        notification.success({
          message: "Operación exitosa",
          description: "Notificación creada exitosamente",
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        const errorMessage =
          error?.message ||
          "Hubo un error al intentar crear la notificación, inténtelo de nuevo.";
        notification.error({
          message: "Error",
          description: errorMessage,
          placement: "bottomRight",
        });
      });
  };

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Pre-generación de notificaciones</h2>

      {notificationTriggers?.length > 0 &&
        notificationTriggers?.map((trigger) => {
          return (
            <div key={trigger?._id} className={styles.switchContainer}>
              <Switch
                className={styles.switch}
                isActive={trigger?.enabled}
                activeText={trigger?.description}
                desactiveText={trigger?.description}
                disabled={
                  isUpdatingNotificationTrigger ||
                  isCreatingNotificationForTrigger
                }
                onClickFn={() => handleUpdateNotificationTrigger(trigger)}
              />
              {trigger?.isAvailable && (
                <button
                  className={styles.button}
                  disabled={
                    isCreatingNotificationForTrigger ||
                    isUpdatingNotificationTrigger
                  }
                  onClick={() =>
                    handleCreateNotificationForTrigger(trigger?.type)
                  }
                >
                  Crear
                </button>
              )}
            </div>
          );
        })}
    </section>
  );
};

export default NotificationsManagement;
