import React from "react";
import ShoppingIcon from "./Components/ShoppingIcon";
import FlavorIcon from "./Components/FlavorIcon";
import TravelIcon from "./Components/TravelIcon";
import DecorationIcon from "./Components/DecorationIcon";
import HealthIcon from "./Components/HealthIcon";
import OtherIcon from "./Components/OtherIcon";

const Icon = ({ type, className }) => {
  const availableIcons = {
    shopping: <ShoppingIcon className={className} />,
    flavor: <FlavorIcon className={className} />,
    decoration: <DecorationIcon className={className} />,
    health: <HealthIcon className={className} />,
    travel: <TravelIcon className={className} />,
    other: <OtherIcon className={className} />,
  };

  return availableIcons[type || "other"];
};

export default Icon;
