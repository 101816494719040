import React from "react";
import styles from "./index.module.css";

const PromotionHeader = ({ image, localLogo, localName }) => {
  return (
    <header className={styles.header} style={{ background: `url(${image})` }}>
      <img
        className={styles.headerLogo}
        title={localName}
        alt={localName}
        src={localLogo}
      />
    </header>
  );
};

export default PromotionHeader;
