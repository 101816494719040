import React from "react";
import styles from "./index.module.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, Input, Button, Select, DatePicker } from "antd";

const RegisterForm = ({
  formData,
  setFormData,
  handleInputChange,
  handleRegister,
  formValidation,
}) => {
  const { Option } = Select;

  return (
    <section className={styles.container}>
      <Form className={styles.form}>
        <div className={`${styles.formItem} ${styles.formItemSpecial}`}>
          <label className={styles.formItemLabel} htmlFor="firstName">
            Nombre
          </label>
          <input
            autoComplete="off"
            className={styles.formItemInput}
            name="firstName"
            onChange={(e) => handleInputChange(e)}
            placeholder="Ingresa tu nombre"
            spellCheck="false"
            title="Ingresa tu nombre"
            type="text"
            value={formData.firstName}
          />
        </div>

        <div className={`${styles.formItem} ${styles.formItemSpecial}`}>
          <label className={styles.formItemLabel} htmlFor="lastName">
            Apellido
          </label>
          <input
            autoComplete="off"
            className={styles.formItemInput}
            name="lastName"
            onChange={(e) => handleInputChange(e)}
            placeholder="Ingresa tu apellido"
            spellCheck="false"
            title="Ingresa tu apellido"
            type="text"
            value={formData.lastName}
          />
        </div>

        <div className={`${styles.formItem} ${styles.formItemSpecial}`}>
          <label className={styles.formItemLabel} htmlFor="birthDate">
            Fecha de nacimiento
          </label>
          <DatePicker
            className={styles.formItemInput}
            name="birthDate"
            onChange={(_, dateString) =>
              setFormData({
                ...formData,
                birthDate: dateString,
              })
            }
            placeholder="Fecha de Nacimiento"
          />
        </div>

        <div className={`${styles.formItem} ${styles.formItemSpecial}`}>
          <label className={styles.formItemLabel} htmlFor="gender">
            Genero
          </label>
          <Select
            showArrow={true}
            placeholder="Seleccione un genero"
            name="gender"
            onChange={(e) => setFormData({ ...formData, gender: e })}
            className={`${styles.formItemInput} ${styles.formItemInputSelect} `}
          >
            <Option value="Masculino">Masculino</Option>
            <Option value="Femenino">Femenino</Option>
          </Select>
        </div>

        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="email">
            Correo electrónico
          </label>
          <input
            className={styles.formItemInput}
            name="email"
            autoComplete="off"
            spellCheck="false"
            title="Ingresa tu correo electrónico"
            placeholder="Ingresa tu correo electrónico"
            type="email"
            value={formData.email}
            onChange={(e) => handleInputChange(e)}
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="password">
            Contraseña
          </label>
          <Input.Password
            className={styles.formItemInput}
            name="password"
            type="password"
            autoComplete="off"
            spellCheck="false"
            placeholder="Ingresa tu contraseña"
            title="Ingresa tu contraseña"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            value={formData.password}
            onChange={(e) => handleInputChange(e)}
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="confirmPassword">
            Confirmar contraseña
          </label>
          <Input.Password
            className={styles.formItemInput}
            name="confirmPassword"
            type="password"
            spellCheck="false"
            autoComplete="off"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            placeholder="Confirma tu contraseña"
            title="Confirma tu contraseña"
            value={formData.confirmPassword}
            onChange={(e) => handleInputChange(e)}
          />
        </div>

        <Button
          className={styles.formButton}
          onClick={() => formValidation(formData) && handleRegister()}
        >
          Registrarse
        </Button>
      </Form>
    </section>
  );
};

export default RegisterForm;
