import styles from "./index.module.css";
import UsersOriginChart from "./Components/UsersOriginChart";
import { getUser } from "../../../../Helpers/auth";
import UsersLocationChart from "./Components/UsersLocationChart";

const TrafficReports = ({ reportsData = {} }) => {
  if (!reportsData) return null;

  const user = getUser();
  const isSuperAdmin = user.role === "SuperAdmin";

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Trafico de los usuarios</h2>

      <div className={styles.stats}>
        <UsersLocationChart data={reportsData} />
        {isSuperAdmin && <UsersOriginChart data={reportsData} />}
      </div>
    </section>
  );
};

export default TrafficReports;
