import React from "react";
import styles from "./index.module.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import { Link } from "react-router-dom";

const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
  formValidation,
}) => {
  return (
    <section className={styles.container}>
      <Form id="form-login" className={styles.form}>
        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="email">
            Correo
          </label>
          <input
            className={styles.formItemInput}
            name="email"
            autoComplete="off"
            spellCheck="false"
            type="email"
            placeholder="Ingresa tu correo"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formItemLabel} htmlFor="password">
            Contraseña
          </label>
          <Input.Password
            className={styles.formItemInput}
            name="password"
            type="password"
            spellCheck="false"
            placeholder="Ingresa tu contraseña"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <Button
          className={styles.formButton}
          onClick={() => formValidation() && handleLogin()}
        >
          Iniciar sesión
        </Button>
      </Form>
      <footer className={styles.footer}>
      <p className={styles.forgotPasswordText}>
          <Link to="/forgotPassword">Olvidé mi contraseña</Link>
        </p>
        <p className={styles.footerDescription}>
          -¿No tienes cuenta? <Link to="/register">Regístrate aquí</Link> -
        </p>
      </footer>
    </section>
  );
};

export default LoginForm;
