import styles from "./index.module.css";
import { Col, Form, Input, Row, Select } from "antd";

const PromotionsFilters = ({
  sortBy,
  setSortBy,
  filter,
  setFilter,
  search,
  setSearch,
}) => {
  const { Option } = Select;

  return (
    <section className={styles.container}>
      <Row justify="space-between" align="middle" wrap>
        <Col
          xs={24}
          md={10}
          xl={7}
          className={`${styles.formItemsContainer} ${styles.sortInputsContainer}`}
        >
          <Form.Item className={styles.formItem} label="Ordenar por">
            <Select
              className={styles.selectInputWithLabel}
              placeholder="Selecciona una opción"
              defaultValue={sortBy?.field || "CreatedAt"}
              value={sortBy?.field || "CreatedAt"}
              onChange={(value) => setSortBy({ ...sortBy, field: value })}
            >
              <Option value="Code">ID</Option>
              <Option value="CreatedAt">Fecha</Option>
              <Option value="Visible">Visibilidad</Option>
              <Option value="Status">Estado</Option>
            </Select>
          </Form.Item>
          <Form.Item className={styles.formItem} label="">
            <Select
              className={styles.selectInputForValue}
              placeholder="Ordenar por"
              defaultValue={sortBy?.direction || "DESC"}
              value={sortBy?.direction || "DESC"}
              onChange={(value) => setSortBy({ ...sortBy, direction: value })}
            >
              <Option value="ASC">Ascendente</Option>
              <Option value="DESC">Descendente</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col
          xs={24}
          md={7}
          xl={4}
          className={`${styles.formItemsContainer} ${styles.filterInputsContainer}`}
        >
          <Form.Item className={styles.formItem} label="Visibilidad">
            <Select
              className={`${styles.selectInputWithLabel} ${styles.selectInputForFilter}`}
              placeholder="Filtrar por"
              defaultValue={filter?.visible || undefined}
              value={filter?.visible || undefined}
              onChange={(value) => setFilter({ ...filter, visible: value })}
            >
              <Option value={true}>Visible</Option>
              <Option value={false}>No visible</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col
          xs={24}
          md={7}
          xl={4}
          className={`${styles.formItemsContainer} ${styles.filterInputsContainer}`}
        >
          <Form.Item className={styles.formItem} label="Estado">
            <Select
              className={`${styles.selectInputWithLabel} ${styles.selectInputForFilter}`}
              placeholder="Filtrar por"
              defaultValue={filter?.status || undefined}
              value={filter?.status || undefined}
              onChange={(value) => setFilter({ ...filter, status: value })}
            >
              <Option value="Active">Activado</Option>
              <Option value="Inactive">No activado</Option>
              <Option value="Expired">Expirado</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col
          xs={24}
          xl={8}
          className={`${styles.formItemsContainer} ${styles.searchInputsContainer}`}
        >
          <Form.Item className={styles.formItem} label="Buscar por">
            <Select
              className={styles.selectInputWithLabel}
              placeholder="Selecciona una opción"
              defaultValue={search?.field || "Name"}
              value={search?.field || "Name"}
              onChange={(value) => {
                setSearch({ ...search, field: value });
              }}
            >
              <Option value="Name">Nombre</Option>
              <Option value="Code">ID</Option>
              <Option value="CreatedBy">Creado por</Option>
            </Select>
          </Form.Item>
          <Form.Item className={styles.formItem} label="">
            <Input
              defaultValue={search?.value || ""}
              value={search?.value || ""}
              onChange={(e) =>
                setSearch({
                  ...search,
                  value: e.target.value,
                })
              }
              placeholder="Ingresa un valor"
              type="text"
            />
          </Form.Item>
        </Col>
      </Row>
    </section>
  );
};

export default PromotionsFilters;
