import styles from "./index.module.css";
import FilterByDay from "../FilterByDay";
import FilterByCommune from "../FilterByCommune";
import FilterByDiscount from "../FilterByDiscount";
import FilterByCompanies from "../FilterByCompanies";
import FilterByCategories from "../FilterByCategories";
import FilterByMyPartners from "../FilterByMyPartners";
import { getUser } from "../../../../Helpers/auth";

const FiltersList = ({
  filters,
  setFilters,
  availableFilters,
  getFilteredPromotions,
  showShortFiltersList,
}) => {
  const availableDiscounts = {
    min: availableFilters?.discount?.min || 0,
    max: availableFilters?.discount?.max || 0,
  };

  const isUserLogged = getUser()?.role === "User";

  return (
    <section
      className={styles.container}
      style={{ maxHeight: showShortFiltersList ? "400px" : "70vh" }}
    >
      {isUserLogged && (
        <FilterByMyPartners
          filters={filters}
          setFilters={setFilters}
          getFilteredPromotions={getFilteredPromotions}
        />
      )}

      {availableFilters?.scalars?.length > 0 && (
        <FilterByCategories
          filters={filters}
          setFilters={setFilters}
          availableCategories={availableFilters?.scalars}
          getFilteredPromotions={getFilteredPromotions}
        />
      )}
      {availableFilters?.companies?.length > 0 && (
        <FilterByCompanies
          filters={filters}
          setFilters={setFilters}
          availableCompanies={availableFilters?.companies}
          getFilteredPromotions={getFilteredPromotions}
        />
      )}
      {availableFilters?.weekDays?.length > 0 && (
        <FilterByDay
          filters={filters}
          setFilters={setFilters}
          availableDays={availableFilters?.weekDays}
          getFilteredPromotions={getFilteredPromotions}
        />
      )}
      {Boolean(availableDiscounts) && (
        <FilterByDiscount
          filters={filters}
          setFilters={setFilters}
          availableDiscounts={availableDiscounts}
          getFilteredPromotions={getFilteredPromotions}
        />
      )}
      {availableFilters?.regions?.length > 0 && (
        <FilterByCommune
          filters={filters}
          setFilters={setFilters}
          availableRegions={availableFilters?.regions}
          getFilteredPromotions={getFilteredPromotions}
        />
      )}
    </section>
  );
};

export default FiltersList;
