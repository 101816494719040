import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import Switch from "../../../../Components/Switch";
import { Collapse, Table, Row, Spin, Empty } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { COMPANIES } from "../../Graphql/Queries";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import { setAccessToken } from "../../../../Helpers/auth";
import { TOGGLE_REPORTS_OF_COMPANY, TOGGLE_VISIBLE_PROMOTIONS_OF_COMPANY } from "../../Graphql/Mutations";
import readIcon from "../../Assets/read-icon.svg";
import deleteIcon from "../../Assets/delete-icon.svg";
import editIcon from "../../Assets/edit-icon.svg";
import { withRouter } from "react-router-dom";
import DeleteCompanyModal from "../DeleteCompanyModal";

const { Panel } = Collapse;

const Companies = ({history}) => {

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [companySelected, setCompanySelected] = useState('')

  const handleOpenDeleteModal = companyId => {
    setDeleteModalVisible(true)
    setCompanySelected(companyId)
  }

  const onCancel = () => {
    setDeleteModalVisible(false)
    setCompanySelected('')
  }

  const [refreshToken] = useMutation(REFRESH_TOKEN);
  const {
    loading: loadingCompanies,
    error,
    data: companies,
    refetch,
  } = useQuery(COMPANIES, {
    variables: {
      input: {
        page: 1,
        limit: 10000,
      },
    },
  });

  const [toggleReportCompany] = useMutation(TOGGLE_REPORTS_OF_COMPANY)
  const [togglePromotionsCompany] = useMutation(TOGGLE_VISIBLE_PROMOTIONS_OF_COMPANY)
  useEffect(() => {
    const getData = () => {
      refreshToken().then((res) => {
        setAccessToken(res.data.refreshToken.token);
        refetch({
          input: {
            page: 1,
            limit: 10000,
          }
        })
      });
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken, refetch]);

  const handleToggleReport = companyId => {
    refreshToken().then(() => {
      toggleReportCompany({
        variables:{
          companyId: companyId
        }
      }).then(() => {
        refetch()
      }).catch(e => {
        console.log(e)
      })
    }).catch((e) => {
      console.log(e)
    })
  }

  const handleTogglePromotion = companyId => {
    refreshToken().then(() => {
      togglePromotionsCompany({
        variables:{
          companyId: companyId
        }
      }).then(() => {
        refetch()
      }).catch(e => {
        console.log(e)
      })
    }).catch((e) => {
      console.log(e)
    })
  }

  const handleSeeCompany = companyId => {
    localStorage.setItem("companySelected", JSON.stringify(companyId));
    history.push('/myCompany')
  }


  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (name) => <p className={styles.tableName}>{name}</p>,
    },
    {
      title: "Administrador",
      dataIndex: "admin",
      key: "admin",
      render: (admin) => <p className={styles.tableEmail}>{admin?.email}</p>,
    },
    {
      title: "Promociones",
      dataIndex: "visibles",
      key: "visibles",
      render: (isVisible, record) => (
        <div className={styles.tableSwitch}>
          <span
            onClick={() => { handleTogglePromotion(record._id) }
            }
          >
          <Switch
            isActive={isVisible}
            activeText={"Visible"}
            desactiveText={"No visible"}
          />
          </span>
        </div>
      ),
    },
    {
      title: "Reportes",
      dataIndex: "reports",
      key: "reports",
      render: (_, record) => (
        <div className={styles.tableSwitch}>
          <span
            onClick={() => { handleToggleReport(record._id) }
            }
          >
          <Switch
            isActive={record.reports}
            activeText={"Activado"}
            desactiveText={"No activado"}
          />
          </span>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <div className={styles.tableActions}>
          <button className={styles.tableActionsButton} onClick={() => handleSeeCompany(record._id)}>
            <img src={readIcon} alt="" />
          </button>
          <button className={styles.tableActionsButton} onClick={() => handleSeeCompany(record._id)}>
            <img src={editIcon} alt="" />
          </button>
          <button className={styles.tableActionsButton} onClick={() => handleOpenDeleteModal(record._id)}>
            <img src={deleteIcon} alt="" />
          </button>
        </div>
      ),
    },
  ];

  if (loadingCompanies) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  if (error) {
    return (
      <Row justify="center">
        <Empty
          description="No se pudo cargar la información"
          style={{ marginTop: "20px", color: "#aaa", fontWeight: "500" }}
        />
      </Row>
    );
  }

  return (
    <>
      <Table
        className={styles.table}
        dataSource={companies?.companies?.data}
        columns={columns}
      />
      <Collapse
        className={styles.accordion}
        expandIconPosition="right"
        accordion
      >
        <DeleteCompanyModal
          deleteModalVisible={deleteModalVisible}
          onCancel={onCancel}
          company={companySelected}
          refetchCompanies={refetch}
        />
        <h3 className={styles.accordionTitle}>Nombres</h3>
        {companies?.companies?.data?.map((company, index) => (
          <Panel
            header={company.name}
            key={index}
            className={styles.accordionPanel}
          >
            <div className={styles.accordionContent}>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Administrador:
                </h4>
                <p className={styles.tableEmail}>{company?.admin?.email}</p>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>
                  Promociones:
                </h4>
                <div className={styles.tableSwitch}>
                  <span
                    onClick={() => { handleTogglePromotion(company._id) }
                    }
                  >
                    <Switch
                      isActive={company.visibles}
                      activeText={"Visible"}
                      desactiveText={"No visible"}
                    />
                  </span>
                </div>
              </div>
              <div className={styles.accordionContentItem}>
                <h4 className={styles.accordionContentItemTitle}>Reportes:</h4>
                <div className={styles.tableSwitch}>
                  <span
                    onClick={() => { handleToggleReport(company._id) }
                    }
                  >
                <Switch
                  isActive={company.reports}
                  activeText={"Activado"}
                  desactiveText={"No activado"}
                />
                </span>
                </div>
              </div>
              <div className={styles.tableActions}>
                <button className={styles.tableActionsButton} onClick={() => handleSeeCompany(company._id)}>
                  <img src={readIcon} alt="" />
                </button>
                <button className={styles.tableActionsButton} onClick={() => handleSeeCompany(company._id)}>
                  <img src={editIcon} alt="" />
                </button>
                <button className={styles.tableActionsButton} onClick={() => handleOpenDeleteModal(company._id)}>
                  <img src={deleteIcon} alt="" />
                </button>
              </div>
            </div>
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

export default withRouter(Companies);
