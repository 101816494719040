import React from "react";
import styles from "./index.module.css";
import Wave from "../../../../Assets/yellow-wave.png";
import SearchIcon from "../../../../Assets/search-icon-white.svg";
import { useParams } from "react-router-dom";

const SearchHeader = () => {
  const { input } = useParams();

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerTitleContainer}>
          <img src={SearchIcon} alt="" className={styles.headerIcon} />
          <h1 className={styles.headerTitle}>
            Resultados de tu búsqueda: <span>{input}</span>
          </h1>
        </div>
      </header>
      <img src={Wave} alt="wave" className={styles.wave} />
    </>
  );
};

export default SearchHeader;
