import { useQuery } from "@apollo/client";
import { SEARCH_COMPANY } from "./Queries";

export const useCompanies = (currentPage = 1, search = "") => {
  const result = useQuery(SEARCH_COMPANY, {
    variables: {
      input: {
        search,
        page: 1,
        limit: 4 * currentPage,
      },
    },
  });
  return result;
};
