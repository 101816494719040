import gql from "graphql-tag";

const ADD_ALLIED_TO_USER = gql`
  mutation addAlliedToUser($companiesIds: [String!]!) {
    addAlliedToUser(companiesIds: $companiesIds) {
      _id
      alliedCompanies {
        _id
        name
      }
    }
  }
`;

const DELETE_ALLIED_COMPANY_OF_USER = gql`
  mutation deleteAlliedCompaniesOfUser($companiesIds: [String!]!) {
    deleteAlliedCompaniesOfUser(companiesIds: $companiesIds) {
      _id
    }
  }
`;

const ASSOCIATE_MY_COMPANY = gql`
  mutation associateMyCompany($input: AssociateCompanyInput!) {
    associateMyCompany(input: $input) {
      _id
      company {
        _id
        name
      }
    }
  }
`;

const DELETE_COMPANY_TO_USER = gql`
  mutation deleteMyCompanyAssociation {
    deleteMyCompanyAssociation {
      _id
    }
  }
`;

export {
  ADD_ALLIED_TO_USER,
  ASSOCIATE_MY_COMPANY,
  DELETE_ALLIED_COMPANY_OF_USER,
  DELETE_COMPANY_TO_USER,
};
