import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select } from "antd";
import { withRouter } from "react-router-dom";
import Footer from "../../Components/Footer";
import UserAddCompanyImg from "./Assets/UserAddCompanyImg.svg";
import { COMPANIES } from "./Graphql/Queries";
import { PROFILE } from "../../Graphql/Queries"
import { useQuery, useMutation } from "@apollo/client";
import formValidation from "./Helpers/formValidation";
import "./index.css";
import { ASSOCIATE_MY_COMPANY } from "./Graphql/Mutations";
import ErrorModal from "./Components/ErrorModal";
import SuccesModal from "./Components/SuccesModal";

const UserAddCompanyScreen = ({ history }) => {
  const { Option } = Select;
  const { data: userData } = useQuery(PROFILE);
  const { data: companiesData } = useQuery(COMPANIES);
  const [associateMyCompany] = useMutation(ASSOCIATE_MY_COMPANY);
  const [formData, setFormData] = useState({
    company: "",
    email: "",
    validator: "",
  });

  useEffect(() => {
    if(!userData) return
    setFormData({
      company: "",
      email: userData.profile.email,
      validator: "",
    })
  }, [userData])

  const [succesModalVisibility, setSuccesModalVisibility] = useState(false);
  const [errorModalVisibility, setErrorModalVisibility] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (formValidation(formData)) {
      try {
        await associateMyCompany({
          variables: {
            input: {
              companyId: formData.company,
              email: formData.email.toLowerCase(),
              code: formData.validator,
            },
          },
        }).then((res) => {
          setSuccesModalVisibility(true);
        });
      } catch (err) {
        console.log(err);
        setErrorModalVisibility(true);
      }
    }
  };

  return (
    <>
      {errorModalVisibility && (
        <ErrorModal
          errorModalVisibility={errorModalVisibility}
          setErrorModalVisibility={setErrorModalVisibility}
          retryFunction={handleSubmit}
        />
      )}
      {succesModalVisibility && (
        <SuccesModal
          succesModalVisibility={succesModalVisibility}
          setSuccesModalVisibility={setSuccesModalVisibility}
        />
      )}

      <Form id="form-footer">

        <div className="center-addCompany-title">
          <h1 className="addCompanyTitle">Agregar mi empresa</h1>
        </div>

        <div className="center-UserAddCompanyImg">
          <img
            src={UserAddCompanyImg}
            alt="UserImg"
            className="UserAddCompanyImg"
          />
        </div>

        <Select
          showSearch
          placeholder="Selecciona tu empresa"
          className="form-select-register"
          style={{ opacity: "initial" }}
          name="company"
          onChange={(e) => setFormData({ ...formData, company: e })}
          filterOption={(input, option) => 
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {companiesData &&
            companiesData.companies?.map((company) => (
              <Option value={company._id} key={company._id}>
                {company.name}
              </Option>
            ))}
        </Select>

        <Form.Item
          rules={[
            {
              required: true,
              message: "¡Debes ingresar tu Correo corporativo!",
            },
          ]}
        >
          <Input
            placeholder="Correo corporativo"
            type="email"
            name="email"
            spellCheck="false"
            className="contactFormInput"
            disabled
            onChange={(e) => handleInputChange(e)}
            value={formData.email}
          />
        </Form.Item>

        <Form.Item
          rules={[
            { required: true, message: "¡Debes ingresar Dato validador!" },
          ]}
        >
          <Input
            placeholder="Dato validador"
            type="text"
            required={true}
            className="contactFormInput"
            spellCheck="false"
            name="validator"
            onChange={(e) => handleInputChange(e)}
            maxLength="4"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="button"
            className="RegisterFormButton"
            onClick={(e) => handleSubmit(e)}
          >
            Agregar
          </Button>
        </Form.Item>
      </Form>

      <Footer />
    </>
  );
};

export default withRouter(UserAddCompanyScreen);
