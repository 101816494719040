import React, { useEffect } from "react";
import styles from "./index.module.css";
import { DatePicker, Modal, notification as AntNotification } from "antd";
import moment from "moment";
import {
  CANCEL_SCHEDULED_NOTIFICATION,
  SCHEDULE_NOTIFICATION,
} from "../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import CloseIcon from "../../Assets/close-rose-icon.svg";

const ScheduleNotificationModal = ({
  visible,
  notification,
  onCancel,
  getNotifications,
}) => {
  const [selectedDate, setSelectedDate] = React.useState(
    Boolean(notification?.scheduledAt)
      ? moment(notification?.scheduledAt)
      : null
  );

  useEffect(() => {
    setSelectedDate(
      Boolean(notification?.scheduledAt)
        ? moment(notification?.scheduledAt)
        : null
    );
  }, [notification?.scheduledAt]);

  const [scheduleNotificationMutation, { loading: isSchedulingNotification }] =
    useMutation(SCHEDULE_NOTIFICATION, {
      variables: {
        id: notification?._id,
        date: selectedDate,
      },
    });

  const [
    cancelScheduledNotificationMutation,
    { loading: isCancelingScheduledNotification },
  ] = useMutation(CANCEL_SCHEDULED_NOTIFICATION, {
    variables: {
      id: notification?._id,
    },
  });

  const handleScheduleNotification = () => {
    scheduleNotificationMutation()
      .then(() => {
        AntNotification.success({
          message: `Operación exitosa`,
          description: "La notificación ha sido programada exitosamente.",
          placement: "bottomRight",
        });
        onCancel();
        getNotifications();
      })
      .catch(() => {
        AntNotification.error({
          message: `Error`,
          description:
            "Hubo un error al intentar programar la notificación,intente de nuevo.",
          placement: "bottomRight",
        });
      });
  };

  const handleCancelScheduledNotification = () => {
    cancelScheduledNotificationMutation()
      .then(() => {
        AntNotification.success({
          message: `Operación exitosa`,
          description: "Se ha cancelado la programación de la notificación.",
          placement: "bottomRight",
        });
        onCancel();
        getNotifications();
      })
      .catch((error) => {
        const errorMessage =
          error?.message ||
          "Hubo un error al intentar la promogramación de la notificación,intente de nuevo.";
        AntNotification.error({
          message: `Error`,
          description: errorMessage,
          placement: "bottomRight",
        });
      });
  };

  const disabledDate = (currentDate) => {
    const yesterdayDate = dayjs().subtract(1, "day").endOf("day");
    return currentDate && currentDate < yesterdayDate;
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: "1rem" }}
      width={"90%"}
      style={{ maxWidth: "500px" }}
      className={styles.modal}
      closeIcon={<div style={{ display: "none !important" }} />}
      centered
    >
      <section className={styles.container}>
        <header className={styles.header}>
          <h2>Programar notificación</h2>
        </header>

        <div className={styles.datePickerContainer}>
          <strong>Fecha de programado</strong>
          <DatePicker
            className={styles.datePicker}
            format="DD-MM-YYYY  /  HH:mm"
            allowClear={false}
            disabledDate={disabledDate}
            disabledTime={false}
            showTime={{ defaultValue: moment("00:00:00", "HH:mm") }}
            value={selectedDate}
            onChange={(datePickerDate) => setSelectedDate(datePickerDate)}
          />
          <button>
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={handleCancelScheduledNotification}
              disabled={
                isSchedulingNotification || isCancelingScheduledNotification
              }
            />
          </button>
        </div>

        <footer className={styles.footer}>
          <button
            className={styles.cancelButton}
            onClick={onCancel}
            disabled={
              isSchedulingNotification || isCancelingScheduledNotification
            }
          >
            Regresar
          </button>
          <button
            disabled={
              isSchedulingNotification ||
              !selectedDate ||
              isCancelingScheduledNotification
            }
            className={styles.confirmButton}
            onClick={handleScheduleNotification}
          >
            Confirmar
          </button>
        </footer>
      </section>
    </Modal>
  );
};

export default ScheduleNotificationModal;
