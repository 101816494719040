import { Workbook } from 'exceljs';
import { templates } from './templates';

/**
  * Parse an excel template to a json object
  * @param {File} file - The file to be parsed
  * @param {String} template - The template to be used {name: String, columns: Array}
  * @returns {Promise} - The promise of the parsed data
 */


export const parseExcelTemplateToJson = async (file, template) => {
  const workbook = new Workbook();
  const reader = new FileReader();
  
  const promise = new Promise((resolve, reject) => {
    reader.onload = () => {
      const buffer = reader.result;
      workbook.xlsx.load(buffer).then(workbook => {
        const worksheet = workbook.getWorksheet(1);
        worksheet.columns= templates[template].columns
        const data = [];
        
        worksheet.eachRow((row, rowNumber) => {

          if (rowNumber === 1) return;
          const rowValues = {};
          row.eachCell((cell, colNumber) => {
            rowValues[worksheet.getColumn(colNumber).key] = typeof cell.value === 'object' ? cell.value.text : cell.value;
          });
          data.push(rowValues);
        });
        resolve(data);
      });
    };
    reader.onerror = reject;
  });
  reader.readAsArrayBuffer(file);
  return promise;
};
