import React, { useState, useEffect } from 'react'
import styles from "./index.module.css"
import { Row, Spin, Layout } from 'antd';
import { withRouter } from 'react-router-dom'
import { PROFILE } from '../../Graphql/Queries';
import { LOGOUT, REFRESH_TOKEN } from '../../Graphql/Mutations';
import { COMPANIES } from './Graphql/Queries'
import { useMutation, useQuery } from '@apollo/client';
import { setAccessToken, setUser } from '../../Helpers/auth';
import './index.css';
import CompanySider from './Components/CompanySider';
import CompanyDrawer from './Components/CompanyDrawer';
import CompanyNavbar from './Components/CompanyNavbar';

const CompanyLayout = ({children, history}) => {
  const [collapsed, setCollapsed] = useState(false);
    const [visible, setVisible] = useState(false);
    const [refreshToken, { loading }] = useMutation(REFRESH_TOKEN)
    const { refetch: profileRefetch, data: dataProfile } = useQuery(PROFILE, {
      fetchPolicy: "no-cache"
    })
    const [logout, {loadingLogout}] = useMutation(LOGOUT)
    const { refetch: companiesRefetch, data: companies } = useQuery(COMPANIES, {
      variables: {
        input: {
          page: 1,
          limit: 10000
        }
      }
    })

    const handleLogout = () => {
      logout().then(() => {
        setAccessToken('')
        setUser({})
        history.push('/')
        localStorage.removeItem('companySelected')
      }).catch(e => {
        console.log(e)
      })
  }

    useEffect(() => {
      refreshToken().then(res => {
        setAccessToken(res.data.refreshToken.token)
        companiesRefetch()
        profileRefetch().then(profile => {
          if(profile.data.profile.role === 'User') history.push('/userHome')
        }).catch(e => {
          console.log(e)
          history.push('/')
        })
      }).catch(e => {
        if(e.toString().startsWith('Error: Unauthorized')) handleLogout()
        console.log(e)
      })
      // eslint-disable-next-line
    },[refreshToken, history,companiesRefetch, profileRefetch])

    useEffect(() => {
      if(dataProfile) {
        setUser(dataProfile.profile)
      }
    },[dataProfile])

    const onClose = () => {
      setVisible(false)
    }

    const handleChangeCompany = company => {
      localStorage.setItem('companySelected',JSON.stringify(company));
      window.location.reload(false);
    }
    
    if (loading || loadingLogout) return (
      <Row justify="center" style={{paddingTop: 350}}>
        <Spin size="large"/>
      </Row>
    )

    return (
      <> 
        <CompanyNavbar
        role={dataProfile ? dataProfile?.profile?.role : null}
        handleChangeCompany={handleChangeCompany}
        companies={companies ? companies : null}
        visible={visible}
        setVisible={setVisible}
        companyName={dataProfile ? dataProfile?.profile?.company?.name : null}
        />

        <Layout>
        <CompanySider
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        handleLogout={handleLogout}
        role={dataProfile ? dataProfile?.profile?.role : null}
        />
              <div id="background" className={`${styles.container} containerCompanyLayout`}>
        {children}
      </div>
    </Layout>
      <CompanyDrawer
      handleLogout={handleLogout}
      visible={visible}
      onClose={onClose}
      companyName={dataProfile ? dataProfile?.profile?.company?.name : null}
      role={dataProfile ? dataProfile?.profile?.role : null}
      handleChangeCompany={handleChangeCompany}
      companies={companies ? companies : null}
      />
      </>
    )
}

export default withRouter(CompanyLayout)