import React from "react";
import styles from "./index.module.css";
import { ResponsivePie } from "@nivo/pie";
import InfoIcon from "../../../../Assets/info-icon.svg";

const PromotionsSeenByAge = ({ data }) => {
  const dataChart = [
    {
      id: "< 25",
      label: "< 25",
      value: data?.lessThan25 !== 0 ? data?.lessThan25 / (data?.count || 0) : 0,
    },
    {
      id: "25 - 45",
      label: "25 - 45",
      value:
        data?.greaterThan25LessThan45 !== 0
          ? data?.greaterThan25LessThan45 / (data?.count || 0)
          : 0,
    },
    {
      id: "> 45",
      label: "> 45",
      value:
        data?.greaterThan45 !== 0
          ? data?.greaterThan45 / (data?.count || 0)
          : 0,
    },
  ];
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3>Distribución por edad</h3>
        <img src={InfoIcon} alt="" />
      </header>
      <div style={{ height: "80%" }}>
        <ResponsivePie
          data={dataChart}
          margin={{ top: 20, bottom: 60 }}
          valueFormat=" >-.1%"
          innerRadius={0.7}
          padAngle={1}
          activeOuterRadiusOffset={8}
          colors={["#C6C6C6", "#E4056F", "#FCC02C"]}
          colorBy="index"
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 63,
              itemWidth: 70,
              itemHeight: 20,
              itemsSpacing: 0,
              symbolSize: 12,
              itemDirection: "left-to-right",
            },
          ]}
        />
      </div>
    </section>
  );
};

export default PromotionsSeenByAge;
