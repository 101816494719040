import React from "react";
import styles from "./index.module.css";
import { Upload, Form, Input, Radio, Select, Tooltip, notification } from "antd";
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import uploadIcon from "../../Assets/upload-icon.svg";
import * as dayjs from 'dayjs'
import "dayjs/locale/es"
import RichText from "../../../../../../Components/RichText";
dayjs.locale("es");


const { Option } = Select;

const DetailBoxForm = ({
    promotionName,
    setPromotionName,
    localName,
    setLocalName,
    discount,
    setDiscount,
    description,
    setDescription,
    status,
    handleStatus,
    promotionImage,
    setPromotionImage,
    setLoadingPromotionImage,
    localImageUrl,
    setLocalImageUrl,
    setLoadingLocalImage,
    benefitURL,
    setBenefitURL,
    setPromotionImageFile,
    setLocalImageFile,
    page,
    code,
    createdBy,
    createdAt,
    isPublic,
    setIsPublic,
    segments,
    segment,
    setSegment,
    checkMaxCharsInput,
}) => {

  const children = [];
  for (let i = 0; i < segment.length; i++) {
    children.push(`${segment[i]._id}`);
  }
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    const handleChange = info => {
      if(info.file.type !== 'image/jpeg' && info.file.type !== 'image/png' && info.file.type !== 'image/svg+xml') {
        notification.error({
          message: "Error",
          description: "Solo puedes agregar archivos que terminen en .png, .jpeg y .svg",
          placement: "bottomRight",
        });
        return
      }
      if(info.file.size > 10000000) {
        notification.error({
          message: "Error",
          description: "Solo puedes cargar archivos que pesen máximo 10MB",
          placement: "bottomRight",
        });
        return
      }
        if (info.file.status === 'uploading') {
            setLoadingLocalImage(true);
          return;
        }
        if (info.file.status === 'done') {
          setLocalImageFile(info.file)
          getBase64(info.file.originFileObj, imageUrl => {
            setLocalImageUrl(imageUrl)
            setLoadingLocalImage(false)
          }
          );
        }
      };

      
      const handleChangePromotion = info => {
      console.log(info)
        if(info.file.type !== 'image/jpeg' && info.file.type !== 'image/png' && info.file.type !== 'image/svg+xml') {
          notification.error({
            message: "Error",
            description: "Solo puedes agregar archivos que terminen en .png, .jpeg y .svg",
            placement: "bottomRight",
          });
          return
        }
        if(info.file.size > 10000000) {
          notification.error({
            message: "Error",
            description: "Solo puedes cargar archivos que pesen máximo 10MB",
            placement: "bottomRight",
          });
          return
        }
        if (info.file.status === 'uploading') {
            setLoadingPromotionImage(true);
          return;
        }
        if (info.file.status === 'done') {
          setPromotionImageFile(info.file)
          getBase64(info.file.originFileObj, imageUrl => {
            setPromotionImage(imageUrl)
            setLoadingPromotionImage(false)
          }
          );
        }
      };

      const checkDiscount = n => {
        if(n > 100) return
        setDiscount(n)
      }


    return (
      <div className={styles.formContainer}>
        {page === "create" ? null : (
          <Form.Item
            label="ID"
            className={`${styles.formItem} ${styles.formItemCode} `}
          >
            <Input
              type="text"
              className={styles.formItemInput}
              disabled
              value={code}
            />
          </Form.Item>
        )}

        <Form.Item
          className={`${styles.formItem} ${styles.formItemName} ${
            page !== "create" && styles.formItemNameEdit
          } `}
          label={`Nombre de promoción (${promotionName.length}/100)`}
        >
          <Input
            type="text"
            className={styles.formItemInput}
            value={promotionName}
            onChange={(e) =>
              checkMaxCharsInput(setPromotionName, e.target.value, 100)
            }
          />
        </Form.Item>
        <Form.Item
          className={`${styles.formItem} ${styles.formItemName} ${
            page !== "create" && styles.formItemNameEdit
          } ${
            page !== "create" && styles.formItemPartnerNameEdit
          } `}
          label={`Nombre del Socio (${localName.length}/100)`}
        >
          <Input
            type="text"
            className={styles.formItemInput}
            value={localName}
            onChange={(e) =>
              checkMaxCharsInput(setLocalName, e.target.value, 100)
            }
          />
        </Form.Item>
        <Form.Item
          label={`Descripción de promoción`}
          className={`${styles.formItem} ${styles.formItemTextarea}`}
        >
          <RichText
            className={styles.richTextInputContainer}
            value={description}
            onChange={(richText) => {
              checkMaxCharsInput(setDescription,richText, 3000)
            }}
          />
        </Form.Item>
        <Form.Item
          className={`${styles.formItem} ${styles.formItemDiscount} ${page !== "create" && styles.formItemDiscountEditing} `}
          label="% de descuento"
        >
          <Input
            type="number"
            className={styles.formItemInput}
            value={discount}
            min={1}
            max={100}
            onChange={(e) => checkDiscount(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          className={`${styles.formItem} ${styles.formItemURL}`}
          label={`Web de la promoción (${benefitURL.length}/100)`}
        >
          <Input
            type="text"
            spellCheck={false}
            className={styles.formItemInput}
            value={benefitURL}
            onChange={(e) =>
              checkMaxCharsInput(setBenefitURL, e.target.value, 100)
            }
          />
        </Form.Item>

        <Form.Item
          label="Estado"
          className={`${styles.formItem} ${styles.formItemStatus} ${page !== "create" ? styles.formItemStatusEditing : styles.formItemStatusCreating}  `}
        >
          <Select className={`${styles.statusSelect} ${status === "active" ? styles.statusSelectActive : styles.statusSelectInactive} `}  defaultValue={status} onChange={(e) => handleStatus(e)}>
            <Option value="active">Activado</Option>
            <Option value="inactive">No activado</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Segmento"
          className={`${styles.formItem} ${styles.formItemSegment} `}
        >

          {
            isPublic ? (
              <Tooltip title="Para seleccionar un segmento, la promoción tiene que ser privada">
          <Select
            mode="multiple"
            className="formSelectSegment"
            bordered={false}
            defaultValue={children}
            onChange={(e) => setSegment(e)}
            disabled={isPublic}
          >
            {segments
              ? segments?.segments?.map((seg) => {
                  return (
                    <Option key={seg._id} value={seg._id}>
                      {seg.name}
                    </Option>
                  );
                })
              : null}
          </Select>
          </Tooltip>
            ):(
          <Select
            mode="multiple"
            className="formSelectSegment"
            bordered={false}
            defaultValue={children}
            onChange={(e) => setSegment(e)}
            disabled={isPublic}
          >
            {segments
              ? segments?.segments?.map((seg) => {
                  return (
                    <Option key={seg._id} value={seg._id}>
                      {seg.name}
                    </Option>
                  );
                })
              : null}
          </Select>
            )
          }
          
        </Form.Item>

        <Form.Item
          label="Visibilidad"
          className={`${styles.formItem} ${styles.formItemPublic} ${
            page !== "create" && styles.formItemPublicEditing
          } `}
        >
          <Radio.Group
            onChange={(e) => setIsPublic(e.target.value)}
            value={isPublic}
          >
            <Radio value={true}>Público</Radio>
            <Radio value={false}>Privado</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Banner Promoción" className={styles.formItemUpload}>
          <Upload
            name="promotionBanner"
            listType="picture-card"
            className={styles.formItemInputUpload}
            showUploadList={false}
            customRequest={dummyRequest}
            onChange={handleChangePromotion}
          >
            {promotionImage ? (
              <img
                src={promotionImage}
                alt="avatar"
                style={{ width: "50%" }}
              />
            ) :<img src={uploadIcon} alt="" />}
          </Upload>
        </Form.Item>

        <Form.Item label="Logo local" className={styles.formItemUpload}>
          <Upload
            name="localLogo"
            listType="picture-card"
            className={styles.formItemInputUpload}
            showUploadList={false}
            customRequest={dummyRequest}
            onChange={handleChange}
          >
            {localImageUrl ? (
              <img src={localImageUrl} alt="avatar" style={{ width: "50%" }} />
            ) : (
              <img src={uploadIcon} alt="" />
            )}
          </Upload>
        </Form.Item>

        {page === "create" ? null : (
          <>
            <Form.Item
              label="Creado por"
              className={`${styles.formItem} ${styles.formItemData} `}
            >
              <Input
                type="text"
                className={styles.formItemInput}
                disabled
                value={createdBy}
              />
            </Form.Item>
            <Form.Item
              className={`${styles.formItem} ${styles.formItemData} `}
              label="Fecha de creación"
            >
              <Input
                type="text"
                className={styles.formItemInput}
                disabled
                value={dayjs(createdAt).format("DD/MM/YYYY")}
              />
            </Form.Item>
            <Form.Item
              className={`${styles.formItem} ${styles.formItemData} `}
              label="Hora de creación"
            >
              <Input
                type="text"
                className={styles.formItemInput}
                disabled
                value={dayjs(createdAt).format("hh:mm")}
              />
            </Form.Item>
          </>
        )}
      </div>
    );
}

export default DetailBoxForm