import React from "react";
import Companies from "./Components/Companies";
import Header from "./Components/Header";

const CompanyCompanies = () => {
  return (
    <>
      <Header />
      <Companies />
    </>
  );
};

export default CompanyCompanies;
