import styles from "./index.module.css";
import Switch from "../../../Switch";
import { Link } from "react-router-dom";

const FilterByMyPartners = ({ filters, setFilters, getFilteredPromotions }) => {
  const handleChange = () => {
    setFilters({
      ...filters,
      includeAlliedPartners: !filters.includeAlliedPartners,
    });
    getFilteredPromotions({
      customFilters: {
        includeAlliedPartners: !filters.includeAlliedPartners,
      },
    });
  };

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Mis socios</h2>
      </header>

      <div className={styles.optionsContainer}>
        <Switch
          onClickFn={handleChange}
          activeText={""}
          desactiveText={""}
          isActive={filters?.includeAlliedPartners}
        />
        <Link className={styles.link} to="/userPartners">
          Agregar socios
        </Link>
      </div>
    </section>
  );
};

export default FilterByMyPartners;
