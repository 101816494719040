import React from "react";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import { Layout, Menu } from "antd";

import promotionsIcon from "../../Assets/CompanyIcons/promotions-icon.svg";
import reportsIcon from "../../Assets/CompanyIcons/reports-icon.svg";
import usersIcon from "../../Assets/CompanyIcons/users-icon.svg";
import commentsIcon from "../../Assets/CompanyIcons/comments-icon.svg";
import companyIcon from "../../Assets/CompanyIcons/company-icon.svg";
import companiesIcon from "../../Assets/CompanyIcons/companies-icon.svg";
import notificationsIcon from "../../Assets/CompanyIcons/notifications_icon.svg";
import logoutIcon from "../../Assets/CompanyIcons/logout-icon.svg";
import collapseIcon from "../../Assets/CompanyIcons/collapse-sider-icon.svg";

const { Sider } = Layout;

const CompanySider = ({ collapsed, setCollapsed, handleLogout, role }) => {
  const history = useHistory();

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      className={`${styles.container} siderLayoutCompany`}
      trigger={
        <footer className={styles.footer}>
          <Menu theme="dark" mode="inline" className="siderLayoutCompany">
            <Menu.Item
              className={styles.menuItem}
              key="logout"
              icon={<img src={logoutIcon} alt="" style={{ width: 20 }} />}
              onClick={() => handleLogout()}
            >
              <span className={styles.menuItemText}>Cerrar sesión</span>
            </Menu.Item>
          </Menu>
          <button
            className={styles.footerCollapseButton}
            onClick={() => setCollapsed(!collapsed)}
          >
            <img
              src={collapseIcon}
              alt=""
              style={{ transform: !collapsed ? "none" : "rotate(180deg)" }}
            />
          </button>
        </footer>
      }
    >
      <div className="logo" />
      <Menu theme="dark" mode="inline" className="siderLayoutCompany">
        <Menu.Item
          className={styles.menuItem}
          key="1"
          icon={<img src={companyIcon} alt="" style={{ width: 20 }} />}
          onClick={() => history.push("/companyHome")}
        >
          <span className={styles.menuItemText}>Inicio</span>
        </Menu.Item>

        <Menu.Item
          className={styles.menuItem}
          key="2"
          icon={<img src={promotionsIcon} alt="" style={{ width: 20 }} />}
          onClick={() => history.push("/companyPromotions")}
        >
          <span className={styles.menuItemText}>Promociones</span>
        </Menu.Item>

        <Menu.Item
          className={styles.menuItem}
          key="3"
          icon={<img src={reportsIcon} alt="" style={{ width: 20 }} />}
          onClick={() => history.push("/companyReport")}
        >
          <span className={styles.menuItemText}>Reportes</span>
        </Menu.Item>

        <Menu.Item
          className={styles.menuItem}
          key="4"
          icon={<img src={usersIcon} alt="" style={{ width: 20 }} />}
          onClick={() => history.push("/companyUsers")}
        >
          <span className={styles.menuItemText}>Usuarios</span>
        </Menu.Item>

        <Menu.Item
          className={styles.menuItem}
          key="5"
          icon={<img src={commentsIcon} alt="" style={{ width: 20 }} />}
          onClick={() => history.push("/companyReviews")}
        >
          <span className={styles.menuItemText}>Comentarios</span>
        </Menu.Item>

        <Menu.Item
          className={styles.menuItem}
          key="6"
          icon={<img src={companyIcon} alt="" style={{ width: 20 }} />}
          onClick={() => history.push("/myCompany")}
        >
          <span className={styles.menuItemText}>Mi empresa</span>
        </Menu.Item>

        {role === "SuperAdmin" && (
          <Menu.Item
            className={styles.menuItem}
            key="7"
            icon={<img src={companiesIcon} alt="" style={{ width: 20 }} />}
            onClick={() => history.push("/companyCompanies")}
          >
            <span className={styles.menuItemText}>Empresas</span>
          </Menu.Item>
        )}

        {role === "SuperAdmin" && (
          <Menu.Item
            className={styles.menuItem}
            key="8"
            icon={<img src={notificationsIcon} alt="" style={{ width: 26 }} />}
            onClick={() => history.push("/companyNotifications")}
          >
            <span className={styles.menuItemText}>Notificaciones</span>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
};

export default CompanySider;
