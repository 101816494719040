import React from "react";
import styles from "./index.module.css";
import { Col } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import parseArrayOfDays from "../../../../Helpers/weekDaysToSpanish";

const FilterTags = ({ activeFilters, deleteFilter }) => {
  return (
    <>
      {(activeFilters.filterFields.length > 0 || activeFilters.allied) && (
        <Col
          xs={21}
          sm={21}
          md={0}
          offset={4}
          className={styles.mobileTitleCol}
        >
          <h3 className={styles.mobileTitle}>Filtros activos:</h3>
        </Col>
      )}
      <Col
        xs={21}
        sm={21}
        md={22}
        xl={18}
        offset={4}
        className={styles.tagsCol}
      >
        {(activeFilters.filterFields.length > 0 || activeFilters.allied) && (
          <>
            <h3 className={styles.tagsColDeskTitle}>Filtros activos:</h3>
            {activeFilters.filterFields.map((filterType, index) => {
              return (
                <button
                  key={activeFilters.filterValues[index]}
                  className={styles.tagButton}
                  onClick={() =>
                    deleteFilter(filterType, activeFilters.filterValues[index])
                  }
                >
                  <CloseCircleFilled />
                  <span>
                    {filterType === "weekDays"
                      ? "Día: "
                      : filterType === "discount"
                      ? "% de descuento: "
                      : "Ubicación:"}
                  </span>
                  <span>
                    {filterType === "weekDays"
                      ? parseArrayOfDays([activeFilters.filterValues[index]])
                      : activeFilters.filterValues[index]}{" "}
                  </span>
                </button>
              );
            })}
            {activeFilters.allied && (
              <button
                key={"allied"}
                className={styles.tagButton}
                onClick={() => deleteFilter("allied", activeFilters.allied)}
              >
                <CloseCircleFilled />
                <span>Solo mis socios</span>
              </button>
            )}
          </>
        )}
      </Col>
    </>
  );
};

export default FilterTags;
