import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { Drawer, Select } from "antd";

import promotionsIcon from "../../Assets/CompanyIcons/promotions-icon.svg";
import reportsIcon from "../../Assets/CompanyIcons/reports-icon.svg";
import usersIcon from "../../Assets/CompanyIcons/users-icon.svg";
import commentsIcon from "../../Assets/CompanyIcons/comments-icon.svg";
import companyIcon from "../../Assets/CompanyIcons/company-icon.svg";
import companiesIcon from "../../Assets/CompanyIcons/companies-icon.svg";
import logoutIcon from "../../Assets/CompanyIcons/logout-icon.svg";
import notificationsIcon from "../../Assets/CompanyIcons/notifications_icon.svg";

const CompanyDrawer = ({
  visible,
  onClose,
  handleLogout,
  role,
  companies,
  handleChangeCompany,
  companyName = "empresa",
}) => {
  return (
    <Drawer
      placement="right"
      className={styles.container}
      closable
      closeIcon={null}
      maskStyle={{ backgroundColor: "transparent" }}
      onClose={() => onClose()}
      visible={visible}
      drawerStyle={{
        padding: "0px 12px",
        boxShadow: "2px 0px 10px rgba(0, 0, 0, 1)",
      }}
      key="right"
      width={360}
      footer={
        <footer className={styles.footer}>
          <button
            onClick={() => {
              handleLogout();
              onClose();
            }}
          >
            <img src={logoutIcon} alt="close" className="closeIcon" />
            Cerrar Sesión
          </button>
        </footer>
      }
      footerStyle={{ borderTop: "none" }}
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>
          Bienvenido,
          {role === "SuperAdmin" ? (
            <Select
              className={styles.headerSelectCompany}
              type="select"
              defaultValue={JSON.parse(localStorage.getItem("companySelected"))}
              onChange={(e) => handleChangeCompany(e)}
            >
              <Select.Option key={"all"} value={"all"}>
                SuperAdmin
              </Select.Option>
              {companies
                ? companies.companies?.data?.map((company) => {
                    return (
                      <Select.Option key={company._id} value={company._id}>
                        {company.name}
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
          ) : (
            " " + companyName + "!"
          )}
        </h2>
      </header>

      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <Link to="/companyHome" className={styles.navLink}>
              <img src={companyIcon} alt="" className={styles.navLinkIcon} />
              <span className={styles.navLinkText}>Inicio</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/companyPromotions" className={styles.navLink}>
              <img src={promotionsIcon} alt="" className={styles.navLinkIcon} />
              <span className={styles.navLinkText}>Promociones</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/companyReport" className={styles.navLink}>
              <img src={reportsIcon} alt="" className={styles.navLinkIcon} />
              <span className={styles.navLinkText}>Reportes</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/companyUsers" className={styles.navLink}>
              <img src={usersIcon} alt="" className={styles.navLinkIcon} />
              <span className={styles.navLinkText}>Usuarios</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/companyReviews" className={styles.navLink}>
              <img src={commentsIcon} alt="" className={styles.navLinkIcon} />
              <span className={styles.navLinkText}>Comentarios</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/myCompany" className={styles.navLink}>
              <img src={companyIcon} alt="" className={styles.navLinkIcon} />
              <span className={styles.navLinkText}>Mi empresa</span>
            </Link>
          </li>
          {role === "SuperAdmin" && (
            <li className={styles.navItem}>
              <Link to="/companyCompanies" className={styles.navLink}>
                <img
                  src={companiesIcon}
                  alt=""
                  className={styles.navLinkIcon}
                />
                <span className={styles.navLinkText}>Empresas</span>
              </Link>
            </li>
          )}
          {role === "SuperAdmin" && (
            <li className={styles.navItem}>
              <Link to="/companyNotifications" className={styles.navLink}>
                <img
                  src={notificationsIcon}
                  alt=""
                  className={styles.navLinkIcon}
                />
                <span className={styles.navLinkText}>Notificaciones</span>
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </Drawer>
  );
};

export default CompanyDrawer;
