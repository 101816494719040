import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import styles from "../PromotionsSeenByAge/index.module.css";
import InfoIcon from "../../../../Assets/info-icon.svg";

const PromotionsSeenByAgeAndSex = ({ data }) => {
  const dataChart = [
    {
      sex: "Mujer",
      "< 25":
        data?.femaleCount !== 0
          ? data?.lessThan25Female / data?.femaleCount
          : 0,
      "< 25Color": "#C6C6C6",
      "25 - 45":
        data?.femaleCount !== 0
          ? data?.greaterThan25LessThan45Female / data?.femaleCount
          : 0,
      "25 - 45Color": "#E4056F",
      "> 45":
        data?.femaleCount !== 0
          ? data?.greaterThan45Female / data?.femaleCount
          : 0,
      "> 45Color": "#FCC02C",
    },
    {
      sex: "Hombre",
      "< 25":
        data?.maleCount !== 0 ? data?.lessThan25Male / data?.maleCount : 0,
      "< 25Color": "#C6C6C6",
      "25 - 45":
        data?.maleCount !== 0
          ? data?.greaterThan25LessThan45Male / data?.maleCount
          : 0,
      "25 - 45Color": "#E4056F",
      "> 45":
        data?.maleCount !== 0 ? data?.greaterThan45Male / data?.maleCount : 0,
      "> 45Color": "#FCC02C",
    },
  ];
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3>Distribución por sexo y edad</h3>
        <img src={InfoIcon} alt="" />
      </header>
      <div style={{ height: "80%" }}>
        <ResponsiveBar
          data={dataChart}
          keys={["< 25", "25 - 45", "> 45"]}
          indexBy="sex"
          valueFormat=" >-.1%"
          margin={{ top: 5, right: 90, bottom: 25, left: 50 }}
          padding={0.4}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={({ id, data }) => data[`${id}Color`]}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
        />
      </div>
    </section>
  );
};

export default PromotionsSeenByAgeAndSex;
