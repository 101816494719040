import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { Row, Col, Button, notification } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { PROFILE } from "../UserLayout/Graphql/Queries";
import { withRouter } from "react-router-dom";
import UserScreenContent from "../UserLayout/Components/UserScreenSection";
import AddPartnersModal from "./Components/AddPartnersModal";
import {
  DELETE_ALLIED_COMPANY_OF_USER,
  DELETE_COMPANY_TO_USER,
} from "./Graphql/Mutations";

import deleteAlliedIcon from "./Assets/delete-allied-icon.svg";
import closeIcon from "./Assets/cancel-icon.svg";
import saveIcon from "./Assets/save-icon.svg";
import addIcon from "./Assets/add-icon.svg";
import deleteIcon from "./Assets/delete-icon.svg";

const UserPartnerScreen = ({ history }) => {
  const { loading, error, data: profileData, refetch } = useQuery(PROFILE);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPartnersModalVisible, setIsPartnersModalVisible] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState({
    alliedCompanies: [],
    company: null,
  });
  const [deleteAlliedCompanyOfUser] = useMutation(
    DELETE_ALLIED_COMPANY_OF_USER
  );
  const [deleteCompanyToUser] = useMutation(DELETE_COMPANY_TO_USER);

  const toggleCompany = (companyId) => {
    if (selectedCompanies.company === companyId) {
      setSelectedCompanies({ ...selectedCompanies, company: null });
    } else setSelectedCompanies({ ...selectedCompanies, company: companyId });
  };

  const toggleAlliedCompany = (companyId) => {
    if (selectedCompanies.alliedCompanies.includes(companyId)) {
      setSelectedCompanies({
        ...selectedCompanies,
        alliedCompanies: selectedCompanies.alliedCompanies.filter(
          (company) => company !== companyId
        ),
      });
    } else
      setSelectedCompanies({
        ...selectedCompanies,
        alliedCompanies: [...selectedCompanies.alliedCompanies, companyId],
      });
  };

  const handleDeleteCompany = async () => {
    try {
      await deleteCompanyToUser().then(() => {
        history.push("/userPartners");
        notification.success({
          message: "Éxito",
          description: "Empresa eliminada correctamente",
          placement: "bottomRight",
        });
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        placement: "bottomRight",
      });
    }
  };

  const handleDeleteAlliedCompanies = async () => {
    try {
      await deleteAlliedCompanyOfUser({
        variables: {
          companiesIds: selectedCompanies.alliedCompanies,
        },
      }).then(() => {
        history.push("/userPartners");
        notification.success({
          message: "Éxito",
          description: "Operación realizada correctamente",
          placement: "bottomRight",
        });
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        placement: "bottomRight",
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => refetch(), []);

  return (
    <Row className={styles.userScreenRow}>
      <Col xs={0} sm={0} md={12} className={styles.userScreenHeaderCol}>
        <header className={styles.userScreenHeader}>
          <h2 className={styles.userScreenHeaderTitle}>
            <span>{!loading && !error && profileData?.profile?.firstName}</span>
            <span>{!loading && !error && profileData?.profile?.lastName}</span>
          </h2>
        </header>
      </Col>
      <Col xs={0} sm={0} md={12} className={styles.actionButtonsCol}>
        <AddPartnersModal
          visible={isPartnersModalVisible}
          onCancel={() => setIsPartnersModalVisible(false)}
          userEmail={profileData?.profile?.email}
        />
        {!isDeleting ? (
          <>
            <Button
              className={`${styles.actionButton} 
            ${isDeleting && styles.actionButtonHidden}
            ${styles.actionButtonDelete}`}
              ghost
              onClick={() => setIsDeleting(true)}
            >
              <span className={styles.actionButtonText}>Eliminar</span>
              <img
                src={deleteIcon}
                alt=""
                className={styles.actionButtonIcon}
              />
            </Button>
            <Button
              className={`${styles.actionButton} 
          ${isDeleting && styles.actionButtonHidden}
          ${styles.actionButtonAdd}`}
              ghost
              onClick={() => setIsPartnersModalVisible(true)}
            >
              <span className={styles.actionButtonText}>Agregar</span>
              <img src={addIcon} alt="" className={styles.actionButtonIcon} />
            </Button>
          </>
        ) : (
          <>
            <Button
              className={`${styles.actionButton} 
          ${!isDeleting && styles.actionButtonHidden}
          ${styles.actionButtonCancel}`}
              ghost
              // hidden={!isDeleting}
              onClick={() => {
                setIsDeleting(false);
                setSelectedCompanies({ alliedCompanies: [], company: null });
              }}
            >
              <span className={styles.actionButtonText}>Cancelar</span>
              <img src={closeIcon} alt="" className={styles.actionButtonIcon} />
            </Button>
            <Button
              className={`${styles.actionButton} ${styles.actionButtonSave}
          ${!isDeleting && styles.actionButtonHidden}
          ${
            (selectedCompanies.alliedCompanies.length > 0 ||
              typeof selectedCompanies.company === "string") &&
            styles.actionButtonSaveHasChanged
          }`}
              disabled={
                selectedCompanies.alliedCompanies.length === 0 &&
                selectedCompanies.company === null
              }
              onClick={() => {
                selectedCompanies.alliedCompanies.length > 0 &&
                  handleDeleteAlliedCompanies();
                selectedCompanies.company && handleDeleteCompany();
              }}
            >
              <span className={styles.actionButtonText}>Guardar</span>
              <img src={saveIcon} alt="" className={styles.actionButtonIcon} />
            </Button>
          </>
        )}
      </Col>

      <Col span={24} className={styles.userScreenCol}>
        <UserScreenContent title="Mis aliados:">
          <section className={styles.companies}>
            {!loading &&
            !error &&
            profileData?.profile?.alliedCompanies?.length > 0 ? (
              profileData?.profile?.alliedCompanies.map((company) => (
                <div
                  key={company._id}
                  className={`${styles.company} ${
                    selectedCompanies.alliedCompanies.includes(company._id)
                      ? styles.companySelected
                      : ""
                  }`}
                  onClick={() => isDeleting && toggleAlliedCompany(company._id)}
                >
                  {isDeleting && (
                    <img
                      src={deleteAlliedIcon}
                      alt=""
                      className={styles.companyDeleteIcon}
                    />
                  )}
                  <img
                    src={company.logo}
                    alt={company.name}
                    title={company.name}
                    className={styles.companyLogo}
                  />
                </div>
              ))
            ) : (
              <p className={styles.companiesEmpty}>
                Los aliados que agregue a su perfil aparecerán aquí.
              </p>
            )}
          </section>
        </UserScreenContent>
      </Col>
      <Col span={24} className={styles.userScreenCol}>
        <UserScreenContent title="Mi empresa:">
          <div className={styles.dataContainer}>
            {!loading && !error && profileData?.profile?.company ? (
              <div
                className={`${styles.company} ${
                  selectedCompanies.company ===
                  profileData?.profile?.company._id
                    ? styles.companySelected
                    : ""
                }`}
                onClick={() =>
                  isDeleting && toggleCompany(profileData?.profile?.company._id)
                }
              >
                {isDeleting && (
                  <img
                    src={deleteAlliedIcon}
                    alt=""
                    className={styles.companyDeleteIcon}
                  />
                )}
                <img
                  src={profileData?.profile?.company.logo}
                  alt={profileData?.profile?.company.name}
                  title={profileData?.profile?.company.name}
                  className={styles.companyLogo}
                />
              </div>
            ) : (
              <p className={styles.companiesEmpty}>
                Las empresas asociadas a su perfil aparecerán aquí.
              </p>
            )}
          </div>
        </UserScreenContent>
      </Col>
    </Row>
  );
};

export default withRouter(UserPartnerScreen);
