import React from "react";
import styles from "./index.module.css";
import Wave from "../../../../Assets/yellow-wave.png";
import ContactIcon from "../../Assets/contact-icon.svg";

const ContactHeader = () => {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerTitleContainer}>
          <img
            src={ContactIcon}
            alt="contact-icon"
            className={styles.headerIcon}
          />
          <h1 className={styles.headerTitle}>Contacto</h1>
        </div>
        <p className={styles.headerDescription}>
          ¿Tienes alguna duda o comentario? Solo ecribenos y te contactaremos en
          la brevedad.
        </p>
      </header>
      <img src={Wave} alt="wave" className={styles.wave} />
    </>
  );
};

export default ContactHeader;
