import React from "react";
import styles from "./index.module.css";
import addIcon from "../../Assets/add-icon.svg";

const Header = ({
  isUserList,
  setIsUserList,
  addUser,
  masiveLoad,
  addEmployee,
}) => {
  return (
    <header className={styles.container}>
      <h2 className={styles.title}>Usuarios</h2>
      {isUserList ? (
        <>
          <button
            className={`${styles.button} ${styles.buttonGray}`}
            onClick={() => masiveLoad()}
          >
            <span>Carga masiva</span>
          </button>
          <button className={styles.button} onClick={() => addUser()}>
            <img src={addIcon} alt="" />
            <span>Crear</span>
          </button>
        </>
      ) : (
        <button className={styles.button} onClick={() => addEmployee()}>
          <img src={addIcon} alt="" />
          <span>Crear</span>
        </button>
      )}

      <div className={styles.listToggle}>
        <button
          onClick={() => setIsUserList(true)}
          className={`${styles.listToggleButton}
        ${isUserList && styles.listToggleButtonActive}
        `}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 8C9.06087 8 10.0783 7.57857 10.8284 6.82843C11.5786 6.07828 12 5.06087 12 4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4C4 5.06087 4.42143 6.07828 5.17157 6.82843C5.92172 7.57857 6.93913 8 8 8ZM10.6667 4C10.6667 4.70724 10.3857 5.38552 9.88562 5.88562C9.38552 6.38572 8.70724 6.66667 8 6.66667C7.29276 6.66667 6.61448 6.38572 6.11438 5.88562C5.61428 5.38552 5.33333 4.70724 5.33333 4C5.33333 3.29276 5.61428 2.61448 6.11438 2.11438C6.61448 1.61428 7.29276 1.33333 8 1.33333C8.70724 1.33333 9.38552 1.61428 9.88562 2.11438C10.3857 2.61448 10.6667 3.29276 10.6667 4ZM16 14.6667C16 16 14.6667 16 14.6667 16H1.33333C1.33333 16 0 16 0 14.6667C0 13.3333 1.33333 9.33333 8 9.33333C14.6667 9.33333 16 13.3333 16 14.6667ZM14.6667 14.6613C14.6653 14.3333 14.4613 13.3467 13.5573 12.4427C12.688 11.5733 11.052 10.6667 8 10.6667C4.94667 10.6667 3.312 11.5733 2.44267 12.4427C1.53867 13.3467 1.336 14.3333 1.33333 14.6613H14.6667Z"
              fill={isUserList ? "#E4056F" : "#808080"}
            />
          </svg>
          <span>Usuarios</span>
        </button>
        <button
          onClick={() => setIsUserList(false)}
          className={`${styles.listToggleButton}
        ${!isUserList && styles.listToggleButtonActive}
        `}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0C7.51037 0 7.04079 0.194505 6.69457 0.540726C6.34835 0.886947 6.15385 1.35652 6.15385 1.84615V2.46154H1.84615C1.35652 2.46154 0.886947 2.65604 0.540726 3.00226C0.194505 3.34849 0 3.81806 0 4.30769L0 14.1538C0 14.6435 0.194505 15.1131 0.540726 15.4593C0.886947 15.8055 1.35652 16 1.84615 16H17.8462C18.3358 16 18.8054 15.8055 19.1516 15.4593C19.4978 15.1131 19.6923 14.6435 19.6923 14.1538V4.30769C19.6923 3.81806 19.4978 3.34849 19.1516 3.00226C18.8054 2.65604 18.3358 2.46154 17.8462 2.46154H13.5385V1.84615C13.5385 1.35652 13.344 0.886947 12.9977 0.540726C12.6515 0.194505 12.1819 0 11.6923 0H8ZM8 1.23077H11.6923C11.8555 1.23077 12.012 1.2956 12.1275 1.41101C12.2429 1.52642 12.3077 1.68294 12.3077 1.84615V2.46154H7.38462V1.84615C7.38462 1.68294 7.44945 1.52642 7.56486 1.41101C7.68026 1.2956 7.83679 1.23077 8 1.23077ZM10.3212 9.74031L18.4615 7.57046V14.1538C18.4615 14.3171 18.3967 14.4736 18.2813 14.589C18.1659 14.7044 18.0094 14.7692 17.8462 14.7692H1.84615C1.68294 14.7692 1.52642 14.7044 1.41101 14.589C1.2956 14.4736 1.23077 14.3171 1.23077 14.1538V7.56923L9.37108 9.74031C9.68237 9.82321 10.0099 9.82321 10.3212 9.74031ZM1.84615 3.69231H17.8462C18.0094 3.69231 18.1659 3.75714 18.2813 3.87255C18.3967 3.98796 18.4615 4.14448 18.4615 4.30769V6.29662L10.0049 8.55138C9.9009 8.57916 9.79141 8.57916 9.68738 8.55138L1.23077 6.29662V4.30769C1.23077 4.14448 1.2956 3.98796 1.41101 3.87255C1.52642 3.75714 1.68294 3.69231 1.84615 3.69231Z"
              fill={!isUserList ? "#E4056F" : "#808080"}
            />
          </svg>
          <span>Empleados</span>
        </button>
      </div>
    </header>
  );
};

export default Header;
