import gql from "graphql-tag";

const COMPANY = gql`
  query company($input: FindCompany!) {
    company(input: $input) {
      _id
      name
      phone
      logo
    }
  }
`;

const REPORTS = gql`
  query report($input: ReportInput) {
    report(input: $input) {
      visibility {
        promotionViews {
          count
          date
        }
        activeUsers {
          count
          date
        }
        newUsers {
          count
          date
        }
      }
      demographics {
        count
        ages
        genders
        maleCount
        femaleCount
        lessThan25
        lessThan25Male
        lessThan25Female
        greaterThan45
        greaterThan45Male
        greaterThan45Female
        greaterThan25LessThan45
        greaterThan25LessThan45Male
        greaterThan25LessThan45Female
      }
      interactions {
        averageInteractionTimes {
          time
          date
        }
        platformInteractions {
          platform
          count
        }
      }
      promotions {
        promotionStatusHistory {
          status
          count
          date
        }
        promotionCreationHistory {
          date
          count
        }
        promotionCountByStatus {
          status
          count
        }
      }
      traffic {
        trafficByCity {
          city
          count
        }
        trafficByChannel {
          channel
          count
        }
      }
    }
  }
`;

const COMPANY_REPORT = gql`
  query companyReport($type: String, $companyId: String!) {
    companyReport(type: $type, companyId: $companyId) {
      byUserViews {
        views
        ages
        genders
        count
        lessThan25
        greaterThan25LessThan45
        greaterThan45
        lessThan25Male
        greaterThan25LessThan45Male
        greaterThan45Male
        lessThan25Female
        greaterThan25LessThan45Female
        greaterThan45Female
        maleCount
        femaleCount
      }
      byStatus {
        active_promos
        inactive_promos
        expired_promos
        visible_promos
        no_visible_promos
      }
      byPromotionType {
        _id
        amount
        name
      }
    }
  }
`;

const PROMOTION_REPORT = gql`
  query promoReport($promoId: String!) {
    promoReport(promoId: $promoId) {
      byUserViews {
        views
        ages
        genders
        count
        lessThan25
        greaterThan25LessThan45
        greaterThan45
        lessThan25Male
        greaterThan25LessThan45Male
        greaterThan45Male
        lessThan25Female
        greaterThan25LessThan45Female
        greaterThan45Female
        maleCount
        femaleCount
      }
    }
  }
`;

const PROMOTIONS_BY_COMPANY_REPORT = gql`
  query promotionsByCompanyReport($companyId: String, $type: String) {
    promotionsByCompany(companyId: $companyId, filter: { type: $type }) {
      _id
      name
      description

      company {
        name
      }

      localName
      localLogo
      dueDate
      status
      visible
      code

      createdBy {
        email
      }

      createdAt
      discount
    }
  }
`;

export {
  REPORTS,
  COMPANY,
  COMPANY_REPORT,
  PROMOTION_REPORT,
  PROMOTIONS_BY_COMPANY_REPORT,
};
