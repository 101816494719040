import React, { useState } from "react";
import styles from "./index.module.css";
import "./index.css";
import { Row, Col, Input } from "antd";
import { withRouter } from "react-router";
import { getUser, setAccessToken, setUser } from "../../Helpers/auth";
import NavDrawer from "./Components/NavDrawer";
import { LOGOUT } from "../../Graphql/Mutations";
import { useMutation } from "@apollo/client";

import Logo from "../../Assets/Logo_loia.png";
import userIconWhite from "../../Assets/user-icon-white.svg";
import closeMenuIcon from "../../Assets/Menu/close.svg";
import openMenuIcon from "../../Assets/Menu/open.svg";
import searchIconRose from "../../Assets/search-icon-rose.svg";
import searchIconWhite from "../../Assets/search-icon-white.svg";
import { Link } from "react-router-dom";

const HeaderComponent = ({ history }) => {
  const [logout] = useMutation(LOGOUT);
  const [searchWord, setSearchWord] = useState("");
  const [visible, setVisible] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const handleSearch = (event) => {
    if (event.key === "Enter" && searchWord !== "") {
      history.push(`/searchPromotion/${searchWord}`);
    }
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        setAccessToken("");
        setUser({});
        history.push("/");
        localStorage.removeItem("companySelected");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOpenSearch = () => {
    setIsSearching(!isSearching);
  };

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        className={`${styles.container} containerHeader`}
      >
        <Col xs={12} sm={9} md={7} lg={6} className="leftCol">
          <img
            src={Logo}
            alt="logo"
            className="logoIcon"
            onClick={() => history.push("/")}
          />
        </Col>
        <Col xs={12} sm={15} md={17} lg={18} className="rightCol">
          <div className="headerSearchWeb">
            <Input
              placeholder="Búsqueda..."
              className="searchInputHeader"
              prefix={
                <img
                  src={searchIconRose}
                  alt=""
                  className="searchIcon"
                  onClick={() => {
                    if (searchWord !== "")
                      history.push(`/searchPromotion/${searchWord}`);
                  }}
                />
              }
              onChange={(e) => setSearchWord(e.target.value)}
              onKeyPress={handleSearch}
            />
            <div className="userLabel">
              {getUser() && getUser().firstName && getUser().lastName ? (
                <h2
                  className="userLabelName"
                  onClick={() => getUser().role === "User" ? history.push("/userHome") : history.push("/companyHome")}
                >
                  {`${getUser().firstName} ${getUser().lastName}`}{" "}
                </h2>
              ) : (
                <h2 className="userLabelName">
                  <Link to="/login">Inicia sesión</Link> o{" "}
                  <Link to="/register">regístrate</Link>
                </h2>
              )}
              <img
                onClick={() => history.push("/login")}
                src={userIconWhite}
                className="userLabelIcon"
                alt=""
              />
              <img
                src={visible ? closeMenuIcon : openMenuIcon}
                alt=""
                onClick={() => setVisible(!visible)}
              />
            </div>
          </div>
          <div className="headerSearchMobile">
            <img
              src={searchIconWhite}
              alt=""
              className="searchIconMobile"
              onClick={() => handleOpenSearch()}
            />
            <img
              src={visible ? closeMenuIcon : openMenuIcon}
              alt=""
              onClick={() => setVisible(!visible)}
            />
          </div>
        </Col>
      </Row>
      <div
        className={!isSearching ? "searchMobileArea" : "searchMobileAreaOpen"}
      >
        <Input
          placeholder="Búsqueda..."
          className={
            !isSearching
              ? "searchInputHeaderMobile"
              : "searchInputHeaderMobileOpen"
          }
          prefix={
            <img
              src={searchIconRose}
              alt=""
              className="searchIcon"
              onClick={() => {
                if (searchWord !== "")
                  history.push(`/searchPromotion/${searchWord}`);
              }}
            />
          }
          onChange={(e) => setSearchWord(e.target.value)}
          onKeyPress={handleSearch}
        />
      </div>
      <NavDrawer
        visible={visible}
        isLoged={getUser()?.firstName && true}
        onClose={() => setVisible(!visible)}
        handleLogout={handleLogout}
        firstName={getUser()?.firstName}
        lastName={getUser()?.lastName}
      />
    </>
  );
};

export default withRouter(HeaderComponent);
