import { useState } from "react";
import { Checkbox } from "antd";
import styles from "./index.module.css";
import ArrowIcon from "../../Assets/black_arrow_icon.svg";

const getCommunesWithPromos = (regions) => {
  const communes = regions?.map((region) => region?.communes) || [];
  return communes?.flat();
};

const FilterByCommune = ({
  availableRegions,
  filters,
  setFilters,
  getFilteredPromotions,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const communesWithPromos = getCommunesWithPromos(availableRegions);

  const handleChange = (values) => {
    const newFilters = [...values];
    setFilters({ ...filters, communes: newFilters });
    getFilteredPromotions({
      customFilters: {
        communes: newFilters?.length === 0 ? undefined : newFilters,
      },
    });
  };

  return (
    <section className={styles.container}>
      <header
        className={styles.header}
        onClick={() => setShowOptions(!showOptions)}
      >
        <h2 className={styles.headerTitle}>Comuna</h2>
        <button
          className={`${styles.headerArrowButton} ${
            showOptions ? styles.rotate : ""
          }`}
        >
          <img src={ArrowIcon} alt="close icon" />
        </button>
      </header>

      {communesWithPromos?.length > 0 && showOptions && (
        <Checkbox.Group
          className={styles.checkboxGroup}
          options={communesWithPromos?.map((commune) => {
            return {
              label: `${commune?.name} (${commune?.count})`,
              value: commune?.name,
            };
          })}
          value={filters.communes}
          onChange={(checkedValues) => handleChange(checkedValues)}
        />
      )}
    </section>
  );
};

export default FilterByCommune;
