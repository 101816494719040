import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { Button, Modal, notification, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery, useMutation } from "@apollo/client";
import { ADD_ALLIED_TO_USER } from "../../Graphql/Mutations";
import { SEARCH_COMPANY } from "../../Graphql/Queries";
import searchIcon from "../../Assets/search-icon.svg";
import { useHistory } from "react-router-dom";

const UpdatePasswordModal = ({ visible, onCancel }) => {
  const history = useHistory();
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [addAlliedToUser] = useMutation(ADD_ALLIED_TO_USER);
  const {
    error,
    loading,
    data: companiesData,
  } = useQuery(SEARCH_COMPANY, {
    variables: {
      input: {
        search,
        page: 1,
        limit: 5 * currentPage,
      },
    },
  });

  const toggleCompany = (companyId) => {
    if (selectedCompanies.includes(companyId)) {
      setSelectedCompanies(selectedCompanies.filter((c) => c !== companyId));
    } else setSelectedCompanies([...selectedCompanies, companyId]);
  };

  const handleSubmit = async () => {
    try {
      await addAlliedToUser({
        variables: {
          companiesIds: selectedCompanies,
        },
      }).then(() => {
        history.push("/userPartners");
        notification.success({
          message: "Éxito",
          description: "Aliados agregados correctamente",
          placement: "bottomRight",
        });
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description:
          "Intente de nuevo, si el problema persiste contacte al administrador",
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    currentPage === 1 && loadMoreCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreCompanies = () => setCurrentPage(currentPage + 1);

  return (
    <Modal
      className={styles.container}
      visible={visible}
      closable
      closeIcon={<CloseOutlined style={{ display: "none" }} />}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ backgroundColor: "#fff", borderRadius: "16px" }}
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Agrega tus aliados</h2>
        <CloseOutlined className={styles.headerCloseIcon} />
      </header>

      <section className={styles.searchbar}>
        <img src={searchIcon} alt="search" className={styles.searchbarIcon} />
        <input
          className={styles.searchbarInput}
          autoComplete="off"
          type="search"
          spellCheck="false"
          value={search}
          placeholder="Buscar..."
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && setSearch("")}
        />
      </section>

      <section className={styles.infiniteScroll}>
        <div
          id="scrollableDiv"
          style={{
            height: 200,
            overflowY: "auto",
          }}
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
            }}
            dataLength={companiesData?.searchCompany?.metadata?.totalDocs}
            next={loadMoreCompanies}
            hasMore={
              companiesData?.searchCompany?.metadata?.totalDocs >
              companiesData?.searchCompany?.data?.length
            }
            loader={<Spin />}
            scrollableTarget="scrollableDiv"
          >
            <ul className={styles.list}>
              {loading && <Spin className={styles.listSpin} />}
              {(error || companiesData?.searchCompany?.data?.length === 0) && (
                <p className={styles.listError}>No hay aliados disponibles</p>
              )}
              {companiesData?.searchCompany?.data?.map((company) => (
                <li
                  key={company.id}
                  className={`${styles.listItem} ${
                    selectedCompanies.includes(company._id)
                      ? styles.listItemSelected
                      : ""
                  }`}
                  onClick={() => toggleCompany(company._id)}
                >
                  <img
                    src={company.logo}
                    title={company.name}
                    alt={company.name}
                    className={styles.listItemLogo}
                  />
                </li>
              ))}
            </ul>
          </InfiniteScroll>
        </div>
      </section>

      <Button
        className={styles.button}
        disabled={selectedCompanies.length === 0}
        onClick={() => handleSubmit()}
      >
        Seleccionar
      </Button>
    </Modal>
  );
};

export default UpdatePasswordModal;
