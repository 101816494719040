import React from "react";
import { withRouter } from "react-router";
import MainHeader from "../../Components/Header";
// import NavbarComponent from "./Components/Navbar";
// import AsideComponent from './Components/Aside'
import Promotions from "./Components/Promotions";
import Instructions from "./Components/Instructions";
import MainFooter from "../../Components/Footer";
import "./index.css";
import Banner from "./Components/Banner";

const HomeScreen = () => {
  return (
    <>
      <MainHeader />
      <section id="background">
        <Banner />
        <Promotions />
        <Instructions />
      </section>
      <MainFooter />
    </>
  );
};

export default withRouter(HomeScreen);
