import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { Row, Col, Button, Input, Select, notification } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { PROFILE } from "../UserLayout/Graphql/Queries";
import { UPDATE_PROFILE_INFO } from "./Graphql/Mutations";
import { withRouter } from "react-router-dom";

import UserScreenContent from "../UserLayout/Components/UserScreenSection";
import UpdatePasswordModal from "./Components/UpdatePasswordModal/index";

import editIcon from "./Assests/edit-icon.svg";
import closeIcon from "./Assests/cancel-icon.svg";
import saveIcon from "./Assests/save-icon.svg";
import NotificationsManagement from "./Components/NotificationsManagement";

const UserHomeScreen = ({ history }) => {
  const { loading, error, data: profileData, refetch } = useQuery(PROFILE);
  const [updateProfileInfo] = useMutation(UPDATE_PROFILE_INFO, {
    refetchQueries: [{ query: PROFILE }],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isPasswordModalVisible, setisPasswordModalVisible] = useState(false);
  const { Option } = Select;

  const [userInfo, setUserInfo] = useState({
    birthDate:
      (!loading && profileData?.profile?.birthDate?.slice(0, 10)) || "",
    email: (!loading && profileData?.profile?.email) || "",
    gender: (!loading && profileData?.profile?.gender) || "",
  });

  const checkChanges = () => {
    if (
      profileData?.profile?.birthDate?.includes(userInfo.birthDate) &&
      profileData?.profile?.email === userInfo.email &&
      profileData?.profile?.gender === userInfo.gender
    ) {
      setHasChanged(false);
    } else setHasChanged(true);
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      await updateProfileInfo({
        variables: {
          input: {
            currentEmail: profileData?.profile?.email,
            newEmail:
              profileData?.profile?.email !== userInfo.email
                ? userInfo.email
                : null,
            birthDate: userInfo.birthDate.slice(0, 10),
            gender: userInfo.gender,
          },
        },
      }).then(() => {
        history.push("/userHome");
        notification.success({
          message: "Éxito",
          description: "Perfil actualizado con éxito.",
          placement: "bottomRight",
        });
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error",
        description:
          error.message === "Email already in use"
            ? "El email ya se encuentra en uso"
            : "Error al actualizar el perfil, verifique los datos e intente nuevamente",
        placement: "bottomRight",
      });
    }
  };

  /* eslint-disable-next-line */
  useEffect(() => refetch(), []);
  /* eslint-disable-next-line */
  useEffect(() => checkChanges(), [userInfo]);

  return (
    <Row className={styles.userScreenRow}>
      <Col xs={0} sm={0} md={12} className={styles.userScreenHeaderCol}>
        <header className={styles.userScreenHeader}>
          <h2 className={styles.userScreenHeaderTitle}>
            <span>{!loading && !error && profileData.profile.firstName}</span>
            <span>{!loading && !error && profileData.profile.lastName}</span>
          </h2>
        </header>
      </Col>
      <Col xs={0} sm={0} md={12} className={styles.actionButtonsCol}>
        {!isEditing ? (
          <Button
            className={`${styles.actionButton} ${styles.actionButtonEdit}
          ${isEditing && styles.actionButtonHidden}
          `}
            ghost
            onClick={() => setIsEditing(true)}
            hidden={isEditing}
          >
            <span className={styles.actionButtonText}>Editar</span>
            <img src={editIcon} alt="" className={styles.actionButtonIcon} />
          </Button>
        ) : (
          <>
            <Button
              className={`${styles.actionButton} 
          ${!isEditing && styles.actionButtonHidden}
          ${styles.actionButtonCancel}`}
              ghost
              onClick={() => {
                setIsEditing(false);
                setUserInfo({
                  birthDate:
                    (!loading &&
                      profileData?.profile?.birthDate.slice(0, 10)) ||
                    "",
                  email: (!loading && profileData?.profile?.email) || "",
                  gender: (!loading && profileData?.profile?.gender) || "",
                });
              }}
            >
              <span className={styles.actionButtonText}>Cancelar</span>
              <img src={closeIcon} alt="" className={styles.actionButtonIcon} />
            </Button>
            <Button
              className={`${styles.actionButton} ${styles.actionButtonSave}
          ${hasChanged && styles.actionButtonSaveHasChanged}
          ${!isEditing && styles.actionButtonHidden}
          `}
              disabled={!hasChanged}
              onClick={() => handleSubmit()}
            >
              <span className={styles.actionButtonText}>Guardar</span>
              <img src={saveIcon} alt="" className={styles.actionButtonIcon} />
            </Button>
          </>
        )}
      </Col>
      <Col span={24} className={styles.userScreenCol}>
        <UserScreenContent title="Datos de acceso">
          <div className={styles.dataContainer}>
            <div className={styles.dataContainerItem}>
              <label>Correo:</label>
              <Input
                className={`${styles.dataContainerItemInput} ${
                  isEditing ? styles.dataContainerItemInputEditing : ""
                }`}
                name="email"
                type="email"
                defaultValue={userInfo.email}
                onChange={(e) => handleInputChange(e)}
                value={userInfo.email}
                disabled={!isEditing}
              />
            </div>
            <div
              className={styles.dataContainerItem}
              onClick={() => isEditing && setisPasswordModalVisible(true)}
            >
              <label>Contraseña:</label>
              <Input.Password
                className={`${styles.dataContainerItemInputPassword} ${
                  isEditing ? styles.dataContainerItemInputEditing : ""
                }`}
                name="password"
                type="password"
                value="**************"
                disabled={true}
              />
            </div>
          </div>
        </UserScreenContent>
      </Col>
      <Col span={24} className={styles.userScreenCol}>
        <UserScreenContent title="Datos de usuario">
          <div className={styles.dataContainer}>
            <div className={styles.dataContainerItem}>
              <label>Fecha de nacimiento:</label>
              <Input
                className={`${styles.dataContainerItemInput} ${
                  isEditing ? styles.dataContainerItemInputEditing : ""
                }`}
                name="birthDate"
                type="date"
                disabled={!isEditing}
                placeholder="Fecha de nacimiento"
                defaultValue={userInfo.birthDate}
                value={userInfo.birthDate}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className={styles.dataContainerItem}>
              <label>Genero:</label>
              <Select
                className={`${styles.dataContainerItemInputGender} ${
                  isEditing ? styles.dataContainerItemInputEditing : ""
                }`}
                disabled={!isEditing}
                placeholder="Genero"
                name="gender"
                defaultValue={userInfo.gender}
                value={userInfo.gender}
                onChange={(e) => setUserInfo({ ...userInfo, gender: e })}
              >
                <Option value="hombre">Masculino</Option>
                <Option value="mujer">Femenino</Option>
              </Select>
            </div>
          </div>
        </UserScreenContent>
        <NotificationsManagement
          getProfile={refetch}
          isLoadingProfile={loading}
          notifications={profileData?.profile?.preferences?.notifications}
        />
      </Col>
      <UpdatePasswordModal
        visible={isPasswordModalVisible}
        onCancel={() => setisPasswordModalVisible(false)}
      />
    </Row>
  );
};

export default withRouter(UserHomeScreen);
