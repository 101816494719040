import React from "react";
import styles from "./index.module.css";
import { Rate } from "antd";
import { useQuery } from "@apollo/client";
import { AVERAGE_COMPANY_RATING } from "../../Graphql/Queries";
import { getUser } from "../../../../Helpers/auth";
// import filterIcon from "../../Assets/filter-icon.svg";

const Header = () => {
  const { loading, data } = useQuery(AVERAGE_COMPANY_RATING, {
    variables: {
      companyId: getUser().company
        ? getUser().company._id
        : JSON.parse(localStorage.getItem("companySelected")),
    },
  });

  return (
    <header className={styles.container}>
      <section className={styles.main}>
        <h2 className={styles.mainTitle}>Comentarios</h2>
        {/* <button className={styles.mainButton}>
          <img src={filterIcon} alt="" />
          <span>Filtrar</span>
        </button> */}
      </section>

      <section className={styles.rating}>
        <h3 className={styles.ratingTitle}>Ranking general:</h3>
        <div className={styles.rateContainer}>
          <Rate
            disabled
            value={!loading && data ? Math.round(data.averageCompanyRating) : 0}
          />
        </div>
      </section>
    </header>
  );
};

export default Header;
