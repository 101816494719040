import React from "react";
import MainHeader from "../../Components/Header";
import SearchHeader from "./Components/SearchHeader";
import Promotions from "./Components/Promotions";
import MainFooter from "../../Components/Footer";

const PromotionSearchScreen = () => {
  return (
    <>
      <MainHeader />
      <div className="background">
        <SearchHeader />
        <Promotions />
      </div>
      <MainFooter />
    </>
  );
};

export default PromotionSearchScreen;
