import gql from "graphql-tag";

const COMMENTS = gql`
  query comments($promoId: String!, $input: QueryParams!) {
    comments(promoId: $promoId, input: $input) {
      data {
        _id
        stars
        body
        visible
        user {
          firstName
        }
      }
      metadata {
        totalDocs
      }
    }
  }
`;

const CHECK_MY_COMMENT = gql`
  query checkMyComment($promoId: String!) {
    checkMyComment(promoId: $promoId) {
      stars
      visible
      body
    }
  }
`;

const COMPANY = gql`
query company($input: FindCompany!){
    company(input: $input){
      _id
      name
      phone
      logo
      category{
          _id
      }
    }
  }
`;


export { COMMENTS, CHECK_MY_COMMENT, COMPANY };
