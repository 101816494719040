import gql from "graphql-tag";

const PROMOTIONS_BY_COMPANY = gql`
  query promotionsByCompany(
    $companyId: String
    $filter: PromotionsByCompanyFilter
    $sortBy: PromotionsByCompanySortBy
  ) {
    promotionsByCompany(
      companyId: $companyId
      filter: $filter
      sortBy: $sortBy
    ) {
      _id
      name
      description
      averageRating
      type {
        name
      }
      conditions
      company {
        name
        logo
        icon
      }
      localName
      localLogo
      startDate
      dueDate
      weekDays
      benefitURL
      termAndConditions
      status
      image
      visible
      visibility
      code
      segments {
        _id
        name
      }
      locations {
        address
        latitude
        longitude
      }
      createdBy {
        email
      }
      createdAt
      communes
      discount
    }
  }
`;

const SEGMENTS = gql`
  query segments($companyId: String!) {
    segments(companyId: $companyId) {
      _id
      name
      company {
        _id
      }
    }
  }
`;

export { PROMOTIONS_BY_COMPANY, SEGMENTS };
