import React, { useState } from "react";
import styles from "./index.module.css";
import { Button, Modal, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_COMPANY } from "../../Graphql/Mutations";
import StepOne from "./Components/StepOne";
import StepTwo from "./Components/StepTwo";
import stepOneValidation from "./Helpers/stepOneValidation";
import stepTwoValidation from "./Helpers/stepTwoValidation";

const CreateCompanyModal = ({ isVisible, onCancel }) => {
  const history = useHistory();
  const [createCompany, { loading: loadingCreateCompany }] = useMutation(CREATE_COMPANY);
  const [currentStep, setCurrentStep] = useState(1);
  const [registerData, setRegisterData] = useState({
    company: {
      name: "",
      phone: "",
      category: "",
    },
    admin: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
  });

  const handleSubmit = async () => {
    try {
      const { company, admin } = registerData;
      const { data } = await createCompany({
        variables: {
          input: {
            name: company.name,
            phone: company.phone,
            category: company.category,
            adminFirstName: admin.firstName,
            adminLastName: admin.lastName,
            adminEmail: admin.email,
            adminPassword: admin.password,
          },
        },
      });
      if (data) {
        notification.success({
          message: "Éxito",
          description:
            "La compañía se ha creado correctamente, ahora puedes iniciar sesión",
          placement: "bottomRight",
        });
        history.push("/login");
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
        placement: "bottomRight",
      });
    }
  };

  return (
    <Modal
      className={styles.container}
      visible={isVisible}
      footer={null}
      bodyStyle={{ paddingBottom: "2rem" }}
      onCancel={onCancel}
      closeIcon={<CloseOutlined className={styles.closeIcon} />}
    >
      <section className={styles.content}>
        <header className={styles.header}>
          <h3 className={styles.headerTitle}>
            Crear {currentStep === 1 ? "empresa" : "administrador"}
          </h3>
          <span className={styles.headerSubtitle}>Paso {currentStep} de 2</span>
        </header>

        {currentStep === 1 ? (
          <StepOne
            registerData={registerData}
            setRegisterData={setRegisterData}
          />
        ) : (
          <StepTwo
            registerData={registerData}
            setRegisterData={setRegisterData}
          />
        )}

        <footer className={styles.footer}>
          {currentStep === 1 ? (
            <button
              className={styles.footerButton}
              onClick={() =>
                stepOneValidation(registerData.company) && setCurrentStep(2)
              }
            >
              Siguiente
            </button>
          ) : (
            <>
              <button
                className={`${styles.footerButton} ${styles.footerGhostButton}`}
                onClick={() => setCurrentStep(1)}
              >
                Anterior
              </button>
              <Button
                className={styles.footerButton}
                onClick={() =>
                  stepOneValidation(registerData.company) &&
                  stepTwoValidation(registerData.admin) &&
                  handleSubmit()
                }
                loading={loadingCreateCompany}
              >
                Guardar
              </Button>
            </>
          )}
        </footer>
      </section>
    </Modal>
  );
};

export default CreateCompanyModal;
