import React from 'react'
import styles from './index.module.css'
import ChangePasswordIcon from "../../../../Assets/change-password-icon.svg";

const ChangePasswordHeader = () => {
    return (
        <header className={styles.header}>
            <img src={ChangePasswordIcon} alt="" className={styles.headerIcon} />
            <h2 className={styles.headerTitle}>Crea tu nueva contraseña</h2>
        </header>
    )
}

export default ChangePasswordHeader