import { useMutation } from "@apollo/client";
import Switch from "../../../../Components/Switch";
import styles from "./index.module.css";
import { UPDATE_NOTIFICATIONS_PREFERENCES } from "../../Graphql/Mutations";
import { notification } from "antd";

const NotificationsManagement = ({
  notifications,
  getProfile,
  isLoadingProfile,
}) => {
  const { newPromotions, promotionsAboutToExpire } = notifications || {};

  const [
    updateNotificationsPreferences,
    { loading: isUpdatingNotificationsPreferences },
  ] = useMutation(UPDATE_NOTIFICATIONS_PREFERENCES);

  const handleUpdateNotificationsPreferences = (key, value) => {
    updateNotificationsPreferences({
      variables: {
        input: {
          notifications: {
            newPromotions: key === "newPromotions" ? value : newPromotions,
            promotionsAboutToExpire:
              key === "promotionsAboutToExpire"
                ? value
                : promotionsAboutToExpire,
          },
        },
      },
    })
      .then(() => {
        getProfile();
        notification.success({
          message: "Operación exitosa",
          description: "Preferencia de notificación actualizada",
          placement: "bottomRight",
        });
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description:
            "Hubo un error al intentar actualizar las preferencias de notificaciones, inténtelo de nuevo.",
          placement: "bottomRight",
        });
      });
  };

  return (
    <section className={styles.container}>
      {newPromotions !== undefined && (
        <Switch
          className={styles.switch}
          isActive={newPromotions}
          activeText={
            "Deseo recibir correos beneficios nuevos en el ultimo mes"
          }
          desactiveText={
            "Deseo recibir correos beneficios nuevos en el ultimo mes"
          }
          disabled={isUpdatingNotificationsPreferences || isLoadingProfile}
          onClickFn={() =>
            handleUpdateNotificationsPreferences(
              "newPromotions",
              !newPromotions
            )
          }
        />
      )}
      {promotionsAboutToExpire !== undefined && (
        <Switch
          className={styles.switch}
          isActive={promotionsAboutToExpire}
          activeText={
            "Deseo recibir correos de beneficios a vencer próximamente"
          }
          desactiveText={
            "Deseo recibir correos de beneficios a vencer próximamente"
          }
          disabled={isUpdatingNotificationsPreferences || isLoadingProfile}
          onClickFn={() =>
            handleUpdateNotificationsPreferences(
              "promotionsAboutToExpire",
              !promotionsAboutToExpire
            )
          }
        />
      )}
    </section>
  );
};

export default NotificationsManagement;
