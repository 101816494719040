import React, { useEffect } from 'react'
import { Result, Button, Spin, Row } from 'antd';
import { VERIFY_ACCOUNT } from './Graphql/Mutations';
import { useMutation } from "@apollo/client";
import { useParams, withRouter } from 'react-router-dom';

const VerifyAccountScreen = ({history}) => {

    let { token } = useParams()

    const [verifyAccount, {loading: loadingVerifyAccount, error: errorVerifyAccount}] = useMutation(VERIFY_ACCOUNT,{
        variables:{
            token: token
        }
    })

    useEffect(() => {
        verifyAccount().catch(e => {
            console.log(e)
        })
        // eslint-disable-next-line 
    }, [])

    if(loadingVerifyAccount) return (
        <Row justify="center" style={{paddingTop: 300}}>
            <Spin size="large"/>
        </Row>
    )
    else if(errorVerifyAccount && errorVerifyAccount.message.includes('User already verified')) return (
        <Row justify="center" style={{paddingTop: 220}}>
            <Result
            status="warning"
            title="Tu cuenta ya se encuentra verificada"
            subTitle="Haz click en el siguiente botón para iniciar sesión"
            extra={[
                <Button type="primary" key="console" style={{backgroundColor: '#E4056F', borderRadius: 30, border: 'none'}} onClick={() => history.push('/login')}>
                    Iniciar sesión
                </Button>
                ]}
            />
        </Row>
    )
    else if( errorVerifyAccount && errorVerifyAccount.message.includes('User not found')) return (
        <Row justify="center" style={{paddingTop: 220}}>
            <Result
            status="error"
            title="No hemos podido verificar tu cuenta"
            subTitle="Por favor reinténtalo más tarde"
            />
        </Row>
    )
    else return (
        <Row justify="center" style={{paddingTop: 200}}>
        <Result
            status="success"
            title="¡Tu cuenta ha sido verificada con éxito!"
            subTitle="Haz click en el siguiente botón para iniciar sesión"
            extra={[
            <Button type="primary" key="console" style={{backgroundColor: '#E4056F', borderRadius: 30, border: 'none'}} onClick={() => history.push('/login')}>
                Iniciar sesión
            </Button>
            ]}
        />
        </Row>
    )
}

export default withRouter(VerifyAccountScreen)