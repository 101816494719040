import React from "react";
import styles from "../index.module.css";
import { ResponsiveBar } from "@nivo/bar";
import InfoIcon from "../../../../Assets/info-icon.svg";

const PromotionsStatus = ({ data }) => {
  const activePromotions = data?.promotions?.promotionCountByStatus?.find(
    (item) => {
      return item.status === "active";
    }
  );

  const inactivePromotions = data?.promotions?.promotionCountByStatus?.find(
    (item) => {
      return item.status === "inactive";
    }
  );

  const expiredPromotions = data?.promotions?.promotionCountByStatus?.find(
    (item) => {
      return item.status === "expired";
    }
  );

  const dataChart = [
    {
      status: "Activo",
      count: activePromotions?.count || 0,
      color: "#E4056F",
    },
    {
      status: "Inactivo",
      count: inactivePromotions?.count || 0,
      color: "#E4056F",
    },
    {
      status: "Vencido",
      count: expiredPromotions?.count || 0,
      color: "#E4056F",
    },
  ];

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3>Promociones por estatus</h3>
        <img src={InfoIcon} alt="" />
      </header>
      <div style={{ height: "85%", width: "100%" }}>
        <ResponsiveBar
          data={dataChart.reverse()}
          keys={["count"]}
          indexBy="status"
          animate={true}
          margin={{ bottom: 40, left: 80, right: 40, top: 10 }}
          padding={0.4}
          layout="horizontal"
          valueScale={{ type: "linear", min: 0, max: "auto" }}
          indexScale={{ type: "band", round: true }}
          colors={({ id, data }) => data?.color}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          valueFormat=">-c"
          axisRight={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          enableGridX={true}
          enableGridY={true}
          isInteractive={true}
          labelTextColor="#fff"
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          minValue={0}
        />
      </div>
    </section>
  );
};

export default PromotionsStatus;
