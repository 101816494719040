import gql from "graphql-tag";

const COMPANIES = gql`
  query companies($input: QueryParams) {
    companies(input: $input) {
      data {
        _id
        name
      }
    }
  }
`;

const SEARCH_COMPANY = gql`
  query searchCompany($input: QueryParams) {
    searchCompany(input: $input) {
      data {
        _id
        name
        logo
      }
      metadata {
        totalPages
        page
        limit
        totalDocs
      }
    }
  }
`;

export { COMPANIES, SEARCH_COMPANY };
