import gql from "graphql-tag";

const CREATE_COMPANY = gql`
mutation createCompany($input: CreateCompanyAndAdminInput!){
  createCompany(input: $input){
    _id
    name 
  }
}
`;

export { CREATE_COMPANY }