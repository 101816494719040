const OtherIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width || "46"}
      height={height || "46"}
      viewBox="0 0 46 46"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2701 46C23.2601 46 23.25 46 23.24 46C17.16 45.99 11.43 43.6 7.11002 39.25C2.82002 34.93 0.47004 29.23 0.50004 23.21C0.56004 10.68 10.76 0.5 23.23 0.5C23.24 0.5 23.25 0.5 23.26 0.5C29.34 0.51 35.0701 2.9 39.3901 7.25C43.6801 11.57 46.03 17.27 46 23.29C45.94 35.82 35.7401 46 23.2701 46ZM23.23 3.12C12.19 3.12 3.17003 12.13 3.12003 23.23C3.09003 28.55 5.17001 33.58 8.97001 37.41C12.8 41.26 17.87 43.38 23.25 43.39C23.26 43.39 23.2701 43.39 23.2701 43.39C34.3101 43.39 43.33 34.38 43.38 23.28C43.41 17.96 41.3301 12.93 37.5301 9.10001C33.7001 5.25001 28.63 3.13 23.25 3.12C23.25 3.12 23.24 3.12 23.23 3.12Z"
        fill="currentColor"
      />
      <path
        d="M11.8101 26.82C9.87012 26.82 8.22005 25.17 8.24005 23.23C8.25005 21.3 9.8501 19.7 11.7901 19.67C13.7201 19.64 15.3701 21.27 15.3801 23.23C15.4001 25.17 13.7601 26.82 11.8101 26.82Z"
        fill="currentColor"
      />
      <path
        d="M38.26 23.32C38.19 25.28 36.5601 26.87 34.6601 26.83C32.6901 26.79 31.06 25.12 31.12 23.21C31.18 21.22 32.84 19.61 34.74 19.68C36.73 19.75 38.33 21.4 38.26 23.32Z"
        fill="currentColor"
      />
      <path
        d="M23.23 19.68C25.16 19.65 26.81 21.29 26.83 23.24C26.84 25.16 25.2701 26.76 23.3001 26.82C21.3901 26.88 19.7301 25.25 19.6801 23.28C19.6301 21.36 21.26 19.71 23.23 19.68Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OtherIcon;
