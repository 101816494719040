import React from 'react'
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import styles from './index.module.css'

const ChangePasswordForm = ({
    newPassword,
    setNewPassword,
    repeatPassword,
    setRepeatPassword,
    handleChangePassword,
    formValidation
}) => {
    return (
        <section className={styles.container}>
            <Form id="form-login" className={styles.form}>
                <h2 className={styles.formTitle}>
                    Ingresa tu nueva contraseña para la cuenta
                </h2>
                <div className={styles.formItem}>
                <label className={styles.formItemLabel} htmlFor="password">
                    Nueva Contraseña
                </label>
                <Input.Password
                    className={styles.formItemInput}
                    name="newPassword"
                    type="password"
                    spellCheck="false"
                    placeholder="Ingresa tu nueva clave"
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                </div>

                <div className={styles.formItem}>
                <label className={styles.formItemLabel} htmlFor="password">
                    Confirmar nueva contraseña
                </label>
                <Input.Password
                    className={styles.formItemInput}
                    name="repeatPassword"
                    type="password"
                    spellCheck="false"
                    placeholder="Repite la nueva clave"
                    iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                />
                </div>

                <Button
                className={styles.formButton}
                onClick={() => formValidation() && handleChangePassword()}
                >
                Siguiente
                </Button>
            </Form>
        </section>
    )
}

export default ChangePasswordForm