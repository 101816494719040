import React from "react";
import { Button, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import AliadosImg from "./../UserHomeScreen/Assests/Aliados-img.svg";
import EmpresaImg from "./../UserHomeScreen/Assests/Empresa-img.svg";
import HomeImgButton from "./../UserHomeScreen/Assests/HomeImg-button.svg";
import Footer from "../../Components/Footer";
import "./index.css";

const UserAddPartnerScreen = ({ history }) => {
  return (
    <>
      <div className="h1-userScreen">Agregar socios</div>

      <Row align="middle" className="UserScreen-flex ">
        <Col xs={24} sm={24} md={24} lg={10} xl={8}>
          <img src={AliadosImg} alt="logo" className="UserScreen-img " />
          <p className="h2-userScreen">Mis aliados</p>
          <p className="text-userScreen">
            Asocia a tu banco, isapre o cualquier otro aliado
          </p>
          <Button
            className="userScreen-ButtonHome"
            onClick={() => history.push("/addAlly")}
          >
            + Agregar
          </Button>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={10}
          style={{ paddingTop: "40px" }}
        >
          <img src={EmpresaImg} alt="logo" className="UserScreen-img " />
          <p className="h2-userScreen" style={{ paddingTop: "11px" }}>
            Mi empresa
          </p>
          <p className="text-userScreen">
            Asocia para ver los beneficios de la empresa en la cual trabajas
          </p>
          <Button
            className="userScreen-Button"
            onClick={() => history.push("/addCompany")}
          >
            + Agregar
          </Button>
        </Col>
      </Row>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Button
          className="userScreen-ButtonHome"
          onClick={() => history.push("/")}
        >
          <img src={HomeImgButton} alt="logo" className="HomeImgButton" />
          Home
        </Button>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(UserAddPartnerScreen);
