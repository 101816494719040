import React from "react";
import styles from "./index.module.css";
import { Row, Spin } from "antd";
import PromotionsSeenByAge from "./Components/PromotionsSeenByAge";
import PromotionsSeenBySex from "./Components/PromotionsSeenBySex";
import PromotionsSeenByAgeAndSex from "./Components/PromotionsSeenByAgeAndSex";
import { PROMOTION_REPORT } from "../../Graphql/Queries";
import { useQuery } from "@apollo/client";
import "./index.css";

const DemographicsReports = ({
  isModal,
  promotion,
  loadingCompanyReport,
  dataCompanyReport = {},
}) => {
  const isAnyCompanyReportAvailable =
    Object?.values(dataCompanyReport)?.filter(
      (stat) => typeof stat === "number" && stat > 0
    ).length > 0;

  const { loading: loadingPromotionReport, data: dataPromotionReport } =
    useQuery(PROMOTION_REPORT, {
      variables: {
        promoId: promotion ? promotion._id : "",
      },
      skip: !isModal ? true : false,
      fetchPolicy: "no-cache",
    });

  if (loadingPromotionReport || loadingCompanyReport) {
    return (
      <Row justify="center" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Spin size="large" />
      </Row>
    );
  }

  if (!dataPromotionReport && dataCompanyReport) {
    return (
      <section className={styles.container}>
        <h2 className={styles.title}>Demografía de los usuarios</h2>
        {dataCompanyReport && isAnyCompanyReportAvailable ? (
          <div className={styles.stats}>
            <PromotionsSeenByAge data={dataCompanyReport} />
            <PromotionsSeenBySex data={dataCompanyReport} />
            <PromotionsSeenByAgeAndSex data={dataCompanyReport} />
          </div>
        ) : (
          <div className={styles.stats}>
            <p className={styles.noData}>
              No hay datos actualmente. Una vez que tus usuarios visualicen
              alguna de tus promociones, acá podrás ver su información.
            </p>
          </div>
        )}
      </section>
    );
  }

  if (!dataCompanyReport && dataPromotionReport) {
    if (dataPromotionReport.promoReport.byUserViews !== null)
      return (
        <section className={styles.container}>
          <h2 className={styles.title}>Demografía de los usuarios</h2>
          <h3 className={styles.statsVisualizations}>
            <span>
              {dataPromotionReport &&
              dataPromotionReport.promoReport.byUserViews
                ? dataPromotionReport.promoReport.byUserViews.count
                : 0}
            </span>
            usuarios visualizaron promociones
          </h3>

          <div className={styles.stats}>
            <PromotionsSeenByAge data={dataPromotionReport.promoReport} />
            <PromotionsSeenBySex data={dataPromotionReport.promoReport} />
            <PromotionsSeenByAgeAndSex data={dataPromotionReport.promoReport} />
          </div>
        </section>
      );
    else
      return (
        <section className={styles.container}>
          <h2 className={styles.title}>Demografía de los usuarios</h2>
          <h3 className={styles.statsVisualizations}>
            <span>
              {dataPromotionReport &&
              dataPromotionReport.promoReport.byUserViews
                ? dataPromotionReport.promoReport.byUserViews.count
                : 0}
            </span>{" "}
            usuarios visualizaron promociones
          </h3>

          <div className={styles.stats}>
            <p className={styles.noData}>
              No hay datos actualmente. Una vez que tus usuarios visualicen esta
              promoción, acá podrás ver su información.
            </p>
          </div>
        </section>
      );
  } else
    return (
      <>
        <section className={styles.container}>
          <h2 className={styles.title}>Demografía de los usuarios</h2>

          <div className={styles.stats}>
            <p className={styles.noData}>
              No hay datos actualmente. Una vez que tus usuarios visualicen esta
              promoción, acá podrás ver su información.
            </p>
          </div>
        </section>
      </>
    );
};

export default DemographicsReports;
