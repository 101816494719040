import { useState } from "react";
import styles from "./index.module.css";
import addIcon from "../../Assets/add-icon.svg";
import FiltersIcon from "../../Assets/filters_icon.svg";
import { Col, Form, Input, Select } from "antd";
import PromotionsFiltersModal from "../PromotionsFiltersModal";
import PromotionsFilters from "../PromotionsFilters";
import { PlusOutlined } from "@ant-design/icons";

const Header = ({
  handlePage,
  sortBy,
  setSortBy,
  filter,
  setFilter,
  search,
  setSearch,
}) => {
  const [showPromotionsFiltersModal, setShowPromotionsFiltersModal] =
    useState(false);
  const { Option } = Select;

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Promociones</h2>
        <button
          className={styles.headerButton}
          onClick={() => handlePage("create")}
        >
          <img className={styles.headerButtonPlusImage} src={addIcon} alt="" />
          <PlusOutlined className={styles.headerButtonPlusIcon} />
          <span>Crear nueva promoción</span>
        </button>
        <button
          className={styles.headerPromotionsFiltersButton}
          onClick={() => setShowPromotionsFiltersModal(true)}
        >
          <img src={FiltersIcon} alt="" />
          Filtros
        </button>
      </header>

      <Col xs={24} md={0} className={styles.searchInputsContainer}>
        <Form.Item label="Buscar por" className={styles.searchByInputContainer}>
          <Select
            className={styles.searchByInput}
            placeholder="Selecciona una opción"
            defaultValue={search?.field || "Name"}
            value={search?.field || "Name"}
            onChange={(value) => {
              setSearch({ ...search, field: value });
            }}
          >
            <Option value="Name">Nombre</Option>
            <Option value="Code">ID</Option>
            <Option value="CreatedBy">Creado por</Option>
          </Select>
        </Form.Item>
        <Form.Item label="" className={styles.searchValueInputContainer}>
          <Input
            defaultValue={search?.value || ""}
            value={search?.value || ""}
            onChange={(e) =>
              setSearch({
                ...search,
                value: e.target.value,
              })
            }
            placeholder="Ingresa un valor"
            type="text"
          />
        </Form.Item>
      </Col>

      <div className={styles.desktopPromotionsFilters}>
        <PromotionsFilters
          filter={filter}
          setFilter={setFilter}
          sortBy={sortBy}
          setSortBy={setSortBy}
          search={search}
          setSearch={setSearch}
        />
      </div>

      <PromotionsFiltersModal
        filter={filter}
        setFilter={setFilter}
        sortBy={sortBy}
        setSortBy={setSortBy}
        search={search}
        setSearch={setSearch}
        showPromotionsFiltersModal={showPromotionsFiltersModal}
        setShowPromotionsFiltersModal={setShowPromotionsFiltersModal}
      />
    </section>
  );
};

export default Header;
