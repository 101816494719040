import React from "react";
import styles from "./index.module.css";
import { Button } from "antd";
import { useQuery } from "@apollo/client";
import ArrowIcon from "./Assets/arrow_icon.svg";
import { FILTERS } from "./Graphql/Queries";
import FiltersList from "./Components/FiltersList";

const PromotionsFilter = ({
  filters,
  setFilters,
  getActiveFilters,
  showStickyFilters,
  setShowStickyFilters,
  getFilteredPromotions,
  showShortFiltersList,
  className,
}) => {
  const { data} = useQuery(FILTERS, {
    variables: {
      filters: {
        daysOfWeek: filters?.daysOfWeek,
        minDiscount: filters?.minDiscount,
        maxDiscount: filters?.maxDiscount,
        communes: filters?.communes,
        scalars: filters?.scalars,
        partners: filters?.partners,
        includeAlliedPartners: filters?.includeAlliedPartners,      
      }
    },
    fetchPolicy: "network-only",
  });




  const handleFiltersDisplay = () => {
    setShowStickyFilters(!showStickyFilters);
  };

  return (
    <div className={`${styles.filter} ${className}`}>
      <Button
        className={styles.filterButton}
        icon={null}
        onClick={handleFiltersDisplay}
        shape="round"
        size="large"
        type="primary"
      >
        {showStickyFilters || showStickyFilters
          ? "Ocultar filtros"
          : "Mostrar filtros"}
        <img
          src={ArrowIcon}
          alt="Arrow icon"
          className={
            showStickyFilters
              ? styles.filterIconOpened
              : styles.filterIconClosed
          }
        />
      </Button>

      {showStickyFilters && (
        <FiltersList
          filters={filters}
          setFilters={setFilters}
          getActiveFilters={getActiveFilters}
          availableFilters={data?.getFilters}
          showShortFiltersList={showShortFiltersList}
          getFilteredPromotions={getFilteredPromotions}
        />
      )}
    </div>
  );
};

export default PromotionsFilter;
