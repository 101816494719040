import gql from "graphql-tag";

const UPDATE_PROFILE_INFO = gql`
  mutation updateProfileInfo($input: UpdateProfileInput!) {
    updateProfileInfo(input: $input) {
      _id
      email
      birthDate
      gender
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      _id
    }
  }
`;

const UPDATE_NOTIFICATIONS_PREFERENCES = gql`
  mutation updatePreferences($input: UserPreferencesInput!) {
    updatePreferences(input: $input) {
      _id
    }
  }
`;

export {
  UPDATE_PROFILE_INFO,
  UPDATE_PASSWORD,
  UPDATE_NOTIFICATIONS_PREFERENCES,
};
