const ShoppingIcon = ({ className, width, height }) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 54 48"
      width={width || "54"}
      className={className}
      height={height || "48"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7501 37.79C18.9901 37.79 18.2301 37.8 17.4701 37.79C15.2801 37.75 13.5601 36.47 12.9401 34.45C12.3101 32.39 13.2201 30.13 15.1201 29.1C15.4501 28.92 15.5201 28.76 15.4401 28.39C13.6201 20.27 11.8301 12.15 10.0301 4.02999C9.96006 3.71 9.81007 3.64 9.51007 3.64C6.99007 3.65 4.47005 3.66 1.96005 3.64C0.770049 3.63 0.0100601 2.56 0.44006 1.52C0.69006 0.91 1.18005 0.549999 1.84005 0.549999C4.94005 0.539999 8.05005 0.529999 11.1501 0.549999C11.9901 0.549999 12.5401 1.05 12.7301 1.91C13.0601 3.34 13.3801 4.75999 13.6701 6.19999C13.7601 6.62999 13.9301 6.75999 14.3801 6.75999C26.6601 6.74999 38.9401 6.76 51.2201 6.74C52.1801 6.74 52.8901 7 53.3101 7.89C53.3101 8.17 53.3101 8.44 53.3101 8.72C52.8301 9.95 52.5401 11.24 52.1701 12.51C50.4801 18.38 48.8101 24.26 47.1201 30.14C46.8101 31.24 46.3301 31.59 45.18 31.59C36.0601 31.59 26.9301 31.59 17.8101 31.59C16.5701 31.59 15.8501 32.16 15.8501 33.14C15.8501 34.12 16.5701 34.69 17.8101 34.69C26.9201 34.69 36.0201 34.69 45.1301 34.69C46.3701 34.69 47.0901 35.26 47.0901 36.24C47.0901 37.22 46.3701 37.79 45.1301 37.79C44.4801 37.79 43.8201 37.79 43.0401 37.79H19.7501ZM32.08 9.85C26.41 9.85 20.7401 9.85999 15.0701 9.83999C14.5501 9.83999 14.51 9.98999 14.61 10.43C15.93 16.29 17.23 22.15 18.52 28.02C18.59 28.36 18.7101 28.49 19.0901 28.49C27.3601 28.48 35.6401 28.48 43.9101 28.49C44.2801 28.49 44.4001 28.37 44.5001 28.04C46.1701 22.15 47.86 16.26 49.55 10.37C49.68 9.92999 49.6001 9.84 49.1401 9.85C43.4601 9.86 37.77 9.85 32.08 9.85Z"
        fill="currentColor"
      />
      <path
        d="M22.0501 47.27C21.0001 47.27 20.0201 46.86 19.2601 46.11C18.5001 45.36 18.0901 44.37 18.0801 43.32C18.0701 41.14 19.8501 39.35 22.0301 39.35H22.0401C23.0901 39.35 24.0701 39.76 24.8301 40.51C25.5901 41.26 26.0001 42.25 26.0101 43.3C26.0101 44.35 25.6001 45.34 24.8501 46.1C24.1001 46.86 23.1101 47.27 22.0601 47.28C22.0601 47.27 22.0601 47.27 22.0501 47.27ZM22.0501 41.97C21.3201 41.97 20.7101 42.59 20.7101 43.32C20.7101 43.67 20.8501 44 21.1101 44.26C21.3701 44.52 21.7001 44.66 22.0501 44.66C22.4001 44.66 22.7301 44.52 22.9901 44.26C23.2501 44 23.3901 43.67 23.3901 43.32C23.3901 42.97 23.2501 42.64 22.9901 42.38C22.7301 42.11 22.4001 41.97 22.0501 41.97Z"
        fill="currentColor"
      />
      <path
        d="M38.46 47.27C37.41 47.27 36.4301 46.86 35.6701 46.11C34.9101 45.36 34.5 44.37 34.49 43.32C34.48 41.14 36.26 39.35 38.44 39.35H38.4501C39.5001 39.35 40.48 39.76 41.24 40.51C42 41.26 42.4101 42.25 42.4201 43.3C42.4201 44.35 42.0101 45.34 41.2601 46.1C40.5101 46.86 39.52 47.27 38.47 47.28C38.47 47.27 38.46 47.27 38.46 47.27ZM38.46 41.97C37.73 41.97 37.12 42.59 37.12 43.32C37.12 43.67 37.26 44 37.52 44.26C37.78 44.52 38.11 44.66 38.46 44.66C38.81 44.66 39.14 44.52 39.4 44.26C39.66 44 39.8 43.67 39.8 43.32C39.8 42.97 39.66 42.64 39.4 42.38C39.14 42.11 38.81 41.97 38.46 41.97Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ShoppingIcon;
