import {Workbook} from 'exceljs'
import { templates } from './templates'
/**
 * Download a template from the server
 * @param {string} name - The name of the template
 * @param {Array} data - The data to be inserted into the template
 * @returns {Promise} - The promise of the download
 */

export default async function downloadTemplate(name,data = []) {

  if(!templates[name]) throw new Error(`El template ${name} no existe`)
  
  const workbook = new Workbook()
  const { title,columns } = templates[name] 

  
  const worksheet = workbook.addWorksheet(title)
  worksheet.columns = columns
  data.forEach((row) => {
    worksheet.addRow(row)
  })
  
  const buffer = await  workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${name}.xlsx`
  a.click()
  window.URL.revokeObjectURL(url)
}


