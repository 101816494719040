import React, { useState } from "react";
import { Modal, Rate, Spin } from "antd";
import styles from "./index.module.css";
import closeIcon from "../../Assests/Modal-close-img.svg";
import { useQuery } from "@apollo/client";
import { COMMENTS } from "../../Graphql/Queries";

const ReviewList = ({ isVisible, onCancel, promoId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    error,
    loading,
    data: reviewsData,
  } = useQuery(COMMENTS, {
    variables: {
      promoId,
      input: {
        page: 1,
        limit: 2 * currentPage,
      },
    },
  });

  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      style={{ top: 50, maxWidth: "500px" }}
      width={"85%"}
      closeIcon={
        <img
          src={closeIcon}
          alt="close icon"
          style={{
            display: "none",
          }}
        />
      }
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Comentarios</h2>
        <button className={styles.headerCloseButton} onClick={() => onCancel()}>
          <img src={closeIcon} alt="close icon" />
        </button>
      </header>

      {loading && (
        <div className={styles.reviewEmpty}>
          <Spin />
        </div>
      )}

      {(!loading && reviewsData.comments?.data.length === 0) || error ? (
        <p className={styles.reviewEmpty}>
          Actualmente no hay comentarios sobre esta promoción
        </p>
      ) : null}

      {reviewsData &&
        reviewsData.comments?.data.length > 0 &&
        reviewsData.comments?.data.map((review) => (
          <section className={styles.review} key={review._id}>
            <header className={styles.reviewHeader}>
              <h3 className={styles.reviewHeaderTitle}>
                {review.user.firstName}
              </h3>
              <Rate
                className={styles.reviewHeaderStars}
                value={review.stars}
                disabled
              />
            </header>
            <p className={styles.reviewComment}>{review.body}</p>
          </section>
        ))}

      <footer className={styles.buttonContainer}>
        {reviewsData &&
          reviewsData.comments?.metadata.totalDocs >
            reviewsData.comments.data.length && (
            <button
              className={styles.paginationButton}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Ver más
            </button>
          )}
      </footer>
    </Modal>
  );
};

export default ReviewList;
