import React from "react";
import { ResponsivePie } from "@nivo/pie";
import InfoIcon from "../../../../Assets/info-icon.svg";
import styles from "../index.module.css";

const platformsMatcher = {
  mobile: "Mobile",
  desktop: "Web",
};

const PlatformsChart = ({ data }) => {
  const totalCount =
    data?.interactions?.platformInteractions?.reduce(
      (acc, item) => acc + item?.count,
      0
    ) || 0;

  const dataChart =
    data?.interactions?.platformInteractions?.map((item) => {
      return {
        id: platformsMatcher[item?.platform] || item?.platform,
        label: platformsMatcher[item?.platform] || item?.platform,
        value: item?.count / totalCount,
      };
    }) || [];

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3>Usuarios por plataforma</h3>
        <img src={InfoIcon} alt="" />
      </header>
      <div style={{ height: "80%" }}>
        <ResponsivePie
          data={dataChart}
          margin={{ top: 20, bottom: 60 }}
          valueFormat=" >-.1%"
          innerRadius={0.7}
          padAngle={1}
          activeOuterRadiusOffset={8}
          colors={["#E4056F", "#FCC02C"]}
          colorBy="index"
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 63,
              itemWidth: 70,
              itemHeight: 20,
              itemsSpacing: 0,
              symbolSize: 12,
              itemDirection: "left-to-right",
            },
          ]}
        />
      </div>
    </section>
  );
};

export default PlatformsChart;
