import React from "react";
import styles from "./index.module.css";
import { Carousel } from "antd";
import Wave from "../../../../Assets/rose-wave.png";

const Instructions = () => {
  return (
    <section className={styles.container}>
      <Carousel
        autoplay
        className={styles.carousel}
        effect="scroll"
        speed={1000}
      >
        <div className={styles.carouselItem}>
          <h3 className={styles.carouselItemTitle}> Paso 1</h3>
          <p className={styles.carouselItemDescription}>
            {" "}
            Es súper simple, nosotros nos encargamos de recopilar todas las
            promociones disponibles en nuestro portal
          </p>
        </div>
        <div className={styles.carouselItem}>
          <h3 className={styles.carouselItemTitle}> Paso 2</h3>
          <p className={styles.carouselItemDescription}>
            Selecciona tus socios para que podamos mostrarte y organizarte todas
            tus promociones disponibles en un solo lugar
          </p>
        </div>
        <div className={styles.carouselItem}>
          <h3 className={styles.carouselItemTitle}> Paso 3</h3>
          <p className={styles.carouselItemDescription}>
            ¡No te pediremos ingresar ningún dato personal ni privado!
          </p>
        </div>
        <div className={styles.carouselItem}>
          <h3 className={styles.carouselItemTitle}> Paso 4</h3>
          <p className={styles.carouselItemDescription}>
            Y listo! una vez seleccionado tus socios ya tendrás organizados tus
            beneficios!
          </p>
        </div>
      </Carousel>
      <img src={Wave} alt="" className={styles.wave} />
    </section>
  );
};

export default Instructions;
