import moment from "moment";
import styles from "./index.module.css";
import { DatePicker } from "antd";

const Header = ({
  endDate,
  startDate,
  getReports,
  setEndDate,
  setStartDate,
  isLoadingReports,
}) => {
  const { RangePicker } = DatePicker;

  return (
    <header className={styles.container}>
      <h2 className={styles.title}>Reportes</h2>

      <div className={styles.filtersContainer}>
        <div className={styles.datePickerContainer}>
          <label htmlFor="rangeDate">Rango de tiempo</label>
          <RangePicker
            className={styles.datePickerInput}
            name="rangeDate"
            picker="month"
            disabled={isLoadingReports}
            disabledDate={(current) =>
              current && current > moment().endOf("month")
            }
            format={"MMMM YYYY"}
            allowClear={false}
            defaultValue={[startDate, endDate]}
            value={[startDate, endDate]}
            onChange={(dates) => {
              setStartDate(dates[0]);
              setEndDate(moment(dates[1]).endOf("month"));
            }}
          />
        </div>
        <button disabled={isLoadingReports} onClick={() => getReports()}>
          Filtrar
        </button>
      </div>
    </header>
  );
};

export default Header;
