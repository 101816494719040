import gql from "graphql-tag";

const CREATE_COMPANY = gql`
mutation createCompany($input: CreateCompanyAndAdminInput!){
  createCompany(input: $input){
    _id
    name 
  }
}
`;

const TOGGLE_REPORTS_OF_COMPANY = gql`
mutation toggleReportsOfCompany($companyId: String!){
  toggleReportsOfCompany(companyId: $companyId){
    _id
  }
}
`;

const TOGGLE_VISIBLE_PROMOTIONS_OF_COMPANY = gql`
mutation toggleVisiblePromotionsOfCompany($companyId: String!){
  toggleVisiblePromotionsOfCompany(companyId: $companyId)
}
`;

const DELETE_COMPANY = gql`
mutation deleteCompany($input: FindCompany!){
  deleteCompany(input: $input){
    _id
  }
}
`;

export { CREATE_COMPANY, TOGGLE_REPORTS_OF_COMPANY, TOGGLE_VISIBLE_PROMOTIONS_OF_COMPANY, DELETE_COMPANY }