import React from "react";
import styles from "./index.module.css";
import { useHistory, Link } from "react-router-dom";
import LogoFooter from "./Assets/white-logo.svg";
import wave from "../../Assets/yellow-wave.png";

const Footer = () => {
  const history = useHistory();
  return (
    <footer className={styles.container}>
      <img src={wave} alt="wave" className={styles.wave} />
      <section className={styles.content}>
        <img
          className={styles.logo}
          src={LogoFooter}
          alt="Logo de LOIA"
          title="Logo de LOIA"
          onClick={() => history.push("/")}
        />
        <nav className={styles.nav}>
          <Link className={styles.navLink} to="/contact">
            Contactanos
          </Link>
          <Link className={styles.navLink} to="/">
            Terminos y condiciones
          </Link>
          <Link className={styles.navLink} to="/">
            Politicas de privacidad
          </Link>
        </nav>
      </section>
    </footer>
  );
};

export default Footer;
