import React, { useEffect } from "react";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { getUser } from "../../Helpers/auth";
import MainHeader from "../../Components/Header";
import RegisterHeader from "./Components/RegisterHeader";
import RegisterForm from "./Components/RegisterForm";

const CompanyRegister = () => {
  const history = useHistory();
  useEffect(() => getUser().role && history.push("/"), [history]);

  return (
    <>
      <MainHeader />
      <div className={styles.container}>
        <RegisterHeader />
        <RegisterForm />
      </div>
    </>
  );
};

export default withRouter(CompanyRegister);
