import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Spin, Select, Button, Result, Upload, notification } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import LogoDefault from './Assets/logo-default.png'
import EditIcon from './Assets/edit-icon.svg'
import SaveIcon from './Assets/save-icon.svg'
import CancelIcon from './Assets/close-icon.svg'
import { getUser } from '../../Helpers/auth'
import { COMPANY } from './Graphql/Queries'
import { UPDATE_COMPANY, UPDATE_COMPANY_ICON, UPDATE_COMPANY_LOGO } from './Graphql/Mutations'
import { CATEGORIES } from '../../Graphql/Queries'
import { REFRESH_TOKEN } from '../../Graphql/Mutations'
import styles from './index.module.css'

const { Option } = Select;

const MyCompanyScreen = () => {

    const {refetch: companyDataRefetch, data: companyData, loading: loadingCompany, error } = useQuery(COMPANY, {
        variables:{
          input: {
            _id: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected')),
          }
        }
      })
    const [refreshToken] = useMutation(REFRESH_TOKEN)
    const [updateCompany, { loading: loadingUpdateCompany }] = useMutation(UPDATE_COMPANY)
    const [updateCompanyLogo] = useMutation(UPDATE_COMPANY_LOGO)
    const [updateCompanyIcon] = useMutation(UPDATE_COMPANY_ICON)
    let company = companyData

    useEffect(() => {
        if(companyData === undefined) companyDataRefetch()
        else {
            setName(companyData ? companyData.company.name : '')
            setPhone(companyData ? companyData.company.phone : '')
            setCategory(companyData ? companyData.company.category._id : '')
            setLogo(companyData ? companyData.company.logo : '')
            setIcon(companyData ? companyData.company.icon : '')
        }
    }, [companyData, companyDataRefetch])

    const { loading, data: categories } = useQuery(CATEGORIES);
    const [name, setName] = useState(companyData ? companyData.company.name : '')
    const [category, setCategory] = useState(companyData ? companyData.company.category._id : '')
    const [phone, setPhone] = useState(companyData ? companyData.company.phone : '')
    const [logo, setLogo] = useState(companyData ? companyData.company.logo : '')
    const [logoFile, setLogoFile] = useState({})
    const [icon, setIcon] = useState(Boolean(companyData?.company?.icon) ? companyData?.company?.icon : '')
    const [iconFile, setIconFile] = useState({})
    const [isEditing, setIsEditing] = useState(false)

    const handleCancel = () => {
        setIsEditing(false)
        setName(company ? company.company.name : '')
        setPhone(company ? company.company.phone : '')
        setCategory(company ? company.company.category._id : '')
        setLogo(company ? company.company.logo : '')
    }
    

    const handleChangePhone = value => {
        if(value.match('^$|(?=.*[0-9 +])[ +()0-9]+$')) setPhone(value)
    }

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }

    const dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

      const handleChangeLogo = info => {
        if (info.file.status === 'uploading') {
          return;
        }
        if (info.file.status === 'done') {
          setLogoFile(info.file)
          getBase64(info.file.originFileObj, imageUrl => {
            setLogo(imageUrl)
          }
          );
        }
      };

      const handleChangeIcon = (info) => {
        if (info.file.status === "uploading") {
          return;
        }
        if (info.file.status === "done") {
          setIconFile(info.file);
          getBase64(info.file.originFileObj, (imageUrl) => {
            setIcon(imageUrl);
          });
        }
      };

      const handleUpdateCompany = () => {
        refreshToken().then(() => {
            if(!isEmpty(logoFile)) {
                updateCompanyLogo({
                    variables: {
                        companyLogo: logoFile.originFileObj,
                        companyId: company.company._id
                    }
                }).then(() => {
                    companyDataRefetch()
                })
                .catch(e => {
                    setLogo(companyData ? companyData.company.logo : '')
                    notification.error({
                        message: "Error",
                        description: "Hubo un error al intentar actualizar el logo de la empresa, inténtelo de nuevo.",
                        placement: "bottomRight",
                      });
                })
            } 
            if(!isEmpty(iconFile)) {
                updateCompanyIcon({
                    variables: {
                        companyIcon: iconFile.originFileObj,
                        companyId: company.company._id
                    }
                }).then(() => {
                    companyDataRefetch()
                }).catch(e => {
                    setIcon(companyData ? companyData.company.icon : '')
                    notification.error({
                        message: "Error",
                        description: "Hubo un error al intentar actualizar el logo corto, inténtelo de nuevo.",
                        placement: "bottomRight",
                        });
                })
            }

            updateCompany({
                variables:{
                    input: {
                        name: name,
                        phone: phone,
                        category: category
                    },
                    id: company.company._id
                }
            }).then(() => {
                setIsEditing(false)
                notification.success({
                    message: "Éxito",
                    description: "Se han guardado los cambios exitósamente",
                    placement: "bottomRight",
                  });
                  company.logo = logo
                companyDataRefetch()
            }).catch(e => {
                console.log(e)
            })
        }).catch(e => {
            console.log(e)
        })
        
    }


    if(loadingCompany && !companyData) return (
        <Row justify="center" style={{paddingTop: 350}}>
            <Spin size="large"/>
        </Row>
    )

    if(error || (JSON.parse(localStorage.getItem('companySelected') === 'all'))) return (
        <Result
          style={{marginTop: 140}}
          status="warning"
          title="Para poder ver esta sección selecciona alguna empresa"
        />
      )


    return (
        <section className={styles.container}>
            <h2 className={styles.title}>
                Mi empresa
                {
                    !isEditing ? (
                        <>
                        <Button className={styles.button} style={{width: '94px'}} onClick={() => setIsEditing(true)}>
                            <img src={EditIcon} alt="edit" />
                            <span>Editar</span>
                        </Button>
                        </>
                    ) : (
                        <div>
                        <Button className={styles.buttonCancel} onClick={() => handleCancel()}>
                            <img src={CancelIcon} alt="cancel" />
                            <span>Cancelar</span>
                        </Button>
                        <Button loading={loadingUpdateCompany} className={styles.button} onClick={() => handleUpdateCompany()}>
                            <img src={SaveIcon} alt="save" />
                            <span>Guardar</span>
                        </Button>
                        </div>
                    )
                }
                
            </h2>
            
            <h3 className={styles.subtitle}>
                Datos generales
            </h3>

            
            <Row justify='space-between'>
                <Col xs={{span: 24, order: 1}} sm={{span: 24, order: 1}} md={{span: 12, order: 1}} lg={{span: 6, order: 0}} className={styles.colCenter}>
                    <label>Logo corto</label>
                    <img className={styles.logo} src={icon ? icon : LogoDefault} alt="Icon" />
                    {
                        isEditing ? (
                            <>
                            <Upload
                                showUploadList={false}
                                customRequest={dummyRequest}
                                onChange={handleChangeIcon}
                            >
                                <button className={styles.buttonChangeLogo}>
                                    <span>Subir imagen</span>
                                </button>
                            </Upload>
                            
                            </>
                            
                        ) : null
                    }
                    
                </Col>
                <Col xs={{span: 24, order: 1}} sm={{span: 24, order: 1}} md={{span: 12, order: 1}} lg={{span: 6, order: 0}} className={styles.colCenter}>
                    <label>Logo de la empresa</label>
                    <img className={styles.logo} src={logo ? logo : LogoDefault} alt="logo" />
                    {
                        isEditing ? (
                            <>
                            <Upload
                                showUploadList={false}
                                customRequest={dummyRequest}
                                onChange={handleChangeLogo}
                            >
                                <button className={styles.buttonChangeLogo}>
                                    <span>Subir imagen</span>
                                </button>
                            </Upload>
                            
                            </>
                            
                        ) : null
                    }
                    
                </Col>
                <Col xs={0} sm={0} md={2} lg={0}/>
                <Col xs={24} sm={24} md={10} lg={6}>
                    <label>Nombre de la empresa</label>
                    <Input
                        className={`${styles.dataContainerItemInput} ${
                        isEditing ? styles.dataContainerItemInputEditing : ""
                        }`}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        disabled={!isEditing}
                    />
                    <div style={{marginBottom: 20}} />
                    <label className={styles.formItemLabel} htmlFor="companyCategory">
                    Rubro de la empresa
                    </label>
                    <Select
                    className={`${styles.formItemInputSelect} ${
                        isEditing ? styles.formItemInputSelectEditing : ""
                    }`}
                    name="companyCategory"
                    placeholder="Rubro de la empresa..."
                    onChange={(e) => setCategory(e)}
                    defaultValue={category}
                    value={category}
                    disabled={!isEditing}
                    >
                    <Option key={"none"} value={""} style={{ color: "#ccc" }} disabled>
                        <span style={{ color: "#ccc" }}>Seleccione un rubro</span>
                    </Option>
                    ;
                    {!loading &&
                        categories?.categories?.length > 0 &&
                        categories?.categories?.map((category) => {
                        return (
                            <Option key={category._id} value={category._id}>
                            {category.name}
                            </Option>
                        );
                        })}
                    </Select>
                    <div style={{marginBottom: 20}} />
                </Col>
                <Col xs={24} sm={24} md={10} lg={6}>
                    <label>Número de teléfono</label>
                    <Input
                        className={`${styles.dataContainerItemInput} ${
                        isEditing ? styles.dataContainerItemInputEditing : ""
                        }`}
                        onChange={(e) => handleChangePhone(e.target.value)}
                        value={phone}
                        disabled={!isEditing}
                    />
                    <div style={{marginBottom: 20}} />
                </Col>
                <Col xs={0} sm={0} md={2} lg={0}/>
            </Row>
           
        </section>
    )
}

export default MyCompanyScreen