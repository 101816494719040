import styles from "./index.module.css";
import InteractionsChart from "./Components/InteractionsChart";
import PlatformsChart from "./Components/PlatformsChart";

const InteractionReports = ({ reportsData = {} }) => {
  if (!reportsData) return null;

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Interacción de los usuarios</h2>

      <div className={styles.stats}>
        <InteractionsChart data={reportsData} />
        <PlatformsChart data={reportsData} />
      </div>
    </section>
  );
};

export default InteractionReports;
