import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { withRouter } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import "./index.css";
import { Button, Col, Spin, Result, Row } from "antd";
import { useLocation } from "react-router-dom";
import ModalPromotion from "../../../../Components/ModalPromotion";
import { useQuery, useMutation } from "@apollo/client";
import { PAGINATED_PROMOTIONS, PROMOTION } from "../../Graphql/Queries";
import { NEW_VIEW } from "../../../../Graphql/Mutations";
import PromotionsFilter from "../../../../Components/PromotionsFilter";
import useFilters from "../../../../Hooks/useFilters";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import { getUser, setAccessToken } from "../../../../Helpers/auth";
import PromotionCard from "../../../../Components/PromotionCard";
import Benefits from "./assets/benefits_icon.svg";
import NoPromotions from "../../../../Components/NoPromotions";
import ReactGA from "react-ga4";

const Promotions = () => {
  const [currentPromotion, setCurrentPromotion] = useState({});
  const [isPromotionModalVisible, setIsPromotionModalVisible] = useState(false);
  const { filters, setFilters, getActiveFilters } = useFilters();
  const [isLoadingPromotions, setIsLoadingPromotions] = useState(true);

  const [error, setError] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePromotions, setHasMorePromotions] = useState(true);

  const [showStickyFilters, setShowStickyFilters] = useState(false);
  const showNoPromotions = !isLoadingPromotions && promotions?.length === 0;

  const [newViewPromotion] = useMutation(NEW_VIEW);
  const [refreshToken] = useMutation(REFRESH_TOKEN);
  const { refetch: getPaginatedPromotions } = useQuery(PAGINATED_PROMOTIONS, {
    skip: true,
  });
  const { refetch: getPromotion } = useQuery(PROMOTION, {
    skip: true,
  });

  const { search } = useLocation();

  const loadInitialPromotions = () => {
    const isUserLogged = getUser()?.role === "User";
    if (isUserLogged) {
      setFilters({ ...filters, includeAlliedPartners: true });
      getMorePromotions({
        page: 1,
        customFilters: { includeAlliedPartners: true },
      });
    } else {
      getInitialPromotions({
        customFilters: { includeAlliedPartners: false },
      });
    }
  };

  //to check when header element get's position sticky
  const headerElementObserver = new IntersectionObserver(
    function (entries) {
      // no intersection
      if (entries?.[0]?.intersectionRatio === 0)
        document
          ?.querySelector("#header-container")
          ?.classList.add("stickyHeader");
      // fully intersects
      else if (entries?.[0]?.intersectionRatio === 1)
        document
          ?.querySelector("#header-container")
          ?.classList.remove("stickyHeader");
    },
    { threshold: [0, 1] }
  );
  const headerElement = document?.querySelector("#header-container-top");

  if (headerElement) {
    headerElementObserver.observe(headerElement);
  }

  const getInitialPromotions = async ({ customFilters = {} }) => {
    setIsLoadingPromotions(true);
    setCurrentPage(1);
    const activeFilters = getActiveFilters(filters) || {};
    const commonFilters = { limit: 15, page: 1 };
    const filter = { ...commonFilters, ...activeFilters, ...customFilters };

    try {
      const promotionsData = await getPaginatedPromotions({ filter });
      setHasMorePromotions(
        promotionsData?.data?.paginatedPromotions?.metadata?.totalPages >
          currentPage
      );
      setPromotions(promotionsData?.data?.paginatedPromotions?.data || []);
    } catch (e) {
      setPromotions([]);
      setError(true);
    } finally {
      setIsLoadingPromotions(false);
    }
  };

  const getMorePromotions = async ({ page = 1, customFilters = {} }) => {
    setIsLoadingPromotions(true);
    const activeFilters = getActiveFilters(filters) || {};
    const commonFilters = { limit: 15, page };
    const filter = { ...commonFilters, ...activeFilters, ...customFilters };
    try {
      const promotionsData = await getPaginatedPromotions({ filter });
      setHasMorePromotions(
        promotionsData?.data?.paginatedPromotions?.metadata?.totalPages > page
      );
      const prevPromotions = promotions || [];
      setPromotions(
        prevPromotions.concat(
          promotionsData?.data?.paginatedPromotions?.data || []
        )
      );
    } catch (e) {
      setPromotions([]);
      setError(true);
    } finally {
      setIsLoadingPromotions(false);
    }
  };

  const showPromotionDetailsModal = (promotion) => {
    setIsPromotionModalVisible(true);
    setCurrentPromotion(promotion);
  };

  useEffect(() => {
    refreshToken().then((res) => {
      setAccessToken(res?.data?.refreshToken?.token);
    });
    loadInitialPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const promotion = new URLSearchParams(search).get("promotion");
    if (!isLoadingPromotions && promotion) {
      getPromotion({
        input: { _id: promotion },
      })?.then((res) => {
        if (res?.data?.promotion) {
          showPromotionDetailsModal(res?.data?.promotion);
          ReactGA.event({
            category: "promotions",
            action: "view_promotion",
            label: "view_promotion",
          });
          newViewPromotion({
            variables: {
              input: {
                modelName: "promotions",
                eventType: "view",
                ref: promotion,
              },
            },
          });
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPromotions]);

  if (error && !isLoadingPromotions) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Lo sentimos, hubo un error al intentar cargar la información."
        extra={
          <Button type="primary">
            <a href="/"> Volver a intentarlo </a>
          </Button>
        }
      />
    );
  }
  return (
    <Row justify="center" className={styles.container}>
      <ModalPromotion
        promotion={currentPromotion}
        visible={isPromotionModalVisible}
        onCancel={() => setIsPromotionModalVisible(false)}
      />

      {showStickyFilters && <div className={styles.overlay} />}

      <Col
        xs={22}
        md={20}
        lg={18}
        xl={20}
        className={styles.promotionsContainer}
      >
        <div id="header-container-top" />
        <header
          className={`${styles.headingContainer} ${
            showStickyFilters ? styles.headingContainerWithFiltersVisible : {}
          } `}
          id="header-container"
        >
          <img src={Benefits} alt="Benefits icon" />
          <h2>Beneficios</h2>
          <div className={styles.promotionsFiltersSticky}>
            <PromotionsFilter
              filters={filters}
              setFilters={setFilters}
              getActiveFilters={getActiveFilters}
              showStickyFilters={showStickyFilters}
              setShowStickyFilters={setShowStickyFilters}
              getFilteredPromotions={getInitialPromotions}
              showShortFiltersList={Boolean(
                promotions?.length < 12 ||
                  promotions?.length === 0 ||
                  !promotions
              )}
            />
          </div>
        </header>

        <section className={styles.promotions}>
          {showNoPromotions ? (
            <NoPromotions className={styles.noPromotions} />
          ) : (
            <InfiniteScroll
              next={() => {
                setCurrentPage(currentPage + 1);
                getMorePromotions({ page: currentPage + 1 });
              }}
              dataLength={promotions?.length || 0}
              hasMore={hasMorePromotions}
              style={{ overflow: "hidden", width: "100%" }}
              loader={
                <Row justify="center">
                  <Spin className="loadingSpinner" size="large" />
                </Row>
              }
            >
              <div className={styles.promotionsGrid}>
                {promotions?.map((item, index) => (
                  <PromotionCard
                    pro={item}
                    key={index}
                    showModal={showPromotionDetailsModal}
                    newViewPromotion={newViewPromotion}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
          {showStickyFilters && (
            <div className={styles.promotionsFiltersGridBox}>
              <PromotionsFilter showStickyFilters={false} />
            </div>
          )}
        </section>
      </Col>
    </Row>
  );
};

export default withRouter(Promotions);
