import React from "react";
import styles from "./index.module.css";
import { Empty } from "antd";

const NoPromotions = ({className}) => {
  return (
    <Empty
      className={`${styles.container} ${className}`}
      description={
        <span className={styles.message}>No se encontraron promociones.</span>
      }
    />
  );
};

export default NoPromotions;
