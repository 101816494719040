import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { notification, Spin } from "antd";
import { withRouter } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { LOGIN } from "./Graphql/Mutations";
import { setAccessToken, getAccessToken } from "../../Helpers/auth";
import { REFRESH_TOKEN } from "../../Graphql/Mutations";
import { PROFILE } from "../../Graphql/Queries";
import MainHeader from "../../Components/Header";
import LoginForm from "./Components/LoginForm";
import LoginHeader from "./Components/LoginHeader";

const LoginScreen = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login] = useMutation(LOGIN);
  const [loading, setLoading] = useState(true);
  const [refreshToken] = useMutation(REFRESH_TOKEN);
  const { refetch: profileRefetch } = useQuery(PROFILE, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    refreshToken()
      .then((res) => {
        if (getAccessToken() !== "") {
          profileRefetch()
            .then((profile) => {
              if (profile.data.profile.role === "User")
                history.push("/userHome");
              else history.push("/companyHome");
            })
            .catch((e) => {
              setLoading(false);
            });
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [refreshToken, history, profileRefetch]);

  const formValidation = () => {
    if (email.trim() === "" || password.trim() === "") {
      notification.error({
        message: "Error",
        description: "Debe completar todos los campos",
        placement: "bottomRight",
      });
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    try {
      await login({
        variables: {
          input: {
            email: email,
            password: password,
          },
        },
      }).then((res) => {
        setAccessToken(res.data.login.token);
        if (res.data.login.user.role === "SuperAdmin")
          localStorage.setItem("companySelected", JSON.stringify("all"));

        if (res.data.login.user.role === "User") history.push("/userHome");
        else history.push("/companyHome");
      });
    } catch (e) {
      if(e.message.includes("You must verify your account first"))
        notification.error({
          message: `Error`,
          description:
            "¡Tu cuenta aún no ha sido verificada, por favor revisa tu correo que se te fue enviado al momento del registro y verifica tu cuenta!",
          placement: "bottomRight",
        });

      else if(e.message.includes("Invalid credentials"))
        notification.error({
          message: `Error`,
          description:
            "Usuario y/o contraseña incorrectos, por favor reinténtalo",
          placement: "bottomRight",
        });

      else if(e.message.includes("Your account is blocked"))
        notification.error({
          message: `Error`,
          description:
            "Tu cuenta ha sido bloqueada debido a tantos intentos fallidos de inicio de sesión. Te enviaremos un correo electrónico para que puedas reactivar tu cuenta con una nueva contraseña.",
          placement: "bottomRight",
        });

      else 
        notification.error({
          message: `Error`,
          description:
            "Hubo un error al iniciar sesión, por favor reinténtalo más tarde",
          placement: "bottomRight",
        });
    }
  };

  if (loading) return <Spin />;

  return (
    <>
      <MainHeader />
      <div className={styles.container}>
        <LoginHeader />
        <LoginForm
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          handleLogin={handleLogin}
          formValidation={formValidation}
        />
      </div>
    </>
  );
};

export default withRouter(LoginScreen);
