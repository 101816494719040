import React from "react";
import styles from "../index.module.css";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

const InteractionsChart = ({ data }) => {
  if (!data) return null;

  const avgInteractionTime =
    data?.interactions?.averageInteractionTimes?.reduce(
      (acc, item) => acc + item?.time,
      0
    ) / data?.interactions?.averageInteractionTimes?.length || 0;

  const parseSecondsToRoundedMinutes = (seconds = 0) => {
    const minutes = Math.round(seconds / 60);
    return `${minutes} ${minutes === 1 ? "minuto" : "minutos"}`;
  };

  const dataChart = [
    {
      id: "Activo",
      color: "#E4056F",
      data:
        data?.interactions?.averageInteractionTimes?.map((item) => {
          return {
            x: moment(item?.date)?.format("DD/MM"),
            y: parseSecondsToRoundedMinutes(item?.time),
          };
        }) || [],
    },
  ];

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3>Tiempo promedio de interacción:</h3>
        <strong>{parseSecondsToRoundedMinutes(avgInteractionTime)}</strong>
      </header>
      <div style={{ height: "80%", width: "100%" }}>
        <ResponsiveLine
          data={dataChart}
          margin={{
            top: 10,
            right: 30,
            bottom: 40,
            left: 65,
          }}
          colors={["#E4056F"]}
          xScale={{
            type: "point",
          }}
          yScale={{
            type: "linear",
            stacked: true,
            min: "auto",
            max: "auto",
          }}
          stacked={true}
          curve="monotoneX"
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Minutos",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          dotSize={10}
          dotColor="inherit:darker(0.3)"
          dotBorderWidth={2}
          dotBorderColor="#ffffff"
          enableDotLabel={true}
          dotLabel="y"
          dotLabelYOffset={-12}
          animate={true}
          isInteractive={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </section>
  );
};

export default InteractionsChart;
