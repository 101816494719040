import gql from "graphql-tag";

const RESTORE_PASSWORD = gql`
mutation restorePassword($token: String!, $input: RestorePasswordInput!){
    restorePassword(token: $token, input: $input){
        _id
    }
  }
`;

export { RESTORE_PASSWORD }