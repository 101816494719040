import React, { useState } from 'react'
import { Form, notification } from "antd";
import FormHeader from './Components/FormHeader';
import FormContent from './Components/FormContent';
import DetailBoxForm from './Components/DetailBoxForm';
import LifeBoxForm from './Components/LifeBoxForm';
import CategoryBoxForm from './Components/CategoryBoxForm';
import ConditionsBoxForm from './Components/ConditionsBoxForm';
import { getUser } from '../../../../Helpers/auth'
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PROMOTION, UPDATE_PROMOTION } from '../../Graphql/Mutation';
import { SEGMENTS } from '../../Graphql/Queries';
import { REFRESH_TOKEN } from '../../../../Graphql/Mutations'
import { setAccessToken } from '../../../../Helpers/auth';
import PreviewPromotionModal from './Components/PreviewPromotionModal';
import * as dayjs from 'dayjs'
import { checkFormLabel } from '../../../../Helpers/promotionForm';
import moment from 'moment';
import "dayjs/locale/es"
import "./index.css";
// import { geocodeByAddress, getLatLng } from 'react-places-autsocomplete'
dayjs.locale("es");

const PromotionForm = ({
    page,
    setPage,
    promotion,
    promotionsByCompanyQuery
}) => {
    let _id = promotion && promotion.code ? promotion._id : ""
    let code = promotion && promotion.code ? promotion.code : ""
    const [promotionName, setPromotionName] = useState(promotion && promotion.name ? promotion.name : "");
    const [localName, setLocalName] = useState(promotion && promotion.localName ? promotion.localName : "")
    const [discount, setDiscount] = useState(promotion && promotion.discount ? promotion.discount : 1)
    const [description, setDescription] = useState(promotion && promotion.description ? promotion.description : "")
    const [promotionImage, setPromotionImage] = useState(promotion && promotion.image ? promotion.image : "")
    const [loadingPromotionImage, setLoadingPromotionImage] = useState(false)
    const [localImageUrl, setLocalImageUrl] = useState(promotion && promotion.localLogo ? promotion.localLogo : "")
    const [loadingLocalImage, setLoadingLocalImage] = useState(false)
    const [isPublic, setIsPublic] = useState(promotion && promotion.visibility === 'private' ? false : true)
    const [segment, setSegment] = useState(promotion && promotion.segments ? promotion.segments : [])
    const [status, setStatus] = useState(promotion.status && promotion.status ? promotion.status.toLowerCase() : "");
    const [visible, setVisible] = useState(promotion && promotion.visible !== undefined ? promotion.visible === true ? "visible" : "notVisible" : "visible");
    const [benefitURL, setBenefitURL] = useState(promotion && promotion.benefitURL ? promotion.benefitURL : "")
    const [weekDays, setWeekDays] = useState(promotion && promotion.weekDays ? promotion.weekDays : []);
    const [startDate, setStartDate] = useState(promotion && promotion.startDate ? moment(promotion.startDate) : '')
    const [dueDate, setDueDate] = useState(promotion && promotion.dueDate ? moment(promotion.dueDate) : '')
    const [type, setType] = useState(promotion && promotion.type ? promotion.type.name : "")
    const [communes, setCommunes] = useState(promotion && promotion.communes ? promotion.communes : [])
    const [termsAndConditions, setTermsAndConditions] = useState(promotion && promotion.termAndConditions ? promotion.termAndConditions : [])
    const [howUse, setHowUse] = useState(promotion && promotion.conditions ? promotion.conditions : "")
    const [modalVisible, setModalVisible] = useState(false)
    const [promotionImageFile, setPromotionImageFile] = useState({})
    const [localImageFile, setLocalImageFile] = useState({})
    let createdBy = promotion && promotion.createdBy ? promotion.createdBy.email : ""
    let createdAt = promotion && promotion.createdAt ? promotion.createdAt : ""
    let weekDaysModel = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

    const { loading: loadingSegments, data: segments } = useQuery(SEGMENTS, {
        variables: {
            companyId: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected'))
        }
    })
    const getAllFormCreatePromotion = () => {
        let promotionInput = {
            name: promotionName,
            description: description?.toString() || "",
            type: type,
            conditions: howUse?.toString() || "",
            company: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected')),
            localName: localName,
            segments: checkIsPublicSegment(),
            visibility: isPublic ? 'public' : 'private',
            startDate: startDate,
            dueDate: dueDate,
            weekDays: weekDays,
            benefitURL: benefitURL,
            termAndConditions: termsAndConditions?.toString() || "",
            status: status,
            discount: parseInt(discount, 0),
            communes,
            // locations: listAddress,
            visible: visible === 'visible' ? true : false
        }
        return promotionInput
    }

    const checkIsPublicSegment = () => {
        if(segment === '' && segments && !isPublic) {
            return segments.segments[0]._id
        }else{
            if(isPublic){
                return null
            }else{
                return segment
            }
        }
    }

    const [createPromotionMutation, {loading: loadingCreatePromotion}] = useMutation(CREATE_PROMOTION, {
        variables:{
            input: getAllFormCreatePromotion(),
            localImage: localImageFile.originFileObj,
            promoImage: promotionImageFile.originFileObj
        }
    })

    const [updatePromotionMutation, {loading: loadingUpdatePromotion}] = useMutation(UPDATE_PROMOTION, {
        variables:{
            id: {
                _id: _id
            },
            input: getAllFormCreatePromotion(),
            localImage: localImageFile ? localImageFile.originFileObj : null,
            promoImage: promotionImageFile ? promotionImageFile.originFileObj: null
        }
    })

    const getAllForm = () => {
        let promotionForm = {...getAllFormCreatePromotion(), image: promotionImage, localLogo: localImageUrl, company: promotion.company}
        return promotionForm
    }

    const handleStatus = (newStatus) => {
        if (status === newStatus) setStatus("");
        else setStatus(newStatus);
    };

    const handleVisible = (isVisible) => {
        if (visible === isVisible) setVisible("");
        else setVisible(isVisible);
    };

    const handleWeekDays = (day) => {
        let array = [...weekDays]
        let isSame = false

        for(let [index, arr] of array.entries()){
        if(arr === day) {
            array.splice(index, 1)
            isSame = true
        }
        }
        if(!isSame) array.push(day)
        
        setWeekDays(fixOrderWeekDays(array))
    };

    const fixOrderWeekDays = weeks => {
        let newArrayWeeks = []
        for(let day of weekDaysModel){
            for(let selectedDay of weeks){
                if(day === selectedDay) {
                    newArrayWeeks.push(selectedDay)
                }
            }
            
        }
        return newArrayWeeks
    }

    const handleCheckAllDays = bool => {
        if (bool) {
        setWeekDays(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])
        } else setWeekDays([])
    }

    const handleStartDate = date => {
        setStartDate(date)
        setDueDate(null)
    }
    
    const handleDueDate = date => {
        setDueDate(date)
    }

    const handleType = type => {
        if (status === type) setStatus("");
        else setType(type);
    }

    const disabledDate = current => {
        return current && current < dayjs(startDate).subtract(1, 'day').endOf('days')
    }

    const handleSaveForm = () => {
        if(page === 'create'){
            createPromotion()
        }else if(page === 'edit'){
            updatePromotion()
        }  
    }

    const [refreshToken] = useMutation(REFRESH_TOKEN)

    const createPromotion = async () => {
        let err = checkForm()
            if(err !== '') {
                notification.error({
                    message: `Error`,
                    description:
                      `Falta completar el siguiente campo para poder crear la promoción: ${checkFormLabel(err)}`,
                    placement: "bottomRight",
                  });
            }
            else 
            refreshToken().then(res => {
                setAccessToken(res.data.refreshToken.token)
                createPromotionMutation().then(() => {
                    notification.success({
                        message: `Éxito`,
                        description:
                          'La promoción ha sido creado exitósamente',
                        placement: "bottomRight",
                      });
                    setPage('list')
                    promotionsByCompanyQuery()
                }).catch(e => {
                    notification.error({
                        message: `Error`,
                        description:
                          'Hubo un error al intentar crear la promoción, por favor revisa todos los campos e inténtalo de nuevo.',
                        placement: "bottomRight",
                      });
                })
            })
    }

    const updatePromotion = async () => {
        let err = checkForm()
            if(err !== '') {
                notification.error({
                    message: `Error`,
                    description:
                      `Falta completar el siguiente campo para poder editar la promoción: ${checkFormLabel(err)}`,
                    placement: "bottomRight",
                  });
            }
            else 
            refreshToken().then(res => {
                setAccessToken(res.data.refreshToken.token)
                updatePromotionMutation().then(() => {
                notification.success({
                    message: `Éxito`,
                    description:
                      'La promoción ha sido editada exitósamente',
                    placement: "bottomRight",
                  });
                setPage('list')
                promotionsByCompanyQuery()
            }).catch(e => {
                notification.error({
                    message: `Error`,
                    description:
                      'Hubo un error al intentar editar la promoción, por favor revisa todos los campos e inténtalo de nuevo.',
                    placement: "bottomRight",
                  });
            })
        })
    }

    const checkForm = () => {
        let formInputs = (({name, description, localName, status, benefitURL, weekDays, startDate, dueDate, type, termAndConditions, conditions, communes}) =>({
            name, 
            localName, 
            description,
            status, 
            benefitURL, 
            weekDays, 
            startDate, 
            dueDate, 
            type, 
            termAndConditions,
            conditions,
            communes
        }))(getAllFormCreatePromotion())

        if(page === 'create') formInputs = {...formInputs, promotionImageFile, localImageFile}

        let err = ''
        for( const [key, value] of Object.entries(formInputs)) {
            if(value === '' || value === [] || value === null) {
                err = key
                break
            }else if(value !== null){
                if(Object.keys(value).length === 0){
                    err = key
                    break
                }
            }
        }
        return err

    }

    const handleOpenPreview = () => {
        let err = checkForm()
        if(err === '') setModalVisible(true)
        else notification.error({
            message: `Error`,
            description:
              `Falta completar el siguiente campo para poder ver la previsualización: ${checkFormLabel(err)}`,
            placement: "bottomRight",
          });
    }

    const onCancelModal = () => {
        setModalVisible(false)
    }

    const checkMaxCharsInput = (setVariable, value, max) => {
        if(value.length <= max) {
            setVariable(value)
        }
    }


    return (
      <>
        <PreviewPromotionModal
          visible={modalVisible}
          promotion={getAllForm()}
          onCancel={onCancelModal}
        />

        <FormHeader
          promoVisible={visible}
          handleVisible={handleVisible}
          handleSaveForm={handleSaveForm}
          handleOpenPreview={handleOpenPreview}
          loadingCreatePromotion={loadingCreatePromotion}
          loadingUpdatePromotion={loadingUpdatePromotion}
          page={page}
        />

        <Form>
          <FormContent title="Detalle">
            <DetailBoxForm
              code={code}
              promotionName={promotionName}
              setPromotionName={setPromotionName}
              localName={localName}
              setLocalName={setLocalName}
              discount={discount}
              setDiscount={setDiscount}
              description={description}
              setDescription={setDescription}
              status={status}
              handleStatus={handleStatus}
              visible={visible}
              handleVisible={handleVisible}
              promotionImage={promotionImage}
              setPromotionImage={setPromotionImage}
              loadingPromotionImage={loadingPromotionImage}
              setLoadingPromotionImage={setLoadingPromotionImage}
              localImageUrl={localImageUrl}
              setLocalImageUrl={setLocalImageUrl}
              loadingLocalImage={loadingLocalImage}
              setLoadingLocalImage={setLoadingLocalImage}
              benefitURL={benefitURL}
              setBenefitURL={setBenefitURL}
              setPromotionImageFile={setPromotionImageFile}
              setLocalImageFile={setLocalImageFile}
              page={page}
              createdBy={createdBy}
              createdAt={createdAt}
              isPublic={isPublic}
              setIsPublic={setIsPublic}
              segments={segments}
              segment={segment}
              setSegment={setSegment}
              loadingSegments={loadingSegments}
              checkMaxCharsInput={checkMaxCharsInput}
            />
          </FormContent>

          <FormContent title="Vigencia">
            <LifeBoxForm
              weekDays={weekDays}
              handleWeekDays={handleWeekDays}
              handleCheckAllDays={handleCheckAllDays}
              startDate={startDate}
              handleStartDate={handleStartDate}
              dueDate={dueDate}
              handleDueDate={handleDueDate}
              disabledDate={disabledDate}
            />
          </FormContent>

          <FormContent title="Categorización">
            <CategoryBoxForm
              // address={address}
              // handleChange={handleChange}
              // handleSelect={handleSelect}
              // listAddress={listAddress}
              // removeAddress={removeAddress}
              communes={communes}
              setCommunes={setCommunes}
              type={type}
              handleType={handleType}
            />
          </FormContent>

          <FormContent title="Condiciones">
            <ConditionsBoxForm
              termsAndConditions={termsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
              howUse={howUse}
              setHowUse={setHowUse}
              handleSaveForm={handleSaveForm}
              loadingCreatePromotion={loadingCreatePromotion}
              loadingUpdatePromotion={loadingUpdatePromotion}
              page={page}
              checkMaxCharsInput={checkMaxCharsInput}
            />
          </FormContent>
        </Form>
      </>
    );
};

export default PromotionForm