import React from "react";
import PromotionsTableReviews from "./Components/PromotionsTableReviews";
import Header from "./Components/Header";

const CompanyReviewsScreen = () => {
  return (
    <>
      <Header />
      <PromotionsTableReviews />
    </>
  );
};

export default CompanyReviewsScreen;
