const regex = {
  firstName: /^[a-zA-ZÀ-ÿ\s]{2,40}$/, // Letras y espacios, pueden llevar acentos, máximo 40 caracteres.

  lastName: /^[a-zA-ZÀ-ÿ\s]{2,40}$/, // Letras y espacios, pueden llevar acentos, máximo 40 caracteres.

  email:
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,

  password: /^.{6,12}$/, // 6 a 12 digitos.

  strictPassword:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$/,
  // 6 - 12 caracteres, 1 letra minúscula, 1 letra mayúscula, 1 número y 1 caracter especial.
};

export default regex;
