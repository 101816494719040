import React from "react";
import styles from "./index.module.css";
import { Form, Select, TreeSelect  } from "antd";
// import GoogleAutocomplete from "../GoogleAutocomplete";
import shoppingIcon from "../../../../../../Assets/rose-icons/carrito-icon.svg";
import flavorIcon from "../../../../../../Assets/rose-icons/sabor-icon.svg";
import decorationIcon from "../../../../../../Assets/rose-icons/decoracion-icon.svg";
import healthIcon from "../../../../../../Assets/rose-icons/health-icon.svg";
import travelIcon from "../../../../../../Assets/rose-icons/travel-icon.svg";
import otherIcon from "../../../../../../Assets/rose-icons/others-icon.svg";
import regionsAndCommunes from "../../../../../../Helpers/regionsAndCommunes.json"

const { Option } = Select;

const { TreeNode } = TreeSelect;
const CategoryBoxForm = ({
    // address,
    // handleChange,
    // handleSelect,
    // listAddress,
    // removeAddress,
    communes,
    setCommunes,
    type,
    handleType,
}) => {
    return (
      <div className={styles.formContainer}>
        {/* <GoogleAutocomplete 
            address={address} 
            handleChange={handleChange} 
            handleSelect={handleSelect} 
            listAddress={listAddress} 
            removeAddress={removeAddress}
        /> */}

        <Form.Item
          label="Ubicación"
          className={`${styles.formItem} ${styles.formItemCommunes}`}
        >
          <TreeSelect
            showSearch
            multiple
            className={styles.formItemInputCommunes}
            treeDefaultExpandAll
            showArrow={false}
            value={communes}
            onChange={(e) => setCommunes(e)}
          >
            {regionsAndCommunes.regions.map((region) => {
              return (
                <TreeNode
                  value={region.name}
                  title={region.name}
                  key={region.number}
                >
                  {region.communes.map((commune) => {
                    return (
                      <TreeNode
                        value={commune.name}
                        title={commune.name}
                        key={region.identifier}
                      />
                    );
                  })}
                </TreeNode>
              );
            })}
          </TreeSelect>
        </Form.Item>

        <Form.Item
          className={`${styles.formItem} ${styles.formItemCategory}`}
          label="Categoría"
        >
          <Select
            className={styles.categorySelect}
            onChange={(e) => handleType(e)}
            defaultValue={type}
          >
            <Option disabled value="">
              Seleccione una categoria
            </Option>
            <Option className={styles.categoryOption} value="shopping">
              <div>
                <img
                  src={shoppingIcon}
                  alt="shopping"
                  className={styles.categoryOptionIcon}
                />
                <span className={styles.categoryOptionText}>Shopping</span>
              </div>
            </Option>
            <Option className={styles.categoryOption} value="flavor">
              <img
                src={flavorIcon}
                alt=""
                className={styles.categoryOptionIcon}
              />
              <span className={styles.categoryOptionText}>Sabores</span>
            </Option>
            <Option className={styles.categoryOption} value="decoration">
              <img
                src={decorationIcon}
                alt=""
                className={styles.categoryOptionIcon}
              />
              <span className={styles.categoryOptionText}>Decoración</span>
            </Option>
            <Option className={styles.categoryOption} value="health">
              <img
                src={healthIcon}
                alt=""
                className={styles.categoryOptionIcon}
              />
              <span className={styles.categoryOptionText}>Salud y belleza</span>
            </Option>
            <Option className={styles.categoryOption} value="travel">
              <img
                src={travelIcon}
                alt=""
                className={styles.categoryOptionIcon}
              />
              <span className={styles.categoryOptionText}>Panoramas</span>
            </Option>
            <Option className={styles.categoryOption} value="other">
              <img
                src={otherIcon}
                alt=""
                className={styles.categoryOptionIcon}
              />
              <span className={styles.categoryOptionText}>Otros</span>
            </Option>
          </Select>
          {/* <Row className="row-Segments">
                <Col xs={8} sm={7} md={7} lg={4} xl={4} 
                    className="padding-col-CreatePromotions segments-text-CreatePromotions"
                    onClick={() => handleType('shopping')}
                    style={{opacity: type === 'shopping' ? 1 : 0.25, cursor: 'pointer'}}>
                    <img
                    className="col-img-CreatePromotions background-yellow"
                    src={Car}
                    alt="car"
                    />
                    <p style={{ marginTop: "5px" }}>Shopping</p>
                </Col>
                <Col xs={8} sm={7} md={7} lg={4} xl={4}
                    className="padding-col-CreatePromotions segments-text-CreatePromotions"
                    onClick={() => handleType('flavor')}
                    style={{opacity: type === 'flavor' ? 1 : 0.25, cursor: 'pointer'}}>
                    <img
                    className="col-img-CreatePromotions background-pink"
                    src={Meal}
                    alt="meal"
                    />
                    <p style={{ marginTop: "5px" }}>Sabores</p>
                </Col>
                <Col xs={8} sm={7} md={7} lg={4} xl={4} 
                    className="padding-col-CreatePromotions segments-text-CreatePromotions"
                    onClick={() => handleType('decoration')}
                    style={{opacity: type === 'decoration' ? 1 : 0.25, cursor: 'pointer'}}>
                    <img
                    className="col-img-CreatePromotions background-yellow"
                    src={Lamp}
                    alt="lamp"
                    />
                    <p style={{ marginTop: "5px" }}>Decoración</p>
                </Col>
                <Col xs={8} sm={7} md={7} lg={4} xl={4} 
                    className="padding-col-CreatePromotions segments-text-CreatePromotions"
                    onClick={() => handleType('health')}
                    style={{opacity: type === 'health' ? 1 : 0.25, cursor: 'pointer'}}>
                    <img
                    className="col-img-CreatePromotions background-pink"
                    src={Icon}
                    alt="icon"
                    />
                    <p style={{ marginTop: "5px" }}>Salud y Belleza</p>
                </Col>
                <Col xs={8} sm={7} md={7} lg={4} xl={4} 
                    className="padding-col-CreatePromotions segments-text-CreatePromotions"
                    onClick={() => handleType('travel')}
                    style={{opacity: type === 'travel' ? 1 : 0.25, cursor: 'pointer'}}>
                    <img
                    className="col-img-CreatePromotions background-yellow"
                    src={Plane}
                    alt="plane"
                    />
                    <p style={{ marginTop: "5px" }}>Panoramas</p>
                </Col>
                <Col xs={8} sm={7} md={7} lg={4} xl={4} 
                    className="padding-col-CreatePromotions segments-text-CreatePromotions"
                    onClick={() => handleType('other')}
                    style={{opacity: type === 'other' ? 1 : 0.25, cursor: 'pointer'}}>
                    <img
                    className="col-img-CreatePromotions background-pink"
                    src={ButtonIcon}
                    alt="button"
                    />
                    <p style={{ marginTop: "5px" }}>Otros</p>
                </Col>
                </Row> */}
        </Form.Item>
      </div>
    );
}

export default CategoryBoxForm