import React from "react";
import styles from "./index.module.css";

export default function index({ title = "Formulario", children }) {
  return (
    <section className={styles.formContainer}>
      <h3 className={styles.formTitle}>{title}</h3>
      {children}
    </section>
  );
}
