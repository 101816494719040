import React from "react";
import { Modal, Button, notification } from "antd";
import { CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { DELETE_SEGMENT } from "../../Graphql/Mutations";
import { REFRESH_TOKEN } from "../../../../../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import { setAccessToken } from "../../../../../../Helpers/auth";
import "./index.css";

const DeleteSegmentsModal = ({ visible, onCancel, segmentsQuery, segment, usersByCompanyQuery }) => {

  const [refreshToken] = useMutation(REFRESH_TOKEN)
  const [deleteSegmentMutation, {loading}] = useMutation(DELETE_SEGMENT, {
    variables:{
      id: segment._id
    }
  })

  const handleDeleteSegment = () => {
      refreshToken().then(res => {
          setAccessToken(res.data.refreshToken.token)
          deleteSegmentMutation().then(() => {
              notification.success({
                  message: `Éxito`,
                  description:
                    'El segmento ha sido eliminado exitósamente',
                  placement: "bottomRight",
                });
                onCancel()
                segmentsQuery()
                usersByCompanyQuery()
          }).catch(e => {
            notification.error({
              message: `Error`,
              description:
                'Hubo un error al intentar eliminar el segmento, por favor inténtalo de nuevo.',
              placement: "bottomRight",
            });
          })
      })
  }
  return (
    <>
      <Modal
        className="DeleteSegmentsModal"
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ top: 50 }}
        closeIcon={<CloseOutlined />}
      >
        <h1 className="userModal-h1">Eliminar segmento</h1>

        <div className="DeleteSegmentsModal-content">
          <h3 className="DeleteSegmentsModal-h3">
            ¿Estas seguro que quieres eliminar este segmento?
          </h3>
          <p>
            {" "}
            <WarningOutlined style={{ marginRight: "10px" }} />
            Nota: ten en cuenta que se borrar en los usuarios asociados el
            segmento que deberá ser editado
          </p>
          <Button
            className="CreateAndEditUserModal-Button"
            type="button"
            htmlType="submit"
            onClick={() => handleDeleteSegment()}
            loading={loading}
          >
            Eliminar
          </Button>
          <Button
            className="CreateAndEditUserModal-Button"
            type="button"
            htmlType="submit"
          >
            Cancelar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteSegmentsModal;
