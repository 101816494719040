import React from "react";
import styles from "../index.module.css";
import { ResponsiveLine } from "@nivo/line";
import InfoIcon from "../../../../Assets/info-icon.svg";
import moment from "moment";

const PromotionsStatusTimeline = ({ data }) => {
  const activePromotions = data?.promotions?.promotionStatusHistory?.filter(
    (item) => item?.status === "active"
  );
  const inactivePromotions = data?.promotions?.promotionStatusHistory?.filter(
    (item) => item?.status === "inactive"
  );
  const expiredPromotions = data?.promotions?.promotionStatusHistory?.filter(
    (item) => item?.status === "expired"
  );

  const dataChart = [
    {
      id: "Activo",
      color: "#E4056F",
      data: activePromotions?.map((item) => {
        return {
          x: moment(item?.date).format("MMM"),
          y: item?.count,
        };
      }),
    },
    {
      id: "Inactivo",
      color: "#E4056F",
      data: inactivePromotions?.map((item) => {
        return {
          x: moment(item?.date).format("MMM"),
          y: item?.count,
        };
      }),
    },
    {
      id: "Vencido",
      color: "#E4056F",
      data: expiredPromotions?.map((item) => {
        return {
          x: moment(item?.date).format("MMM"),
          y: item?.count,
        };
      }),
    },
  ].reverse();

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3>Estatus de promociones en el tiempo</h3>
        <img src={InfoIcon} alt="" />
      </header>
      <div style={{ height: "80%", width: "100%" }}>
        <ResponsiveLine
          data={dataChart}
          margin={{
            top: 10,
            right: 40,
            bottom: 50,
            left: 50,
          }}
          curve="monotoneX"
          colors={["#49BA35", "#FFC700", "#FF0000"].reverse()}
          xScale={{
            type: "point",
          }}
          yScale={{
            type: "linear",
            stacked: true,
            min: "auto",
            max: "auto",
          }}
          stacked={true}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 4,
            tickPadding: 5,
            tickRotation: 0,
          }}
          dotSize={10}
          dotColor="inherit:darker(0.3)"
          dotBorderWidth={2}
          dotBorderColor="#ffffff"
          enableDotLabel={true}
          dotLabel="y"
          dotLabelYOffset={-12}
          animate={true}
          isInteractive={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[
            {
              anchor: "bottom-left",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 50,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </section>
  );
};

export default PromotionsStatusTimeline;
