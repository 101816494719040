import React from "react";
import styles from "./index.module.css";
import UserIcon from "../../Assets/register-icon.svg";

const RegisterHeader = () => {
  return (
    <header className={styles.header}>
      <img src={UserIcon} alt="" className={styles.headerIcon} />
      <h2 className={styles.headerTitle}>Regístrate</h2>
    </header>
  );
};

export default RegisterHeader;
