import React, { useState } from "react";
import styles from "./index.module.css";
import { Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AddCommpanyModal from "../AddCompanyModal";
import AddAlliesModal from "../AddAlliesModal";

const UpdatePasswordModal = ({ visible, onCancel, userEmail }) => {
  const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
  const [isAlliesModalVisible, setIsAlliesModalVisible] = useState(false);

  return (
    <Modal
      className={styles.container}
      visible={visible}
      closable
      closeIcon={<CloseOutlined style={{ display: "none" }} />}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ backgroundColor: "#fff", borderRadius: "16px" }}
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Agrega tus aliados</h2>
        <CloseOutlined className={styles.headerCloseIcon} />
      </header>

      <section className={styles.content}>
        <article className={styles.contentItem}>
          <h3 className={styles.contentItemTitle}>Tus aliados</h3>
          <p className={styles.contentItemDescription}>
            Indicanos cuales son tus aliados para poder mostrarte las
            promociones que tienes disponibles.
          </p>
        </article>
        <article className={styles.contentItem}>
          <h3 className={styles.contentItemTitle}>Tu empresa</h3>
          <p className={styles.contentItemDescription}>
            Ingresa a los beneficios que tienes como empleado ingresando tu
            código validador de tu empresa.
          </p>
        </article>
      </section>

      <div className={styles.buttons}>
        <Button
          className={styles.button}
          onClick={() => setIsAlliesModalVisible(true)}
        >
          Agregar Aliados
        </Button>
        <Button
          className={styles.button}
          onClick={() => setIsCompanyModalVisible(true)}
        >
          Agregar Empresa
        </Button>
      </div>

      <AddCommpanyModal
        visible={isCompanyModalVisible}
        onCancel={() => setIsCompanyModalVisible(false)}
        userEmail={userEmail}
      />
      <AddAlliesModal
        visible={isAlliesModalVisible}
        onCancel={() => setIsAlliesModalVisible(false)}
      />
    </Modal>
  );
};

export default UpdatePasswordModal;
