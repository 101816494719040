import React from "react";
import styles from "./index.module.css";
import { Col, Row, Select } from "antd";
import { useHistory } from "react-router-dom";

import openIcon from "../../Assets/CompanyIcons/open-menu-icon.svg";
import closeIcon from "../../Assets/CompanyIcons/close-menu-icon.svg";
import loiaRoseLogo from "../../../../Assets/loia-rose-logo.svg";

const CompanyNavbar = ({
  role,
  handleChangeCompany,
  companies,
  visible,
  setVisible,
  companyName = "empresa",
}) => {
  const history = useHistory();
  return (
    <Row align="middle" justify="space-between " className={styles.container}>
      <Col xs={14} sm={8} className={styles.logoCol}>
        <img
          src={loiaRoseLogo}
          alt=""
          className={styles.logoImage}
          onClick={() => history.push("/")}
        />
      </Col>
      <div className={styles.content}>
        <h2 className={styles.contentTitle}>
          Bienvenido,
          {role === "SuperAdmin" ? (
            <Select
              className={styles.contentSelectCompany}
              type="select"
              defaultValue={JSON.parse(localStorage.getItem("companySelected"))}
              onChange={(e) => handleChangeCompany(e)}
            >
              <Select.Option key={"all"} value={"all"}>
                SuperAdmin
              </Select.Option>
              {companies
                ? companies?.companies?.data?.map((company) => {
                    return (
                      <Select.Option key={company._id} value={company._id}>
                        {company.name}
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
          ) : (
            " " + companyName + "!"
          )}
        </h2>
        <img
          src={visible ? closeIcon : openIcon}
          alt=""
          className={styles.menuIcon}
          onClick={() => setVisible(!visible)}
        />
      </div>
    </Row>
  );
};

export default CompanyNavbar;
