import React from "react";
import styles from "./index.module.css";
import { Form, Select } from "antd";
import { useQuery } from "@apollo/client";
import { CATEGORIES } from "../../../../../../Graphql/Queries";

const { Option } = Select;

const StepOne = ({ registerData, setRegisterData }) => {
  const { loading, data: categories } = useQuery(CATEGORIES);
  return (
    <Form className={styles.form}>
      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="companyName">
          Nombre de la empresa
        </label>
        <input
          className={styles.formItemInput}
          name="companyName"
          autoComplete="off"
          spellCheck="false"
          type="text"
          placeholder="Nombre de la empresa..."
          value={registerData.company.name}
          onChange={(e) =>
            setRegisterData({
              ...registerData,
              company: { ...registerData.company, name: e.target.value },
            })
          }
        />
      </div>
      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="companyPhone">
          Número de telefono
        </label>
        <input
          className={styles.formItemInput}
          name="companyPhone"
          autoComplete="off"
          type="tel"
          maxLength={15}
          spellCheck="false"
          placeholder="Número de telefono..."
          value={registerData.company.phone}
          onChange={(e) =>
            setRegisterData({
              ...registerData,
              company: { ...registerData.company, phone: e.target.value },
            })
          }
        />
      </div>
      <div className={styles.formItem}>
        <label className={styles.formItemLabel} htmlFor="companyCategory">
          Rubro de la empresa
        </label>
        <Select
          className={styles.formItemInputSelect}
          name="companyCategory"
          placeholder="Rubro de la empresa..."
          onChange={(e) =>
            setRegisterData({
              ...registerData,
              company: { ...registerData.company, category: e },
            })
          }
          defaultValue={registerData.company.category}
        >
          <Option key={"none"} value={""} style={{ color: "#ccc" }} disabled>
            <span style={{ color: "#ccc" }}>Seleccione un rubro</span>
          </Option>
          ;
          {!loading &&
            categories?.categories?.length > 0 &&
            categories?.categories?.map((category) => {
              return (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              );
            })}
        </Select>
      </div>
    </Form>
  );
};

export default StepOne;
