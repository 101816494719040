import gql from "graphql-tag";

const COMPANIES = gql`
  query {
    companies {
      _id
      name
    }
  }
`;

const PROFILE = gql`
  query profile {
    profile {
      _id
      firstName
      lastName
      email
      birthDate
      gender
      preferences {
        notifications {
          promotionsAboutToExpire
          newPromotions
        }
      }
      company {
        _id
        name
        logo
      }
      alliedCompanies {
        _id
        name
        logo
      }
    }
  }
`;

export { COMPANIES, PROFILE };
