import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route  } from 'react-router-dom'
import Screens from './Screens'
import { useQuery, useMutation } from '@apollo/client'
import { REFRESH_TOKEN } from './Graphql/Mutations'; 
import { PROFILE } from './Graphql/Queries';
import { setAccessToken, setUser } from './Helpers/auth'; 
import { Row, Spin, ConfigProvider } from 'antd'
import en_ES from 'antd/lib/locale-provider/es_ES';
import moment from 'moment';
import 'moment/locale/es'
import ReactGA from 'react-ga4';
moment.locale('es')

export default function App() {
  const [refreshToken, { loading }] = useMutation(REFRESH_TOKEN)
  const { data: dataProfile } = useQuery(PROFILE)

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  useEffect(() => {
      refreshToken().then(res => {
        setAccessToken(res.data.refreshToken.token)
      }).catch(() => {})
  },[refreshToken])

  useEffect(() => {
    if(dataProfile) {
      setUser(dataProfile.profile)
    }
  },[dataProfile])

  if(loading) return (
    <Row justify="center" style={{marginTop: 300, marginBottom: 50}}>
      <Spin size="large"/>
    </Row>
  )
  return (
    <>
    <ConfigProvider locale={en_ES}>
      <Router>
        <Route>{Screens}</Route>
      </Router>
    </ConfigProvider>
      
    </>
  );
}