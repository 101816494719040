import React from "react";
import { Modal, Button, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { DELETE_EMPLOYEE } from "../../Graphql/Mutations";
import { REFRESH_TOKEN } from "../../../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import { getUser, setAccessToken } from "../../../../Helpers/auth";
import styles from './index.module.css'
import "./index.css";

const DeleteEmployeeModal = ({ visible, onCancel, companyData, employee }) => {

  const [deleteEmployeeMutation, {loading: loadingDeleteEmployee}] = useMutation(DELETE_EMPLOYEE,{
    variables:{
      input: employee.email,
      companyId: getUser().company ? getUser().company._id : JSON.parse(localStorage.getItem('companySelected'))
    }
  })
  const [refreshToken] = useMutation(REFRESH_TOKEN)

  const deleteEmployee = () => {
    refreshToken().then(res => {
        setAccessToken(res.data.refreshToken.token)
        deleteEmployeeMutation().then(() => {
            notification.success({
                message: `Éxito`,
                description:
                  'El empleado ha sido eliminado exitósamente',
                placement: "bottomRight",
              });
              onCancel()
              companyData()
        }).catch(e => {
            notification.error({
              message: `Error`,
              description:
                'Hubo un error al eliminar el empleado, por favor inténtalo de nuevo.',
              placement: "bottomRight",
            });
        })
    })
}

  return (
    <>
      <Modal
            className={styles.container}
            visible={visible}
            footer={null}
            bodyStyle={{ paddingBottom: "2rem" }}
            onCancel={onCancel}
            closeIcon={<CloseOutlined className={styles.closeIcon} />}
        >
            <section className={styles.content}>
                <header className={styles.header}>
                <h3 className={styles.headerTitle}>
                    Eliminar empleado
                </h3>
                <span>¿Estás seguro/a que quieres eliminar a este empleado?</span>
                </header>

                
                <footer className={styles.footer}>
                    <Button
                        className={`${styles.footerButton} ${styles.footerGhostButton}`}
                        onClick={() => onCancel()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        loading={loadingDeleteEmployee}
                        className={styles.footerButton}
                        onClick={() => deleteEmployee()}
                    >
                        Eliminar
                    </Button>
                </footer>
            </section>
        </Modal>
    </>
  );
};

export default DeleteEmployeeModal;
