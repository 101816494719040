import gql from "graphql-tag";

const COMPANY = gql`
query company($input: FindCompany!){
    company(input: $input){
      _id
      name
      phone
      logo
      employees{
        firstName
        lastName
        email
        companyRole{
          _id
          name
        }
      }
    }
  }
`;

const USERS_BY_COMPANY = gql`
query usersByCompany($roles: [String!]!, $companyId: String){
    usersByCompany(roles: $roles, companyId: $companyId){
      _id
      firstName
      lastName
      email
      role
      company{
        _id
      }
      phone
      
    }
  }
`;

const SEGMENTS = gql`
query segments($companyId: String!){
  segments(companyId: $companyId){
    _id
    name
    company{
      _id
    }
  }
}
`;


export { COMPANY, USERS_BY_COMPANY, SEGMENTS }