import React from "react";
import styles from "./index.module.css";
import { Button } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import parseArrayOfDays from "../../../../Helpers/weekDaysToSpanish";
import PromotionReviews from "../PromotionReviews";
import { Link } from "react-router-dom";

import Icon from "../../../Icon";
import * as dayjs from "dayjs";
import "dayjs/locale/es";
import {
  checkLabel,
  colorPromotion,
} from "../../../../Helpers/promotionsHelper";
dayjs.locale("es");

const parseArrayOfCommunes = (communes) => {
  let result = "";
  communes.map((commune, index) => {
    if (index === 0) result = result + commune;
    else if (index + 1 !== communes.length) result = result + ", " + commune;
    else result = result + " y " + commune;
    return true;
  });

  return result;
};

const PromotionInfo = ({
  promotion,
  isLoged,
  checkMyComment,
  checkMyCommentData,
  loadingCheckMyComment,
}) => {
  const promotionTypeLabel = checkLabel(promotion?.type?.name);
  const promotionTypeColor = colorPromotion(promotion?.type?.name);

  return (
    <section className={styles.container}>
      <section className={styles.info}>
        <h2 className={styles.infoTitle}>{promotion?.localName}</h2>
        <h2 className={styles.infoPromotionName}>{promotion?.name}</h2>

        {window.location.pathname !== "/companyReport" && (
          <PromotionReviews
            promotion={promotion}
            promoId={promotion?._id}
            isLoged={isLoged}
            checkMyComment={checkMyComment}
            loadingCheckMyComment={loadingCheckMyComment}
            checkMyCommentData={checkMyCommentData}
            key={promotion?._id}
          />
        )}

        <div
          className={`${styles.promotionType} ${styles.promotionTypeLeftSide}`}
          style={{
            backgroundColor: promotionTypeColor + "4D",
            color: promotionTypeColor,
          }}
        >
          <Icon
            type={promotion?.type?.name}
            className={styles.promotionTypeIcon}
          />
          <strong className={styles.promotionTypeLabel}>
            {promotionTypeLabel}
          </strong>
        </div>

        {promotion?.communes?.length > 0 && (
          <p className={styles.infoCommunes}>
            <EnvironmentOutlined />
            {`Solo disponible en ${
              promotion?.communes
                ? parseArrayOfCommunes(promotion.communes)
                : null
            }`}
          </p>
        )}

        {promotion?.description && (
          <div
            className={styles.infoDescription}
            dangerouslySetInnerHTML={{ __html: promotion?.description }}
          />
        )}

        {promotion?.conditions && (
          <article className={styles.infoConditions}>
            <h3 className={styles.infoConditionsTitle}>¿Cómo usar?</h3>
            <div
              className={styles.infoConditionsDescription}
              dangerouslySetInnerHTML={{ __html: promotion?.conditions }}
            />
          </article>
        )}
      </section>

      <section className={styles.info}>
        <div
          className={`${styles.promotionType} ${styles.promotionTypeRightSide}`}
          style={{
            backgroundColor: promotionTypeColor + "4D",
            color: promotionTypeColor,
          }}
        >
          <Icon
            type={promotion?.type?.name}
            className={styles.promotionTypeIcon}
          />
          <strong className={styles.promotionTypeLabel}>
            {promotionTypeLabel}
          </strong>
        </div>
        <div className={styles.dates}>
          <h3 className={styles.datesTitle}>Vigencia</h3>
          <p className={styles.datesDescription}>
            Hasta el {dayjs(promotion.dueDate).format("DD MMMM YYYY", "es")}
          </p>
          <h3 className={styles.datesTitle}>Días</h3>
          <p className={styles.datesDescription}>
            {promotion.weekDays ? parseArrayOfDays(promotion.weekDays) : null}
          </p>
          <Button
            href={promotion.benefitURL}
            target="_blank"
            className={styles.buttonBenefitURL}
            shape="round"
            size="middle"
            type="primary"
          >
            <p>¡Conoce más!</p>
          </Button>
        </div>
        {!isLoged && (
          <p className={styles.infoReviewsMessage}>
            <Link className={styles.infoReviewsMessageLink} to="/register">
              Registrate
            </Link>
            o
            <Link className={styles.infoReviewsMessageLink} to="/login">
              inicia sesión
            </Link>
            para ver los comentarios
          </p>
        )}
      </section>
    </section>
  );
};

export default PromotionInfo;
